<div class="summary-agent-fees-container" *ngIf="showFee" [ngClass]="{'hidden': !loginAs || (stepService.currentStep == 3 && !isBookTerminated)}">
    <strong class="text--lg light d-block" translate>Summary of Agent Booking Fees</strong>
    <a *ngIf="checkPageSize() <= 575" data-toggle="collapse" class="float-right collapsed" href="#collapsePrice" role="button" aria-expanded="false" aria-controls="collapsePrice">
        <img aria-hidden="true" alt="" src="/assets/images/icons/chevron-down-black.svg"> </a>
    <div [ngClass]="{'show': size > 575}" class="mb-2 collapse" id="collapsePrice">
        <div class="summary clearfix" >
            <p *ngIf="flightFees.points > 0">
                <strong>
                    <span translate>Agent Flight Booking Fee</span>
                    <span class="swap-price"> {{internalFlightFees.points.toLocaleString(translateService.currentLang)}} pts</span>
                </strong>
                <span class="sub-price">(<price-component [price]="internalFlightFees.price.toString()" [currency]="internalFlightFees.currencyCode" ></price-component>)</span>
            </p>
            <p *ngIf="hotelFees.points > 0">
                <strong>
                    <span translate>Agent Hotel Booking Fee</span>
                    <span class="swap-price">{{internalHotelFees.points.toLocaleString(translateService.currentLang)}} pts</span>
                </strong>
                <span class="sub-price">(<price-component [price]="internalHotelFees.price.toString()" [currency]="internalHotelFees.currencyCode" ></price-component>)</span>
            </p>
            <p *ngIf="carFees.points > 0">
                <strong>
                    <span translate>Agent Car Booking Fee</span>
                    <span class="swap-price">{{internalCarFees.points.toLocaleString(translateService.currentLang)}} pts</span>
                </strong>
                <span class="sub-price">(<price-component [price]="internalCarFees.price.toString()" [currency]="internalCarFees.currencyCode" ></price-component>)</span>
            </p>
            <p>
                <strong>
                    <span translate>Total</span>:
                    <span class="swap-price">{{totals.points.toLocaleString(translateService.currentLang)}} pts</span>
                </strong>
                <span class="sub-price">(<price-component [price]="totals.price.toString()" [currency]="totals.currencyCode" ></price-component>)</span>
            </p>
        </div>
    </div>
    <hr aria-hidden="true">
    <div class="text-center">
        <strong class="font-m mb-3" translate>Total Fees</strong>
        <div *ngIf="getStep() == 0" aria-atomic="true" aria-live="assertive">
            <div class="total-points">{{totals.points.toLocaleString(translateService.currentLang)}} pts</div>
            <span class="total-taxes-in-dollar d-block ">(<span translate>value of </span><price-component [price]="totals.price.toString()" [currency]="totals.currencyCode" ></price-component>)</span>
        </div>
        <div *ngIf="getStep() > 0" aria-atomic="true" aria-live="assertive">
            <div *ngIf="feePricePaymentEnabled">
                <div class="total-points"><price-component [price]="feePaymentValue.toString()" [currency]="" ></price-component></div>
                <span class="total-taxes-in-dollar d-block ">({{totals.currencyCode}} {{'Payment' | translate}})</span>
            </div>
            <div *ngIf="!feePricePaymentEnabled">
                <div class="total-points">{{feePaymentValue.toLocaleString(translateService.currentLang)}}</div>
                <span class="total-taxes-in-dollar d-block ">({{'Points' | translate}} {{'Payment' | translate}})</span>
            </div>
        </div>
        <br>
        <p class="d-block summary-description">
            <span translate>Fees associated with phone booking, changes to itineraries or cancellations are non-refundable</span>.
        </p>
    </div>
</div>
