import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable()
export class ItineraryService {

    private _itineraryList: IItinerary;

    constructor(private router: Router,
                private http: HttpClient) {
    }

    getItinerary(reference = null, booking_item = null, type = null) {

        //Use this if we want to send params from url
        let payload =Object.assign({},
            reference && {reference}, booking_item && {booking_item}, type && {type}
        );

        if(reference && booking_item && type){
            return this.http.get<any>('user/bookings', {params: {reference: reference, booking_item: booking_item, type: type}}).pipe(map(data => {
                this.itineraryList = data.data;
                return data.data
            })).catch((err) => {
                return throwError(err)
            });
        } else {
            return this.http.get<any>('user/bookings').pipe(map(data => {
                this.itineraryList = data.data;
                return data.data
            })).catch((err) => {
                return throwError(err)
            });

        }


    }

    sendItinerary(reference:string, booking_item:string, email: string) {

        return this.http.post('user/bookings/email',{reference: reference, booking_item: booking_item, email: email}).pipe(map(data => {
            return data
        })).catch((err) => {
            return throwError(err)
        });


    }

    getDetails(){
        // return this.http.post<any>('flight/search', payload).pipe(map(data => {
        //     this.lastSearchFlight = _.extend(data.data, {adults: payload.adults, flight_mode: payload.mode, form_data: payload});
        //     return this.lastSearchFlight
        // })).catch((err) => {
        //     return throwError(err)
        // });
    }

    get itineraryList(): IItinerary {
        return this._itineraryList;
    }

    set itineraryList(value: IItinerary) {
        this._itineraryList = value;
    }

}
export interface IBookingItems{
    flight:string
    hotel:string
    car:string
}
export interface IItinerary {

    booking_id: number
    booking_items: IBookingItems
    booking_status: string
    status: string
    cash_currency: string
    cash_total: string
    conversion_rate: string
    destination_city: string
    end_date: string
    id: number
    item_description: string
    item_name: string
    origin_city: string
    point_total: number
    product_name: string
    redeemed_date: string
    updated_at: string
    reference: string
    items: any[]
    start_date: string
    statement_credit: string
    status_changed_date: string
    supplier_name: string
    type: string

}
