import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[autofocus]'
})
export class Autofocus implements AfterViewInit {
    public constructor(private el: ElementRef) {

    }

    public ngAfterViewInit() {
        setTimeout(() => {

            this.el.nativeElement.focus();

        }, 100);
    }
}
