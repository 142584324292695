import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AlertService} from './alerts.service';

const $ = require("jquery");
const errorIcon = "/assets/images/icons/error-red.svg";

@Component({
    selector: 'alert',
    templateUrl: 'alerts.component.html'
})

export class AlertComponent implements OnInit {
    @ViewChild('alertContainer', {static: false}) el:ElementRef;

    constructor(private alertService: AlertService) {}

    close = (currentAlert: any) => {
        $(currentAlert).alert('close')
    };

    ngOnInit() {

        this.alertService.getMessage().subscribe(alert => {
            let currentAlert = $(`
                        <div class="alert alert-${alert.type} alert-dismissible fade" role="alert">
                        <div class="d-flex align-items-center">
                          <span class="p-3 icon-wrapper"></span>
                          <span class="p-3">${alert.message}</span>
                        </div>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span class="custom-close" aria-hidden="true"></span></button></div>`);
            // $(currentAlert).find('a').bind('click', () => {
            //     $(currentAlert).alert('close')
            // });
            $(this.el.nativeElement).append(currentAlert);
            setTimeout(() => {
                currentAlert.addClass("show")
            });
            setTimeout(() => { $(currentAlert).alert('close'); }, 15000);
        });
    }
}
