import {UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {Directive} from '@angular/core';

@Directive({
    selector: '[emailvalidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: EmailValidator,
            multi: true
        }
    ]
})
export class EmailValidator implements Validator {
    validator: ValidatorFn;
    constructor() {
        this.validator = this.emailValidator();
    }
    validate(c: UntypedFormControl) {
        return this.validator(c);
    }

    emailValidator(): ValidatorFn {
        let patternString = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";

        return (c: UntypedFormControl) => {
            let pattern = new RegExp(patternString);
            let isValid = pattern.test(c.value);

            if (isValid) {
                return null;
            } else {
                return {
                    emailvalidator: {
                        valid: false
                    }
                };
            }
        }
    }
}
