<div role="navigation" title="main-header" id="travel-header"  >
  <div *ngIf="loginAsBanner" id="login-in-as-container" class="logged-in-as-banner text-center medium py-2">{{'You are logged in on behalf of' | translate}} {{loggedInMemberName}}</div>
  <div *ngIf="showNotification" class="notification-wrapper">
    <div class="container">
      <div class="d-flex">
        <div class="d-flex">
          <span class="pr-3"><img alt="Warning" width="18px" src="/assets/images/icons/status_warning_onamber.svg"></span>
          <span class="text--base">
            <span style="font-family: 'univers-next-medium', sans-serif" translate>Attention Cash Rewards credit cardholders.</span><span translate> Currently, your Cash Rewards are referenced throughout the site as “Points”. Cash Rewards and Points can be viewed interchangeably - One Point is equal to one Cash Reward. The site will be updated in the coming weeks and we apologize for any confusion this may cause.</span>
          </span>
        </div>
        <div class="ml-auto">
          <span role="button" (keydown.space)="showNotification = false" (click)="showNotification = false"><img alt="Close" class="close-btn" src="/assets/images/icons/close-black.svg"> <span class="sr-only" translate>Close</span></span>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="top-header">
      <div class="d-flex align-items-center h-100">
        <img width="90" height="20" alt="HSBC logo" src="/assets/images/hsbc-brand-logo.svg" class="logo">
        <div class="ml-auto h-100 d-md-flex d-none">
          <div id="profile-button">
            <button id="profile-button-toggle" class="h-100 button hidden-xs-down visible-sm-up btn-no-border" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown">
              <div class="d-flex justify-content-between align-items-center h-100 px-4">
                <div class="greeting d-flex">
                <span class="regular mr-3">
                  My account
                </span>
                </div>
                <img alt="" [src]="creditCard" class="mastercard img-fluid">
                <div class="icon chevron-icon"></div>
              </div>
            </button>
            <div #profilemenu class="dropdown-menu py-0">
              <div class="profile-info">
                <h2><span class="medium">{{authService.user.account_members[0].member.first_name}} {{authService.user.account_members[0].member.last_name}}</span></h2>
                <div class="font-s">{{authService.user.account_type.translated_name['en-US']}}</div>
                <div class="balance"> <span>{{ formatPoints(authService.user.last_balance)}}</span> <span class="font-xs"> <pts-currency></pts-currency></span></div>
                <img alt=""  [src]="creditCard" class="mastercard img-fluid">
                <hr>
                <div class="my-profile d-flex align-items-center py-2">
                  <div class="icon mr-3"></div>
                  <a role="link" tabindex="0" (click)="redirectToPodium('account')" class="navlink" translate>Account</a>
                </div>
                <div class="my-card-benefits d-flex align-items-center py-2">
                  <div class="icon mr-3"></div>
                  <a role="link" tabindex="0" (click)="redirectToPodium('my-card-benefits')" class="navlink" translate>My Card Benefits</a>
                </div>
                <div class="my-wishlist d-flex align-items-center py-2">
                  <div class="icon mr-3"></div>
                  <a role="link" tabindex="0" (click)="redirectToPodium('account/wishlist')" class="navlink" translate>My Wishlist</a>
                </div>
                <div class="sign-out d-flex align-items-center py-2">
                  <div class="icon mr-3"></div>
                  <a role="link" tabindex="0" (click)="logout()" class="navlink" translate>Log Out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="mobilemenu" class="mobile-view ml-auto d-flex d-md-none align-items-center justify-content-end">
          <div *ngIf="menuDisplayed" class="close-icon selectable-element" tabindex="0" role="button" (keydown.enter)="closeMenu()" (click)="closeMenu()"><span class="sr-only" translate>Close menu</span></div>
          <div *ngIf="!menuDisplayed" class="profile-icon selectable-element" tabindex="0" role="button" (keydown.enter)="openMenu('profile')" (click)="openMenu('profile')"><span class="sr-only" translate>Open profile menu</span></div>
          <div *ngIf="!menuDisplayed" class="menu-icon selectable-element" tabindex="0" role="button" (keydown.enter)="openMenu('menu')" (click)="openMenu('menu')" ><span class="sr-only" translate>Open navigation menu</span></div>
          <div *ngIf="hamburgerMenu" class="mobile-menu mobile-full-screen">
            <div class="scroll">
              <div class="d-flex flex-column justify-content-start container">
                <div class="content">
                  <div autofocus *ngIf="currentMenu">
                    <div class="back selectable-element">
                      <h5 class="bold text-right" role="button" tabindex="0" (keydown.enter)="goBack($event)" (click)="goBack($event)">{{ currentMenu.title }}</h5>
                    </div>
                    <hr>
                    <div class="menu" *ngFor="let menu of currentMenu.items let i = index">
                      <div class="item">
                        <h5 role="link" tabindex="0" [attr.title]="menu.description" class="bold selectable-element" (keydown.enter)="goTo(menu.route,$event)" (click)="goTo(menu.route,$event)">{{ menu.title }}</h5>
                      </div>
                      <hr>
                    </div>
                  </div>
                  <div *ngIf="!currentMenu">
                    <div class="item">
                      <h5 class="bold selectable-element" role="link" tabindex="0" (keydown.enter)="selectMenu('travel',$event)" (click)="selectMenu('travel',$event)" translate>Travel
                        <span class="sr-only">Current page</span>
                      </h5>
                    </div>
                    <hr>
                    <div class="item">
                      <h5 class="bold selectable-element" role="link" tabindex="0" (keydown.enter)="selectMenu('shop',$event)" (click)="selectMenu('shop',$event)" translate>Shop</h5>
                    </div>
                    <hr>
                    <div class="item">
                      <h5 class="bold selectable-element" role="link" tabindex="0"(keydown.enter)="redirectToPodium('amazon')" (click)="redirectToPodium('amazon')" translate>Amazon</h5>
                    </div>
                    <hr>
                    <div class="item">
                      <h5 class="bold selectable-element" role="link" tabindex="0" (keydown.enter)="selectMenu('cashback',$event)" (click)="selectMenu('cashback',$event)" translate>Cash Back</h5>
                    </div>
                    <hr>
                    <div class="item" *ngIf="authService.user.account_type.reference_identifier == 'HSBCPREM' || authService.user.account_type.reference_identifier == 'HSBCPWE'">
                      <h5 class="bold selectable-element" role="link" tabindex="0" (keydown.enter)="redirectToPodium('travel-miles')" (click)="redirectToPodium('travel-miles')" translate>Reward for Miles</h5>
                    </div>
                    <hr *ngIf="authService.user.account_type.reference_identifier == 'HSBCPREM' || authService.user.account_type.reference_identifier == 'HSBCPWE'">
                    <div class="item">
                      <h5 class="bold selectable-element" role="link" tabindex="0" (keydown.enter)="selectMenu('paywithpoints',$event)" (click)="selectMenu('paywithpoints',$event)" translate>Pay With Points</h5>
                    </div>
                    <hr>
                    <div class="item">
                      <h5 class="bold selectable-element" role="link" tabindex="0" (keydown.enter)="selectMenu('globaloffers',$event)" (click)="selectMenu('globaloffers',$event)" translate>Global Offers</h5>
                    </div>
                    <hr>
                  </div>
                </div>
                <div class="menu-footer mt-5 mb-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex flex-column">
                        <div class="links d-flex flex-column align-items-start">
                          <a class="selectable-element" tabindex="0" (keydown.enter)="redirectToPodium('faq','_blank')"  (click)="redirectToPodium('faq','_blank')" translate>FAQ</a>
                          <a class="selectable-element" tabindex="0" (keydown.enter)="redirectToPodium('customer-support')" (click)="redirectToPodium('customer-support')">Customer Support</a>
                          <a class="selectable-element" tabindex="0" (keydown.enter)="redirectToPodium('program-rules')" (click)="redirectToPodium('program-rules')" translate>Program Rules</a>
                          <a class="selectable-element" rel="noopener noreferrer" href="https://www.us.hsbc.com/site-terms-and-conditions" target="_blank" translate>HSBC Terms and Conditions</a>
                          <a  class="primary-white selectable-element" rel="noopener noreferrer" href="https://www.us.hsbc.com/online-privacy-statement" target="_blank" translate>Privacy</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="profileMenu" class="mobile-profile mobile-full-screen">
            <div class="scroll">
              <div class="h-100 d-flex flex-column justify-content-between container">
                <div class="content">
                  <div class="menu-item back">
                    <div role="button" tabindex="0" (click)="closeMenu()" class="bold w-100 text-right"><strong translate>Customer Profile</strong></div>
                  </div>
                  <div class="info text-center my-5">
                    <img width="170" alt="" [src]="creditCard" class="mastercard mb-5 img-fluid">
                    <h5 class="bold mb-2 mt-2"><strong>{{authService.user.account_members[0].member.first_name}} {{authService.user.account_members[0].member.last_name}}</strong></h5>
                    <span class="card-name">{{authService.user.account_type.translated_name['en-US']}}</span>
                  </div>
                  <a role="link" tabindex="0" (click)="redirectToPodium('account')" class="menu-item my-profile">
                    <img alt="" src="/assets/images/icons/user-outline-black.svg">
                    <div class="bold" translate>My Profile</div>
                  </a>
                  <a role="link" tabindex="0" (click)="redirectToPodium('my-card-benefits')" class="menu-item my-card-benefits">
                    <img alt="" src="/assets/images/icons/star-outline-black.svg">
                    <div class="bold" translate>My Card Benefits</div>
                  </a>
                  <a role="link" tabindex="0" (click)="redirectToPodium('account/wishlist')" class="menu-item my-card-benefits">
                    <img alt="" src="/assets/images/icons/health-outline-black.svg">
                    <div class="bold" translate>My Wishlist</div>
                  </a>
                  <a role="link" tabindex="0" (click)="logout()" class="menu-item sign-out">
                    <img alt="" src="/assets/images/icons/export-black.svg">
                    <div class="bold" translate>Log Out</div>
                  </a>
                </div>
                <div class="menu-footer mt-5 mb-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex flex-column">
                        <div class="links d-flex flex-column align-items-start">
                          <a role="link" tabindex="0" (click)="redirectToPodium('faq','_blank')" translate>FAQ</a>
                          <a role="link" tabindex="0" (click)="redirectToPodium('customer-support')">Customer Support</a>
                          <a role="link" tabindex="0"  (click)="redirectToPodium('program-rules')" translate>Program Rules</a>
                          <a rel="noopener noreferrer" role="link" tabindex="0" href="https://www.us.hsbc.com/site-terms-and-conditions" target="_blank" translate>HSBC Terms and Conditions</a>
                          <a class="primary-white" role="link" tabindex="0" rel="noopener noreferrer" href="https://www.us.hsbc.com/online-privacy-statement" target="_blank" translate>Privacy</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span>© {{year}}. HSBC | All rights reserved</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="sub-nav pl-15 pr-15 d-flex" title="header">
      <div class="d-flex align-items-center w-100">
        <a role="link" tabindex="0" (click)="redirectToPodium('')"  class="home-link">
          <span class="text--lg medium">HSBC Rewards</span>
        </a>
        <div class="p-2 ml-auto">
          <div *ngIf="authService.user" class="h1 mb-0"><span class="regular text--lg pts-wrapper">{{formatPoints(authService.user.last_balance) }} <span><pts-currency></pts-currency></span></span></div>
        </div>
      </div>
    </nav>
    <div class="custom-navbar d-none d-md-block">
      <div class="navigations">
        <ul class="navbar-nav">
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/" aria-current="page" translate>Travel</a>
          </li>
          <li class="nav-item">
            <a role="link" tabindex="0" data-toggle="dropdown" id="shop-menu-dropdown" aria-haspopup="true" aria-expanded="false">
              <span translate>Shop</span>
              <div aria-hidden="true" class="icon chevron-icon ml-2"></div>
            </a>
            <ul class="dropdown-menu shop-menu-dropdown" aria-labelledby="shop-menu-dropdown">
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('shop%3Fcatalog_slug=apple')">Apple</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('shop%3FproductType=merchandise%26catalog_slug=shop')">Merchandise</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('shop%3Fcatalog_slug=GIFT_CARD%26productType=egiftcard,giftcard%26category=Gift%20Cards%20>%20Physical%20Gift%20Cards')">Gift Cards</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('shop%3Fcatalog_slug=GIFT_CARD%26productType=egiftcard,giftcard%26category=Gift%20Cards%20>%20Digital%20Gift%20Cards')">eGift Cards</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a role="link" tabindex="0" (click)="redirectToPodium('amazon')" translate>Amazon</a>
          </li>
          <li class="nav-item">
            <a role="link" tabindex="0" data-toggle="dropdown" id="cashback-menu-dropdown" aria-haspopup="true" aria-expanded="false">
              <span translate>Cash Back</span>
              <div aria-hidden="true" class="icon chevron-icon ml-2"></div>
            </a>
            <ul class="dropdown-menu shop-menu-dropdown" aria-labelledby="cashback-menu-dropdown">
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('redeem/direct-deposit')">Direct Deposit</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('redeem/cash-back')">Statement Credit</a>
              </li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="authService.user.account_type.reference_identifier == 'HSBCPREM' || authService.user.account_type.reference_identifier == 'HSBCPWE'">
            <a role="link" tabindex="0" (click)="redirectToPodium('travel-miles')" translate>Rewards for Miles</a>
          </li>
          <li class="nav-item">
            <a role="link" tabindex="0" data-toggle="dropdown" id="paywithpoints-menu-dropdown" aria-haspopup="true" aria-expanded="false">
              <span translate>Pay With Points</span>
              <div aria-hidden="true" class="icon chevron-icon ml-2"></div>
            </a>
            <ul class="dropdown-menu shop-menu-dropdown" aria-labelledby="paywithpoints-menu-dropdown">
              <li *ngIf="authService.user.account_type.reference_identifier == 'HSBCPREM' || authService.user.account_type.reference_identifier == 'HSBCPWE'">
                <a class="dropdown-item" (click)="redirectToPodium('account/transactions')">Annual Fee Eraser</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="redirectToPodium('pay-with-rewards')">Pay with Rewards&reg;</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a role="link" tabindex="0" data-toggle="dropdown" id="global-offer-menu-dropdown" aria-haspopup="true" aria-expanded="false">
              <span translate>Global Offers</span>
              <div aria-hidden="true" class="icon chevron-icon ml-2"></div>
            </a>
            <ul class="dropdown-menu shop-menu-dropdown" aria-labelledby="global-offer-menu-dropdown">
              <li>
                <a class="dropdown-item" [title]="'Enjoy hidden-gem experiences and special-access events virtually and worldwide.'" (click)="redirectToPodium('global-offers/priceless-experiences')">Priceless™</a>
              </li>
              <li>
                <a class="dropdown-item" [title]="'Get exclusive offers from top brands in over 15 countries outside the U.S.'" (click)="redirectToPodium('global-offers/mastercard-travel-rewards')">Mastercard Travel Rewards®</a>
              </li>
              <li>
                <a class="dropdown-item" [title]="'Get benefits, amenities and upgrades, preferential treatment and premium travel offers from best-in-class companies across hotels, air travel, tours, cruises, car rentals and more.'" (click)="redirectToPodium('global-offers/mastercard-travel-lifestyle-services')">Mastercard Travel & Lifestyle Services®</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
<div [ngClass]="{'notification-padding': showNotification,'is-admin':authService.isAdmin()}" class="main-page" >
  <div [ngClass]="{'container-fluid': showFullScreen , 'container':!showFullScreen }"   role="main" >
    <spinner-component customClass="absolute-spinner" [ladda]="routeLoading"></spinner-component>
    <router-outlet *ngIf="!routeLoading" ></router-outlet>
  </div>
</div>
<footer id="travel-footer">
  <div class="primary-footer">
    <div class="container">
      <div class="row mb-lg-4">
        <div class="col footer-link pt-4">
          <ul class=" d-flex flex-column flex-lg-row col mb-0">
            <li>
              <a role="link"  tabindex="0" rel="noopener noreferrer" href="https://rewards.us.hsbc.com/faq" target="_blank" translate>FAQ</a>
            </li>
            <li>
              <a role="link" tabindex="0" rel="noopener noreferrer" href="https://rewards.us.hsbc.com/customer-support" target="_blank">Customer support</a>
            </li>
            <li>
              <a class="font-l"><span>HSBC Rewards</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="secondary-footer">
      <div class="container">
        <div class="row">
          <div class="col footer-link pt-3">
            <ul class="terms-and-conditions d-flex flex-column flex-lg-row col mb-0">
              <li class="order-lg-1">
                <span><a class="primary-white" role="link" tabindex="0" rel="noopener noreferrer" href="https://rewards.us.hsbc.com/program-rules" target="_blank" translate>Program Rules</a></span>
              </li>
              <li class="order-lg-2">
                <span><a class="primary-white" role="link" tabindex="0" rel="noopener noreferrer" href="https://www.us.hsbc.com/site-terms-and-conditions" target="_blank" translate>HSBC Terms and Conditions</a></span>
              </li>
              <li class="order-lg-3">
                <span> <a class="primary-white" role="link" tabindex="0" rel="noopener noreferrer" href="https://www.us.hsbc.com/online-privacy-statement" target="_blank" translate>Privacy</a></span>
              </li>
              <li class="order-lg-3">
                <span> <a class="primary-white" role="link" tabindex="0" rel="noopener noreferrer" href="https://www.us.hsbc.com/accessibility" target="_blank" translate>Accessibility</a></span>
              </li>
              <li class="order-lg-4 mt-4 mt-lg-0">
                <a tabindex="-1">© {{year}}. <span class="text--xs">HSBC | All rights reserved</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  </div>

</footer>
