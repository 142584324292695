import {RouterModule, Routes} from '@angular/router';
import {NgModule} from "@angular/core";
import {DashComponent} from "./dashboard/dashboard.component";
import {AuthResolver} from "../services/resolve/auth.resolver";
import {AuthGuard} from "./authentication/authentication.guard";
import {FaqComponent} from "./dashboard/faq/faq.component";
import {TecComponent} from "./dashboard/tec/tec.component";
import {ExpiredComponent} from "./dashboard/expired/expired.component";

const appRoutes: Routes = [
    {
        path: "",
        component: DashComponent,
        data: {title: 'Dashboard'},
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "travel",
                data: {title: 'Flights'},
            },
            {
                path: "travel",
                loadChildren: () => import('./travel/travel.module').then(m => m.TravelModule),
                data: {title: 'Flights'},
            },
            {
                path: "hotels",
                loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule),
                data: {title: 'Hotels'},
            },
            {
                path: "cars",
                loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule),
                data: {title: 'Car Rentals'},
            },
            {
                path: "bundle",
                loadChildren: () => import('./bundle/bundle.module').then(m => m.BundleModule),
                data: {title: 'Flight + Hotel'},
            },
            {
                path: "itinerary",
                loadChildren: () => import('./itinerary/itinerary.module').then(m => m.ItineraryModule),
            },
            {
                path: "faq",
                component: FaqComponent,
                data: {title: 'FAQ'},
            },
            {
                path: "tec",
                component: TecComponent,
                data: {title: 'Customer Support'},
            }

        ],
        canActivate: [AuthGuard],
        resolve: {
            admin: AuthResolver
        }
    },
    {
        path: "authentication",
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
        data: {title: 'Login'},
    },
    {
        path: "expired",
        component: ExpiredComponent,
        data: {title: 'Authentication Expired'},
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}

