<div class="circle-loader-wrapper">
    <div class="d-flex justify-content-center mt-4 mb-3">
        <div>
            <spinner-component [ladda]="true"></spinner-component>
        </div>

    </div>
    <p class="text-center mt-3"><span class="medium" translate>Please wait while we look for the best deals for you...</span></p>
    <div class="partner d-flex justify-content-center flex-wrap">
        <span *ngIf="selectedItem.includes('hotel')" class="p-3"><img aria-hidden="true" alt="" src="../../../assets/images/agoda-logo.png"></span>
        <span *ngIf="selectedItem.includes('hotel')" class="p-3"><img aria-hidden="true" alt="" src="../../../assets/images/booking-logo.png"></span>
        <span  class="p-3"><img aria-hidden="true" alt="" src="../../../assets/images/priceline-logo.png"></span>
    </div>
</div>

