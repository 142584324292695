import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {StepService} from "./step.service";
import {NavigationEnd, Router} from "@angular/router";
import {FlightService} from "../../services/flight.service";
import {CarService} from "../../services/car.service";
import {TranslateService} from "@ngx-translate/core";
import {HotelService} from "../../services/hotel.service";
import {IHotelContract} from "../../interfaces/hotel.interface";
import {IViewResponse} from "../../interfaces/view.interface";

@Component({
    selector: 'step-component',
    templateUrl: 'step.component.html',
    styleUrls: ['./step.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StepComponent implements  OnInit {
    @Input() type: any = "";
    private stepPercentage = [33,66,100];
    private contract: IViewResponse;
    private contractHotel:IHotelContract;
    laddaBack:boolean=false;

    constructor(public stepService: StepService,
                private flightService:FlightService,
                private carService:CarService,
                private translateService: TranslateService,
                private hotelService: HotelService,
                private router: Router) {

        this.contract = this.flightService.contract;
        this.contractHotel = this.hotelService.contract;

        if(router.url.includes('payment')){
            this.stepService.currentStep = 1;
        }

        router.events.subscribe((val:any) => {
            if(val instanceof NavigationEnd){
                if(val.url.includes('payment')){
                    this.stepService.currentStep = 1;
                }
            }
        });

    }



    goToDetailFlight() {
        this.router.navigate(['/travel/contract'], {queryParams: {contract: this.contract.ppn_book_bundle}});
    }

    goToCarSelection(){
        this.laddaBack = true;
        this.carService.searchCars(this.carService.searchCarParams).subscribe((resp) => {
            this.laddaBack = false;
            this.router.navigate(['cars/search']);
        }, (err) => {
            this.laddaBack = false;
        })
    }
    goToHotelDetails(){
        this.laddaBack = true;
        this.router.navigate(['/hotels/detail',this.contractHotel.hotel_detail.id], { queryParams: { ppn_bundle:  this.contractHotel.room_data.rate_data[0].ppn_bundle} }).then(()=>{
            this.laddaBack = false
        })
    }


    calculateProgress(step){
        return this.stepPercentage[step];
    }

    ngOnInit(): void {

    }
}
