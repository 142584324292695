<div class="insurance-container">
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="bundle-heading-section mb-4">
                <span class="text--lg light">Travel Insurance with HSBC Credit Card</span>
            </div>
        </div>
        <div class="col-12 col-md-12 text--sm">
            <p>Please visit the <a class="medium" rel="noopener noreferrer" href=" https://www.us.hsbc.com/credit-card-resource-center" target="_blank">HSBC Credit Card Resource Center</a> to review any travel benefits that you may already have access to with your HSBC Credit Card. Please remember that your travel benefits may change occasionally and are specific to your card type and should be reviewed carefully.</p>
        </div>
    </div>
</div>
