import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'clear-input',
    styleUrls: ['./clear-input.component.scss'],
    templateUrl: 'clear-input.component.html',
})

export class ClearInputComponent {
    @Input() type: string;

    constructor( ) {
    }

    onClickClear(val = null){
        // console.log(val);
        val.setValue(' ');
    }
}
