import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {IBaggageAllowance} from "../../interfaces/flight.interface";

const $ = require("jquery");

@Component({
    selector: 'baggage-fees-link',
    styleUrls: ['./baggage-fees-link.component.scss'],
    templateUrl: 'baggage-fees-link.component.html'
})

export class BaggageFeesLinkComponent {

    @Input() baggage_allowances: IBaggageAllowance[]= [];
    @Input() baggage_carrier: any= null;
    @Input() index: number= 0;
    @Output() openModal = new EventEmitter();


    constructor() {


    }
    openBaggageModal(index,baggage_allowances){
      this.openModal.emit({index: index,baggage_allowances:baggage_allowances})
    }
}
