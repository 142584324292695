<div id="{{id}}" class="modal infant-modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="medium" translate>Traveling with Infants</h5>
        <button type="button" class="close" data-dismiss="modal" [attr.aria-label]="'Close' | translate"><span
          aria-hidden="true"><img aria-hidden="true" alt="" src="../../assets/images/icons/close-black.svg"></span></button>
      </div>
      <div class="modal-body">
        <p>When traveling by air, you will need to decide whether you want your infant to fly as a ticketed
          passenger in their own seat or as a lap infant.</p>
        <p class="medium"> Domestic Flights (within the U.S and to Puerto Rico):</p>
        <ul>
          <li>If you would like your infant to sit in their own seat, you will need to book your flight directly
            with the airline. You will be required to seat your infant in an FAA approved car seat.</li>
          <li>Most airlines will allow one lap infant per adult traveler at no charge, proof of age is required.
            Call your airline immediately, after you make a travel purchase, to let them know you will be
            holding an infant.
          </li>
          <li>If you're traveling with more than one infant per adult, you'll need to purchase a seat for each
            additional infant traveler.</li>
          <li>If you're traveling with more than two infants per adult (or if you're flying in Premium Economy,
            Business or First class), check with your airline prior to purchase to confirm it is allowed.
          </li>
        </ul>
        <p class="medium">International Flights (flying between two or more countries):</p>
        <ul>
          <li>If you would like your infant to sit in their own seat, you will need to book your flight directly
            with the airline. You will be required to seat your infant in an FAA approved car seat</li>
          <li>If you will be holding your infant on your lap on an international flight, you will need to
            purchase an infant ticket directly with your airline as our system does not sell infant tickets.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
