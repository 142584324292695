import {Component, ElementRef, EventEmitter, Output, ViewChild} from "@angular/core";

const $ = require("jquery");

@Component({
    selector: 'modal',
    templateUrl: 'modal.component.html',
})

export class ModalComponent{

    @ViewChild('myModal', {static: false}) myModal:ElementRef;
    private openedElement = null;
    @Output() closed = new EventEmitter<boolean>();

    constructor() {}

    show(optClass?, openItem=null){
        //open modal using jQuery
        (<any>$(this.myModal.nativeElement)).children().addClass(optClass ? optClass : 'modal-lg').end().modal({
            backdrop: 'static'
        });
        this.openedElement = openItem;
        this.trapFocus(this.myModal.nativeElement);
    }

    isVisible(): boolean{
        return (<any>$(this.myModal.nativeElement)).hasClass('show');
    }

    dismiss(){
        //close modal using jQuery
        (<any>$(this.myModal.nativeElement)).modal('hide');
        if(this.openedElement){
            this.openedElement.focus();
        }
        this.closed.emit();
    }

    trapFocus(element) {
        let focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'),
            firstFocusableEl = focusableEls[0];
        let lastFocusableEl = focusableEls[focusableEls.length - 1];

        element.addEventListener('keydown', function(e) {
            let isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

            if (!isTabPressed) {
                return;
            }

            if ( e.shiftKey ) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }

        });
    }

}
