import {CanActivate} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {Injectable} from "@angular/core";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        public auth: AuthenticationService) {}

    canActivate(): boolean {
        if (!this.auth.isLogged) {
            this.auth.logout();
            return false;
        }
        return true;
    }
}