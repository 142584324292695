import {Component} from "@angular/core";

@Component({
    selector: 'spinner-component',
    inputs: ['ladda', 'customClass'],
    styleUrls: ['./spinner.component.scss'],
    templateUrl: 'spinner.component.html'
})
export class SpinnerComponent{
    ladda: boolean;
    customClass: string;

    constructor() {
    }
}
