import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {throwError} from "rxjs";
import * as _ from 'lodash-es'
import {AlertService} from "../directives/alerts/alerts.service";

@Injectable()
export class CarService {

    constructor(private router: Router,
                private http: HttpClient,
                private alertService: AlertService) {
    }
    private _lastSearchCar: any;
    private _contract:any;
    private _lastCarSelected:any;
    private _searchCarParams;
    private _lastCity;
    private _carDiscountCode = {
        corp_discount_code: null,
        rate_code: null,
        promotion_code: null,
        loyalty_member_id: null,
    };


    getView(car_id:string,discount_code:any=null){
        let payload = {ppn_bundle: car_id};

        let discount = discount_code || this.carDiscountCode;
        if(discount){
            _.extend(payload,discount)
        }
        return this.http.post<any>('car/view', payload).pipe(map(data => {
            this.lastCarSelected = car_id;
            this.contract = data.data;
            try{
                if (!this.contract.pricing.breakdown_data[0]) {
                    this.alertService.addMessage('danger', 'Invalid data search again');
                    this.router.navigate(['cars/search']);
                }
            } catch (e) {
                this.alertService.addMessage('danger', 'Invalid data search again');
                this.router.navigate(['cars/search']);
            }

            return this.contract
        })).catch((err) => {
            return throwError(err)
        });
    }

    getCities(str: string) {
        return this.http.post<any>('car/locations', {
            location: str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }).pipe(map(data => {
            let result = [];

            _.forEach(data.data.city_data,(item)=>{
                result.push(item);
                _.forEach(item.airport_data, (airports)=>{
                    //result.push(airports)
                    result.push(_.extend(airports, {city: item.city}))
                })
            });

            let airportsArray = data.data.airport_data ? data.data.airport_data : [];
            result = _.orderBy(result.concat(airportsArray), 'airport_code');
            this.lastCity = result;
            return result
        })).catch((err) => {
            if(err.status == 400){

                let firstKey = Object.keys(err.error.data);

                _.forEach(firstKey, (item)=>{
                    _.forEach(err.error.data[item], (msg)=>{

                        this.alertService.addMessage('danger', msg)
                    })
                })
            }else{
                this.alertService.addMessage('danger' , err.error.data)
            }


            return throwError(err)
        });
    }
    searchCars(payload: any = this.searchCarParams) {
        this.searchCarParams = payload;
        return this.http.post<any>('car/search', payload).pipe(map(data => {
            let resp = {data: data.data};
            this.lastSearchCar = _.extend(resp, {search_data: payload})
            return this.lastSearchCar
        })).catch((err) => {
            return throwError(err)
        });
    }

    get contract(): any {
        return this._contract;
    }

    set contract(value: any){
        this._contract = value;
    }

    get lastCarSelected(): any {
        return this._lastCarSelected || JSON.parse(localStorage.getItem('lastCarSelected'));
    }

    set lastCarSelected(value: any) {
        localStorage.setItem('lastCarSelected', JSON.stringify(value));
        this._lastCarSelected = value;
    }

    set lastCity(value:any){
        this._lastCity = value;
    }

    get lastCity(){
        return this._lastCity

    }
    get lastSearchCar(): any {
        return this._lastSearchCar;
    }

    set lastSearchCar(value: any) {
        this._lastSearchCar = value;
    }

    get searchCarParams() {
        return this._searchCarParams || JSON.parse(localStorage.getItem('searchCarParams'));
    }

    set searchCarParams(value) {
        localStorage.setItem('searchCarParams', JSON.stringify(value));
        this._searchCarParams = value;
    }
    get carDiscountCode() {
        const isEmpty = !Object.values(this._carDiscountCode).some(x => (x !== null && x !== ''));
        return isEmpty ? JSON.parse(localStorage.getItem('carDiscountCode')) : this._carDiscountCode;
    }

    set carDiscountCode(value) {
        let parsedValue = null;
        //Do this since in the priceline contract the parameters are called different, need to be renamed to be accepted correcttly
        if(value){
            parsedValue = {
                corp_discount_code: value.corp_discount_code || value.corp_discount || null,
                rate_code: value.rate_code || null,
                promotion_code: value.promotion_code || value.promo_code || null,
                loyalty_member_id: value.loyalty_member_id || null,
            };
        } else {
          parsedValue = {
              corp_discount_code: null,
              rate_code: null,
              promotion_code: null,
              loyalty_member_id: null,
          }
        }
        localStorage.setItem('carDiscountCode', JSON.stringify(parsedValue));
        this._carDiscountCode = parsedValue;
    }

}
