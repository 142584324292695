    <div  class="mt-4 alert-notification-cancellation d-flex" [ngClass]="{'success':successMsg, 'error':errorMsg}"   >
        <div class="alert-icon">
            <img *ngIf="successMsg" alt="" width="21" src="/assets/images/icons/SuccessOnLight.svg">
            <img  *ngIf="errorMsg" alt="" width="21" src="/assets/images/icons/StatusErrorOnLight.svg">
        </div>
        <div id="alert-msg-wrap" class="pl-2 alert-msg-wrap " >
            <span class="medium" [ngClass]="{'d-none':!successMsg}"   translate>The itinerary was cancelled successfully.</span>
            <span  [ngClass]="{'d-none':!errorMsg}"   ><span class="medium" translate>There was a problem cancelling the itinerary.</span><br *ngIf="errorMsg">
    <span  [ngClass]="{'d-none':showMsgFee}" ><span  translate>Please contact Customer Service at</span> {{authService.SupportTelephoneNumber}} <span translate>to further assist you</span></span>
        <span  [ngClass]="{'d-none':!showMsgFee}"  >
            <span translate>Please cancel it through the Priceline Backoffice Tool or Priceline Agent Line</span> <span>1-888-837-3797</span>
        </span>
    </span>
        </div>
        <span aria-describedby="alert-msg-wrap" role="button" tabindex="0" (click)="closeBanner()" class="close-alert ml-auto"><span class="sr-only" translate>Close</span><img alt="" width="24" src="../../assets/images/icons/close-black.svg"></span>
    </div>
