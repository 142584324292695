<div class="book-overlay">
  <div class="overlay-text text-center">
    <p class="h1 overlay-title" translate>Please wait while we book your travel with the service provider.</p>
    <p class="overlay-subtitle" translate>It may take up to 3 minutes to process your booking.</p>
    <div class="travel-spinner" role="alert" aria-live="polite">
      <div class="loading-spinner">
        <span class="sr-only" translate>Loading...</span>
        <img aria-hidden="true" alt="" src="../../assets/images/loading-white.svg"/>
      </div>
    </div>
  </div>
</div>
