<modal id="baggage_fees_modal" #baggage_fees_modal>
  <div class="app-modal-header"><h3 translate>Baggage Allowances</h3></div>
  <div class="app-modal-body" style="min-height: 100px">
    <div *ngIf="baggage_info.length">
      <span class="sr-only" id="baggage-allowances-label" translate>Baggage allowances table</span>
      <table aria-describedby="baggage-allowances-label" class="table table-responsive table-bordered ">
        <tr>
          <th translate>Type</th>
          <th translate>Note</th>
          <th translate>Quantity</th>
          <th translate>Provider</th>
          <th translate>Restrictions</th>
        </tr>
        <tr *ngFor=" let info of baggage_info; let i = index">
          <td>{{info.type}}</td>
          <td>{{info.note || "--"}}</td>
          <td>{{info.quantity || "--"}}</td>
          <td>{{info.provider}}</td>
          <td>
            <span class="d-block" *ngFor=" let r of info.restrictions">
              {{r.restriction_type}}: {{r.value}} {{r.unit}}
            </span>
          </td>
        </tr>
      </table>
      <p *ngIf="baggage_info[0]?.baggage_info_url">
        <span translate>More information:</span>
        <a target="_blank" rel="noopener noreferrer" [href]="'https://'+baggage_info[0]?.baggage_info_url">
          <u>{{baggage_info[0]?.baggage_info_url}}</u> <img width="16" alt="Open in new tab" class="new-modal-window-icon" src="../../assets/images/new_window.svg">
        </a>
      </p>
    </div>

  </div>
</modal>
