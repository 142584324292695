import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "../../../../services/authentication.service";
import {IItineraryData} from "../../../../interfaces/flight.interface";
import * as _ from 'lodash-es'
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {AlertService} from "../../../../directives/alerts/alerts.service";
import {CarService} from "../../../../services/car.service";
import utils, {eBookingType} from "../../../../resources/utils";


@Injectable()
export class CarBookService {

    private _bookData: IBookData;
    private _bookInsurance:any = 0 ;
    private _selectedPoints: number = null;
    private _bookResponse: any = null;
    private statusPolling: any;
    private _carInsuranceSelected:any;
    private errors$: BehaviorSubject<any> = new BehaviorSubject<any>([]);


    constructor(private router: Router,
                private http: HttpClient,
                private alertService:AlertService,
                private carService: CarService,
                private authenticationService: AuthenticationService) {
        // if(this.bookData){
        //     this.bookInsurance = this.bookData.passenger_info[0].insurance_value
        // }
    }

    bookCar() {
        return this.http.post<any>('booking/book', this.prepareCarBookPayload()).pipe(map(data => {
            this.bookResponse = data;
            localStorage.removeItem('carBookData');
            this.statusPolling = setInterval(()=>{
                this.bookCarStatus(data.data.reference).subscribe( (resp)=>{
                    this.bookResponse = resp.data
                    //When the status change stop interval
                    if (this.bookResponse.status != 'PENDING'){
                        clearInterval(this.statusPolling);
                        if(this.bookResponse.status == 'SUCCESS'){
                            this.authenticationService.getUserInfo().subscribe()
                        }else {
                            let attempts = resp.data.process.car_book_errors?.length
                            let message = resp.data.process.car_book_errors[attempts-1]?.message
                            if(message){
                                this.errors$.next([{message: message, attempts: attempts, type: 'car'}]);
                            }
                        }
                    }
                }, (err)=>{
                    clearInterval(this.statusPolling);
                })
            }, 5000)
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    cleanStatusInterval(){
        clearInterval(this.statusPolling);
    }

    bookCarStatus(reference: string) {
        return this.http.post<any>('booking/status', {reference: reference}).pipe(map(data => {
            _.extend(this.bookResponse,{status: data.data});
            if(data.data.context && data.data.context.exception){
                this.alertService.addMessage('danger',data.data.context.exception)
            }
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    prepareCarBookPayload(){
        let payload = {
            car_ppn_bundle: this.bookData.car_contract.car_book_bundle,
            first_name: this.bookData.card_info.card_first_name,
            last_name: this.bookData.card_info.card_last_name,
            email: this.bookData.billing_info.email,
            phone_number: this.bookData.billing_info.phone_number,
            address: this.bookData.billing_info.address,
            city: this.bookData.billing_info.city?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            postal_code: this.bookData.billing_info.postal_code,
            country_code: this.bookData.billing_info.country_code,
            state: this.bookData.billing_info.state || this.bookData.billing_info.state2,
            //state2: this.bookData.billing_info.state2,
            // passenger: this.parsePassengerInfo(),
            driver_first_name:this.bookData.passenger_info[0].first_name,
            driver_last_name:this.bookData.passenger_info[0].last_name,
            start_date:this.bookData.passenger_info[0].start_date,
            points: Math.round(this.bookData.points),
            insurance_selected:this.bookData.passenger_info[0].insurance ? 1 : 0,
            toddler_seat_selected:this.bookData.passenger_info[0].toddler ? 1 : 0,
            sms_selected:this.bookData.passenger_info[0].promotion ? 1 : 0,
            sms_confirmation:this.bookData.passenger_info[0].reminder   ? 1 : 0,
            infant_seat_selected:this.bookData.passenger_info[0].infant ? 1 : 0,
            booster_seat_selected:this.bookData.passenger_info[0].booster ? 1 : 0,
            navigation_system_selected:this.bookData.passenger_info[0].navigation ? 1 : 0,
            ski_equipment_selected:this.bookData.passenger_info[0].ski ? 1 : 0,
            handctrl_left_selected:this.bookData.passenger_info[0].lefth ? 1 : 0,
            handctrl_right_selected:this.bookData.passenger_info[0].righth ? 1 : 0,
        };
        //Do this to reassign some fields like flight number, airline code...
        payload = {...this.bookData.passenger_info[0],...payload}
        //Remove empty values
        Object.keys(payload).forEach((key) => (payload[key] === '') && delete payload[key]);

        _.extend(payload, this.bookData.card_info);

        //Additional Fees
        if (this.authenticationService.isAdmin() && this.authenticationService.additionalFees.length > 0) {
            let payWithPoints = this.bookData.agentBookingPaymentType;
            if (this.bookData.agentBookingCarNoFeeReason == '') {
                let id = utils.getBookingFeeId(this.authenticationService.additionalFees, eBookingType.car);
                if (id != -1) {
                    _.extend(payload, {pay_additional_fee_in_points: payWithPoints, car_additional_fee_id: id});
                }
            }
            else {
                let noFeeReasonId = utils.getBookingFeeNoReasonID(this.authenticationService.additionalFees);
                _.extend(payload, {pay_additional_fee_in_points: 1, car_no_fee_reason_id: Number(this.bookData.agentBookingCarNoFeeReason), car_additional_fee_id: noFeeReasonId})
            }
        }

        return payload;
    }

    cancelCar(booking_id: string, reference: string, email: string,cancellation_info?: object) {
        return this.http.post<any>('car/cancel', {
            booking_id: booking_id,
            reference: reference,
            email: email,
            cancellation_info:cancellation_info
            // cancellation_info:cancellation_info,
        }).pipe(map(data => {
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    carLookup(booking_id: string, email: string) {
        return this.http.post<any>('car/lookup', {booking_id: booking_id, email: email}).pipe(map(data => {
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    clearBookData(){
        this.carService.carDiscountCode = null;
        this.bookData = null;
        this.bookResponse = null;
        this.bookInsurance = 0;
        this.selectedPoints = null;
        this.bookResponse = null
    }


    get bookInsurance():any{
        // let saved_insurance = 0;
        // if(this.bookData){
        //     saved_insurance = _.cloneDeep(this.bookData.passenger_info[0].insurance_value)
        //     return saved_insurance
        // }
        // if(this.bookData && this.bookData.passenger_info[0].insurance == true){
        //     return this.bookData.passenger_info[0].insurance_value
        // } else {
        //
        // }
        // insurance = this._bookInsurance;
        let insurance = this._bookInsurance || JSON.parse(localStorage.getItem('carInsurance')) ;

        return parseFloat(insurance)
    }

    set bookInsurance(value:any){
        localStorage.setItem('carInsurance', JSON.stringify(value))
        this._bookInsurance = value ;
    }

    get bookData(): any {
        let book_data = this._bookData || JSON.parse(localStorage.getItem('carBookData'));
        return book_data
    }

    set bookData(value: any) {
        localStorage.setItem('carBookData', JSON.stringify(value));
        this._bookData = value;
    }

    get selectedPoints(): number {
        return this._selectedPoints

    }

    set selectedPoints(value: number) {
        this._selectedPoints = value;
    }

    get bookResponse(): any {
        return this._bookResponse;
    }

    set bookResponse(value: any) {
        this._bookResponse = value;
    }
    getErrors(): Observable<any> {
        return this.errors$.asObservable();
    }


}

export interface IBookData {
    flight_contract: IItineraryData,
    card_info: ICardInfo,
    billing_info: IBillingInfo,
    passenger_info: IPassengerInfo[],
    points: number
}
export interface ICardInfo {
    card_cvc: number
    card_exp_month: string
    card_exp_year: number
    card_first_name: string
    card_last_name: string
    card_number: number
    card_type: string
    name_prefix: string
}
export interface IBillingInfo {
    address: string
    city: string
    country_code: string
    email: string
    phone_number: string
    postal_code: string
}
export interface IPassengerInfo {
    day: string
    ff: string
    first_name: string
    gender: string
    infant: string
    last_name: string
    middle_name: string
    insurance_value: any
    month: string
    name_prefix: string
    birthday?: string
    seat: string
    year: string
}
