import {Component, ViewEncapsulation} from "@angular/core";

@Component({
    selector: 'book-overlay',
    styleUrls: ['./book-overlay.component.scss','../spinner/spinner.component.scss'],
    templateUrl: 'book-overlay.component.html',
    encapsulation: ViewEncapsulation.None
})

export class BookOverlayComponent{
    constructor() {
    }
}

