import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable, Observer, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class CommonService {

    private _dollarToPoints: number;
    public backChange: Observable<string>;
    private dataChangeObserver: any;
    private back: boolean;

    constructor(private http: HttpClient) {
        this.backChange = new Observable((observer:Observer<string>) => {
            this.dataChangeObserver = observer;
        });
    }

    get dollarToPoints(): number {
        return this._dollarToPoints;
    }
    set dollarToPoints(value: number) {
        this._dollarToPoints = value;
    }
    getFaq(type) {
        return this.http.get<any>('faq/'+type).pipe(map(data => {
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    setBack(data:any) {
        this.back = data;
        this.dataChangeObserver.next(this.back);
    }

}
