import {Component, OnInit} from "@angular/core";
import {CartService} from "../../services/cart.service";
import {Router} from "@angular/router";
import * as _ from 'lodash-es'
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'cart-component',
    templateUrl: 'cart.component.html',
    styleUrls: ['./cart.component.scss'],
})

export class CartComponent implements OnInit {
    progressValue = 0;

    constructor(public cartService: CartService,
                private translateService: TranslateService,
                private router: Router) {

        this.cartService.observableStep
            .subscribe(step => {
                this.calculateProgress(step);
            });
    }

    ngOnInit(): void {
        //this.calculateProgress(this.cartService.cartStep);
        this.checkStepFromRoute(this.router.url);
    }

    checkStepFromRoute(route) {

        let index = _.findIndex(this.cartService.bundle.from, (item) => {
            return route.includes(item.url)
        });
        //TODO find better way (if it's-1 means is from hotel + flight set step to 0 to avoid that the flow brakes up)
        if(index == -1){
            index = 0
        }
        this.cartService.cartStep = index;
        this.calculateProgress(index);
        //Check if the user press back, we need remove the total of other selection
        let type = this.cartService.bundle.item[index];
        switch (type) {
            case 'flight':
                this.cartService.flight_total = 0;
                break;
            case 'car':
                this.cartService.car_total = 0;
                break;
            case 'hotel':
                this.cartService.hotel_total = 0;
                break;
        }

    }

    getBackRoute(){

        let result = '';

        if(this.cartService.cartStep > 0){
            result =  (this.cartService.bundle.from[this.cartService.cartStep- 1].url + '/search').replace('bundle','travel')
        } else{
            result =  this.cartService.bundle.from[0].url
        }
        return result
    }

    calculateProgress(step) {
        if(this.cartService.bundle){
            let percentage = Math.abs(1 / this.cartService.bundle.item.length) * 100;
            let delta = this.cartService.bundle.item.length == 2 ? 25 : 15;
            this.progressValue = ((step + 1) * percentage) - delta;
        } else {
            this.progressValue = 0;
        }

    }


    getTypeIndex(type: string) {
        return this.cartService.bundle.item.indexOf(type)
    }

    calculateDayDiff(date1, date2) {
        let diff = new Date((new Date(date2)).getTime() - (new Date(date1)).getTime());
        let res = diff.getUTCDate() - 1;

        return res;
    }

    getMonth(date1, date2) {
        let start = new Date(date1.split('-')[0], date1.split('-')[1] - 1, date1.split('-')[2]);
        let end = new Date(date2.split('-')[0], date2.split('-')[1] - 1, date2.split('-')[2]);

        let start_month = start.toLocaleString(this.translateService.currentLang, {month: 'short'});
        let end_month = end.toLocaleString(this.translateService.currentLang, {month: 'short'});

        return start_month + ' ' + date1.split('-')[2] + ' - ' + end_month + ' ' + date2.split('-')[2]
    }
}
