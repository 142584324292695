import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "../../../../services/authentication.service";
import * as _ from 'lodash-es'
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {AlertService} from "../../../../directives/alerts/alerts.service";
import {IHotelContract} from "../../../../interfaces/hotel.interface";
import {IBillingInfo, ICardInfo} from "../../../travel/contract/book/flight-book.service";
import {eAgentBookingPaymentType} from "../../../../directives/agent-fees-payment/agent-fees-payment";
import utils, {eBookingType} from "../../../../resources/utils";

@Injectable()
export class HotelBookService {

    private _selectedPoints: number = null;
    private _bookData: IHotelBookData;
    private _bookResponse: any = null;
    private statusPolling: any;
    private errors$: BehaviorSubject<any> = new BehaviorSubject<any>([]);


    constructor(private router: Router,
                private http: HttpClient,
                private alertService: AlertService,
                private authenticationService: AuthenticationService) {
    }




    bookHotel() {
        return this.http.post<any>('booking/book', this.prepareHotelBookPayload()).pipe(map(data => {
            this.bookResponse = data;
            //Poll the status
            this.pollStatus(data.data.reference);
            //this.alertService.addMessage('success', 'Your flight has been booked successfully')
            return data
        })).catch((err) => {
            this.bookResponse = null;
            return throwError(err)
        });
    }

    bookFlightStatus(reference: string) {
        return this.http.post<any>('booking/status', {reference: reference}).pipe(map(data => {
            _.extend(this.bookResponse,{status: data.data});

            if(data.data.context && data.data.context.exception){
                this.alertService.addMessage('danger',data.data.context.exception)
            }
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    pollStatus(reference){
        this.statusPolling = setInterval(()=>{
            this.bookFlightStatus(reference).subscribe( (resp)=>{
                this.bookResponse = resp.data
                //When the status change stop interval
                if (this.bookResponse.status != 'PENDING'){
                    clearInterval(this.statusPolling);
                    if(this.bookResponse.status == 'SUCCESS'){
                        this.authenticationService.getUserInfo().subscribe()
                    }else {

                        let attempts = resp.data.process.hotel_book_errors?.length
                        let message = resp.data.process.hotel_book_errors[attempts-1]?.message
                        if(message){
                            this.errors$.next([{message: message, attempts: attempts, type: 'hotel'}]);
                        }
                    }
                }
            }, (err)=>{
                clearInterval(this.statusPolling);
            })
        }, 5000)
    }

    prepareHotelBookPayload(){
        let payload = {
            hotel_ppn_bundle: this.bookData.hotel_contract.room_data.rate_data[0].ppn_bundle,
            first_name: this.bookData.guest_info.guest_first_name[0], // the first guest is the card holder
            last_name: this.bookData.guest_info.guest_last_name[0],
            start_date: this.bookData.hotel_contract.hotel_view.input_data.check_in,
            initials: this.bookData.card_info.card_first_name.charAt(0) + this.bookData.card_info.card_last_name.charAt(0),
            email: this.bookData.billing_info.email,
            phone_number: this.bookData.billing_info.phone_number,
            address: this.bookData.billing_info.address,
            city: this.bookData.billing_info.city?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            postal_code: this.bookData.billing_info.postal_code,
            state: this.bookData.billing_info.state || this.bookData.billing_info.state2,
            //state2: this.bookData.billing_info.state2,
            country_code: this.bookData.billing_info.country_code,
            guest_first_name: this.bookData.guest_info.guest_first_name.slice(1), //Since the first guest is the card holder (first and last name), we remove the data at index 0
            guest_last_name: this.bookData.guest_info.guest_last_name.slice(1),
            points: Math.round(this.bookData.points)
        };
        _.extend(payload, this.bookData.card_info);

        //Additional Fees
        if (this.authenticationService.isAdmin() && this.authenticationService.additionalFees.length > 0) {
            let payWithPoints = this.bookData.agentBookingPaymentType;
            if (this.bookData.agentBookingHotelNoFeeReason == '') {
                let id = utils.getBookingFeeId(this.authenticationService.additionalFees, eBookingType.hotel);
                if (id != -1) {
                    _.extend(payload, {pay_additional_fee_in_points: payWithPoints, hotel_additional_fee_id: id});
                }
            }
            else {
                let noFeeReasonId = utils.getBookingFeeNoReasonID(this.authenticationService.additionalFees);
                _.extend(payload, {pay_additional_fee_in_points: 1, hotel_no_fee_reason_id: Number(this.bookData.agentBookingHotelNoFeeReason), hotel_additional_fee_id: noFeeReasonId})
            }
        }

        return payload;
    }

    cancelHotel(booking_id: string, reference: string,cancellation_info?: object){
        return this.http.post<any>('hotel/cancel', {booking_id: booking_id, reference:reference,cancellation_info:cancellation_info}).pipe(map(data => {
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    hotelLookup(booking_id: string, email: string) {
        return this.http.post<any>('hotel/lookup', {booking_id: booking_id, email: email}).pipe(map(data => {
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    cleanStatusInterval(){
        clearInterval(this.statusPolling);
    }

    clearBookData(){
        this.bookData = null;
        this.bookResponse = null;
        this.selectedPoints = null;
    }

    get selectedPoints(): number {
        return this._selectedPoints
    }

    set selectedPoints(value: number) {
        this._selectedPoints = value;
    }

    get bookData(): IHotelBookData {
        let book_data = this._bookData || JSON.parse(localStorage.getItem('hotelBookData'));
        if(book_data != null){
            this.selectedPoints = book_data.points
        }
        return book_data
    }

    set bookData(value: IHotelBookData) {
        localStorage.setItem('hotelBookData', JSON.stringify(value));
        this._bookData = value;
        if(this._bookData != null){
            this.selectedPoints = this._bookData.points
        }
    }
    get bookResponse(): any {
        return this._bookResponse || JSON.parse(localStorage.getItem('hotelBookResponse'));
    }

    set bookResponse(value: any) {
        localStorage.setItem('hotelBookResponse', JSON.stringify(value));
        this._bookResponse = value;
    }

    getErrors(): Observable<any> {
        return this.errors$.asObservable();
    }

}

export interface IHotelBookData {
    hotel_contract: IHotelContract,
    card_info: ICardInfo,
    billing_info: IBillingInfo,
    guest_info: any,
    points: number,
    agentBookingPaymentType: eAgentBookingPaymentType,
    agentBookingHotelNoFeeReason: string
}
