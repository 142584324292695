<div  class="taxes-container mt-5" *ngIf="contractFlight || contractHotel || contractCar " >
  <span class="text--lg light d-block" translate>Summary of charges
  <a *ngIf="checkPageSize() <= 575" data-toggle="collapse" class="float-right collapsed" href="#collapsePrice" role="button" aria-expanded="false" aria-controls="collapsePrice">
    <img aria-hidden="true" alt="" src="/assets/images/icons/chevron-down-black.svg"> </a>
  </span>
  <div *ngIf="type.length == 1 " [ngClass]="{'show': size > 575}"  class="mb-2 collapse"  id="collapsePrice">
    <div *ngIf="type == 'car'" class="summary clearfix" >
      <p>

          <span class="medium">{{contractCar.pricing.breakdown_data[0].type}} <span translate>Rate:</span></span>
          <span class="swap-price" > {{ (contractCar.pricing.breakdown_data[0].display_price ) | pointConverter}} <pts-currency></pts-currency></span><br>

        <span class="sub-price"> (<price-component  [price]="contractCar.pricing.breakdown_data[0].source_price" [currency]="contractCar.pricing.source_currency" ></price-component>) </span>
      </p>
      <p *ngIf="contractCar.pricing.breakdown_data[1]">

          <span class="medium">{{contractCar.pricing.breakdown_data[1].type}} <span translate>Rate:</span></span>
          <span class="swap-price" > {{ (contractCar.pricing.breakdown_data[1].display_price ) | pointConverter}} <pts-currency></pts-currency></span><br>

        <span class="sub-price"> (<price-component  [price]="contractCar.pricing.breakdown_data[1].source_price" [currency]="contractCar.pricing.source_currency" ></price-component>) </span>
      </p>
      <p>

          <span role="button" tabindex="0" class="medium" data-toggle="tooltip" data-html="true" title="{{getTaxesAndFeeTooltip()}}">
            <span translate>Taxes & Fees:</span>
            <img alt="Open in modal" class="new-modal-window-icon" src="../../assets/images/new_modal.svg">
          </span>&nbsp;
          <span class="swap-price"> {{contractCar.pricing.taxes_and_fees.display_total | pointConverter}} <pts-currency></pts-currency></span>

        <span  class="sub-price">(<price-component  [price]="contractCar.pricing.taxes_and_fees.source_total" [currency]="contractCar.pricing.source_currency" ></price-component>)</span>
      </p>

      <p class="mb-4">

          <span class="total-label medium" translate>Days:</span>
          <span *ngIf="contractCar.pricing.breakdown_data[0].type == 'Daily' " class="swap-price">{{contractCar.pricing.breakdown_data[0].factor}} </span>
          <span *ngIf="contractCar.pricing.breakdown_data[0].type != 'Daily' " class="swap-price">{{differenceBetweenToDate()}} </span>

      </p>
      <p >

            <span class="total-label medium" translate>Total:</span>
            <span class="swap-price">{{parseNumber(contractCar.pricing.display_total )  | pointConverter}} <pts-currency></pts-currency></span>

        <span class="sub-price">(<price-component  [price]="parseNumber(contractCar.pricing.source_total ) " [currency]="contractCar.pricing.source_currency" ></price-component>)</span>
      </p>
      <p *ngIf="bookingCarService.bookInsurance " >

        <span ><u class="medium" translate>Car Coverage:</u></span>
        <span class="swap-price"> {{bookingCarService.bookInsurance | pointConverter}} <pts-currency></pts-currency></span> <br>

        <span  class="sub-price">(<price-component  [price]="bookingCarService.bookInsurance" [currency]="contractCar.pricing.display_currency" ></price-component>)</span>
      </p>
    </div>
    <div *ngIf="type == 'flight'" class="summary clearfix">
      <!--Case without split ticketing-->
      <div *ngIf="!contractFlight.price_details.component_itin_pricing_info">
        <p>

            <span class="medium" translate>Ticket Price:</span>
            <span class="swap-price">{{contractFlight.price_details.display_base_fare | pointConverter}} <pts-currency></pts-currency></span>

          <span class="sub-price">(<price-component  [price]="contractFlight.price_details.display_base_fare" [currency]="contractFlight.price_details.display_currency"></price-component>)</span>
        </p>
        <p>

            <span role="button" tabindex="0" class="medium" data-target="#flight-tax-and-fees-modal" data-toggle="modal" (click)="openTaxesModal()">
              <u translate>Taxes & Fees:</u>
              <img alt="Open in modal" class="new-modal-window-icon" src="../../assets/images/new_modal.svg">
            </span>
            <span class="swap-price">{{contractFlight.price_details.display_taxes | pointConverter}} <pts-currency></pts-currency></span>

          <span class="sub-price">(<price-component [currency]="contractFlight.price_details.display_currency"  [price]="contractFlight.price_details.display_taxes"></price-component>)</span>
        </p>
        <p *ngIf="contractFlight.price_details.display_fees > 0 ">

            <span role="button" tabindex="0" class="medium" data-target="#flight-tax-and-fees-modal" data-toggle="modal" (click)="openTaxesModal()">
              <u translate>Processing Fees:</u>
              <img alt="Open in modal" class="new-modal-window-icon" src="../../assets/images/new_modal.svg">
            </span>
            <span class="swap-price">{{contractFlight.price_details.display_fees | pointConverter}} <pts-currency></pts-currency></span>

          <span class="sub-price">(<price-component [currency]="contractFlight.price_details.display_currency"  [price]="contractFlight.price_details.display_fees"></price-component>)</span>
        </p>
        <p  class="mb-4">

            <span class="ticket-label medium" translate>Number of E-Tickets:</span>
            <span class="swap-price">{{contractFlight.adults + contractFlight.children}}</span>

        </p>
        <p >

            <span class="medium" translate>Subtotal:</span>
            <span class="swap-price"> {{contractFlight.price_details.display_total_fare | pointConverter}} <pts-currency></pts-currency></span><br>

          <span class="sub-price">(<price-component [price]="contractFlight.price_details.display_total_fare" [currency]="contractFlight.price_details.display_currency"></price-component>)</span>
        </p>
      </div>
      <!--Case with Split Ticketing-->
      <div id="split-section" *ngIf="contractFlight.price_details.component_itin_pricing_info">
        <div *ngFor="let tk of contractFlight.price_details.component_itin_pricing_info; let last = last;let i = index">
          <p class="show-destination">
            <span class="plane-icon">
              <em aria-hidden="true" class="fas" [ngClass]="{'fa-plane-departure':i == 0,'fa-plane-arrival':i == 1}" ></em>
            </span>
            <span id="dest-{{i}}">{{contractFlight.slice_data[i].departure.airport.code}} > {{contractFlight.slice_data[i].arrival.airport.code}}</span></p>
          <p>

              <span class="medium" translate>Ticket Price:</span>
              <span class="swap-price">{{tk.display_base_fare | pointConverter}} <pts-currency></pts-currency></span>

            <span class="sub-price">(<price-component  [currency]="tk.display_currency" [price]="tk.display_base_fare"></price-component>)</span>
          </p>
          <p>

              <span role="button" tabindex="0" class="medium" [attr.aria-describedby]="'dest-'+i" data-target="#flight-tax-and-fees-modal" data-toggle="modal" (click)="openTaxesModal()">
                <u translate>Taxes & Fees:</u>
                <img aria-hidden="true" alt="Open in modal" class="new-modal-window-icon" src="../../assets/images/new_modal.svg">
               </span>
              <span class="swap-price">{{tk.display_taxes | pointConverter}}<pts-currency></pts-currency></span>

            <span class="sub-price">(<price-component  [currency]="tk.display_currency" [price]="tk.display_taxes"></price-component>)</span>
          </p>
          <p *ngIf="tk.display_fees > 0">

              <span role="button" tabindex="0" class="medium" data-target="#flight-tax-and-fees-modal" data-toggle="modal" (click)="openTaxesModal()">
                <u translate>Processing Fees:</u>
                <img alt="Open in modal" class="new-modal-window-icon" src="../../assets/images/new_modal.svg">
              </span>
              <span class="swap-price">{{tk.display_fees | pointConverter}} <pts-currency></pts-currency></span>

            <span class="sub-price">(<price-component  [currency]="tk.display_currency" [price]="tk.display_fees"></price-component>)</span>
          </p>
          <p class="mb-4">

              <span class="ticket-label medium" translate>Number of E-Tickets:</span>
              <span class="swap-price">{{contractFlight.adults + contractFlight.children}}</span>

          </p>
          <p >

              <span class="medium" translate>Subtotal:</span>
              <span class="swap-price"> {{tk.display_total_fare | pointConverter}} <pts-currency></pts-currency></span><br>

            <span class="sub-price">(<price-component  [currency]="tk.display_currency" [price]="tk.display_total_fare"></price-component>)</span>
          </p>
          <hr aria-hidden="true" *ngIf="!last">
        </div>
      </div>
    </div>
    <div *ngIf="type == 'hotel'" class="summary clearfix">
      <p>

          <span class="medium" translate>Price per night:</span>
          <span class="swap-price">{{contractHotel.room_data.rate_data[0].price_details.night_price_data[0].display_night_price | pointConverter}} <pts-currency></pts-currency></span>

        <span class="sub-price">(<price-component  [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency" [price]="contractHotel.room_data.rate_data[0].price_details.night_price_data[0].display_night_price"></price-component>)</span>
      </p>
      <p>
        <span >
          <span class="medium">
            {{contractHotel.hotel_view.input_data.num_rooms}}
            <span *ngIf="contractHotel.hotel_view.input_data.num_rooms == 1" translate>Room</span>
            <span *ngIf="contractHotel.hotel_view.input_data.num_rooms > 1" translate>Rooms</span>
            x {{contractHotel.hotel_view.input_data.num_nights}}
            <span *ngIf="contractHotel.hotel_view.input_data.num_nights == 1" translate>Night</span>
            <span *ngIf="contractHotel.hotel_view.input_data.num_nights > 1" translate>Nights</span>:
          </span>
          <span class="swap-price">{{contractHotel.room_data.rate_data[0].price_details.display_sub_total | pointConverter}} <pts-currency></pts-currency></span>
        </span>
        <span class="sub-price">(<price-component  [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency" [price]="contractHotel.room_data.rate_data[0].price_details.display_sub_total"></price-component>)</span>
      </p>
      <p >
        <span>
          <span role="button" tabindex="0" class="medium" data-toggle="modal" data-target="#hotel-tax-link" (click)="openTaxAndFee()">
            <u translate>Taxes & Fees:</u>
            <img alt="Open in modal" class="new-modal-window-icon" src="../../assets/images/new_modal.svg">
          </span>
          <span class="swap-price">{{contractHotel.room_data.rate_data[0].price_details.display_taxes | pointConverter}} <pts-currency></pts-currency></span>
        </span>
        <span class="sub-price">(<price-component [price]="contractHotel.room_data.rate_data[0].price_details.display_taxes"  [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency"></price-component>)</span>
      </p>
      <p >

          <span class="total-label medium" translate>Total:</span>
          <span class="swap-price">{{(contractHotel.room_data.rate_data[0].price_details.display_total)  | pointConverter}} <pts-currency></pts-currency></span>

        <span class="sub-price">(<price-component [price]="contractHotel.room_data.rate_data[0].price_details.display_total"  [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency"></price-component>) </span>
      </p>
    </div>
  </div>
  <div *ngIf="type.length > 1" [ngClass]="{'show': size > 575}" class="mb-2 collapse" id="collapsePrice" >
    <div class="summary clearfix" *ngIf="type.includes('flight')">
      <p class="d-flex">
        <span id="flight-subtotal "><span class="medium" translate>Flight Subtotal: </span>
          <ng-template #tipFlight>
            <div *ngIf="!contractFlight.price_details.component_itin_pricing_info">
              <div class="row">
                <div class="col-12">
                  <span class="float-left" translate>Ticket Price:</span>
                  <span class="float-right">{{contractFlight.price_details.display_base_fare | pointConverter}} <pts-currency></pts-currency></span>
                </div>
                <div class="col-12 text-right">
                  (<price-component  [price]="contractFlight.price_details.display_base_fare" [currency]="contractFlight.price_details.display_currency"></price-component>)
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span class="float-left" translate>Taxes & Fees:</span>
                  <span class="float-right">{{contractFlight.price_details.display_taxes | pointConverter}} <pts-currency></pts-currency></span></div>
                <div class="col-12 text-right">
                  (<price-component [currency]="contractFlight.price_details.display_currency"  [price]="contractFlight.price_details.display_taxes"></price-component>)
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span class="float-left" translate>Number of E-Tickets:</span>
                  <span class="float-right">{{contractFlight.adults + contractFlight.children}}</span>
                </div>
              </div>
            </div>
            <div *ngIf="contractFlight.price_details.component_itin_pricing_info">
              <div  *ngFor="let tk of contractFlight.price_details.component_itin_pricing_info; let last = last;let i = index">
                <div class="row">
                  <div class="col-12">
                    <span translate>Flight</span>:
                    <span>{{contractFlight.slice_data[i].departure.airport.code}} > {{contractFlight.slice_data[i].arrival.airport.code}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <span class="float-left" translate>Ticket Price:</span>
                    <span class="float-right">{{tk.display_base_fare | pointConverter}} <pts-currency></pts-currency></span>
                  </div>
                  <div class="col-12">
                    <span class="float-right">(<price-component  [currency]="tk.display_currency" [price]="tk.display_base_fare"></price-component>)</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <span class="float-left"  translate>Taxes & Fees:</span>
                    <span class="float-right">{{tk.display_taxes | pointConverter}} <pts-currency></pts-currency></span>
                  </div>
                  <div class="col-12 text-right">
                    (<price-component  [currency]="tk.display_currency" [price]="tk.display_taxes"></price-component>)
                  </div>
                </div>
                <div class="row" *ngIf="tk.display_fees > 0">
                    <div class="col-12">
                      <span class="float-left" translate>Processing Fees:</span>
                      <span class="float-right">{{tk.display_fees | pointConverter}} <pts-currency></pts-currency></span>
                    </div>
                    <div class="col-12">
                      <span class="float-right">(<price-component  [currency]="tk.display_currency" [price]="tk.display_fees"></price-component>)</span>
                    </div>
                </div>
                <div class="row">
                  <div class="col-12" >
                    <span class="float-left"  translate>Number of E-Tickets:</span>
                    <span class="float-right">{{contractFlight.adults + contractFlight.children}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <span class="float-left" translate>Subtotal:</span>
                    <span class="float-right"> {{tk.display_total_fare | pointConverter}} <pts-currency></pts-currency></span>
                  </div>
                  <div class="col-12">
                    <span class="float-right">(<price-component  [currency]="tk.display_currency" [price]="tk.display_total_fare"></price-component>)</span>
                  </div>
                </div>
                <hr aria-hidden="true" *ngIf="!last">
              </div>

            </div>
          </ng-template>
          <span [ngbTooltip]="tipFlight" [autoClose]="true"  role="button" tabindex="0" triggers="manual" #t="ngbTooltip" (blur)="t.close();tooltipFlight=false" (focus)="t.open()" (click)="t.open(); tooltipFlight = !tooltipFlight" > <img aria-hidden="true" alt="help" width="17" width="20" [src]=" !tooltipFlight ? tooltipOpen : tooltipClose"> <span class="sr-only" translate>Open tooltip</span></span>
        </span>
        <span class="flex-price ml-auto text-right ">
          <span class="swap-price other-swap"> {{contractFlight.price_details.display_total_fare | pointConverter}} <pts-currency></pts-currency></span><br>
          <span class="sub-price other-price">(<price-component [currency]="contractFlight.price_details.display_currency"  [price]="contractFlight.price_details.display_total_fare"></price-component>)</span>
        </span>
      </p>
    </div>
    <div class="summary clearfix"  *ngIf="type.includes( 'hotel')">
      <p class="d-flex">
        <span id="hotel-subtotal"><span class="medium" translate>Hotel Subtotal: </span>
          <ng-template #tipHotel>
            <div class="row">
              <div class="col-12">
                <span class="float-left" translate>Room subtotal:</span>
                <span class="float-right">{{contractHotel.room_data.rate_data[0].price_details.display_sub_total | pointConverter}} <pts-currency></pts-currency></span>
              </div>
              <div class="col-12 text-right">
                (<price-component  [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency" [price]="contractHotel.room_data.rate_data[0].price_details.display_sub_total"></price-component>)
              </div>
            </div>
            <div class="row">
              <div class="col-12">
              <span translate>Taxes & Fees:</span>
                <span class="float-right">{{contractHotel.room_data.rate_data[0].price_details.display_taxes | pointConverter}} <pts-currency></pts-currency></span>
              </div>
              <div class="col-12">
                <span class="float-right">(<price-component  [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency" [price]="contractHotel.room_data.rate_data[0].price_details.display_taxes"></price-component>)</span>
              </div>
            </div>
          </ng-template>
          <span [ngbTooltip]="tipHotel"  [autoClose]="true"    role="button" tabindex="0" triggers="manual" #h="ngbTooltip" (blur)="h.close();tooltipHotel=false" (focus)="h.open()" (click)="h.open() ; tooltipHotel = !tooltipHotel" ><img aria-hidden="true" alt="help" width="17" width="20" [src]=" !tooltipHotel ? tooltipOpen : tooltipClose"><span class="sr-only" translate>Open tooltip</span></span>
        </span>
        <span class="flex-price ml-auto text-right">
          <span class="swap-price other-swap">{{(contractHotel.room_data.rate_data[0].price_details.display_total)  | pointConverter}} <pts-currency></pts-currency></span><br>
            <span class="sub-price other-price">(<price-component [currency]="contractHotel.room_data.rate_data[0].price_details.display_currency" [price]="contractHotel.room_data.rate_data[0].price_details.display_total" ></price-component>) </span>
        </span>

      </p>
    </div>
    <div class="summary clearfix"  *ngIf="type.includes('car')">
      <p class="d-flex">
        <span id="car-subtotal" class="float-left"><span class="medium" translate>Car Subtotal: </span>
          <ng-template #tipCar>
            <div class="row">
              <div class="col-12"> {{contractCar.pricing.breakdown_data[0].type}} <span translate>Rate:</span><span class="float-right">{{ (contractCar.pricing.breakdown_data[0].display_price ) | pointConverter}} <pts-currency></pts-currency></span></div>
              <div class="col-12">
                <span class="float-right">(<price-component  [price]="contractCar.pricing.breakdown_data[0].source_price" [currency]="contractCar.pricing.source_currency" ></price-component>)</span>
              </div>
            </div>
            <div class="row" *ngIf="contractCar.pricing.breakdown_data[1]">
              <div class="col-12"> {{contractCar.pricing.breakdown_data[1].type}} <span translate>Rate:</span><span class="float-right">{{ (contractCar.pricing.breakdown_data[1].display_price ) | pointConverter}} <pts-currency></pts-currency></span></div>
              <div class="col-12">
                <span class="float-right">(<price-component  [price]="contractCar.pricing.breakdown_data[1].source_price" [currency]="contractCar.pricing.source_currency" ></price-component>)</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span><span translate>Taxes & Fees:</span></span>&nbsp;
                <span class="float-right"> {{contractCar.pricing.taxes_and_fees.display_total | pointConverter}} <pts-currency></pts-currency></span>
              </div>
              <div class="col-12">
                <span  class="float-right">(<price-component  [price]="contractCar.pricing.taxes_and_fees.source_total" [currency]="contractCar.pricing.source_currency" ></price-component>)</span>
              </div>
            </div>
            <div *ngIf="bookingCarService.bookInsurance " class="row">
              <div class="col-12">
                <span ><u translate>Car Coverage:</u></span>
                <span class="float-right"> {{bookingCarService.bookInsurance | pointConverter}} <pts-currency></pts-currency></span>
              </div>
              <div class="col-12">
                  <span  class="float-right">(<price-component  [price]="bookingCarService.bookInsurance" [currency]="contractCar.pricing.display_currency" ></price-component>)</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span  translate>Days:</span>
                <span *ngIf="contractCar.pricing.breakdown_data[0].type == 'Daily' " class="float-right">{{contractCar.pricing.breakdown_data[0].factor}} </span>
                <span *ngIf="contractCar.pricing.breakdown_data[0].type != 'Daily' " class="float-right">{{differenceBetweenToDate()}} </span>
              </div>
            </div>
          </ng-template>
          <span [ngbTooltip]="tipCar" [autoClose]="true" role="button"   tabindex="0" triggers="manual" #c="ngbTooltip" (blur)="c.close();tooltipCar=false" (focus)="c.open()" (click)="c.open(); tooltipCar = !tooltipCar"  > <img aria-hidden="true" alt="help" width="17" width="20" [src]=" !tooltipCar ? tooltipOpen : tooltipClose"> <span class="sr-only" translate>Open tooltip</span></span>
        </span>
        <span class="flex-price ml-auto text-right">
          <span class="swap-price other-swap">{{parseNumber(contractCar.pricing.display_total ) + bookingCarService.bookInsurance | pointConverter}} <pts-currency></pts-currency></span> <br>
          <span class="sub-price other-price">(<price-component  [price]="parseNumber(contractCar.pricing.display_total ) + bookingCarService.bookInsurance" [currency]="contractCar.pricing.display_currency"></price-component>)</span>
        </span>
      </p>
    </div>
    <div class=" mt-4 mb-4">
      <p class="d-flex">
          <span class="medium text--sm total-label other-label" translate>Total:</span>
        <span class="flex-price ml-auto text-right" >
          <span class="swap-price other-swap">{{  (totalCar)   + totalHotel + totalFlight  | pointConverter}} <pts-currency></pts-currency></span><br>
          <span class="sub-price other-price">(<price-component [currency]="'USD'" [price]="(totalCar) + totalHotel + totalFlight " ></price-component>)</span>
        </span>

      </p>
    </div>
  </div>
  <hr aria-hidden="true">
  <div class="text-center">
    <span class="font-20 mb-3" translate>Total Charges</span>
    <div aria-atomic="true" aria-live="assertive">
      <div class="total-points">{{(showTopUp ? (displayPrice()  | number:'1.0-0':translateService.currentLang) :   displayPrice() | pointConverter)}} <pts-currency></pts-currency></div>
      <span class="total-taxes-in-dollar font-s d-block ">(<span translate>value of</span>&nbsp;<price-component [price]="(showTopUp ? displayPrice()/ conversion_rate :  displayPrice())" [currency]="'USD'"  ></price-component>)</span>
      <div class="top-up-section" *ngIf="showTopUp ">
        <div *ngIf="displayCashAmount() > 0">
          <span class="d-block">
            <img aria-hidden="true" alt="" width="14" src="../../assets/images/icons/add.svg">
            <span class="sr-only" translate>plus</span>
          </span>
          <span class="total-tax"><price-component  [price]="displayCashAmount()"></price-component></span>
          <span class=" d-block font-s" translate>(USD Payment)</span>
        </div>
      </div>
    </div>
    <br>
    <p *ngIf="type=='flight' || (type.length > 1 && type.includes('flight'))" class="d-block summary-description">
      <span translate>Total price includes taxes and fees. Prices are not guaranteed until purchase is complete</span>.
      <a data-toggle="modal" data-target="#baggage-modal" tabindex="0" role="button" (keydown.enter)="openBaggeInfoModal()" (click)="openBaggeInfoModal()">
      <span translate>Baggage fees</span>
      </a>
      <img alt="Open in modal" class="new-modal-window-icon in-text" src="../../assets/images/new_modal.svg">
      <span translate>are not included in your trip cost</span>.
    </p>
    <p *ngIf="type == 'hotel' || (type.length > 1 && type.includes('hotel')) " class="d-block summary-description">
      <span translate>Total price includes taxes and fees. Prices are not guaranteed until purchase is complete</span>.
      <span><u id="resort-fee-open" role="button" tabindex="0" (click)="openFeeModal()" class=" pointer" translate>Resort fee</u></span>
      <span translate> is not included in your trip cost</span>.
    </p>
    <p *ngIf="type=='car' || (type.length > 1 && type.includes('car') )" class="d-block summary-description" translate>
      <span>Total estimated price shown above is in pick-up location's local currency and includes most of the taxes and fees. You'll not be charged now and will be paying the rental car company in the local currency at the time of pick-up. Price may change depending on special requests, additional items, upgrades, local taxes and fees so please review reservation terms and conditions carefully.</span><br><br>
      <span>If you are using a corporation or association discount, you may be asked for Identification or Proof of Eligibility at the time of pickup. Please save the booking confirmation for pricing breakdown as only the total estimated price may be available post-booking.</span>
    </p>
  </div>
</div>


<modal *ngIf="type.includes('flight')" id="flight-tax-and-fees-modal" #allTaxesModal>
  <div class="app-modal-header"> <h3 translate>Taxes & Fees </h3></div>
  <div class="app-modal-body" style="min-height: 100px">
    <p *ngFor="let text of contractFlight.popup_data.taxes_and_fees.paragraph_data" [innerHTML]="text"></p>
  </div>
</modal>
<modal *ngIf="type.includes('flight')" id="baggage-modal" #baggageModal>
  <div *ngIf="type.length == 1" class="app-modal-header"> <h3 translate>Baggage Information</h3></div>
  <div *ngIf="type.length == 1" class="app-modal-body" style="min-height: 100px">
    <h4>{{contractFlight.popup_data.baggage_fees.title}}</h4>
    <span *ngFor="let info of contractFlight.popup_data.baggage_fees.paragraph_data" [innerHTML]="info"></span>
  </div>
  <div *ngIf="type.length > 1" class="app-modal-header"> <h3 translate>Taxes and Baggages Information</h3></div>
  <div *ngIf="type.length > 1" class="app-modal-body" style="min-height: 100px">
    <h4>{{contractFlight.popup_data.baggage_fees.title}}</h4>
    <span *ngFor="let info of contractFlight.popup_data.baggage_fees.paragraph_data" [innerHTML]="info"></span>
    <br>
    <h4>Taxes Information</h4>
    <p *ngFor="let text of contractFlight.popup_data.taxes_and_fees.paragraph_data" [innerHTML]="text"></p>
  </div>
</modal>
<modal *ngIf="type.includes('hotel')" id="hotel-fee-link" #hotelInfoModal>
  <div class="app-modal-header"> <h3 translate>Hotel Information</h3></div>
  <div class="app-modal-body" style="min-height: 100px">
    <div *ngFor="let item of contractHotel.hotel_view.policy_data">
      <h5><strong>{{item.title}}</strong></h5>
      <p *ngFor="let text of item.paragraph_data" [innerHTML]="text"></p>
    </div>
  </div>
</modal>
<modal *ngIf="type.includes('hotel')" id="hotel-tax-link" #hotelTaxAndFees>
  <div class="app-modal-header"> <h3 translate>Taxes & Fees</h3>
  </div>
  <div class="app-modal-body" style="min-height: 100px">
    <p class="d-block font-s" *ngFor="let tax of contractHotel.hotel_view.taxes_and_fees">{{tax}}</p>
  </div>
</modal>
<modal *ngIf="type.includes('hotel')"   id="resort-fee" #resortFee>
  <div class="app-modal-header"> <h3 [ngClass]="{'text-capitalize':translateService.currentLang == 'en'}"  translate>Resort fee</h3></div>
  <div class="app-modal-body" style="min-height: 100px">

    <div class="d-block font-s">
      <p translate>Depending on the property you stay at you may also be charged (i) certain mandatory hotel specific service fees, for example, resort fees (which typically apply to resort-type destinations), energy surcharges, newspaper delivery fees, in-room safe fees, tourism fees, or housekeeping fees and/or (ii) certain optional incidental fees, for example, parking charges, minibar charges, phone calls, room service and movie rentals, etc. These charges, if applicable, will be payable by you to the hotel directly in local currency at the time of checkout. When you check-in, a credit card or, in the hotel's discretion, a debit card will be required to secure these charges and fees that you may incur during your stay. Please contact the hotel directly as to whether and which charges or service fees apply.</p>
    </div>

  </div>
</modal>
<div *ngIf="type.includes('hotel') && contractHotel?.room_data.rate_data[0].price_details.mandatory_fee_details"  class="tax-wrapper hotel-tax mt-5 pr-3 pl-3">
  <div class="pt-2 pl-1">
    <div>
      <span class="danger-icon"><img aria-hidden="true" alt="" src="../../assets/images/icons/StatusWarningOnLight.svg"> </span>
      <span class="ml-2 font-xs"><span class="medium" translate>Remember:</span>  <span class="remember-desc" translate> Your amount due at hotel</span></span>
    </div>
    <div class="mb-3 mt-2">
      <span class="mandatory-tax"><price-component  [currency]="contractHotel.room_data.rate_data[0].price_details.mandatory_fee_details?.display_currency" [price]="contractHotel.room_data.rate_data[0].price_details.mandatory_fee_details?.display_total"></price-component></span>
      <span class="d-flex font-xs" id="mandatory-fee">
        <ng-template  #tipMandatoryFeeHotel>
            <div class="row">
              <div class="col-12">
               <h5 [ngClass]="{'text-capitalize':translateService.currentLang == 'en'}" translate>Resort fee</h5>
                      <p id="mandatory-text" translate>Depending on the property you stay at you may also be charged (i) certain mandatory hotel specific service fees, for example, resort fees (which typically apply to resort-type destinations), energy surcharges, newspaper delivery fees, in-room safe fees, tourism fees, or housekeeping fees and/or (ii) certain optional incidental fees, for example, parking charges, minibar charges, phone calls, room service and movie rentals, etc. These charges, if applicable, will be payable by you to the hotel directly in local currency at the time of checkout. When you check-in, a credit card or, in the hotel's discretion, a debit card will be required to secure these charges and fees that you may incur during your stay. Please contact the hotel directly as to whether and which charges or service fees apply.</p>
              </div>
            </div>
          </ng-template>
        <span [ngClass]="{'text-capitalize':translateService.currentLang == 'en'}"  translate>Resort fee </span>
        <span class="ml-1" [autoClose]="true"  role="button" tabindex="0" triggers="manual" #rf="ngbTooltip" (blur)="rf.close();tooltipBanner=false" (focus)="rf.open()" (click)="rf.open(); tooltipBanner= !tooltipBanner" [ngbTooltip]="tipMandatoryFeeHotel" ><img alt="{{'Resort Fee Details' | translate}}" aria-describedby="mandatory-text" height="12" [src]=" !tooltipBanner ? tooltipOpen : tooltipClose" > <span class="sr-only" translate>Open tooltip</span></span>
      </span>
    </div>
    <div class="font-10  pb-3"><p translate>This is an estimate of the resort fee that would be charged by the hotel or the accommodation supplier for your stay. <br><br>
      The resort fee is an additional charge not covered as part of your redemption and is subject to change. The resort fee is payable to the hotel or accommodation supplier directly in <span class="medium">local currency</span>.
    </p></div>
  </div>
</div>

<div *ngIf="type.includes('car')" #policy_modal id="policy-modal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" translate>Car Policy Information</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="mt-2" *ngFor="let inf of contractCar.car_policy_data">
        <h4 [innerHTML]="inf.title"></h4>
        <p [innerHTML]="inf.description"></p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="type.includes('car')" #contract_info_modal id="contract-info-modal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" translate>Important Booking Terms</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div  class="mt-2" *ngFor="let inf of contractCar.important_information">
        <h4>{{inf.title}}</h4>
        <p>
          <span #info>{{inf.text}}</span>
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="type.includes('car')" class="tax-wrapper car-tax mt-5 pl-3 pr-3">
  <div class="pt-2 pl-1">
    <div>
      <span ><img aria-hidden="true" alt="" width="20" src="../../assets/images/information.svg"></span>
      <span class="ml-2 "><span class="medium" translate>Note:</span>&nbsp;<span>The estimated amount due at the rental car counter.</span></span>
    </div>
    <div class="mb-3 mt-2">
      <span class="mandatory-tax">
        <price-component [price]="parseNumber(contractCar.pricing.display_total) + bookingCarService.bookInsurance" [currency]="'USD'" ></price-component></span>
    </div>
    <div class="font-10  pb-3"><span translate>You'll not be charged now and will be paying the rental car company in the country's local currency at the time of pick-up. Based on the current currency exchange rate, we expect you to pay the amount noted above.</span> <span *ngIf="displayPrice() > 0"> Prior to your arrival, you will receive a statement credit of
      <price-component [price]="(showTopUp ? displayPrice()/ conversion_rate :  displayPrice())" [currency]="'USD'"  ></price-component>.</span></div>
  </div>
</div>








