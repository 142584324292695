<div class="tec-container mt-3">
  <h2><strong>Terms & Conditions</strong></h2>
  <hr>
  <div class="row mt-3">
    <div class="col-sm-12">
      <h3>Web Site Terms & Conditions</h3>
      <p>
        Engage People has engaged Priceline LLC OR Priceline Partner Network Limited (and for the purposes of this Agreement, individually and together with Engage People, the University of Wisconsin Credit Union “UWCU”, 'Priceline' or 'we') to provide you a single source for your travel service needs. These terms and conditions govern your use of these travel services provided by Engage People, UWCU and this web site (the 'Site'). This Site, along with our mobile sites, is intended for personal, noncommercial use. This Agreement describes the terms and conditions applicable to the services available through this Site. This Agreement describes your responsibilities and, among other things, limits the liability of Engage People, UWCU and Priceline. BEFORE SUBMITTING AN ACCOUNT REGISTRATION FORM AND/OR USING ANY OF THESE SERVICES, PLEASE READ ALL OF THIS AGREEMENT CAREFULLY. BY ACCESSING ANY AREAS OF THIS SITE, USERS ('USERS' OR 'YOU')
        AGREE TO BE LEGALLY BOUND WITHOUT LIMITATION, QUALIFICATION OR CHANGE AND TO ABIDE BY THESE TERMS AND CONDITIONS, WHICH WILL CONSTITUTE OUR AGREEMENT ('AGREEMENT').
        If at any time you do not agree with any part of this Agreement, YOU MUST DISCONTINUE USE OF THIS SITE. Priceline reserves the right, in its sole discretion, to amend, modify or alter
        this Agreement at any time by posting the amended terms on this Site. We recommend that you review these terms and conditions periodically. The amended terms shall be effective from and after the date
        that they are posted on the Site. This Agreement may not otherwise be amended except in writing signed by both parties. Priceline incorporates herein, by reference, its <a rel="noopener noreferrer" target="_blank" href="https://www.engagepeople.com/privacy/">Privacy Policy</a>
      </p>

      <p>
        WITHOUT LIMITATION OF ANY OTHER PROVISIONS OF THIS AGREEMENT, YOU MAY NOT USE THIS SITE FOR ANY PURPOSE THAT IS UNLAWFUL OR PROHIBITED BY THIS AGREEMENT AND/OR ANY APPLICABLE ADDITIONAL TERMS. YOUR ACCESS TO THIS SITE MAY BE TERMINATED IMMEDIATELY IN PRICELINE'S SOLE DISCRETION, WITH OR WITHOUT NOTICE, IF YOU FAIL TO COMPLY WITH ANY PROVISION OF THIS AGREEMENT AND/OR ADDITIONAL TERMS, OR FOR ANY OTHER REASON, OR NO REASON.
      </p>

      <h3>Table of Contents</h3>
      <strong>PART I: Legal Action</strong>
      <ul>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p1.1" target="_blank">Arbitration and Dispute Resolution</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p1.2" target="_blank">Disclaimer of Warranties</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p1.3" target="_blank">General Limitation of Liability</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p1.4" target="_blank">Indemnification and Chargebacks</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p1.5" target="_blank">Trademark and Copyright Notice</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p1.6" target="_blank">Claims of Copyright Infringement</a></li>
      </ul>

      <strong>PART II: Acceptable Use of the Website</strong><br>
      <strong>PART III: User Rights and Responsibilities</strong><br>
      <strong>PART IV: Travel Services</strong>
      <ul>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p4.1" target="_blank">General</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p4.2" target="_blank">Airlines</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p4.3" target="_blank">Hotels</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p4.4" target="_blank">Rental Cars</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p4.5" target="_blank">Cruises</a></li>
        <li><a rel="noopener noreferrer" href="https://secure.rezserver.com/hotels/help/terms/?refid=8626#p4.6" target="_blank">Vacation Package</a></li>
      </ul>

      <strong>PART V: Miscellaneous and Registrations</strong><br>
      <div>
        <h3>PART I: Legal Action</h3>
        <strong>A. Arbitration and Dispute Resolution</strong>
        <p>1. Disputes and Arbitration</p>
        <p>Priceline is committed to customer satisfaction. If you have a problem or dispute, we will try to resolve it. If we are unsuccessful, you may pursue your claim as explained in this section.</p>
        <p>You agree to give us an opportunity to resolve any problem, dispute, or claim relating in any way to the Priceline websites and/or any of its related applications or services (the “Site”); any dealings with our customer service agents; the purchase, use, or performance of any services or products available through this Site; any representations made by Priceline; or our Privacy Policy (collectively, “Claims”) by providing Notice to Customer Support. Unless prohibited by applicable law, any Claim must be brought within two (2) years from the date on which such Claim arose or accrued. If we are unable to resolve your Claims within 60 days, you may seek relief through arbitration or small claims court, as set forth below.</p>
        <p>These Terms and Conditions shall be governed by the laws of the State of Connecticut, United States of America, without regard to conflict of laws. All Claims or other matters in dispute between you or any Third-party and Priceline, its subsidiaries or affiliates, or any travel service providers or companies offering products or services through the Site, whether based upon contract, tort, statute, or otherwise, shall likewise be governed by the laws of the State of Connecticut, without regard to conflict of laws provisions that would result in the application of the laws of any other jurisdiction.</p>
        <p>2. Mandatory Arbitration</p>
        <p>Please read this provision carefully. It requires that any and all claims must be resolved by binding arbitration or in small claims court, and it prevents you from pursuing a class action or similar proceeding in any forum. These limitations apply to any claims against Priceline, its subsidiaries or affiliates, or any travel service providers or companies offering products or services through the site.</p>
        <p>In arbitration, a dispute is resolved by an arbitrator instead of a judge or jury. The arbitrator's decision will generally be final and binding, with no right of appeal. Arbitration procedures are simpler and more limited than court procedures.</p>
        <p>a. By using this Site, you, any Third-party, and Priceline agree that any Claim, including claims regarding the applicability or validity of this arbitration provision, shall be resolved exclusively by final and binding arbitration administered by the American Arbitration Association (“AAA”) and conducted before a single arbitrator pursuant to the then applicable Rules and Procedures established by AAA (“Rules and Procedures”), except that, pursuant to subsection (e) below, under no circumstances may any claim be brought or arbitrated as a class action or be joined with another person's claim, or proceed on a basis involving claims brought on a purported representative capacity (either on behalf of the general public or other users or persons).<br>
          As an exception to arbitration, you, any Third-party, and Priceline retain the right to pursue in a small claims court located in the federal judicial district that includes you or the Third-party’s billing address at the time of the Claim, any Claim that is within the court's jurisdiction and proceeds on an individual basis. All Claims you or a Third-party bring against Priceline, its subsidiaries or affiliates, or any travel service providers or companies offering products or services through the Site must be resolved in accordance with this Disputes and Arbitration Section. All Claims filed or brought contrary to this Section shall be considered improperly filed and void.
          b. If you decide to seek arbitration, you must first send, by certified mail, a written Notice of Dispute ("Notice") addressed to: Legal Department, Priceline LLC, 800 Connecticut Avenue, Norwalk, CT 06854 ("Notice Address"). The Notice must (i) describe the nature and basis of the Claim; and (ii) set forth the specific relief sought. If Priceline and you, or Priceline and any Third-party, do not reach an agreement to resolve the Claim within 60 days after the Notice is received, you, or the Third-party, may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you, any Third-party, or Priceline is entitled.
          c. The arbitration shall be held at a location determined by AAA pursuant to the Rules and Procedures, or at such other location as may be mutually agreed upon.<br>
          d. To the extent that any Claim is held not to be subject to arbitration and proceeds in a Court other than small claims court, such Claim shall be filed only in the United States District Court for Connecticut or, if there is no federal jurisdiction over the action, in the courts of the State of Connecticut located in Fairfield County, Connecticut. You hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such Claim that is not subject to the arbitration provision and not pursued in small claims court, and agree that any such claim shall be resolved individually, without resort to any form of class action (as described more fully in section I.A.2.e below). You further agree that any and all Claims or other matters asserted in such dispute, whether based upon contract, tort, statute, or otherwise, shall be governed by the laws of the State of Connecticut, without regard to conflict of laws provisions that would result in the application of the laws of any other jurisdiction.<br>
          e. You and any third-party further agree that no proceeding against Priceline, its affiliates, or any travel service providers or companies offering products or services through the site (under this provision or otherwise) may proceed as a class action, be joined with another person’s claim, or proceed on a basis involving claims brought in a purported representative capacity (either on behalf of the general public or other users or persons). Any and all proceedings to resolve claims will be conducted only on an individual basis. In addition, no arbitration proceeding under this provision shall be consolidated or joined in any way with any other arbitration proceeding without the express written consent of all parties.<br>
          f. For any arbitration initiated pursuant to this agreement involving Claims that together seek damages that do not exceed $25,000, Priceline will reimburse you for all fees and costs imposed by AAA. If you are unable to pay the filing fee, Priceline will pay it for you. For any arbitration involving Claims that together seek damages that exceed $25,000, if you, or any Third-party, prevail in the arbitration of any Claim against Priceline, Priceline will reimburse such prevailing party for any fees or costs the prevailing party paid to AAA in connection with the arbitration.<br>
          g. If you prevail in the arbitration of any Claim against Priceline and are awarded an amount greater than Priceline’s last written settlement offer to you, Priceline will pay a minimum recovery of $5,000, and Priceline will reimburse you for all reasonable attorney’s fees incurred in arbitrating the Claim(s) upon which you have prevailed. If any Third-party prevails in the arbitration of any Claim against Priceline and is awarded an amount greater than Priceline’s last written settlement offer to such Third-party, Priceline will pay a minimum recovery of $5,000 to the Third-party, and Priceline will reimburse such Third-party for all reasonable attorney’s fees incurred in arbitrating the Claim upon which he or she has prevailed. Any dispute regarding attorney’s fees to be paid pursuant to this paragraph will be decided by the arbitrator who decided the underlying Claim. If you or the Third-party do not prevail on the claim or prevail but are awarded an amount less than or equal to Priceline’s last written settlement offer to you, Priceline will pay only the amount of the award, not the minimum recovery or attorneys’ fees.<br>
          h. Notwithstanding any other provision of law or any of the Rules and Procedures established by AAA which may be to the contrary, Priceline will not be entitled to seek reimbursement of its attorney’s fees for any Claim the arbitrator finds to be non-frivolous.<br>
          i. With the exception of sub-part (e) above (the class action waiver), if any part of this arbitration provision is held to be invalid, unenforceable or illegal, or otherwise conflicts with the Rules and Procedures established by AAA, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not contained herein. If, however, subpart (e) above (the class action waiver) is held to be invalid, unenforceable or illegal, then the entirety of this arbitration provision shall be null and void, and neither you nor any Third-party, nor Priceline shall be entitled to arbitrate their dispute.<br>
          j. Arbitration rules and forms may be obtained from AAA <a rel="noopener noreferrer" href="https://www.adr.org/" target="_blank">here</a> or by calling AAA at 1-800-778-7879.<br>
          k. If you or any third-party do not choose to accept this binding arbitration provision, you or such third-party must notify Priceline in writing by certified mail within thirty (30) days of your purchase or before you begin to use the services purchased on this site, whichever date is sooner. Such notice shall be sent to the notice address defined in subsection (b), above. If you so notify us by that time that you do not accept the binding arbitration provision, you and any such third-party may not continue to purchase services or products on this site unless and until Priceline notifies you or such third-party otherwise. Priceline shall have the right to prohibit your and such third-party’s future purchase of services or products on this site.
        </p>
        <p>3. Credit Card Chargebacks</p>
        <p>You, the User, have the ability to dispute charges with credit card companies (“chargebacks”). If you have a question about a charge on your credit card statement, we encourage you to call Priceline prior to disputing a charge with your credit card company to discuss any questions or concerns about our charges with us. In all cases, Priceline will work with you in resolving your concerns. Priceline retains the right to dispute any chargeback that it believes is improper, as described more fully below. Priceline also retains the right to fully cancel any travel reservation in the event of a chargeback related to that reservation.</p>
        <p>By using our service to make a reservation with a Supplier, you accept and agree to the relevant cancellation policy of that Supplier. In all cases, the cancellation policy of each reservation is made available on our website. Please note that certain rates or special offers are not eligible for cancellation or change. Priceline deems the following chargeback scenarios as improper and retains the right to investigate and rebut any such chargeback claims and to recover costs of such chargeback claims from You, the User.</p>
        <ul>
          <li>Chargebacks resulting from non-cancellable reservations in the event that Priceline or the Supplier cannot provide a refund, whether or not the reservation is used.</li>
          <li>Chargebacks resulting from charges authorized by family, friends, associates or other third parties with direct access to You, the User’s, credit card.</li>
          <li>Chargebacks arising from the Supplier’s failure to deliver a product or service in a manner that’s consistent with the Supplier’s product description.</li>
          <li>Chargebacks resulting from force majeure or other circumstances that are beyond the control of Priceline or its subsidiaries.</li>
        </ul>
        <strong>B. Disclaimer of Warranties</strong>
        <p>All content contained within or available through this site are provided to you on an "as is," basis. Priceline makes no representations or warranties of any kind, either express or implied, as to the operation of this website or the information, content or materials included on this website. To the fullest extent permissible, Priceline disclaims all representations and warranties, including, but not limited to, the implied warranties of merchantability or satisfactory workmanlike effort, informational content, title, or non-infringement of the rights of third parties. Priceline does not warrant or make any representations that this site will operate error-free or uninterrupted, that defects will be corrected, or that this site and/or its servers will be free of viruses and/or other harmful components. Priceline does not warrant or make any representations regarding suitability, availability, accuracy, reliability, completeness, or timeliness of any material of any kind contained within this site for any purpose, including software, products, services, information, text and related graphics content.</p>
        <p>Without limiting the foregoing, no warranty or guarantee is made (i) regarding the acceptance of any request, (ii) that a user will receive the lowest available price for goods and/or services available through this site, (iii) regarding the availability of products and/or services through this site or, where applicable, at any participating retailer or retailer location, or (iv) regarding the results that may be obtained from the use of this site.</p>
         <strong>C. General Limitation of Liability</strong>
        <p>To the extent permitted by law, in no event shall Engage People, UWCU, or Priceline, including its respective officers, directors, employees, representatives, parents, subsidiaries, affiliates, distributors, suppliers, licensors, agents or others involved in creating, sponsoring, promoting, or otherwise making available the site and its contents (collectively the "Covered Parties"), be liable to any person or entity whatsoever for any direct, indirect, incidental, special, exemplary, compensatory, consequential, or punitive damages or any damages whatsoever, including but not limited to: (i) loss of goodwill, profits, business interruption, data or other intangible losses; (ii) your inability to use, unauthorized use of, performance or non-performance of the site; (iii) unauthorized access to or tampering with your personal information or transmissions; (iv) the provision or failure to provide any service; (v) errors or inaccuracies contained on the site or any information, software, products, services, and related graphics obtained through the site; (vi) any transactions entered into through this site; (vii) any property damage including damage to your computer or computer system caused by viruses or other harmful components, during or on account of access to or use of this site or any site to which it provides hyperlinks; or (viii) damages otherwise arising out of the use of the site, any delay or inability to use the site, or any information, products, or services obtained through the site. The limitations of liability shall apply regardless of the form of action, whether based on contract, tort, negligence, strict liability or otherwise, even if a Covered Party has been advised of the possibility of damages.
          Further, the travel service providers are independent contractors and not agents or employees of the Covered Parties. To the extent permitted by law, the Covered Parties do not assume liability or for any injury, damage, death, loss, accident or delay due to an act or omission of a travel service provider and do not accept responsibility for any damage and/or delay due to sickness, pilferage, labor disputes, bankruptcy, machinery breakdown, quarantine, government restraints, weather, terrorism or causes beyond the covered parties' control.
        </p>
        <p>No Covered Party shall be responsible for any Travel Service Provider's breach of warranty, nor for any other wrongdoing of a Travel Service Provider (including any liability in tort), as to any products and/or services available through this Site. No Covered Party shall be responsible for any Travel Service Provider's failure to comply with this Agreement or with applicable federal, state, provincial and local law.</p>
        <p>To the extent not prohibited by law, if, notwithstanding the above, a Covered Party is found liable for any loss or damage relating to the use of this Site, User agrees the liability of any such party shall in no event exceed the total charge to the User assessed by Priceline for making a Request.</p>
        <strong>D. Indemnification</strong>
        <p>You agree to defend and indemnify Engage People, UWCU, Priceline and the Covered Parties from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought (i) by you or on behalf of you in excess of the liability described above; or (ii) by third parties as a result of:</p>
          <ul>
            <li>your breach of this Agreemen</li>
            <li>your violation of any law or the rights of a third party; or
            </li>
            <li>your use of this Site in violation of this Agreement.</li>
          </ul>
        <strong>E. Copyright and Trademark Notices</strong>
        <p>The material and content on this Site, including without limitation, the information, text, software, code, photographs, videos, typefaces, graphics, music, sounds, images, illustrations, maps, designs, icons, trademarks, service marks, logos, and written content (collectively, “Content”), and the arrangement and compilation of the Content, are owned by Engage People, UWCU, Priceline, Priceline’s corporate affiliates and/or the Travel Service Providers. It is possible that some trademarks, service marks and other company designations on the Site may belong to third parties and are used on the Site under license or for identification purposes only. The use of marks on the Site that belong to third parties, and the availability on the Site of goods or services from such third parties, should not be construed as an affiliation, endorsement or sponsorship of this Site and its services by any such third party.</p>
        <p>The Content includes but is not limited to, the following trademarks and service marks, and registered trademarks and service marks, owned by Priceline: PRICELINE, PRICELINE, NAME YOUR OWN PRICE, EXPRESS DEALS, EXPRESS DEALS UNWRAPPED, PRICELINE NEGOTIATOR, THE NEGOTIATOR, PRICEBREAKER, HOTEL FREEBIES, "HAND PICKED DEALS", "PAY WHEN YOU STAY, TONIGHT-ONLY DEALS and WHATEVER’S ON THE LINE.</p>
        <p>The Content on the Site is protected under U.S. and international copyright and trademark laws. Your use of and access to the Site does not grant you any license or right to use any of the Content on the Site. It is expressly prohibited for you to use, transmit, copy or reproduce the Content in any form, including on any website or in any domain name, without authorization from Priceline, except if such use is for personal, non-commercial use.</p>
        <strong>F. Claims of Copyright Infringement</strong>
        <p>Priceline respects the intellectual property rights of others and asks that you do the same. If you believe in good faith that material or content on the Site infringes your copyrighted work, you (or your agent) may send us a written notice under the Digital Millennium Copyright Act that includes the following information:</p>
          <ul>
            <li>A clear identification of the copyrighted work that you claim has been infringed.</li>
            <li>A clear identification of the material you claim infringes the copyrighted work, and information that will allow us to locate that material on the Site, such as a link to the infringing material.</li>
            <li>Your contact information so that we can reply to your complaint, preferably including an email address and telephone number.</li>
            <li> A statement that you have a "good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law."</li>
            <li>A statement that "the information in the notification is accurate, and under penalty of perjury, the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed."</li>
            <li>The written notice must be signed by the person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
          </ul>
        <p>Copyright infringement notifications can be sent to Priceline by email, mail, or facsimile as follows:</p>
        <p>Priceline LLC</p>
         <p> Attn: IP Counsel</p>
          <p>800 Connecticut Avenue</p>
          <p>Norwalk, CT 06854 </p>
          <p>legal@priceline.com </p>
         <p> Facsimile: (203) 299-8917 </p>
         <p> We will not process your notice if it is incomplete. Priceline reserves the right to remove content on the Site alleged to be infringing without prior notice, at its sole discretion. You may wish to seek legal counsel prior to submitting a copyright infringement notification. You could be held liable for alleging false claims of copyright infringement.
         </p>
      </div>
      <div>
        <h3>PART II: Acceptable Use of the Website</h3>
        <p>You must be at least 18 years of age to be eligible to purchase, cancel or modify any services available through Priceline. Accessing materials on this Site by certain persons in certain countries may not be lawful, and Priceline makes no representation that materials on this Site are appropriate or available for use in locations outside of the United States.</p>
        <p>We grant you a limited, personal, nontransferable, non-sub licensable, revocable license to access and use this Site only as expressly permitted in this Agreement. You may only use this Site to make legitimate reservations, purchases or requests to purchase the products or services offered (each, a "Request") and shall not use this Site to make any speculative, false or fraudulent Requests or any Requests in anticipation of demand. You agree to provide correct and true information in connection with your use of this Site. It is a violation of law to place a Request in a false name or with an invalid method of payment. Priceline reserves the right to cancel any airline, hotel or rental car reservation or any other transaction that it reasonably believes to have been fraudulently made, including by unauthorized use of a credit or debit card.</p>
        <p>Any rights or license not expressly granted herein are reserved. The content and information on this Site, as well as the infrastructure used to provide such content and information, is proprietary to Priceline and/or its Travel Service Providers (hotels, rental car companies, airlines, travel insurer and other providers). Accordingly, you agree not to use this Site or its contents or information for any commercial or non-personal purpose or for any purpose that is unlawful or prohibited by this Agreement. While you may make limited copies of your documents for travel, you agree not to modify, copy, distribute, transmit, display, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from this Site.</p>
        <p>In addition, whether or not you have a commercial purpose, you agree not to:</p>
        <ul>
          <li> access, monitor or copy any content or information of this Site using any robot, spider, scraper or other automated means or any manual process for any purpose without express written permission of Priceline;</li>
          <li> violate the restrictions in any robot exclusion headers on this Site or bypass or circumvent other measures employed to prevent or limit access to this Site;</li>
          <li>take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure;</li>
          <li>deep-link to any portion of this Site for any purpose.</li>
          <li>deliver any unlawful postings to or through this Site;</li>
          <li>deliver, or provide links to, any postings containing material that could be considered harmful, harassing, obscene, pornographic, indecent, violent, abusive, profane, insulting, threatening, tortuous, defamatory, false, hateful or otherwise objectionable;</li>
          <li>deliver any posting that infringes or violates the intellectual property of others including, without limitation, copyrights, patents, trademarks, laws governing trade secrets, rights to privacy, or publicity;</li>
          <li>deliver any posting that you do not have a right to make available under law or contractual or fiduciary relationships;</li>
          <li>manipulate identifiers, including by forging headers, in order to disguise the origin of any posting that you deliver;</li>
          <li>use this Site in any manner which could damage, disable, overburden, impair or otherwise interfere with the use of this Site or other users' computer equipment, or cause damage, disruption or limit the functioning of any software, hardware, or telecommunications equipment</li>
          <li>attempt to gain unauthorized access to this Site, any related website, other accounts, computer system, or networks connected to this Site, through hacking, password mining, or any other means; or</li>
          <li>obtain or attempt to obtain any materials or information through any means not intentionally made available through this Site, including harvesting or otherwise collecting information about others such as email addresses</li>
        </ul>
      </div>
      <div>
        <h3>PART III: User Rights and Responsibilities</h3>
        <strong>Privacy Policy</strong>
        <p>Your privacy is very important to us. Please read our <a rel="noopener noreferrer" href="https://www.engagepeople.com/privacy/" target="_blank">Privacy Policy</a>, which governs your use of this Site. By using this Site, you agree that the terms of this policy are reasonable and satisfactory to you. You consent to the use of your personal information by Priceline and/or its Travel Service Providers and distributors in accordance with the terms of this Privacy Policy and the terms of our Travel Service Providers' privacy policies. Priceline will not be responsible for any damages resulting from a lapse in compliance with the Privacy Policy</p>
        <strong>Accounts, Security, Passwords</strong>
        <p>You may register to utilize this Site by completing the specified registration process and providing us with current, complete, and accurate information as requested by the online registration form. It is your responsibility to maintain the completeness and accuracy of your registration data, and any loss caused by your failure to do so is your responsibility. As part of the registration process, you will be asked to choose a security question. It is your responsibility to maintain the confidentiality of your security question and your account. You agree to notify Priceline immediately of any unauthorized use of your account. Priceline is not liable for any loss that you may incur as a result of someone else using your account, either with or without your knowledge.</p>
        <strong>Third Parties</strong>
        <p>If you use this Site to submit Requests for or on behalf of a third-party ("Third-party"), such as a family member or a traveling companion, you are responsible for any error in the accuracy of information provided in connection with such use (for example, the billing address and phone number of the credit card holder). In addition, you must inform the Third-party of all Terms and Conditions applicable to all products or services acquired through this Site. Each User using this Site for or on behalf of a Third-party agrees to indemnify and hold each Covered Party harmless from and against any and all liabilities, losses, damages, suits and claims (including the costs of defense), relating to the Third-party's or the User's failure to fulfill any of its obligations as described above. You are directly responsible for any Request submitted including for total charges and performance obligations.</p>
        <strong>No Agency Relationship</strong>
        <p>Engage People, UWCU and Priceline do not agree to act as your agent or fiduciary in providing services through the Site.</p>
        <strong>Modification/Termination of Usage</strong>
        <p>Priceline solely reserves the right to modify, suspend, or terminate this Site and/or any portion thereof, including any service or product available through the Site, and/or your profile, Account password, or use of the Site, or any portion thereof, at any time for any reason with or without notice to you. In the event of termination, you will still be bound by your obligations under this Agreement and any additional terms, including the warranties made by you, and by the disclaimers and limitations of liability. Additionally, Priceline shall not be liable to you or any third-party for any termination of your access to this Site.</p>
        <strong>Links to Other Web Sites and Services</strong>
        <p>To the extent this Site contains links to outside services and resources, or to the extent that third party websites link to this Site, any concerns regarding such services or resources should be directed to the particular outside service or resource provider. The presence of a link to a third-party site does not constitute or imply Priceline's endorsement, sponsorship, or recommendation of the third-party, or of its content, products or services. If you choose to access any third-party site, you do so at your own risk.</p>
        <strong>User Comments, Feedback, and Other Submissions</strong>
        <ul>
          <li>Certain portions of the Site may permit you to submit post, transmit or upload content created by you ("User Submissions"), such as photographs, information, text, images, graphics, video, comments, suggestions, ideas (including product and advertising ideas), posts to blogs / social networks (including any Priceline branded Facebook, Twitter or similar channel) / discussion forums, and/or communications with other Site users. User Submissions also include content you post on social media and give Priceline permission to use, for example, by your posting of a hashtag Priceline is promoting or other indication of acceptance of these terms and conditions.</li>
          <li> You agree that you will not submit any User Submission that: (a) includes material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights (including, without limitation, trademark, privacy and publicity rights) unless you are the owner of such rights or have express permission from their rightful owner to post the material; (b) includes any material that, infringes upon, misappropriates or violates the rights of any person or entity or any applicable laws; (c) is unlawful, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law or is otherwise inappropriate; (d) contains advertisements or solicitations of any funds, goods or services; (e) is a communication by a user impersonating another user; (f) contains personal information, such as messages which identify an individual’s names, telephone numbers, social security numbers, account numbers and/or addresses; or (g) could be considered bulk unsolicited communications.</li>
          <li> By submitting, transmitting, posting, uploading, modifying or otherwise providing any User Submission to Priceline via the Site, social media or otherwise, whether solicited or unsolicited, you agree that you are granting Priceline a royalty-free, fully paid, non-exclusive, irrevocable, perpetual, unrestricted, worldwide, sub-licensable, transferable license to use, reproduce, broadcast, publish, transmit, perform, display, create derivative works from, translate, adapt, modify, distribute, exhibit, disseminate and otherwise exploit (collectively, "Use") such User Submission for any purpose, including, without limitation, advertising, marketing and promotional purposes, in any media, now or hereafter known, even if this Agreement is later modified or terminated.</li>
          <li>No credit, approval or compensation is due to you for any such Use of any User Submission you may submit. Priceline shall also have the right, but not the obligation, to Use your username (and real name, image, likeness, caption, location information and/or other identifying information)</li>
          <li> You represent and warrant that neither any User Submission, or its Use, will infringe upon, misappropriate or violate the intellectual property, privacy, publicity, statutory, contractual, personal or other rights of any person or entity or any applicable law. You must also obtain all necessary rights for the grants to Priceline, including without limitation, written releases of all rights of privacy and publicity from all individuals included in any way in the User Submission.</li>
          <li>Priceline reserves the right to monitor, review, alter, edit, refuse to post or remove any User Submission. You agree that Priceline does not have any obligation to use or respond to any User Submission. Priceline has no control over whether such User Submissions are of a nature that you might find offensive, distasteful or otherwise unacceptable and, accordingly, Priceline expressly disclaims any and all responsibility for User Submissions.</li>
          <li>Priceline will fully cooperate with any law enforcement authorities or court order requesting or directing Priceline to disclose the identity of anyone posting User Submissions that violate these Terms and Conditions or any law or regulation. Priceline may also disclose such information if it has a good faith belief that such disclosure is reasonably necessary to protect the rights, property, or personal safety of Priceline, its customers or the public.</li>
          <li>By submitting a User Submission, you acknowledge that any personal data supplied by you may be used by Priceline or its agents for the purpose of sending commercial marketing and communication emails to you. Priceline may also share any such personal data with third parties for the purpose of commercial marketing and communications. You hereby consent to such use. You have the right to access, modify and request the destruction of your personal data at any time by sending a request to Priceline; and you have the right to opt out from receiving such marketing and communication at any time by sending a message to Priceline.</li>
          <li>You agree to defend and indemnify Priceline and any Covered Parties involved in creating, sponsoring, promoting, or otherwise making available the Site and its contents, from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature, including but not limited to reasonable legal and accounting fees, brought by third parties as a result of any allegation related to any User Submission that you make.</li>
        </ul>

      </div>
      <div>
        <h3>PART IV: Travel Services</h3>
        <h3>A. General</h3>
        <strong>1. Pricing</strong>
        <p>Priceline endeavors to publish and maintain accurate prices and information for its services. Travel service providers provide us with the price, availability and other information related to these services. In the event, however, that a service is listed or provided to us at an incorrect price or with incorrect information due to typographical error or other error in pricing or service information received from a travel service providers, we retain the right to refuse or cancel any Requests placed for such service, whether or not the order has been confirmed and/or your credit card charged. If your credit card has already been charged for the purchase and your Request is canceled because of incorrect hotel, airline, or car provider information, we will promptly issue a credit to your credit card account in the amount of the charge.</p>
        <strong>2. Travel Service Providers</strong>
        <p>You agree to abide by the terms or conditions of purchase imposed by any Travel Service Provider, whether that Travel Service Provider is selected by you or by Priceline, including, but not limited to, payment of all amounts when due and compliance with the Travel Service Provider's rules and restrictions regarding availability and use of fares, products, or services. You understand that any violation of any such Travel Service Provider's conditions of purchase may result in cancellation of your reservation(s) or purchase(s), in your being denied access to any flights, hotels, or automobiles, in your forfeiting any monies paid for such reservation(s) or purchase(s), and in Priceline debiting your account for any costs Priceline incurs as a result of such violation. You shall be completely responsible for all charges, fees, duties, taxes, and assessments arising out of the use of this Site.
          You agree that we will not be liable for any injury, loss, claim, damage, or any special, exemplary, punitive, indirect, incidental or consequential damages of any kind, which arises out of or is in any way connected with the performance or non-performance of any Travel Service Provider. In the event of non performance resulting from bankruptcy, reorganization, insolvency, dissolution or liquidation of a Travel Service Provider, you agree that your sole recourse shall be toward such Travel Service Provider and not toward us.
          At Priceline, we have no special knowledge regarding the suitability for disabled persons of any reservation. For information concerning the suitability for disabled persons for any reservation, contact the Travel Service Provider directly.
        </p>
        <strong>3. Bookings Through Foreign Sister Companies</strong>
        <p>Certain reservations are facilitated by Priceline through its foreign sister companies. These foreign affiliates include Agoda Company Pte Ltd. (Agoda.com), headquartered in Bangkok, Thailand, Booking.com B.V. (Booking.com), headquartered in Amsterdam, the Netherlands, and TravelJigsaw Limited (rentalcars.com), headquartered in Manchester, United Kingdom.</p>
        <strong>4. Currency and Exchange Rates</strong>
        <p>Purchases made on our Site are transacted in different currencies, depending on the user’s IP address and/or the currency that the user selects. You will be charged in the currency shown upon checkout in the Summary of Charges. If you make a purchase from us using a credit or debit card, please be aware that, due to the constant fluctuation in exchange rates, our charge to your card or the estimated charge amount we provide you, may differ based on the exchange rate at the time you make your reservation versus the rate at the time the charge is reflected on your credit card statement. Also, in the event that we must credit your account, we will refund the exact amount in the currency initially charged and will not be responsible for any fluctuations in exchange rates which may cause differences in your billing statement.</p>
        <strong>5. Trip Protection</strong>
        <p>When you purchase Travel Insurance in connection with one of our travel products, you are subject to the Terms & Conditions of Allianz Global Assistance. Please click <a rel="noopener noreferrer" target="_blank" href="https://www.allianzworldwidepartners.com/usa/terms-and-conditions/001004597">here</a> for Terms & Conditions pertaining to Travel Insurance.</p>
        <h3>B. Airlines</h3>
        <strong>1. Airline Rules</strong>
        <p>Airline tickets available through this Site are subject to the published conditions of carriage and rules, including but not limited to cancellation policies, of the applicable airline. The contract of carriage in use by the applicable airline, when issued, shall be between the applicable airline and the passenger. Airlines retain the right to adjust flight times and schedules at any time. Airlines may also in their discretion change or cancel flights or itineraries. Different tickets on the same airline may carry different restrictions or include different services.</p>
        <strong>2. Code Share</strong>
        <p>Some airlines enter into "code share" agreements with a limited number of select airline partners. This means that on certain routes, the airline carrier selling or marketing the flight does not fly its own aircraft to that destination. Instead, it contracts with a partner airline to fly to that destination. The partner airline is listed as "operated by." In most cases you will check in with your "operating" carrier; however, you should verify your flight check-in location with your ticketing carrier. If your flight is a code share, it will be disclosed to you in the booking process and prior to your payment on a price disclosed, retail airline ticket. If your Express Deals ® itinerary contains a code share, it will be disclosed to you when your booking is confirmed, along with other itinerary information.</p>
        <strong>3. Hazardous Materials</strong>
        <p>Federal law forbids the carriage of hazardous materials aboard aircraft in your luggage or on your person. A violation can result in five years’ imprisonment and penalties of $250,000 or more (49 U.S.C. 5124). Hazardous materials include explosives, compressed gases, flammable liquids and solids, oxidizers, poisons, corrosives and radioactive materials. Examples include paints, lighter fluid, fireworks, tear gases, oxygen bottles, and radio-pharmaceuticals. There are special exceptions for small quantities (up to 70 ounces total) of medicinal and toilet articles carried in your luggage and certain smoking materials carried on your person. See <a rel="noopener noreferrer" target="_blank" href="https://www.faa.gov/about/office_org/headquarters_offices/ash/ash_programs/hazmat/passenger_info/" >https://www.faa.gov/about/office_org/headquarters_offices/ash/ash_programs/hazmat/passenger_info/</a> for more information.</p>
        <strong>4. Airline Prohibited Practices</strong>
        <p>You agree not to purchase a ticket or tickets containing flight segments that you will not be using, such as a "point-beyond", "hidden-city", or "back-to-back tickets" if those practices are prohibited by the airline(s). You further agree not to purchase a round-trip ticket that you plan to use only for one-way travel if that practice is prohibited by the airline(s). You acknowledge that most airlines prohibit all such tickets, and therefore we do not guarantee that the airline will honor your ticket or tickets if you engage in these practices. You agree to indemnify the Covered Parties against airline claims, on the basis of these or any other prohibited practices, for the difference between the full fare of your actual itinerary and the value of the ticket or tickets that you purchased.</p>
        <strong>5. Use of Flight Segments</strong>
        <p>Most airlines require you to use flight coupons in sequential order. For those airlines who allow the use of a ticket for only one segment, if you do not cancel all or part of your journey prior to the departure of any segment of your itinerary, you may forfeit up to the value of your airline ticket, depending on the rules of the fare purchased.</p>
        <strong>6. One Way Tickets</strong>
         <p>For certain itineraries, you are able to purchase two one way tickets on the Site rather than a round trip fare. If you do so, you will be making two separate bookings with two separate and different rules and policies (for example, for baggage fees, change fees, and refunds). Any changes made to one of your bookings will not affect the other booking (for example, if your initial flight is cancelled by either you or the airline, the other airline with which you booked your return flight is not obligated to issue a refund or change your itinerary). If you are traveling internationally, you may need to provide proof that you have a return flight at check in and at immigration.</p>
        <strong>7. Information Required by The Transportation Security Administration</strong>
        <p>The Transportation Security Administration of the U.S. Department of Homeland Security requires us to collect information from you for purposes of watch list screening, under the authority of 49 U.S.C. section 114, and the Intelligence Reform and Terrorism Prevention Act of 2004. TSA may share information you provide with law enforcement or intelligence agencies or others under its published system of records notice. For more on TSA Privacy policies, or to view the system of records notice and the privacy impact assessment, please see TSA's Web site at <a rel="noopener noreferrer" href="https://www.tsa.gov" target="_blank">https://www.tsa.gov</a>.</p>
        <strong>8. Additional Restrictions for Flight Express Deals</strong>
        <p>The following restrictions apply to Priceline's Express Deals® flight service:</p>
        <p>a. All flight reservations are non-cancelable, non-refundable, non-changeable and non-transferable by you. Once you purchase a reservation, your method of payment will be charged for the amount shown - regardless of whether or not the reservation is used. Credit will not be given for any unused reservations and cannot be used toward any future purchases.</p>
        <p>b. We or the airline may, on an exception basis and at the request of the airline passenger, waive one or more of the restrictions after the flight has been booked. We may, in our discretion, impose additional obligations and/or fees in connection with any such waiver.</p>
        <strong>9. Taxes and Fees on Published Price Airline Tickets</strong>
        <p>Government imposed taxes and fees and any applicable airline imposed fees are included in your fare. Tax rates in effect at time of purchase are applied to your ticket price. Retroactive tax rate increases may be the responsibility of the traveler.</p>
        <p>a. Itinerary Changes/Cancellations/Refunds</p>
        <p>Air travel itinerary changes, if permitted by the airline fare rules, may have a change fee charged by the airline typically ranging between $200 and $300 USD per ticket that will vary by market, carrier and specific fare rule. In addition, any changes made with Priceline to your itinerary will incur a per ticket Priceline processing fee.</p>
        <p>b. Baggage and Other Ancillary Airline Fees</p>
        <p>Airlines may require you to pay additional fees at the airport for certain services and/ or if you exceed certain limits as dictated by the airline, such as the number of bags or weight allowed, and some airlines do not offer a free baggage allowance. Some airlines charge additional fees for other services, for example, for carry on baggage, priority boarding and seat assignments. During the booking process, Priceline provides a summary of key terms of an airline’s baggage fees, but each airline should be consulted for complete information about its baggage and ancillary fees.</p>
        <strong>10. International Travel</strong>
        <strong>a. Passports, Visas, Hazards, and Disinsection</strong>
        <p>It is your responsibility to ensure that you have obtained any necessary visas for international travel to or from the United States and that your passport will be accepted for travel.</p>
        <p>Although most travel, including travel to international destinations, is completed without incident, travel to certain destinations may involve greater risk than others. Priceline therefore urges passengers to review travel prohibitions, warnings, announcements and advisories issued by the United States Government prior to booking travel to international destinations. For State Department travel warnings and advisories, go to <a rel="noopener noreferrer" href="https://travel.state.gov/content/passports/en/alertswarnings.html" target="_blank">https://travel.state.gov/content/passports/en/alertswarnings.html</a>. For foreign health requirements and dangers, go to <a rel="noopener noreferrer" href="https://www.cdc.gov/travel/index.htm" target="_blank">https://www.cdc.gov/travel/index.htm</a>. Other information relating to particular international destinations can be found at <a rel="noopener noreferrer" href="https://www.dot.gov" target="_blank">https://www.dot.gov</a> ,<a href="https://www.tsa.gov">https://www.tsa.gov</a> , <a rel="noopener noreferrer" href="https://www.faa.gov" target="_blank">https://www.faa.gov</a>, <a rel="noopener noreferrer" target="_blank" href="https://travel.state.gov/content/passports/en/country.html">https://travel.state.gov/content/passports/en/country.html</a>,<a rel="noopener noreferrer" href="https://www.treasury.gov/resource-center/sanctions/Pages/default.aspx" target="_blank"></a> , or <a rel="noopener noreferrer" href="" target="_blank" href="https://www.cbp.gov">https://www.cbp.gov</a>.</p>
        <p>Although not commonly used, disinsection is permitted under international law in order to protect public health, agriculture and the environment. The World Health Organization and the International Civil Aviation Organization stipulate two approaches for aircraft disinsection--either spray the aircraft cabin with an aerosolized insecticide while passengers are on board or treat the aircraft's interior surfaces with a residual insecticide (residual method) while passengers are not on board. For more information about disinsection or to review a list of airline contacts for disinsection and a list of countries that require disinsection, please visit the Department of
          Transportation website at <a rel="noopener noreferrer" href="https://www.transportation.gov/airconsumer/spray" target="_blank"></a> <a rel="noopener noreferrer" href="https://www.transportation.gov/airconsumer/spray" target="_blank">https://www.transportation.gov/airconsumer/spray</a></p>
        <strong>
          By offering or facilitating travel to or from particular international destinations, we do not represent or warrant that travel to or from such points is advisable or without risk, and we shall not be liable for damages or losses that may result from travel to or from such destinations.
        </strong>
        <strong>b. Airline Entry/Exit Fees</strong>
        <p>If you are traveling internationally, some countries or airports charge fees upon entering and/or exiting the country. These fees are not included in the ticket price, or the taxes and fees we display on our website prior to purchase. For more information on a specific country's entry/exit fee, we suggest that you visit<a rel="noopener noreferrer" href="https://www.travel.state.gov" target="_blank">https://www.travel.state.gov</a>  prior to departure</p>
        <h3>C. Hotels</h3>
        <p><strong>1. General</strong></p>
        <p><strong>a. Pricing</strong></p>
        <p>Sometimes cheaper rates are available for a specific stay at a hotel, however, these rates made available by hotels may carry special restrictions and conditions, for example regarding cancellation and refund. Please check the room and rate details thoroughly for any such conditions prior to making your reservation. Prices, rates and inventory are subject to availability from the hotel.</p>
        <p><strong>b. Check In</strong></p>
        <p>Upon check-in, the reservation holder must present a valid ID and credit card or, in the hotel's discretion, debit card, in his or her name (the amount of available credit required will vary by hotel). Debit cards may not be accepted. The guest on the reservation must be 21 years or older to check into the hotel.</p>
        <p>i. Double Occupancy</p>
        <p>All hotel reservations are guaranteed for double occupancy (i.e. 2 adults). Unless specifically noted, accommodations for more than two adults are not guaranteed. Some hotels may not allow extra guests. If your hotel does allow extra guests, there may be additional charges. These charges vary and will be payable directly to the hotel.</p>
        <p>ii. Late Arrival and Failure to Check-in on First Night</p>
        <p>While you should call Priceline if you wish to cancel a cancellable reservation in advance of your stay, if you are going to be delayed on your date of check in or find that you cannot check in to your hotel on the date you requested, you should contact the hotel to let them know. If you fail to check in to your hotel on the day of your reservation and do not alert the hotel, the remaining portion of your reservation may be canceled and you may not be entitled to a refund, depending on the terms and conditions of the applicable hotel.</p>
        <p><strong>c. Cancellations and Changes to Your Reservation</strong></p>
        <p>Hotel cancellation policies vary. Certain reservations are non-cancellable and non-changeable. Others require you to pay a penalty if you cancel. Still others are fully refundable. Check the hotel cancellation policy when you book.</p>
        <p><strong>d. Disability Needs</strong></p>
        <p>If you have disability needs, you must call the hotel and verify that your disability needs can be met. If your disability needs cannot be accommodated by the hotel, please call Priceline customer service.</p>
        <p><strong>e. Hotel Accommodations and Special Requests</strong></p>
        <p>Depending upon which Priceline hotel reservation service you use, you may or may not be able to select your room type and number and type of beds (as is the case for Name Your Own Price® and Express Deals®). Room assignments are based on hotel availability and are at the hotel's discretion. For hotels that allow smoking on premises, we cannot guarantee a non-smoking room for every property. If you have special requests, you must always call the hotel and verify that it can meet your special requests after you have made your booking. Priceline cannot accommodate or guarantee that special requests will be met.</p>
        <p><strong>f. Hotel Star Ratings</strong></p>
        <p>Priceline's star rating system is provided for your reference. Like other well-known hotel rating systems, Priceline considers a number of factors in evaluating the quality of participating hotels such as: amenities, facilities, reputation, brand, other rating services, customer feedback from guests who have stayed at the hotel, etc., to assign a single star rating. Priceline screens participating hotels carefully and updates our information periodically to ensure the validity of our ratings. Our ratings systems may sometimes differ from those of other rating systems that you may be familiar with. We do not warrant or guarantee that our star rating system is equal to or consistent with any other star rating system and reserve the right to change a hotel's rating at any time with or without notice.</p>
        <p><strong>g. Charges for Taxes and Fees</strong></p>
        <p>In connection with facilitating your transaction, we will charge your method of payment for Taxes and Fees. This charge includes an anticipated amount to recover the amount we pay to the hotel in connection with your reservation for taxes owed by the hotel including, without limitation, sales and use tax, occupancy tax, room tax, excise tax, value added tax and/or other similar taxes. In certain locations, the tax amount may also include government imposed service fees or other fees not paid directly to the taxing authorities but required by law to be collected by the hotel. The amount paid to the hotel in connection with your reservation for taxes may vary from the amount we anticipate and include in the charge to you. The balance of the charge for Taxes and Fees is a fee we retain as part of the compensation for our services and to cover the costs of your reservation, including, for example, customer service costs. The charge for Taxes and Fees varies based on a number of factors including, without limitation, the amount we pay the hotel and the location of the hotel where you will be staying, and may include profit that we retain.
          Except as described below, we are not the vendor collecting and remitting taxes to the applicable taxing authorities. Our hotel suppliers, as vendors, bill all applicable taxes to us and we pay over such amounts directly to the vendors. We are not a co-vendor associated with the vendor with whom we book or reserve our customer's travel arrangements. Taxability and the appropriate tax rate and the type of applicable taxes vary greatly by location.
          For transactions involving hotels located within certain jurisdictions, the charge to your debit or credit card for Taxes and Fees includes an additional payment of tax that we are required to collect and remit to the jurisdiction for tax owed on amounts we retain as compensation for our services.
          Depending on the property you stay at you may also be charged (i) certain mandatory hotel specific service fees, for example, resort fees,, energy surcharges, newspaper delivery fees, in-room safe fees, tourism fees, or housekeeping fees and/or (ii) certain optional incidental fees, for example, parking charges, minibar charges, phone calls, room service and movie rentals, etc. These charges, if applicable, will be payable by you to the hotel directly at checkout. When you check in, a credit card or, in the hotel's discretion, a debit card, will be required to secure these charges and fees that you may incur during your stay. Please contact the hotel directly as to whether and which charges or service fees apply.
          The charges may also include destination marketing fees collected by the hotel and passed along to tourist bureaus. If you are a citizen of a country other than the United States and you travel to your home country, you may owe additional taxes to the hotel at check out. Contact the hotel if you have questions.
        </p>
        <p><strong>2. Express Deals® Hotel Service</strong></p>
        <p>The following restrictions apply to Priceline's Express Deals® hotel service:</p>
        <p>a. Unless specifically noted otherwise, all hotel reservations are non-cancelable, non-refundable, non-changeable and non-transferable by you. Once you purchase a reservation, your method of payment will be charged for the amount shown - regardless of whether or not the reservation is used. Credit will not be given for any unused reservations and cannot be used toward any future purchases.</p>
        <p>b. Upon check-in, the reservation holder must present a valid ID and credit card or, in the hotel's discretion, debit card, in his or her name that is consistent with the transactional details provided to Priceline (the amount of available credit required will vary by hotel). Debit cards may not be accepted.</p>
        <p>c. We or the applicable hotel may, on an exception basis and at the request of the hotel guest, waive one or more of the restrictions after the hotel room has been booked. We may, in our discretion, impose additional obligations and/or fees in connection with any such waiver. You agree that if your booking is successful, Priceline will confirm the reservation and charge the entire amount of the stay, including applicable Taxes and Fees (as described below) disclosed to you before booking, to your method of payment. The price listed is per night and does not include Priceline's charge to you for Taxes and Fees.</p>
        <p>d. For hotels that charge mandatory fees, you will be told both a) the Total Priceline Charges to your method of payment and b) the total cost, which includes the Total Priceline Charges and the Hotel Mandatory Fee which the hotel will charge at check out. Your Total Charges and the Room Rate may include compensation to Priceline for our facilitation services.</p>
        <p>e. If you have special room requests (including, for example, preferences for smoking or nonsmoking rooms, a specific room, particular bedding, floor location, connecting rooms, or pet accommodations), you must call the hotel and verify that special requests can be met after you have made your booking. Priceline cannot accommodate special requests in advance of your booking and cannot guarantee that special requests will be met. A hotel reservation cannot be refunded, canceled or modified on the basis that a special request was not (or could not be) met by the hotel.</p>
        <p><strong>3. Name Your Own Price® Hotel Service</strong></p>
        <p>The restrictions described above in the Express Deals® section also apply to Priceline’s Name Your Own Price® hotel service. Additionally, once you submit a request on Priceline, you cannot modify it. You also agree that if your offer is accepted, Priceline will confirm the reservation and charge the entire amount of the stay, including applicable Taxes and Fees disclosed to you before submitting an offer, to your method of payment. The price you name is per night and does not include Priceline's charge to you for Taxes and Fees. Your offer price may include, in addition to amounts paid to the hotel for your reservation, compensation to Priceline for our facilitation services.
          The "median retail price" which we may display to you is the median of the lowest rates our participating hotels are offering to the general public for the exact or approximate travel dates you selected based on information our hotel suppliers have loaded. These rates are based on the quality level you chose, the city, and the area you selected. These rates are available through our disclosed hotel service or were recorded at the time of booking and do not include rates that are restricted to membership programs. Although we routinely review this data, we make no guarantee or warranty, with respect to the accuracy or completeness of the data used or provided. Further, we make no guarantee or warranty that a room will be available below or within the disclosed price range. The ranges are provided to you solely for information purposes and are not actual rates. All hotel pricing information is subject to change without notice.
        </p>
        <p><strong>4. Merchant Price Disclosed Hotel Service</strong></p>
        <p>Priceline’s Merchant Price Disclosed Hotel Service is a hotel service whereby the price of the hotel is disclosed to the User in advance of booking and whereby Priceline acts as the merchant of record on the transaction (i.e., directly charges your credit card). The "Total Charges" that you will pay for using our Merchant Price Disclosed Hotel Service will always be disclosed to you before you submit your Request. For hotels that charge mandatory fees, you will be told both a) the Total Priceline Charges to your method of payment and b) the total cost, which includes the Total Priceline Charges and the Hotel Mandatory Fee which the hotel will charge at check out. Your Total Charges and the Room Rate may include compensation to Priceline for our facilitation services.</p>
        <p><strong>5. Agency Hotel Service</strong></p>
        <p>For Priceline's Agency Hotel Service, Priceline is not the merchant of record and does not charge your credit card or other form of payment. For these transactions, if you are required to enter a credit card number, you will be notified that your credit card will be used to guarantee the reservation and that the hotel will charge you directly for room and tax and any other charges. Certain accommodations only accept cash even if a credit card is used to book the reservation; this information will be disclosed to you. You will be provided with a "Total Estimated Charges" line item on your contract page that will provide you with an estimate of what the hotel will charge you, which will include taxes. Unless expressly stated, taxes and service fees are excluded from your room cost, but are shown to you in the “Taxes and Fees” line. Taxes are included in the Total Estimated Charges. For hotels that charge mandatory fees, you will be told the Hotel Mandatory Fee which the hotel will charge at check out. Any mandatory fee is also included in the “Total Estimated Charges” line.
          Agency Hotel Service reservations require credit or debit card details to guarantee your reservation. As such, we will send your credit or debit card information directly to the hotel at which your booking is made and we may verify (i.e. pre-authorize) your credit card as well. For certain rates or special offers that state that a deposit is required (which will be disclosed to you ), please note that your credit card may be pre-authorized or charged by the hotel (sometimes without any option for refund) upon reservation and confirmation of the booking. Please check the room details thoroughly for any such conditions prior to making your reservation.
          You accept and agree to the relevant cancellation and no-show policy of the hotel, and to any additional (delivery) terms and conditions of the hotel that may apply to your reservation or during your stay, including for services rendered and/or products offered by the hotel (the delivery terms and conditions of a hotel can be obtained with the relevant hotel). The general cancellation and no-show policy is made available on our website at the hotel information pages, during the reservation procedure and in the confirmation email. Please note that certain rates or special offers that require an upfront deposit to the hotel (which will be disclosed in the Booking Conditions) are not eligible for cancellation or change. Please check the room details thoroughly for any such conditions prior to making your reservation.
        </p>

        <h3>D. Rental Cars</h3>
        <p><strong>1. General</strong></p>
        <p>Vehicle rentals available through this Site are subject to standard rental contracts of the car rental companies, which will be completed by you at the time of pick-up of a vehicle. For retail price disclosed rental car reservation, Priceline is not the merchant of record and does not charge your credit card. Actual prices may vary depending on special requests and items purchased at the counter. Pricing may change if you pick-up, or drop-off the car at a different date, time or location than you requested in your reservation or for other reasons at the discretion of the rental car company. While you may request child seats and other special requests, the requests are not guaranteed and are provided at the discretion of the rental car company. At the time of pick up, the driver will be required to present a valid driver's license, and a valid credit card in his/her name. Some locations also accept debit cards; however, rental partners have different requirements for customers who will only have a debit card at the time of pickup. While our rental car partners strive to honor your request for car type (economy, full size, SUV etc.), your requested car type is not guaranteed to be available. Specific cars, makes and models are not guaranteed and listed car makes and model are for example only. Car fleet information including capacities is based on the latest information provided to us by the supplier and is subject to change.</p>
        <p><strong>2. Charges for Taxes and Service Fees</strong></p>
        <p>In connection with facilitating your Name Your Own Price® or Express Deals ® rental car transaction, we will charge your method of payment, in addition to base rate, a charge for Taxes and Fees (an amount that will always be disclosed to you before you elect to proceed). This charge includes an amount to recover the amount we pay to the rental car supplier in connection with your reservation for taxes, fees and surcharges owed by the rental car supplier including, without limitation, sales and use tax, excise tax, value added tax, airport or facility taxes, surcharges or fees and/or other similar taxes, surcharges or fees. The amount of this charge is intended by us to be sufficient to cover the amount we may be required to pay to a rental car supplier, and may be greater or less than the amount we actually pay the rental car supplier in connection with your reservation for taxes, fees and surcharges. The balance of the charge for Taxes and Fees is a fee which we retain as part of the compensation for our services and to cover the costs of your reservation, including, for example, customer service costs and additional fees. The charge for Taxes and Fees varies based on a number of factors, such as the amount you pay to Priceline and the location at which you will pick-up your car from the rental car supplier. The "Total Charges" that you will pay to Priceline for using our Name Your Own Price® or Express Deals ® rental car service will always be disclosed to you before you submit your Request.</p>
        <p>We are not the vendor collecting and remitting taxes to the applicable taxing authorities. The rental car companies, as vendors, bill all applicable taxes to us and we pay over such amounts directly to the vendors. Taxability and the appropriate tax rate and the type of applicable taxes vary greatly by location.</p>
        <p>In connection with facilitating your retail rental car transaction, we will attempt to provide you, when the information is available, with a breakdown of the estimated taxes, fees and surcharges that may apply to your transaction. We will not charge you for these taxes, fees and surcharges, but the car rental supplier will.</p>
        <p><strong>3. Express Deals and Name Your Own Price ®</strong></p>
        <p>The following restrictions apply to rental car Requests made using our Name Your Own Price® service and Express Deals ® services:</p>
        <p>a. Priceline rental car reservations are non-changeable, non-endorsable, non-transferable and non-refundable;</p>
        <p>b. A valid Driver's License AND credit card or, in the rental car company's discretion, a debit card, which is consistent with the transactional details provided to Priceline, must be presented when a vehicle is picked-up at the designated location;</p>
        <p>c. When picking up the vehicle, a credit card or, in the rental car company's discretion, a debit card, will be required for a security deposit and to pay for any additional ancillary charges (including insurance, child seats, gas, etc.).</p>
        <p>d. All rentals will include Taxes, Fees, and Surcharges ("TFS") associated with the Time and Mileage (T&M) for the rental based on the timing and pick-up/drop-off of the rental; and</p>
        <p>e. If you choose to add on a child seat, the charge for reserving an infant seat varies by the rental company that accepts your request and is over what you paid Priceline for your reservation. These charges are payable directly to the rental company.</p>


        <h3>E. Cruises</h3>
        <p>Priceline Cruises are offered through a partnership with World Travel Holdings, Inc. ("WTH"). When you purchase or book a cruise, you are subject to the Terms & Conditions of WTH. Please <a rel="noopener noreferrer" target="_blank" href="https://cruises.priceline.com/">click here</a> for WTH’s Terms & Conditions.</p>

        <h3>F. Vacation Package</h3>
        <p>In addition to the rules for specific services and packages already listed in these Terms & Conditions, the following restrictions apply to components of your Vacation Package:</p>
        <p><strong>1. Cancellations/Itinerary Changes</strong></p>
        <p>Cancellation policies vary based upon the travel components in your package. The applicable cancel policy for individual reservations can be reviewed prior to purchase. When cancellations or changes are allowed, the airline, hotel or rental car company may charge a cancellation fee or penalty. Priceline Negotiator Flight tickets, Priceline Value Rate hotel room reservations, and discounted rental car reservations are non-cancelable, non-transferable and non-changeable and no refunds are allowed.<p>
        <p><strong>2. Breakout of Cost of Components</strong></p>
        <p>Priceline is able to offer savings on vacation packages because the components of the package are bundled together. We are not, therefore, able to itemize for you the cost of each component that makes up a package.</p>
      </div>
      <div>
        <h3>PART V: Miscellaneous and Registrations</h3>
          <p>In the event that any provision of this Agreement is determined to be unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
          <p>Travel Service Disclosures</p>
          <p>Priceline Partner Network Limited is a wholly owned subsidiary of Priceline.com LLC (“Priceline”), who maintains the aforementioned travel service licenses and registrations.</p>
          <p>Priceline is a registered seller of travel in each of the following states:</p>
          <p>California Registration No. CST 2040530-50.</p>
          <p>Florida Registration No. ST-32150.</p>
          <p>Washington Registration No. 601 875 582.</p>
          <p>Nevada Registration No. 2002-0570.</p>
          <p>Alaska Registration No. 280316.</p>
          <p>Ohio Registration No. 8889077.</p>
          <p>Iowa Registration No. 624.</p>
          <p>Hawaii Registration No. TAR 5946.</p>
          <p>Registration as a Seller of Travel in California does not constitute the state’s approval.</p>
          <p> <a rel="noopener noreferrer" href="https://www.priceline.com/static-pages/pdf/New_York_City_Certificate_Of_Authority.pdf" target="_blank">Click here</a> to see New York City Dept of Finance Certificate of Authority occupancy tax form.</p>

      </div></div>
  </div></div>
