import {Component, OnChanges, SimpleChanges} from "@angular/core";
import * as _ from 'lodash-es'

@Component({
    selector: 'circle-spinner-component',
    inputs: ['ladda', 'customClass', 'selectedItem'],
    styleUrls: ['./circle-spinner.component.scss'],
    templateUrl: 'circle-spinner.component.html'
})
export class CircleSpinnerComponent implements OnChanges{
    ladda: boolean;
    customClass: string;
    selectedItem: string[]=[];
    _ = _;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedItem = changes.selectedItem.currentValue;
    }

}
