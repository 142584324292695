<div id="step-component-wrapper" [ngClass]="{'flights': type == 'flight'}">
  <div class="container">
    <div class="back-btn-wrap d-flex">
      <div class="mt-1" *ngIf="type == 'flight'">
        <a (click)="goToDetailFlight()"  > <em class="fas fa-chevron-left"></em> <span translate> Back to travel summary</span></a>
      </div>
      <div  class="position-relative mt-1 " *ngIf="type == 'car'">
        <a (click)="goToCarSelection()"  > <em class="fas fa-chevron-left"></em> <span translate> Back to car selection</span>
          <span *ngIf="laddaBack" class="spinner-border car spinner-border-sm ml-2 mr-2"></span>
        </a>
      </div>
      <div class="mt-1" *ngIf="type == 'hotel'">
        <a (click)="goToHotelDetails()"> <em class="fas fa-chevron-left"></em> <span translate> Back to hotel details</span>
        </a>
      </div>
    </div>
    <div class="pt-1">
      <div class="d-inline-block">
        <div *ngIf="stepService.currentStep == 0">
          <span class="medium" *ngIf="type == 'flight'" translate>Passenger info</span>
          <span class="medium" *ngIf="type == 'hotel'" translate>Guest info</span>
          <span class="medium" *ngIf="type == 'car'" translate>Driver info</span>
        </div>
        <div>
          <span *ngIf="stepService.currentStep == 1" class="medium" translate>Redeem</span>
          <span *ngIf="stepService.currentStep == 2" class="medium" translate>Review</span>
        </div>
      </div>
      <div class="d-inline-block pl-2">
        <span aria-hidden="true"> | </span><span class="light">Step {{stepService.currentStep + 1}} of 3 </span>
      </div>
      <div>
        <span *ngIf="stepService.currentStep == 0" class="step-subtitle" translate>Fill out your info</span>
        <span *ngIf="stepService.currentStep == 1" class="step-subtitle" translate>Apply your <pts-currency ></pts-currency> </span>
        <span *ngIf="stepService.currentStep == 2" class="step-subtitle" translate>Confirm your redemption</span>
      </div>
    </div>
    <div class="pt-4 pb-4">
      <div class="progress">
        <div class="progress-bar" [ngStyle]="{'width': calculateProgress(stepService.currentStep) + '%'}" role="progressbar" [attr.aria-valuenow]="calculateProgress(stepService.currentStep)" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</div>
