import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Subject, throwError} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {IAdditionalFee} from "../interfaces/admim-user.interface";
import {AlertService} from "../directives/alerts/alerts.service";
import {environment} from '../environments/environment';

@Injectable()
export class AuthenticationService {

    public SupportTelephoneNumber;
    private _sessionID: string = '';
    private _token: string;
    private _isLogged: boolean;
    private _user: IUser;
    private userActivity;
    private userInactive: Subject<any> = new Subject();
    private _additionalFees: IAdditionalFee[] =[];
    private datePause: any;

    constructor(private router: Router,
                private alertService: AlertService,
                private translateService: TranslateService,
                private http: HttpClient) {
        this._sessionID = localStorage.getItem('session_id') || '123456789';

        if(environment.environment != 'develop'){

            this.checkInactivity();

            document.addEventListener("visibilitychange", ()=> {
                if (document.visibilityState === 'visible') {
                    if( (Date.now() - this.datePause) >  (15*60*1000) ){
                        this.logout();
                    }
                } else {
                    this.datePause = Date.now();
                }
            });
        }
    }

    isAdmin(){
        return this.user.admin_id != null;
    }

    checkInactivity(){
        //Set up the listener to check the idle
        window.addEventListener('mousemove', () => this.resetAndRestart('mousemove'));
        window.addEventListener('click', () => this.resetAndRestart('click') );

        this.userInactive.subscribe(() => {
            this.logout()
            clearTimeout(this.userActivity);
        });

    }

    isBusiness(){
        if(this.user.account_type.reference_identifier == 'HSBCRBBC' || this.user.account_type.reference_identifier == 'HSBCRBBI'){
            return true
        }else{
            return false
        }
    }

    resetAndRestart(type){
        clearTimeout(this.userActivity);
        this.setTimeout();
    }

    setTimeout() {
        this.userActivity = setTimeout(() => this.userInactive.next(), 15*60*1000); //15min
    }


    logout(){
        // TODO: To be decided
        localStorage.clear();
        this.isLogged = false;
        window.open(environment.podium_url + '?error=timeout','_self')
        // this.router.navigate(['/expired']);
    }

    getToken(token: string) {
        return this.http.post<any>('auth/token', {token: token ,reference: environment.reference_code}).pipe(map(data => {
            let resp = data.data;
            this.token = resp.jwt;
            this.isLogged = true;
            return resp
        })).catch((err) => {
            return throwError(err)
        });
    }

    getUserInfo() {
        return this.http.get<any>('user/account').pipe(map(data => {
            let resp = data.data;
            this.user = resp;
            if(this.isBusiness() ){
                this.SupportTelephoneNumber = '1-833-722-4722';
            }else{
                this.SupportTelephoneNumber = '1-888-662-4722';
            }

            if (this.isAdmin() && environment.showFee && !this.additionalFees.length) {
                this.getAdditionalFee().subscribe();
            }

            return resp
        })).catch((err) => {
            return throwError(err)
        });
    }

    getAdditionalFee() {
        return this.http.get<any>('booking/additional-fee').pipe(map(data => {
            this.additionalFees = <IAdditionalFee[]>data.data;

            return this.additionalFees;
        })).catch((err) => {
            this.alertService.addMessage('danger' , "Podium didn't return any additional-fee information because the admin user account you are using is not configured correctly. You WILL NOT be able to complete a Travel booking.");
            return throwError(err)
        });
    }

    redirectToPodium(url, target='_self', refresh = true){
        let podium_lang_prefix = this.translateService.currentLang == 'en'? '' : 'fr/';
        if(!refresh){
            window.open(environment.podium_url + podium_lang_prefix + url,target)
            return
        }

        return this.http.post<any>('auth/redirect', null).pipe(map(data => {
            let link = environment.podium_url + podium_lang_prefix + 'sso/' + data.data.token +'?redirect_url=' + url
            window.open(link,target)
        })).catch((err) => {
            return throwError(err)
        });
    }

    validateAddress(payload) {
        return this.http.post<any>('booking/address/validate', payload).pipe(map(data => {
            return data.data
        })).catch((err) => {
            return throwError(err)
        });
    }

    get session_id(): string {
        return this._sessionID;
    }

    set session_id(value: string) {
        this._sessionID = value;
    }
    get token(): string {
        return this._token || localStorage.getItem('token');
    }

    set token(value: string) {
        localStorage.setItem('token', value);
        this._token = value;
    }

    get isLogged(): boolean {
        return this._isLogged || !!localStorage.getItem('token');
    }

    set isLogged(value: boolean) {
        this._isLogged = value;
    }
    get user(): IUser {
        return this._user || JSON.parse(localStorage.getItem('user'));
    }

    set user(value: IUser) {
        localStorage.setItem('user', JSON.stringify(value));
        this._user = value;
    }

    get additionalFees() {
        return this._additionalFees;
    }

    set additionalFees(value: IAdditionalFee[]) {
        this._additionalFees = value;
    }

    checkLoginAs() {
        return this.isAdmin();
    }

    getMemberName(): string {
        return this.user.account_members[0].member.first_name + ' ' + this.user.account_members[0].member.last_name;
    }
    showFee() {
        return environment.showFee;
    }
}

export interface IUser {
    account_members: IAccountMember[],
    account_type: any,
    created_at: string,
    custom_fields: any[]
    conversion_rate: number
    bin_ranges: number[]
    last_balance: number
    last_balance_updated_at: string
    mediums: any[]
    reference_identifier: string
    status: string
    updated_at: string
    admin_id: number
    is_car_cancelable: boolean
    is_flight_cancelable: boolean
    is_hotel_cancelable: boolean
}
export interface IAccountMember {
    is_primary: boolean,
    member: IMember,
    member_account_status: string
}
export interface IMember {
    birth_date: string
    company_name: string
    created_at: string
    email: string
    first_name: string
    last_name: string
    middle_name: string
    phone: string
    reference_identifier: string
    status: string
    updated_at: string
    username: string
}
