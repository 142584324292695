import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ModalComponent} from "../modal/modal.component";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ItineraryService} from "../../services/itinerary.service";

const $ = require("jquery");

@Component({
    selector: 'send-itinerary-component',
    templateUrl: 'send-itinerary.component.html',
    styleUrls: ['./send-itinerary.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SendItineraryComponent implements OnInit {
    @ViewChild('itineraryModal') itineraryModal: ModalComponent;
    itineraryForm: UntypedFormGroup;
    submitted: boolean = false;
    laddaItinerary: boolean = false;
    @Input() private reference: string = '';
    @Input() private booking_item: string = '';

    constructor(private fb: UntypedFormBuilder,
                private itineraryService:ItineraryService) {
        this.itineraryForm = this.fb.group({
            email: ['', Validators.required],
        });
    }

    ngOnInit(): void {
    }

    sendItinerary(){
        this.submitted = true;
        if (this.itineraryForm.invalid) {
            return;
        }
        let email = this.itineraryForm.get('email').value
        //TODO add endpoint call
        this.laddaItinerary = true;
        this.itineraryService.sendItinerary(this.reference, this.booking_item, email).subscribe(
            (resp) => {
                this.laddaItinerary = false
                this.itineraryModal.dismiss()
            },
            (err) => {
                this.laddaItinerary = false
            })

    }

    openItineraryModal(){
        this.itineraryModal.show('modal-md')
    }
    get email() {
        return this.itineraryForm.get('email');
    }
}
