import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {IBookingFee} from "../../interfaces/view.interface";
import {AuthenticationService} from "../../services/authentication.service";
import {TranslateService} from "@ngx-translate/core";
import { eBookingType } from 'src/resources/utils';

@Component({
    selector: 'itinerary-agent-fees',
    templateUrl: 'itinerary-agent-fees.html',
    styleUrls: ['./itinerary-agent-fees.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ItineraryAgentFees implements OnInit {
    @Input() fees: IBookingFee;
    @Input() feePricePayment: boolean = false;
    @Input() currency: string = 'USD';
    @Input() bookingType: eBookingType = eBookingType.none;

    loginAs: boolean = false;
    showFee: boolean = false;
    size: any;
    feePaymentValue: number = 0;
    typeText: string = '';
    hide: boolean = false;

    constructor(private authService: AuthenticationService,
                public translateService: TranslateService) {
        this.loginAs = authService.checkLoginAs();
        this.showFee = authService.showFee();
    }

    ngOnInit() {
        this.initData();
    }

    private initData() {
        switch (this.bookingType) {
            case eBookingType.flight:
                this.typeText = this.translateService.instant('Flight');
                break;
            case eBookingType.car:
                this.typeText = this.translateService.instant('Car');
                break;
            case eBookingType.hotel:
                this.typeText = this.translateService.instant('Hotel');
                break;
            default:
                break;
        }

        this.refreshData();
    }

    private refreshData() {
        if (this.feePricePayment) {
            this.feePaymentValue = this.fees.price;
        }
        else {
            this.feePaymentValue = this.fees.points;
        }

        this.hide = this.fees.price == 0 && this.fees.points == 0;
    }
}
