<div class="alert-wrapper alert-z-container">
    <div class="container">
        <alert></alert>
    </div>
</div>
<spinner-component [ladda]="showSpinner" customClass="absolute-spinner {{isMobileApp? 'mobile-app' : ''}}"></spinner-component>
<div class="main-wrapper {{showBackground}}"  [ngClass]="{ 'mobile-app': isMobileApp}" >
    <router-outlet (activate)='onActivate($event)'></router-outlet>
</div>




