<div id="cart-component-wrapper" >
  <div class="container mt-3">
      <div class="d-flex">
          <div><a [routerLink]="getBackRoute()"> <em class="fas fa-chevron-left"></em> <span translate> Back to</span> {{cartService.cartStep > 0 ? cartService.bundle.from[cartService.cartStep- 1].label : cartService.bundle.from[0].label}}</a></div>
      </div>
    <div class="d-flex">
      <div *ngIf="cartService.bundle.item.includes('flight') && ( cartService.cartStep == getTypeIndex('flight') )" class="d-flex">
        <div class="align-self-center p-2 hidden-sm-down text-color">
          <span class="d-block">Choose your flight | Step {{cartService.cartStep +1 }} of {{cartService.bundle.item.length}}</span>
          <span class="d-block font-xs ">

              {{cartService.bundle.searchParams[getTypeIndex('flight')].departure_city.city}}
              <span *ngIf="cartService.bundle.searchParams[getTypeIndex('flight')].arrival_city.city != cartService.bundle.searchParams[getTypeIndex('flight')].departure_city.city"> - {{cartService.bundle.searchParams[getTypeIndex('flight')].arrival_city.city}}</span>
              |
              {{calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('flight')].start_date,cartService.bundle.searchParams[getTypeIndex('flight')].end_date)}}
              <span *ngIf="calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('flight')].start_date,cartService.bundle.searchParams[getTypeIndex('flight')].end_date) <= 1" translate>day</span>
              <span *ngIf="calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('flight')].start_date,cartService.bundle.searchParams[getTypeIndex('flight')].end_date) > 1" translate>days</span>
              ( {{getMonth(cartService.bundle.searchParams[getTypeIndex('flight')].start_date,cartService.bundle.searchParams[getTypeIndex('flight')].end_date)}} )

          </span>
        </div>
      </div>
      <div *ngIf="cartService.bundle.item.includes('hotel') && cartService.cartStep == getTypeIndex('hotel')"  class="d-flex">
        <div class="align-self-center p-2 hidden-sm-down text-color">
          <span class="d-block">Choose your hotel | Step {{cartService.cartStep +1 }} of {{cartService.bundle.item.length}}</span>
          <span class="d-block font-xs">

              {{cartService.bundle.searchParams[getTypeIndex('hotel')].arrival_city.airport_spell ? cartService.bundle.searchParams[getTypeIndex('hotel')].arrival_city.airport : cartService.bundle.searchParams[getTypeIndex('hotel')].arrival_city.city}}
              |
              {{calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('hotel')].start_date,cartService.bundle.searchParams[getTypeIndex('hotel')].end_date)}}
              <span *ngIf="calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('hotel')].start_date,cartService.bundle.searchParams[getTypeIndex('hotel')].end_date) > 1" translate>days</span>
              <span *ngIf="calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('hotel')].start_date,cartService.bundle.searchParams[getTypeIndex('hotel')].end_date) <=1" translate>day</span>
              ( {{getMonth(cartService.bundle.searchParams[getTypeIndex('hotel')].start_date,cartService.bundle.searchParams[getTypeIndex('hotel')].end_date)}} )
          </span>
        </div>
      </div>
      <div *ngIf="cartService.bundle.item.includes('car') && cartService.cartStep == getTypeIndex('car')"  class="d-flex">
        <div class="align-self-center p-2 hidden-sm-down text-color">
          <span class="d-block">Choose your car | Step {{cartService.cartStep +1 }} of {{cartService.bundle.item.length}}</span>
          <span class="d-block font-xs ">

              {{cartService.bundle.searchParams[getTypeIndex('car')].arrival_city.city || cartService.bundle.searchParams[getTypeIndex('car')].arrival_city.airport_name}}
              |
              {{calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('car')].start_date,cartService.bundle.searchParams[getTypeIndex('car')].end_date)}}
              <span *ngIf="calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('car')].start_date,cartService.bundle.searchParams[getTypeIndex('car')].end_date) > 1" translate>days</span>
              <span *ngIf="calculateDayDiff(cartService.bundle.searchParams[getTypeIndex('car')].start_date,cartService.bundle.searchParams[getTypeIndex('car')].end_date) <= 1" translate>day</span>
              ( {{getMonth(cartService.bundle.searchParams[getTypeIndex('car')].start_date,cartService.bundle.searchParams[getTypeIndex('car')].end_date)}} )
          </span>
        </div>
      </div>
    </div>
    <div class="progress mb-2">
      <div class="progress-bar" [ngStyle]="{'width': progressValue + '%'}" role="progressbar" [attr.aria-valuenow]="progressValue" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="d-flex mb-4 text-color">
      <div class="pt-2 medium">
      <span class="d-block"><span translate>Your trip</span>:
        <span *ngFor="let item of cartService.bundle.item; let $index=index" >
          <span [innerHTML]="item | titlecase | translate"></span> <span *ngIf="cartService.bundle.item.length != $index +1"> + </span>
        </span>

      </span>
      </div>
      <div class="ml-auto pt-2 text-right medium">
        <span class="d-block"><span translate>Subtotal</span>: {{cartService.flight_total + cartService.hotel_total + cartService.car_total | pointConverter}} <pts-currency></pts-currency></span>
        <span class="d-block font-xs "><span translate>or</span>&nbsp; <price-component [symbol]="'$'" [currency]="'USD'" [price]="cartService.flight_total + cartService.hotel_total + cartService.car_total" ></price-component></span>
      </div>
    </div>
  </div>
</div>
