import {Component, OnChanges, SimpleChanges} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'price-component',
    inputs: ['price', 'customClass', 'currency', 'symbol'],
    styleUrls: ['./price.component.scss'],
    templateUrl: 'price.component.html'
})
export class PriceComponent implements OnChanges{
    price: any;
    currency: string;
    symbol: string;

    constructor(public translateService: TranslateService){
    }

    ngOnChanges(changes: SimpleChanges): void {
        // this.price = changes.price.currentValue;
    }
}
