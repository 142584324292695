import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {forkJoin, Observable, throwError} from "rxjs";
import * as _ from 'lodash-es'
import {IFlightSearch} from "../interfaces/flight.interface";
import {IViewResponse} from "../interfaces/view.interface";
import {AlertService} from "../directives/alerts/alerts.service";
import {TranslateService} from "@ngx-translate/core";
import { environment } from 'src/environments/environment';

@Injectable()
export class FlightService {

    constructor(private router: Router,
                private http: HttpClient,
                private translateService: TranslateService,
                private alertService: AlertService) {
    }

    private _lastSearchFlight: IFlightSearch;
    private _contract: IViewResponse;
    private _lastFlightSelected: string;
    private _searchFlightParams;
    private _lastAirport;
    environment = environment;

    get searchFlightParams() {
        return this._searchFlightParams || JSON.parse(localStorage.getItem('searchFlightParams'));
    }

    set searchFlightParams(value) {
        localStorage.setItem('searchFlightParams', JSON.stringify(value));
        this._searchFlightParams = value;
    }

    get lastSearchFlight(): IFlightSearch {
        return this._lastSearchFlight;
    }

    set lastSearchFlight(value: IFlightSearch) {
        this._lastSearchFlight = value;
    }

    get lastFlightSelected(): string {
        return this._lastFlightSelected || JSON.parse(localStorage.getItem('lastFlightSelected'));
    }

    set lastFlightSelected(value: string) {
        localStorage.setItem('lastFlightSelected', JSON.stringify(value));
        this._lastFlightSelected = value;
    }

    set lastAirport(value:any){
        this._lastAirport = value;
    }

    get lastAirport(){
        return this._lastAirport

    }
    get contract(): IViewResponse {
        return this._contract;
    }

    set contract(value: IViewResponse){
        this._contract = value;
    }

    getFlights(payload: any = this.searchFlightParams) {
        this.searchFlightParams = payload;
        payload['platform_version'] = this.environment.platform_version

        return this.http.post<any>('flight/search', payload).pipe(map(data => {
            this.lastSearchFlight = _.extend(data.data, {adults: payload.adults, flight_mode: payload.mode, form_data: payload, children:payload.children });
            return this.lastSearchFlight
        })).catch((err) => {
            return throwError(err)
        });
    }

    getRanges(payload) {
        let param = _.cloneDeep(payload);
        param['platform_version'] = this.environment.platform_version

        return this.http.post<any>('flight/range', param).pipe(map(data => {
            return data.data
        })).catch((err) => {
            return throwError(err)
        });
    }

    getPrices(date_array): Observable<any[]>{

        let http_call_list = [];
        for(let i = 0; i < date_array.length; i++){
            let payload = _.cloneDeep(this.searchFlightParams);
            payload.departure_date = [date_array[i]];
            http_call_list.push(this.http.post<any>('flight/search', payload));
        }

        return forkJoin(http_call_list)
    }


    getReturnFlights(contract_bundle: string) {
        return this.http.post<any>('flight/results', {ppn_bundle: contract_bundle,platform_version: this.environment.platform_version}).pipe(map(data => {
            return data
        })).catch((err) => {
            this.alertService.addMessage('danger' , err.error.data)
            return throwError(err)
        });
    }

    getAirports(str: string) {
        return this.http.post<any>('flight/locations', {location: str?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),platform_version: this.environment.platform_version}).pipe(map(data => {
            if(!data.data.length ){
                this.alertService.addMessage('danger',  this.translateService.currentLang == 'en'? 'No city found' : 'Aucune ville trouvée')
            }
            let  result=[];
            _.forEach(data.data , (dt)=>{
                if( dt.active_air == 'true'){
                    result.push(dt);
                }
            });
            this.lastAirport = result;
            return result
        })).catch((err) => {
            this.alertService.addMessage('danger' , err.error.data.location[0])
            return throwError(err)
        });
    }

    getContract(ppn: string) {
        this.lastFlightSelected = ppn;
        return this.http.post<any>('flight/view', {ppn_bundle: ppn,platform_version: this.environment.platform_version}).pipe(map(data => {
            this.contract = data.data
            return this.contract
        })).catch((err) => {
            this.alertService.addMessage('danger' ,  err.error.data);
            return throwError(err)
        });
    }

    getSeatMap(seat_bundle: string) {
        return this.http.post<any>('flight/seating', {ppn_bundle: seat_bundle, platform_version:2}).pipe(map(data => {
            return data.data.results
        })).catch((err) => {
            return throwError(err)
        });
    }
}
