import {Injectable, SecurityContext} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {DomSanitizer} from "@angular/platform-browser";
import {AuthenticationService} from "../services/authentication.service";
import {Router} from "@angular/router";
import {AlertService} from "../directives/alerts/alerts.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";
/** Pass untouched request through to the next request handler. */
@Injectable()
export class NoopInterceptor implements HttpInterceptor {
    //Add here the endpoints that need use a specific custom message
    private exclude_url = ['flight/locations', 'flight/results', 'flight/view', 'hotel/locations', 'car/locations','flight/range','flight/seating','car/cancel','flight/cancel','hotel/cancel','booking/additional-fee'];
    constructor(private _sanitizer: DomSanitizer,
                private router: Router,
                private alertService: AlertService,
                private translateService: TranslateService,
                private authService: AuthenticationService) {
    }

    getCookie(c_name) {
        let c_value = " " + document.cookie;
        let c_start = c_value.indexOf(" " + c_name + "=");
        try {
            if (c_start == -1) {
                c_value = null;
            }
            else {
                c_start = c_value.indexOf("=", c_start) + 1;
                let c_end = c_value.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = c_value.length;
                }
                c_value = unescape(c_value.substring(c_start,c_end));
            }
            return c_value;
        } catch (e){
            return null
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedReq: any = req;
        //read translations json from localhost instead of API url
        if (!req.url.includes(".json")) {
            clonedReq = {
                url: `${environment.origin}/${req.url}`
            };
        }
        let token = this.authService.token;
        let lang = localStorage.getItem('lang') || this.translateService.currentLang || 'en';
        let xrs_id = this.getCookie('RS-CLIENT');
        let default_header = {['X-Timezone']: [new Date().getTimezoneOffset() / -60], ['Accept-Language']: [lang]};
        //Do this since the first time that site loads xrs-client cookie it's not present
        if(xrs_id){
            _.extend(default_header,{['X-RS-CLIENT']: [xrs_id]})
        }
        clonedReq.setHeaders = default_header;
        if (token && !req.url.includes("token")) {
            try {
                clonedReq.setHeaders = {['Authorization']: ['Bearer ' + token],...default_header}
            }catch (e) {
            }
        }
        let escapeHtml = (unsafe) => {
            return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        };
        let checkType = (currentVal) => {
            if (_.isObject(currentVal)) {
                _.forOwn(currentVal, (value, key) => {
                    if (_.isString(value)) {
                        currentVal[key] = this._sanitizer.sanitize(SecurityContext.HTML, escapeHtml(value));
                    } else {
                        checkType(value);
                    }
                });
            } else if (_.isArray(currentVal)) {
                _.each(currentVal, (value, index) => {
                    if (_.isString(value)) {
                        currentVal[index] = this._sanitizer.sanitize(SecurityContext.HTML, escapeHtml(value));
                    } else {
                        checkType(value);
                    }
                })
            }
        };
        if (req.method === 'POST' || req.method === 'PUT') {
            clonedReq.body = req.body;
            // checkType(clonedReq.body);
        }
        if (req.method === 'DELETE') {
            clonedReq.responseType = 'text';
        }
        const apiReq = req.clone(clonedReq);
        return next.handle(apiReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // success response
                    // If new token exist switch it with the older
                    let currentToken = event.headers.get('Authorization')
                    if (currentToken) {
                        currentToken = currentToken.replace('Bearer ', '')
                        if (this.authService.token !== currentToken) {
                            this.authService.token = currentToken;
                        }
                    }

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const response = error;
                //refresh the token if it is expired
                if (response.status == 401) {
                    this.authService.logout();
                }
                if (!this.exclude_url.includes(req.url)) {
                    if (response.error && response.error.data){
                        if (response.status == 400) {
                            let firstKey = Object.keys(response.error.data);
                            _.forEach(firstKey, (item)=>{
                                _.forEach(response.error.data[item], (msg)=>{
                                    this.alertService.addMessage('danger', msg);
                                })
                            })
                        } else{
                            this.alertService.addMessage('danger', response.error.data);
                        }
                    }else{
                        this.alertService.addMessage('danger', 'Something went wrong');
                    }
                }
                return throwError(error);
            }));
    }
}
