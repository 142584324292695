import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import 'bootstrap';
import {CommonService} from "../services/common.service";
import {TranslateService} from "@ngx-translate/core";
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

const homeback = "../assets/images/homeback.jpg";

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

    showSpinner: boolean = true;
    showBackground: string = '';
    isMobileApp: boolean = false;
    private baseTitle = 'HSBC Travel';

    constructor(private commonService: CommonService,
                private translateService: TranslateService,
                private titleService: Title,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.isMobileApp = window.navigator.userAgent.includes('BMORewardsApp');
        translateService.setDefaultLang(localStorage.getItem('lang')|| 'en');
        translateService.use(localStorage.getItem('lang')|| 'en');
        commonService.backChange.subscribe((data) => {
            this.showBackground = data;
        });

        // Let the document know when the mouse is being used
        document.body.addEventListener('mousedown', function() {
            document.body.classList.add('using-mouse');
        });

        // Re-enable focus styling when Tab is pressed
        document.body.addEventListener('keydown', function(event) {
            if (event.keyCode === 9) {
                document.body.classList.remove('using-mouse');
            }
        });

    }
    onActivate(component): void {
        this.showSpinner = false;
    }

    ngOnInit() {
        this.setFunctionForTitle();
    }

    private setFunctionForTitle() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        )
            .subscribe(() => {

                let rt = this.getChild(this.activatedRoute)

                rt.data.subscribe(data => {
                    let theTitle = this.baseTitle;

                    if (data.title !== undefined) {
                        theTitle = this.baseTitle + ' - ' + data.title;
                    }

                    this.titleService.setTitle(theTitle)
                })
            })
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }
}
