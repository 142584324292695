import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
    selector: 'insurance-banner',
    styleUrls: ['./insurance-banner.component.scss'],
    templateUrl: 'insurance-banner.component.html'
})
export class InsuranceBannerComponent{
    constructor(private translateService: TranslateService,
                private authService: AuthenticationService) {
    }

    goTo(url,target = '_blank'){
        this.authService.redirectToPodium(url,target).subscribe();
    }
}

