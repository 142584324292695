import {Directive, EventEmitter, HostListener, Output} from "@angular/core";

@Directive({selector: '[my-enter]'})
export class MyEnter {
    @Output() onClick = new EventEmitter();

    @HostListener('keypress', ['$event.target'])
    onKeypress() {
        this.onClick.emit();
    }
}
