<div class="agent-fees-payment-container" [ngClass]="{'hidden': !showFee || !loginAs || hideOnNoPoints}">
    <div id="title-container" class="logged-in-as-banner top text-center medium py-2" translate>Agent Booking Fee Payment</div>
<!--    <div *ngIf="!notMuchPoints">-->
        <div style="margin-top: 20px" class="form-group input-wrap input-radio pb-0 pt-0 mt-5">
            <input type="radio" id="agent-fee-price-radio" [value]="true" name="r" [(ngModel)]="feeChecked" (change)="checkedChanged()">
            <label class="container-radio radio-inline pr-3" for="agent-fee-price-radio"><price-component [price]="fees.price.toString()" [currency]="fees.currencyCode"></price-component>&nbsp;<span translate>Payment</span></label>
        </div>
    <div *ngIf="!notMuchPoints">
        <div class="form-group input-wrap input-radio pb-0 pt-0 mt-3">
            <input type="radio" id="agent-fee-points-radio" [value]="false" name="r" [(ngModel)]="feeChecked" (change)="checkedChanged()">
            <label class="container-radio radio-inline pr-3" for="agent-fee-points-radio"><span translate>Redeem</span> {{fees.points.toLocaleString(translateService.currentLang)}} <span translate>pts</span></label>
        </div>
    </div>
<!--    </div>-->
<!--    <div *ngIf="notMuchPoints">-->
<!--        <span>{{fees.points}}</span><span style="margin-left: 7px" translate>points are not available to pay the booking fee with points</span>-->
<!--    </div>-->
</div>
