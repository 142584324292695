import {Component, Input} from "@angular/core";

@Component({
    selector: 'rating-star',
    templateUrl: 'rating.component.html',
    styleUrls: ['./rating.component.scss'],
})


export class RatingComponent {

    @Input() value : number;
    rating;

    constructor(){
        this.rating = new Array <number>(5);
    }

}
