<div class="itinerary-agent-fees-container" [ngClass]="{'hidden': hide || !showFee}">
    <div class="text-center">
        <strong class="font-m mb-3"><span [translateParams]="{typeText: typeText}" translate>Agent [typeText] Booking Fee</span></strong>
        <div aria-atomic="true" aria-live="assertive">
            <div *ngIf="feePricePayment">
                <div class="total-points"><price-component [price]="feePaymentValue.toString()" [currency]="" ></price-component></div>
                <span class="total-taxes-in-dollar d-block ">({{currency}} {{'Payment' | translate}})</span>
            </div>
            <div *ngIf="!feePricePayment">
                <div class="total-points">{{feePaymentValue.toLocaleString(translateService.currentLang)}}</div>
                <span class="total-taxes-in-dollar d-block ">({{'Points' | translate}} {{'Payment' | translate}})</span>
            </div>
        </div>
        <br>
        <p class="d-block summary-description">
            <span translate>Fees associated with phone booking, changes to itineraries or cancellations are non-refundable</span>.
        </p>
    </div>
</div>
