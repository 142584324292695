import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {IBookData} from "../app/travel/contract/book/flight-book.service";
import * as _ from "lodash-es";
import {AuthenticationService} from "./authentication.service";
import {IHotelBookData} from "../app/hotel/contract/book/hotel-book.service";
import {AlertService} from "../directives/alerts/alerts.service";
import utils, {eBookingType} from "../resources/utils";
import { environment } from 'src/environments/environment';

@Injectable()
export class CartService {

    public _bundle: IBundle;
    private _flight_total: number = 0;
    private _hotel_total: number = 0;
    private _car_total: number = 0;
    private _cartStep: number = 0;
    private _observableStep = null;
    private _selectedPoints = null;
    private _bookData: IBookData;
    private _bookResponse: any = null;
    private statusPolling: any;
    environment = environment ;
    private errors$: BehaviorSubject<any> = new BehaviorSubject<any>([]);


    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private alertService:AlertService,
                private http: HttpClient) {

        this._observableStep = new BehaviorSubject<number>(this.cartStep);
        //Set the value of the current item type to 0 (when user refresh page and not finished to select flight for example)
        if(this.bundle){
            this.resetAtIndex(this.cartStep);
        }

    }


    bookBundle(flightBookData:IBookData = null, hotelBookData:IHotelBookData = null, carBookData:any= null) {
        return this.http.post<any>('booking/book', this.prepareBundleBookPayload(flightBookData,hotelBookData,carBookData)).pipe(map(data => {
            this.bookResponse = data;
            //Poll the status
            this.pollStatus(data.data.reference);
            //this.alertService.addMessage('success', 'Your flight has been booked successfully')
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }

    bookBundleStatus(reference: string) {
        return this.http.post<any>('booking/status', {reference: reference}).pipe(map(data => {
            _.extend(this.bookResponse, {status: data.data});
            if(data.data.context && data.data.context.exception){
                this.alertService.addMessage('danger',data.data.context.exception)
            }
            return data
        })).catch((err) => {
            return throwError(err)
        });
    }
    pollStatus(reference) {
        this.statusPolling = setInterval(() => {
            this.bookBundleStatus(reference).subscribe((resp) => {
                this.bookResponse = resp.data
                //When the status change stop interval
                if (this.bookResponse.status != 'PENDING') {
                    clearInterval(this.statusPolling);
                    if (this.bookResponse.status == 'SUCCESS') {
                        this.authenticationService.getUserInfo().subscribe()
                    } else {
                        let car_attempts = resp.data.process.car_book_errors?.length
                        let flight_attempts = resp.data.process.flight_book_errors?.length
                        let hotel_attempts = resp.data.process.hotel_book_errors?.length
                        let err_array= []
                        if(resp.data.process.car_book_errors){
                            let msg = resp.data.process.car_book_errors[car_attempts-1]?.message
                            if(msg){
                                err_array.push({message: msg, attempts: car_attempts, type: 'car'});
                            }
                        }
                        if(resp.data.process.flight_book_errors){
                            let msg = resp.data.process.flight_book_errors[flight_attempts-1]?.message
                            if(msg){
                                err_array.push({message: msg, attempts: flight_attempts, type: 'flight'});
                            }
                        }
                        if(resp.data.process.hotel_book_errors){
                            let msg = resp.data.process.hotel_book_errors[hotel_attempts-1]?.message
                            if(msg){
                                err_array.push({message: msg, attempts: hotel_attempts, type: 'hotel'});
                            }
                        }
                        this.errors$.next(err_array)
                    }
                }
            }, (err) => {
                clearInterval(this.statusPolling);
            })
        }, 5000)
    }

    prepareBundleBookPayload(flightBookData:IBookData = null, hotelBookData:IHotelBookData = null, carBookData:any= null) {

        let payload = null;

        //Common information can be retrieved from any service(flight/hotel/car) since will be the same for every bookData
        //Only specific info need to be retrieved in the related service
        let firstBookData = flightBookData || hotelBookData || carBookData;
        let commonData = {
            email: firstBookData.billing_info.email,
            phone_number: firstBookData.billing_info.phone_number,
            address: firstBookData.billing_info.address,
            city: firstBookData.billing_info.city?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            postal_code: firstBookData.billing_info.postal_code,
            state: firstBookData.billing_info.state || firstBookData.billing_info.state2,
            //state2: firstBookData.billing_info.state2,
            country_code: firstBookData.billing_info.country_code,
            points: Math.round(firstBookData.points)
        };
        commonData = _.extend(commonData, firstBookData.card_info);
        payload = _.extend(payload, commonData);


        //Add specific information
        if(flightBookData){
           let flightPayload = {
                flight_ppn_bundle: flightBookData.flight_contract.ppn_book_bundle,
                first_name: flightBookData.card_info.card_first_name,
                last_name: flightBookData.card_info.card_last_name,
                start_date: flightBookData.flight_contract.slice_data[0].departure.datetime.date,
                passenger: this.parsePassengerInfo(flightBookData),
                platform_version: this.environment.platform_version
            };
            payload = _.extend(payload, flightPayload);

            //Additional Fees
            if (this.authenticationService.isAdmin() && this.authenticationService.additionalFees.length > 0) {
                let payWithPoints = flightBookData.agentBookingPaymentType;
                if (flightBookData.agentBookingFlightNoFeeReason == '') {
                    let id = utils.getBookingFeeId(this.authenticationService.additionalFees, eBookingType.flight);
                    if (id != -1) {
                        _.extend(payload, {pay_additional_fee_in_points: payWithPoints, flight_additional_fee_id: id});
                    }
                }
                else {
                    let noFeeReasonId = utils.getBookingFeeNoReasonID(this.authenticationService.additionalFees);
                    _.extend(payload, {pay_additional_fee_in_points: 1, flight_no_fee_reason_id: Number(flightBookData.agentBookingFlightNoFeeReason), flight_additional_fee_id: noFeeReasonId})
                }
            }
        }
        if(carBookData){
            let carPayload ={
                car_ppn_bundle: carBookData.car_contract.car_book_bundle,
                first_name: carBookData.card_info.card_first_name,
                last_name: carBookData.card_info.card_last_name,
                driver_first_name: carBookData.passenger_info[0].first_name,
                driver_last_name: carBookData.passenger_info[0].last_name,
                insurance_selected: carBookData.passenger_info[0].insurance ? 1 : 0,
                toddler_seat_selected: carBookData.passenger_info[0].toddler ? 1 : 0,
                sms_selected: carBookData.passenger_info[0].promotion ? 1 : 0,
                sms_confirmation: carBookData.passenger_info[0].reminder   ? 1 : 0,
                infant_seat_selected: carBookData.passenger_info[0].infant ? 1 : 0,
                booster_seat_selected: carBookData.passenger_info[0].booster ? 1 : 0,
                navigation_system_selected: carBookData.passenger_info[0].navigation ? 1 : 0,
                ski_equipment_selected: carBookData.passenger_info[0].ski ? 1 : 0,
                handctrl_left_selected: carBookData.passenger_info[0].lefth ? 1 : 0,
                handctrl_right_selected: carBookData.passenger_info[0].righth ? 1 : 0,
            }
            payload = {...carBookData.passenger_info[0],...payload}
            payload = _.extend(payload, carPayload);

            //Additional Fees
            if (this.authenticationService.isAdmin() && this.authenticationService.additionalFees.length > 0) {
                let payWithPoints = carBookData.agentBookingPaymentType;
                if (carBookData.agentBookingCarNoFeeReason == '') {
                    let id = utils.getBookingFeeId(this.authenticationService.additionalFees, eBookingType.car);
                    if (id != -1) {
                        _.extend(payload, {pay_additional_fee_in_points: payWithPoints, car_additional_fee_id: id});
                    }
                }
                else {
                    let noFeeReasonId = utils.getBookingFeeNoReasonID(this.authenticationService.additionalFees);
                    _.extend(payload, {pay_additional_fee_in_points: 1, car_no_fee_reason_id: Number(carBookData.agentBookingCarNoFeeReason), car_additional_fee_id: noFeeReasonId})
                }
            }
        }
        if(hotelBookData){
            let hotelPayload = {
                hotel_ppn_bundle: hotelBookData.hotel_contract.room_data.rate_data[0].ppn_bundle,
                first_name: hotelBookData.guest_info.guest_first_name[0],
                last_name: hotelBookData.guest_info.guest_last_name[0],
                start_date: hotelBookData.hotel_contract.hotel_view.input_data.check_in,
                initials: hotelBookData.card_info.card_first_name.charAt(0) + hotelBookData.card_info.card_last_name.charAt(0),
                guest_first_name: hotelBookData.guest_info.guest_first_name,
                guest_last_name: hotelBookData.guest_info.guest_last_name,
            };
            payload = _.extend(payload, hotelPayload);

            //Additional Fees
            if (this.authenticationService.isAdmin() && this.authenticationService.additionalFees.length > 0) {
                let payWithPoints = hotelBookData.agentBookingPaymentType;
                if (hotelBookData.agentBookingHotelNoFeeReason == '') {
                    let id = utils.getBookingFeeId(this.authenticationService.additionalFees, eBookingType.hotel);
                    if (id != -1) {
                        _.extend(payload, {pay_additional_fee_in_points: payWithPoints, hotel_additional_fee_id: id});
                    }
                }
                else {
                    let noFeeReasonId = utils.getBookingFeeNoReasonID(this.authenticationService.additionalFees);
                    _.extend(payload, {pay_additional_fee_in_points: 1, hotel_no_fee_reason_id: Number(hotelBookData.agentBookingHotelNoFeeReason), hotel_additional_fee_id: noFeeReasonId})
                }
            }
        }
        return payload;
    }

    parsePassengerInfo(flightBookData:IBookData) {
        return flightBookData.passenger_info.map((item:any) => {
            item.birthday = item.year + '-' + item.month + '-' + item.day;

            //Check if ff is null or not
            if (item.ff && item.ff != '') {
                //if the ff was empty remove it
                if (item.ff.value == '') {
                    delete item.ff;
                } else {
                    //format for the BE
                    item.ff[item.ff.code] = item.ff.value
                    delete item.ff.code;
                    delete item.ff.value;
                }
            } else {
                delete item.ff
            }
            //Handle seat preference (send string instead of array)
            if(item.seat_preference){
                if(item.seat_preference.length > 0){
                    item.seat_preference = item.seat_preference[0]
                } else {
                    item.seat_preference = ''
                }
            }

            return item;
        })
    }
    clearBookData(){

        this.bookData = null;
        this.bookResponse = null;
        this.selectedPoints = null;
    }


    get bookResponse(): any {
        return this._bookResponse || JSON.parse(localStorage.getItem('bundleBookResponse'));
    }

    set bookResponse(value: any) {
        localStorage.setItem('bundleBookResponse', JSON.stringify(value));
        this._bookResponse = value;
    }


    get bookData(): any {
        let book_data = this._bookData || JSON.parse(localStorage.getItem('bundleBookData'));
        return book_data
    }

    set bookData(value: any) {
        localStorage.setItem('bundleBookData', JSON.stringify(value));
        this._bookData = value;
    }

    changeBundleParams(type:string,payload:any){

        let index = _.findIndex(this._bundle.item,(el)=>{ return el == type});

        switch (type) {
            case 'hotel':
                this.bundle.searchParams[index].start_date =  payload.check_in;
                this.bundle.searchParams[index].end_date = payload.check_out;
                this.bundle.searchParams[index].arrival_city = payload.city;
                this.bundle.searchParams[index].adults = payload.adults;
                this.bundle.searchParams[index].room = payload.rooms;
                //UPDATE CAR
                if(this.bundle.item.includes('car')){
                    this.bundle.searchParams[index+1].start_date =  payload.check_in;
                    this.bundle.searchParams[index+1].end_date =  payload.check_out;
                    this.bundle.searchParams[index+1].arrival_city = payload.city;
                }
                break;
            case 'car':
                this.bundle.searchParams[index].start_date =  payload.pickup_date;
                this.bundle.searchParams[index].end_date =  payload.dropoff_date;
                this.bundle.searchParams[index].pickup_code =  payload.pickup_code;
                this.bundle.searchParams[index].dropoff_code =  payload.dropoff_code;
                this.bundle.searchParams[index].arrival_city = payload.city;
                this.bundle.searchParams[index].pickup_time = payload.pickup_time;
                this.bundle.searchParams[index].dropoff_time = payload.dropoff_time;
                break;
            case 'flight':
                this.bundle.searchParams[index].start_date =  payload.departure_date[0];
                this.bundle.searchParams[index].end_date =  payload.departure_date[1];
                this.bundle.searchParams[index].departure_city =  payload.origin[0];
                this.bundle.searchParams[index].arrival_city =  payload.destination[0];
                this.bundle.searchParams[index].adults =  payload.adults;
                this.bundle.searchParams[index].children =  payload.children;
                this.bundle.searchParams[index].cabin_class =  payload.cabin_class;
                this.bundle.searchParams[index].mode =  payload.mode;
                //UPDATE HOTEL
                if(this.bundle.item.includes('hotel')){
                    this.bundle.searchParams[index+1].start_date =  payload.departure_date[0];
                    this.bundle.searchParams[index+1].end_date = payload.departure_date[1];
                    this.bundle.searchParams[index+1].arrival_city = payload.destination[0];
                }
                //UPDATE CAR
                if(this.bundle.item.includes('car')){
                    let pos = this.bundle.item.includes('hotel') ? 2 : 1;
                    this.bundle.searchParams[index + pos].start_date =  payload.departure_date[0];
                    this.bundle.searchParams[index + pos].end_date =  payload.departure_date[1];
                    this.bundle.searchParams[index + pos].arrival_city = payload.destination[0];
                }
                break;

        }
        //Force to call set
        this.bundle = this._bundle;
    }

    get bundle(): IBundle {
        if(!this._bundle){
            this._bundle = JSON.parse(localStorage.getItem('bundle'));
        }
        return this._bundle;
    }

    set bundle(value: IBundle) {
        localStorage.setItem('bundle', JSON.stringify(value));
        this._bundle = value;
    }

    get car_total(): number {
        return this._car_total || JSON.parse(localStorage.getItem('car_total'));
    }

    set car_total(value: number) {
        localStorage.setItem('car_total', JSON.stringify(value));
        this._car_total = value;
    }
    get flight_total(): number {
        return this._flight_total || JSON.parse(localStorage.getItem('flight_total'));
    }

    set flight_total(value: number) {
        localStorage.setItem('flight_total', JSON.stringify(value));
        this._flight_total = value;
    }
    get hotel_total(): number {
        return this._hotel_total || JSON.parse(localStorage.getItem('hotel_total'));
    }

    set hotel_total(value: number) {
        localStorage.setItem('hotel_total', JSON.stringify(value));
        this._hotel_total = value;
    }
    get cartStep(): number {
        return this._cartStep || JSON.parse(localStorage.getItem('cartStep'));
    }

    set cartStep(value: number) {
        this._observableStep.next(value);
        localStorage.setItem('cartStep', JSON.stringify(value));
        this._cartStep = value;
    }

    get selectedPoints(): number {
        return this._selectedPoints
    }

    set selectedPoints(value: number) {
        this._selectedPoints = value;
    }

    get observableStep(): any {
        return this._observableStep;
    }
    getErrors(): Observable<any> {
        return this.errors$.asObservable();
    }

    resetAtIndex(index){
        let type = this.bundle.item[index];
        switch (type) {
            case 'flight':
                this.flight_total = 0;
                break;
            case 'car':
                this.car_total = 0;
                break;
            case 'hotel':
                this.hotel_total = 0;
                break;

        }
    }

    resetTotal(){
        this.flight_total = 0;
        this.hotel_total = 0;
        this.car_total = 0;
    }


}
export interface IBundle {
    from: IFrom[],
    item: any[],
    step?: number,
    searchParams: ISearhParams[],
}
export interface IFrom{
    url: string,
    label: string
}
export interface ISearhParams {
    start_date?: string,
    end_date?: string,
    pickup_code?: string,
    dropoff_code?: string,
    dropoff_time?:string,
    pickup_time?:string,
    pick_time?: string,
    drop_time?: string,
    adults: number,
    children?: any,
    mode?: any,
    cabin_class?: any,
    arrival_city?: any,
    departure_city?: any,
    city?:any,
    room?: number
}
