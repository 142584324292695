import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'travel-button',
    inputs: ['ladda', 'customClass', 'disabled', 'ariadescribedby', 'arialabelledby', 'ariainvalid', 'type'],
    styleUrls: ['./travel.button.scss'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'travel.button.html'
})
export class TravelButton{
    @Output() onClick = new EventEmitter();
    customClass: string;
    ladda: boolean = false;
    disabled: boolean;
    ariadescribedby: string;
    arialabelledby: string;
    ariainvalid: string;
    type: string = 'button';

    fireClick() {
        this.onClick.emit({ event:event});
    }
}
