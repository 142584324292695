<div class="faq-container mt-3">
  <h3><strong>FAQ - Booking Online</strong></h3>
  <hr>
  <div class="form-group row mt-3">
    <label for="faq-type" class="col-sm-2 col-form-label">Choose a product:</label>
    <div class="col-sm-4">
      <select name="adults" id="faq-type"   [(ngModel)]="faq_type" class="form-control selectTravel">
        <option *ngFor="let faq of faqList" [ngValue]="faq">
          {{faq.label}}
        </option>
      </select>
    </div>
  </div>
  <div *ngIf="faqData && !faqLoading">
    <div *ngFor="let faq of faqData.categories| keyvalue">
      <h1>{{faqData.categories[faq.key].title}}</h1>
      <div *ngFor="let q of faqData.categories[faq.key].items | keyvalue">
        <p>{{faqData.categories[faq.key].items[q.key].question}}</p>
        <span *ngFor="let a of faqData.categories[faq.key].items[q.key].answer.paragraph_data">
          {{a}}
        </span>
      </div>
    </div>
  </div>
  <spinner-component [ladda]="faqLoading" customClass="absolute-spinner"></spinner-component>
  <div *ngIf="faq_type.id == 'hotel'" class="hotel-faq">
    <h3>Hotel Reservations</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-1" role="button" aria-expanded="false">
          Are there special rates for children?
        </a>
        <div class="collapse" id="h-1">
          We do not offer special rates for children. The rate you pay applies to the total number of guests in your party.
          All rooms are guaranteed for the number of guests indicated in the room rate only. If you are planning on bringing the whole family along, you may want to get more than one room. Requests for additional guests or other special needs (including preferences for smoking or non-smoking rooms) should be requested through your confirmed hotel and cannot be guaranteed.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-2" role="button" aria-expanded="false">
          Can I add nights or rooms to my existing hotel reservation?
        </a>
        <div class="collapse" id="h-2">
          You will need to make another reservation in order to add rooms or nights to your stay. Please note, when you make the new reservation, you may not be given the same room for the additional nights.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-222" role="button" aria-expanded="false">
          Can I book a same day reservation?
        </a>
        <div class="collapse" id="h-222">
          Same-day reservations are available in most areas, for most of our hotels. You can book a reservation for the same night up until 5am Eastern Time the next day! So you night-owls can rest easy knowing that next time you need a hotel room, we're here for you!
          For all hotel reservations, you can book up to 11 months in advance, for a maximum of 21 days.
          Please keep a few things in mind when requesting a same-day check-in:
          Availability may be limited
          Some hotels do not allow same-day bookings
          When booking a reservation for immediate check-in after midnight, enter yesterday's date into the check-in date field
          If you book a one-night reservation after midnight for immediate check-in, you will have to check out at the hotel's normal check-out time later that same day.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-223" role="button" aria-expanded="false">
          Can I get a handicapped-accessible room?
        </a>
        <div class="collapse" id="h-223">
          Most hotels will be glad to accommodate you if rooms are available. If you have disability needs (for example only, you need a wheelchair accessible room or require the use of service animal) you must call the hotel after your booking is made and verify that your needs can be met. We cannot accommodate disability needs in advance of your booking. If your disability needs cannot be accommodated by the hotel, please call customer service.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-3" role="button" aria-expanded="false">
          Can I purchase a tax exempt reservation?
        </a>
        <div class="collapse" id="h-3">
          We do not provide customers with the option to purchase travel that is tax exempt and our partners cannot deduct the taxes from purchases made through our website. If you need to purchase travel that is tax exempt, we recommend that you visit one of our partners and buy from them directly.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-4" role="button" aria-expanded="false">
          Can I request a smoking or non-smoking room?
        </a>
        <div class="collapse" id="h-4">
          If you require a smoking or non-smoking room, you can select a hotel that fits your needs. You should contact your hotel directly after your purchase is confirmed and ask. All special requests, including room-types, are based on availability and honored at the discretion of the hotel.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-5" role="button" aria-expanded="false">
          Can I request a specific bed type?
        </a>
        <div class="collapse" id="h-5">
          When you make a reservation, you can select a specific bed type.
          All rooms are guaranteed for the number of guests indicated in the room rate. Requests for bed types (King, Queen, 2 Doubles, etc.) or other special needs (including preferences for smoking or non-smoking rooms) should be requested through your confirmed hotel and cannot be guaranteed.
          Once your hotel room is reserved, you may contact the hotel to request specific bedding. Special requests are at the discretion of the hotel and subject to availability. Keep in mind that if available, there may be an extra charge payable directly to the hotel. Your confirmed hotels phone number is listed on your itinerary page.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-6" role="button" aria-expanded="false">
          Can I use your website to reserve a hotel room for someone else?
        </a>
        <div class="collapse" id="h-6">
          You can purchase a room for someone else as long as you and the person for whom you book the room for meet the hotel's minimum age requirement, which is provided on the check out page for any hotel reservation made on this site, and can present the appropriate identification before check in.
          When submitting the request on our website, enter the names of the travelers where required. Under the billing information, enter the billing details of the person paying for the reservation. Initialing the contract page during the request process indicates that the person purchasing reservations and the traveler(s) agree with all Terms and Conditions.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-8" role="button" aria-expanded="false">
          Can I bring my pet?
        </a>
        <div class="collapse" id="h-8">
          You can sort by specific amenities to include pet-friendly hotels. Some pet-friendly hotels may indicate that they allow pets in the hotel description. However, there may be additional restrictions. To best determine if the hotel you are selecting allows pets and what restrictions may apply, you should contact the hotel directly.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-9" role="button" aria-expanded="false">
          Does my hotel rate include breakfast?
        </a>
        <div class="collapse" id="h-9">
          With our hotel reservations, you can sort by specific amenities to include breakfast. Please read the descriptions carefully as not all hotels offer free breakfast. If you have any questions about the amenities listed for your particular hotel you can call them directly. The hotel phone number is listed on your confirmation page.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-10" role="button" aria-expanded="false">
          Hotel Amenity Questions
        </a>
        <div class="collapse" id="h-10">
          You can view the amenities for each specific hotel, and even filter by amenity and star level to further refine your search.
          Please check the amenities listed for your hotel before you book a reservation. If you have any questions about the amenities, please contact your hotel. The hotel phone number will be listed on your confirmation page.
          If you need a crib or other special bedding for your infant or child, contact the hotel directly and arrange these items. Each hotel has their own policy on limitations, availability, and costs.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-11" role="button" aria-expanded="false">
          Hotel Charge/Rate Questions
        </a>
        <div class="collapse" id="h-11">
          <span class="d-block">Does my hotel rate include parking?</span>
          <span>While parking at some hotels is free or included in your nightly room rate, others charge for parking as an incidental - just like phone calls or room service. Review the Hotel Details information on our website for specific information on parking options the hotel may offer. </span>
          <span class="d-block">Will I be charged for extra bedding? </span>
          <span>Any requests for extra bedding are at the discretion of the hotel and may result in an extra charge payable directly to the hotel. You may call your confirmed hotel to inquire about extra bedding availability and cost. Remember, all rooms are guaranteed for the number of guests indicated in the room rate only. Be sure to check with the hotel if you plan on having more than that number of people stay in your room - the hotel may not allow extra guests due to fire safety laws. If your hotel does allow extra guests, there may be additional charges. These charges vary and will be payable directly to the hotel - most likely at checkout. </span>
          <span class="d-block" >Will there be an additional charge for a courtesy car?</span>
          <span>If your hotel does offer a courtesy car or shuttle they may charge an extra fee. For more information, call the hotel directly. </span>
          <span class="d-block" >Do I have to pay extra for a cot, rollaway bed or crib?</span>
          <span>Once your hotel room is reserved, you may contact the hotel to request a cot, rollaway bed or crib for your room.Special requests are at the discretion of the hotel. They will be happy to accommodate you if fire-safety laws allow it and the type of extra bedding you need is available. Keep in mind that there may be an extra charge payable directly to the hotel. </span>
          <span class="d-block">Is there a charge if an extra person stays in my room?</span>
          <span>All rooms are guaranteed for the number of guests indicated in the room rate only. Be sure to check with the hotel if you plan on having more than that number of people stay in your room -the hotel may not allow extra guests due to fire safety laws.If your hotel does allow extra guests, there may be additional charges. These charges vary and will be payable directly to the hotel - most likely at checkout. </span>
          <span class="d-block">What if I have problems with my charges? </span>
          <span>If you have problems with your charges, please contact us. </span>
          <span class="d-block">What if I suspect unauthorized charges on my credit card?</span>
          <span>In the unlikely event that an unauthorized use of your credit card occurs, immediately notify your credit card issuer. The appropriate 800 number is usually located on the back of the card. It is critical that you contact your credit card issuer directly; they will explain the specific steps required to report suspected fraud, which generally include signing an affidavit attesting to the unauthorized charges and/or canceling the credit card in question. Your credit card issuer is also responsible for initiating the process to validate and refund or reverse the unauthorized charges on your credit card. Most credit card companies either cover all charges that result from unauthorized use of your credit card or limit your liability to $50.00 - the maximum liability allowed under the Fair Credit Billing Act. If you have experienced unauthorized charges from our website, please complete our Fraud Report Form (after reporting the activity to your credit card issuer) so that we can take the necessary steps to prevent further use of the credit card(s) in question at PPN Merchant & CUG & Agoda Demo with Branding. Please note that after completing the Fraud Report Form, you will need to use a different credit card when making a future offer on our website.</span>
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-12" role="button" aria-expanded="false">
          Hotel Freebies
        </a>
        <div class="collapse" id="h-12">
          Freebie deals range from Instant Discounts, Free Nights, Resort Credits, Room Upgrades, Free Breakfast and more when you book a reservation. Best of all, they are included in the rate you see! Not all Freebies apply to all room rates, so book carefully - make sure you select a room description that has a Freebie listed within the room description.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-13" role="button" aria-expanded="false">
          Hotel Star Ratings
        </a>
        <div class="collapse" id="h-13">
          <span class="d-block">The star rating system is provided for your reference. Like other well-known hotel rating systems, we consider a number of factors in evaluating the quality of participating hotels such as: amenities, facilities, reputation, brand, other rating services, customer feedback from guests who have stayed at the hotel, etc., to assign a single star rating. We screen participating hotels carefully and update our information periodically to ensure the validity of our ratings. Our rating system may sometimes differ from those of other rating systems that you may be familiar with. We do not warrant or guarantee that our star rating system is equal to or consistent with any other star rating system and reserve the right to change a hotel’s rating at any time with or without notice. Star Rating Descriptions <br> Our hotel star ratings are indicators of the general quality you can expect from your chosen hotel. The descriptions below provide general information, based on the information we use to determine a star rating. Circumstances such as ongoing renovations, overbooking, and specific guest needs can affect the quality of your stay. Be sure to let the hotel know of any special requests or requirements you have at check-in. The features described here are often not available at vacation rentals, condos, inns, bed-and-breakfasts, or other specialty lodging. </span>
          <span class="d-block">1-STAR HOTELS These hotels are national name-brand and quality independent hotels that will meet the budget-traveler's basic needs for comfort & convenience. Restaurants are either on-site or close-by.</span>
          <span class="d-block">2-STAR HOTELS All of our 2 star hotels are name-brand and independent hotels which will provide your everyday amenities and services, along with clean and comfortable accommodations. They generally are near major attractions, intersections and casual-dining restaurants. Some may even offer limited hotel-restaurant service. </span>
          <span class="d-block">2.5-STAR HOTELS Our 2 1/2 star hotels are national name-brand and quality independent hotels featuring tasteful accommodations, and casual public spaces. While hotel services may be a bit limited, they may feature a restaurant for breakfast or offer a continental buffet, and may offer special amenities for the business traveler by providing a homier feel. </span>
          <span class="d-block">3-STAR HOTELS Our 3-star hotels include some of the most recognizable brand-names and quality independent hotels in the industry. Enjoy comfortable rooms and well-decorated public spaces. On-site dining is sometimes offered but may not be available for all three meals, and a fitness room may also be available.</span>
          <span class="d-block">3.5-STAR HOTELS These hotels are some of the most recognizable brand-names and quality independent hotels in the industry. Enjoy excellent rooms, well-decorated public spaces with a higher level of customer service.</span>
          <span class="d-block">4-STAR HOTELS The personalized customer service, elegant décor, and amazingly high standards of comfort at these hotels are simply unbeatable. </span>
          <span class="d-block">4.5-STAR HOTELS These hotels are focused on providing impeccable service and the finest amenities. Distinctive touches provided in fashionable surroundings will make your stay unforgettable. </span>
          <span class="d-block">5-STAR HOTELS Our 5-star hotels offer the absolute highest levels of quality, services and amenities. Guest rooms are finely furnished with lovely linens and upscale amenities you may never want to leave. Add to that the personalized guest service, elegant decor and amazingly high standards of comfort, and these hotels are simply unbeatable.</span>
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-14" role="button" aria-expanded="false">
          How can I check the status of my offer?
        </a>
        <div class="collapse" id="h-14">
          You'll usually know within seconds if your reservation is confirmed. However, in some instances, it may take up to 15 minutes for us to book your reservation. If you go to your Check Your Request Status page after half an hour, please call our Customer Service team with your offer number in hand.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-15" role="button" aria-expanded="false">
          How many guests can I book per room?
        </a>
        <div class="collapse" id="h-15">
          All rooms booked are guaranteed for the number of guests indicated in the room rate. Additional guests may incur additional fees payable directly to your confirmed hotel. You may contact your confirmed hotel directly to inquire about extra guests.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-16" role="button" aria-expanded="false">
          How many rooms can I book?
        </a>
        <div class="collapse" id="h-16">
          You may reserve up to 9 rooms at a time, provided they all have the same check-in and check-out dates. For a booking of 5-9 rooms a different name for each room is required.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-17" role="button" aria-expanded="false">
          How old do I have to be to book a hotel room through your website?
        </a>
        <div class="collapse" id="h-17">
          As long as you meet the hotel's minimum age requirement, which is provided on the check out page for any hotel reservation made on this site, and can present the appropriate identification before check in.        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-18" role="button" aria-expanded="false">
          What do I need to bring to the hotel for check-in?
        </a>
        <div class="collapse" id="h-18">
          Checking in is easy. You will need a government-issued photo ID (like a driver's license or passport depending on the hotel's location) and your hotel confirmation number - which is on your itinerary. To print your itinerary, just go to the 'Check Your Request' section of our website. The reservation holder must present a valid photo ID and credit card at check-in. The credit card is not only used to guarantee the reservation, it is required for any additional hotel specific service fees or incidental charges or fees that may be charged by the hotel at checkout. These charges may be mandatory (e.g., resort fees) or optional (parking, phone calls or minibar charges) and are not included in your per night price.
          If you do not have a credit card some properties may allow you to leave a cash deposit for incidentals. If you plan to leave a cash deposit, please call your confirmed hotel to determine if this is possible and what the amount required is.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-19" role="button" aria-expanded="false">
          What if I made a mistake booking my reservation?
        </a>
        <div class="collapse" id="h-19">
          You may be able to cancel the reservation based on the cancellation policy presented to you at the time of purchase. You can find cancellation policy information in the important information section of your confirmation page.
          Please review your reservation information carefully before making your reservation.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-20" role="button" aria-expanded="false">
          What if my flight does not go as scheduled?
        </a>
        <div class="collapse" id="h-20">
          If you are going to be later than expected, please contact your hotel directly. You can find your hotel phone number on your Check Your Request Status page. Also, if you will be checking in later than midnight on your confirmed check-in day, please contact your hotel to let them know. Otherwise, they might give your room to someone else!
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-21" role="button" aria-expanded="false">
          What if my travel plans change?
        </a>
        <div class="collapse" id="h-21">
          You may be able to change or cancel the reservation based on the cancellation policy presented to you at the time of purchase.
          You can find your cancellation policy information in the Important Information section of your confirmation page. Unfortunately, once a reservation is booked, we do not have the ability to change or modify it, so cancelling it may be the only option and you will be subject to any cancel penalties that may apply.
          Please review all of your reservation details carefully before making your hotel reservation.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-22" role="button" aria-expanded="false">
          What taxes and service fees will I pay?
        </a>
        <div class="collapse" id="h-22">
          For reservations where we do not charge your credit card, we pass your credit card information along to the hotel in order to secure your reservation. For those reservations, the hotel will charge your card for the room rate and for taxes. Some hotels, consisting mostly of hotels outside of the United States, charge a tax inclusive rate that includes your room rate and taxes. If the hotel you select is charging a tax inclusive rate, it will be indicated on your contract page prior to finalizing your reservation.
          For other hotel reservations, we will charge your credit card directly. You will be informed on our website whether we are charging you directly or are passing your credit card information to the hotel to secure your reservation. In connection with facilitating your hotel transaction when we charge your card, the charge to your payment method will include a charge for Taxes and Service Fees. This charge includes an estimated or anticipated amount to recover the amount we pay to the hotel in connection with your reservation for taxes owed by the hotel including, without limitation, sales and use tax, occupancy tax, room tax, excise tax, value added tax and/or other similar taxes. In certain locations, the tax amount may also include government imposed service fees or other fees not paid directly to the taxing authorities but required by law to be collected by the hotel. The amount paid to the hotel in connection with your reservation for taxes may vary from the amount we estimate and include in the charge to you. The balance of the charge for Taxes and Service Fees is a fee we retain as part of the compensation for our services and to cover the costs of your reservation, including, for example, customer service costs. The charge for Taxes and Fees varies based on a number of factors including, without limitation, the amount we pay the hotel and the location of the hotel where you will be staying, and may include profit that we retain.
          For transactions involving hotels located within certain jurisdictions, the charge to your debit or credit card for Taxes and Service Fees includes a payment of tax that we are required to collect and remit to the jurisdiction for tax owed on amounts we retain as compensation for our services.
          Depending on the property you stay at you may also be charged (i) certain mandatory hotel specific service fees, for example, resort fees (which typically apply to resort type destinations and, if applicable, may range from $10 to $40 per day), energy surcharges, newspaper delivery fees, in-room safe fees, tourism fees, or housekeeping fees and/or (ii) certain optional incidental fees, for example, parking charges, minibar charges, phone calls, room service and movie rentals, etc.. These charges, if applicable, will be payable by you to the hotel directly at checkout. When you check in, a credit card or, in the hotel's discretion, a debit card will be required to secure these charges and fees that you may incur during your stay. Please contact the hotel directly as to whether and which charges or service fees apply.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-23" role="button" aria-expanded="false">
          How do I file an insurance claim?
        </a>
        <div class="collapse" id="h-23">
          In order to file a claim, please contact Allianz Global Assistance by any one of the following ways: <br>
          <span class="d-block">Online claim initiation: <a href="www.etravelprotection.com/ppn/home">www.etravelprotection.com/ppn/home</a> or via the TravelSmart™ app which is available for free download via the Apple App Store or Google Play Store. </span>
          <span class="d-block">By Phone: Toll free at: <a href="tel:1 (866) 909-4101">1 (866) 909-4101</a> </span>
          <span class="d-block">By Email: <a href="mailto:customerservice@allianzassistance.com">customerservice@allianzassistance.com</a> </span>
          <span class="d-block">By Mail: Allianz Global Assistance P.O. Box 71533 Richmond, VA 23286-4684 </span>
          Claims should be submitted within 90 days of incurring the covered loss. The majority of claims will be processed within 30 days after the Insurance Company receives all supporting documentation from you.
          Terms, conditions, and exclusions apply. Plan(s) underwritten by BCS Insurance Company or Jefferson Insurance Company. AGA Service Company is the licensed producer and administrator of these plans. Contact AGA Service Company at <a href="tel:(800) 284-8300">(800) 284-8300</a> or 9950 Mayland Drive, Richmond, VA 23233 or <a href="mailto:customerservice@allianzassistance.com">customerservice@allianzassistance.com</a> .
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a  data-toggle="collapse" href="#h-24" role="button" aria-expanded="false">
          What is Hotel Cancellation Protection and how do I get it?
        </a>
        <div class="collapse" id="h-24">
          Hotel Cancellation Protection is an optional plan that protects you and your travel companions who are staying in your room against unforeseen, covered illness, injury, job layoff, and more. Hotel Cancellation Protection from Allianz Global Assistance is available for purchase during the hotel reservation process, or directly after you've booked your room(s) via the confirmation page. Plans are only available to US residents.
          Examples of situations when the benefits would apply include a covered illness or injury of traveler and/or immediate family members before or during your trip, getting into a covered traffic accident on the way to your hotel and missing half or all of your stay, or a named hurricane at your destination (if the storm was not named prior to your purchase). Benefits are also available for pre-existing medical conditions. For a complete list of benefits, see the Description of Coverage for Domestic trips, or the International Description of Coverage for International trips.
          Terms, conditions, and exclusions apply. Plan(s) underwritten by BCS Insurance Company or Jefferson Insurance Company. AGA Service Company is the licensed producer and administrator of these plans. Contact AGA Service Company at (800) 284-8300 or 9950 Mayland Drive, Richmond, VA 23233 or <a href="mailto:customerservice@allianzassistance.com">customerservice@allianzassistance.com</a>.
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="faq_type.id == 'car'" class="car-faq">
    <h3>Car Reservations</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-1" role="button" aria-expanded="false">
          Can I cancel/change my reservation/itinerary?
        </a>
        <div class="collapse" id="c-1">
          If your reservation is pre-paid then no, you will have to contact the rental company to make any changes to your reservation. If your reservation is being paid on arrival, then simply visit the Review/Cancel Reservation section of our website and select the 'Cancel this Reservation' link at the top of the page. You will need your Rental Car Itinerary Number and the email address you entered when you reserved your car.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-2" role="button" aria-expanded="false">
          What do I need to bring when I pick up my rental car?
        </a>
        <div class="collapse" id="c-2">
          You should have three things with you when you arrive to pick-up your rental car:
          A valid U.S. drivers license or a valid driver's license issued from your country of residence and an International Driver's Permit -- you'll need to present it at the rental counter to prove your identity, verify your birth-date and, if necessary, for a driving record check.
          Your Rental Car Itinerary/Receipt - which is available on the homepage of our website at the top right-hand corner of the page. Enter your Request Number and your receipt and full itinerary will be right there for you to print.
          A valid major credit card with available credit. A debit/check card that has a Mastercard or Visa logo is accepted but also needs to have available credit as the rental car companies require this as a security deposit on the car. The driver will also need to have this available credit if they choose to purchase optional items at the rental counter.
          Cash: The driver will not be allowed to leave a cash deposit in lieu of a credit card or debit/check card.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-3" role="button" aria-expanded="false">
          Can I rent cars in Canada, Europe, and other International locations?
        </a>
        <div class="collapse" id="c-3">
          Yes you can rent a car in Canada, Europe, and other International locations. For rental cars in Mexico, please ask the rental partner what their specific policies are once you are at the rental counter. We offer great savings, selection, and customer service for your international rental car travel. Simply go to our homepage and type the name of the city or airport you are traveling to in the search fields at the top of the page.
          Important Information Regarding Exchange Rates: Purchases made on our web site are transacted exclusively in United States Dollars (USD). If you make a purchase from us using a non-USD-denominated credit or debit card, please be aware that, due to the constant fluctuation in exchange rates, our charge to your card or the estimated charge amount we provide you, may differ based on the exchange rate at the time you make your reservation versus the rate at the time the charge is reflected on your credit card statement. Also, in the event that we must credit your account, we will refund the exact USD amount initially charged and will not be responsible for any fluctuations in exchange rates which may cause differences in your billing statement.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-4" role="button" aria-expanded="false">
          Can I place a request if I am not a U.S. resident?
        </a>
        <div class="collapse" id="c-4">
          If the driver of the car is not a U.S. resident, the driver must present a valid International driver's license at the rental counter when renting a car in the United States. Most U.S. rental car companies accept international credit cards.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-5" role="button" aria-expanded="false">
          What kinds of cars can I rent?
        </a>
        <div class="collapse" id="c-5">
          We offer the following car types:
          Economy Cars
          Compact Cars
          Mid-size Cars
          Standard Cars
          Full-size (4 door) Cars
          Premium Cars
          Luxury Cars
          Convertibles
          SUVs
          Mini-van (7 passenger)
          Full-size Vans. Our web site includes a full description of each car type, including the typical number of passengers and pieces of luggage each will accommodate. Rental car companies carry a variety of makes and models for each car type listed above. As a result, the rental car companies are not able to guarantee the specific make or model you will receive at the rental counter. Requests for non-smoking vehicles should be made at the rental counter. Rental car companies are not able to guarantee the availability of a non-smoking vehicle.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-6" role="button" aria-expanded="false">
          Same Day Reservations
        </a>
        <div class="collapse" id="c-6">
          You can book a rental car reservation up to one hour in advance of your pick-up time. When searching our site, we will provide you with the available options based on the itinerary details you enter on our website. For example, if you place a request on our website at 11 a.m. the earliest you can pick up your rental car is noon the same day.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-7" role="button" aria-expanded="false">
          Can I reserve a car for someone else?
        </a>
        <div class="collapse" id="c-7">
          You can submit a request for a rental car that someone else will be driving as long as you enter the driver's full name and age and the driver meets the following requirements:
          Has a valid driver's license issued in his or her full name - the rental company will verify age and may check the driver's driving record. Non-U.S. residents must present a valid international driver's license from his/her home country.
          Has a valid credit card with a minimum of $200 or more of available credit. Most locations also accept debit cards.
          Meets the minimum age requirements.
          Keep in mind that when you initial the request on our Web site you are indicating that the DRIVER accepts all of Priceline Partner Network Rental Cars' Terms and Conditions and that the driver will be denied rental if any of the above criteria are not met.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-8" role="button" aria-expanded="false">
          What is Collision Damage Insurance Coverage and how do I get it?
        </a>
        <div class="collapse" id="c-8">
          Collision Damage Coverage is optional insurance which can be purchased on our website either during the reservation process or after the reservation is confirmed. Collision Damage Coverage can be purchased for only $9/calendar day for standard rentals (with $40,000 in coverage), or $12/calendar day for premium rentals (with $75,000 in coverage). Please remember, while no payment is required for your rental car reservation until you pick up the vehicle, Collision Damage Coverage is charged immediately to your credit card at the time of booking your reservation, or when you add it to your existing reservation.

          While the vehicle is in your possession and is damaged due to collision, theft, vandalism, windstorm, fire, hail or flood, you are subject to pay a lesser amount for the total cost of car repairs, additional charges imposed by the rental car company, or the actual cash value of the car. The coverage offered on our website provides a cost-effective way to protect yourself against unforeseen damages or theft. There is also no deductible.

          The plan is primary coverage, meaning you do not have to claim payment from any other insurance plan before receiving coverage under this plan. This helps avoid filing a claim with your personal auto insurance and risking insurance rates increasing in the future. This coverage is very similar to the Collision Damage Waiver (CDW) that the car rental companies try to sell customers at the time of renting a car. Like the CDW, this coverage provides reimbursement to the person purchasing the plan for charges incurred due to loss or damage of the rental car. It is possible that agents at the counter may try to sell you their plan. Please note that while this coverage is similar to the CDW offered by the rental car companies, certain benefits, limits, terms and exclusions may differ.

          If you have additional questions about Collision Damage Insurance Coverage, contact Allianz Global Assistance, our partner and insurance provider, at 1 (866)909-4101 or learn more at www.etravelprotection.com/ppn/home.

          Terms, conditions, and exclusions apply. Plan(s) underwritten by BCS Insurance Company or Jefferson Insurance Company. AGA Service Company is the licensed producer and administrator of these plans. Contact AGA Service Company at 800-284-8300 or 9950 Mayland Drive, Richmond, VA 23233 or customerservice@allianzassistance.com.

          Important: Your car rental cannot exceed 45 consecutive days to be eligible. Exotic and specialty vehicles (e.g. campers, trailers, motorcycles, RV's, etc.), are not covered. See the Description of Coverage (url: https://www.allianzworldwidepartners.com/usa/terms-and-conditions/001004568) for a complete list of excluded vehicles.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-9" role="button" aria-expanded="false">
          How much does Collision Damage Coverage cost?
        </a>
        <div class="collapse" id="c-9">
          Collision Damage Coverage can be purchased during checkout for either $9/calendar day for standard rentals (with $40,000 in coverage), or $12/calendar day for premium rentals (with $75,000 in coverage). Please remember, while no payment is required for your rental car reservation until you pick up the vehicle, Collision Damage Coverage is charged immediately to your credit card at the time you book your rental reservation.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-10" role="button" aria-expanded="false">
          Where can I find my itinerary or get a receipt?
        </a>
        <div class="collapse" id="c-10">
          You can find your itinerary by going to the Review/Cancel Reservation section of our website. Enter your Rental Car Trip Number and the email address you used to place your request, and your receipt and full itinerary will be right there for you to print. You will not get a copy of your receipt or itinerary by mail.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-11" role="button" aria-expanded="false">
          When I cancel my rental car reservation, can I also cancel my Collision Damage Coverage?
        </a>
        <div class="collapse" id="c-11">
          Cancellations with 100% refund will be permitted for Collision Damage Insurance up until your scheduled pick-up time. If you choose to cancel your reservation, please visit www.etravelprotection.com/ppn/home or call 1 (866) 909-4101 to cancel insurance.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-12" role="button" aria-expanded="false">
          How do I file an insurance claim?
        </a>
        <div class="collapse" id="c-12">
          In order to file a claim, please contact Allianz Global Assistance by any one of the following ways:
          Online claim initiation: www.etravelprotection.com/ppn/home or via the TravelSmart™ app which is available for free download via the Apple App Store or Google Play Store.
          By Phone: Toll free at: 1 (866) 909-4101
          By Email: customerservice@allianzassistance.com
          By Mail: Allianz Global Assistance P.O. Box 71533 Richmond, VA 23286-4684

          Claims should be submitted within 90 days of incurring the covered loss. The majority of claims will be processed within 30 days after the Insurance Company receives all supporting documentation from you.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-13" role="button" aria-expanded="false">
          Can I get a one-way rental?
        </a>
        <div class="collapse" id="c-13">
          Yes. You can book a rental car reservation with a different pick-up and drop-off location (also known as a "one-way" rental). For example, you can enter a pick-up location in Orlando and a drop-off location in Miami if that is where you will end your trip.
          One-way reservations are currently limited to airport locations only (for example, pick-up at Orlando International Airport and drop-off at Miami International Airport.) There may also be a "drop-off" fee charged by the rental car company.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-14" role="button" aria-expanded="false">
          Helpful advice for renting a car.
        </a>
        <div class="collapse" id="c-14">
          Know Your Insurance Coverage: Be sure to research the insurance coverage available on your current automobile policy, your credit card agreement and your homeowners or renter's policy prior to picking up your rental car. This will help you make the most informed decision about optional coverages offered at the rental counter.
          Inspect the Condition of Your Rental Car: Take a walk around your rental car with an agent before you leave the rental location. Be sure to make note of any scratches, dents, dings, etc. and report them so there is no confusion regarding the car's condition upon return.
          Get to Know the Features on Your Rental Car: Take a few minutes before you leave the rental location to familiarize yourself with where everything is on your rental car. Check the headlights, hazards, windshield wipers, door locks, spare tire, etc.
          Look at a Map Before Leaving the Airport: Be sure to ask the agent at the rental counter for directions or a map of the area. Most rental car companies provide free maps as a courtesy.
          Avoid Additional Charges at Drop-off: Be sure to return your rental car at the designated date and time on your rental agreement to avoid extra day and/or extra hour charges. If you do not opt to pre-purchase gas at the rental counter, be sure the gas tank is full upon return to avoid gas and re-fueling fees.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-15" role="button" aria-expanded="false">
          Can I drive my rental car outside the United States?
        </a>
        <div class="collapse" id="c-15">
          While your rental car comes with unlimited mileage, the rental car company that is providing the car may have restrictions about driving your rental car into another country. If you plan on taking your rental car outside the U.S., we suggest you ask at the rental counter about its company's policy prior to driving off the rental car lot. This includes inquiries regarding driving into Canada and Mexico.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-16" role="button" aria-expanded="false">
          Can I add a driver to my rental car reservation?
        </a>
        <div class="collapse" id="c-16">
          You can add a driver to your rental car reservation at the rental counter. There may be additional fees which will be disclosed at the rental counter. The primary driver on the reservation must arrive at the counter to pick up the car and add the additional driver to the rental agreement. We are unable to add a driver to your rental reservation or change the name of the primary driver originally entered on our web site.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-17" role="button" aria-expanded="false">
          When will I be charged for my rental car?
        </a>
        <div class="collapse" id="c-17">
          When you choose your rental car company and your exact rate we will provide an estimate of what the rental car company will charge you when you rent the car. This estimate is based on information available from the rental car company directly and includes an estimate of taxes, fees, and surcharges that may apply to your transaction at the time of reservation. It does not include any additional gas, fees, or other surcharges.
          Please check with the rental car company when you are at the rental counter for complete rate details.
          Pointer - If you have any questions about what you've been charged - check your paperwork! Charges for optional items will be listed on your rental agreement (provided by the rental company) and you are often required to initial by them at pick-up. Any questions concerning optional items should be directed to the rental company that accepts your request.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#c-18" role="button" aria-expanded="false">
          What if I have questions about charges that appear on my rental agreement?
        </a>
        <div class="collapse" id="c-18">
          Please read the following information, which will help you to understand your rental agreement and clarify any charges.
          Your rental agreement shows charges for all optional items (insurance, gasoline, upgrades, extensions, ski-racks, cell phones, etc.) you elect to take at the rental counter, and you are typically required to initial by them when you pick up your car. These charges are in addition to the cost of your rental.
          We do not have access to the actual rental contract you signed at the counter or any additional charges the rental car company billed to you based on that contract. If you have questions about any of the charges on your rental, you will need to contact the rental car company directly.
          Here is a definition of terms to help you understand your rental agreement:
          COLLISION DAMAGE WAIVER (CDW/LDW):
          This item involves waiving or limiting your personal responsibility for damage to or loss of your rental car.
          EXTENDED PROTECTION/LIABILITY COVERAGE (EP/SLI):
          Protection for claims made against you.
          PERSONAL ACCIDENT INSURANCE (PAI):
          Coverage for renters in the event of accidental death or bodily injury for the duration of the rental.
          PERSONAL EFFECTS COVERAGE (PEC):
          Coverage for damaged or stolen personal property.
          PREPAY FOR A FULL TANK (GAS A):
          Prepaying for gas at the rental counter upon vehicle pick-UP - UNUSED GASOLINE IS NOT REFUNDED.
          REFUEL UPON RETURN (GAS B):
          Rental Car companies charge for gas if a customer does not pre-pay and returns the car with less than a full tank.
          OPTIONAL EQUIPMENT:
          Rental car companies charge for additional items you elect to add at the rental counter - such as child safety seats, ski racks, cell phones, navigation equipment, etc. These charges vary by rental company and are not included in the cost of the rental reservations itself.
          We do not have the ability to view our rental partner's rental agreements and each partner has a different version, however, this is an idea of what you might see.
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="faq_type.id == 'flight'" class="car-faq">
    <h3>About Airline Tickets</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-1" role="button" aria-expanded="false">
          Do you sell special fares such as military fares, bereavement fares, senior fares, child fares, infant fares or group discounts?
        </a>
        <div class="collapse" id="f-1">
          We do not offer or sell special fares such as military fares, bereavement fares, senior fares, child fares, infant fares or group discounts.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-2" role="button" aria-expanded="false">
          Things to know before you buy
        </a>
        <div class="collapse" id="f-2">
          Below are some things to consider in preparation for placing your request:
          For security, all reservations must be made in the exact name of the person traveling. The name on the ticket must match your government-issued photo ID - nicknames cannot be used. If you use a suffix such as Sr., Jr., III etc. please be aware that this extension will be added immediately after your last name on your confirmation, e.g. SmithIII. For international travel, the name on the reservation must be exactly as it appears on the traveler's passport.
          If you're traveling outside the U.S., all travelers will need a valid passport and you may also need to show additional documentation at your destination and/or in connecting countries.
          In the unfortunate event that you need to change or cancel your reservation, airline policies vary. If changes are permitted, they must be made prior to the original date of departure, and will incur a carrier-imposed $100 to $150 USD change fee per ticket for domestic flights and $200 to $300 USD or more per ticket on international flights. In addition there is a service fee of $30 USD per ticket and the airfare cost may increase due to decreased flight ability.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-3" role="button" aria-expanded="false">
          What is Travel Insurance (Trip Protection) and how do I get it?
        </a>
        <div class="collapse" id="f-3">
          Trip Protection is an optional plan that insures you and your travel companions against unforeseen sickness, injury, job loss, and more. Trip Protection from Allianz Global Assistance is available for purchase during the flight reservation process, or directly after you’ve booked your flight via the confirmation page. Plans are only available to US residents.
          Airline reservations may be restricted and requests to change or cancel reservations are often denied based on airline policy. However, with Travel Insurance provided by Allianz Global Assistance, if you have to cancel or interrupt your trip for a covered reason, you can be reimbursed up to the total cost of your original reservation.
          Some examples of covered emergencies include covered illness, injury or death of insured passengers and/or immediate family members. For a complete list of covered emergencies, see the Description of Coverage for Domestic trips, or the International Description of Coverage for International trips.
          Terms, conditions, and exclusions apply. Plan(s) underwritten by BCS Insurance Company or Jefferson Insurance Company. AGA Service Company is the licensed producer and administrator of these plans. Contact AGA Service Company at (800) 284-8300 or 9950 Mayland Drive, Richmond, VA 23233 or customerservice@allianzassistance.com.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-4" role="button" aria-expanded="false">
          What are my ticketing options?
        </a>
        <div class="collapse" id="f-4">
          We issue e-tickets (when available) for domestic travel -that is travel within the 48 contiguous (connected) states as well as Hawaii, Alaska, Puerto Rico, Guam, and the U.S. Virgin Islands. Some international trips (travel outside of the United States) or multi-destination trips require paper tickets as specified by the airline.
          Whether you are traveling domestically or internationally, e-tickets are issued free of charge. If e-tickets are not available from the airline, paper tickets will be issued for a $34.95 USD express delivery fee. If paper tickets must be issued, you will be notified of the shipping charges prior to your decision to purchase.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-5" role="button" aria-expanded="false">
          Do you sell business or first class tickets?
        </a>
        <div class="collapse" id="f-5">
          You will have the option to select your specific travel class between economy, business, or first class. Also, some economy, business and first class tickets may be eligible for upgrades, but they are not eligible for use with vouchers. If your carrier's loyalty program allows for paid upgrades or mileage upgrades, you can contact the carrier directly for assistance.
          Please note, for some itineraries, if you requested a first or business class seat, it may not be available on all or some of your flights because it either may not be offered by your carrier or is not available on the type of aircraft your flight is operated on. Some airlines also offer 'Premium Economy' seats in addition to coach, business and first class. This type of seating is normally a section of the coach cabin,which generally provides more leg room, along with some form of leg rest,possibly enhanced In-flight entertainment and dedicated cabin crew. It’s a class of travel that’s between Business and Coach/Economy.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-6" role="button" aria-expanded="false">
          What are open-jaw/circle trips?
        </a>
        <div class="collapse" id="f-6">
          Open-jaw tickets are those which allow travelers to start their trip in one city, fly to another and complete their trip with a flight to a third city. For example, a traveler flies from New York City to Los Angeles and then flies from Los Angeles to Boston. The middle city is not a brief stop-over, it's an extended stay.
          Circle trips are those which allow travelers to incorporate more than two cities in a round-trip pattern (for example, New York City to Los Angeles, Los Angeles to Chicago, and then Chicago to New York City.) Additionally, while you may have to stop in a connecting city, you are not permitted to spend more time than is required to make your connection.
          If you are interested in purchasing an open-jaw or circle trip ticket, simply click on the 'multi-destination' link when entering your trip details on our website.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-7" role="button" aria-expanded="false">
          Special needs and requests
        </a>
        <div class="collapse" id="f-7">
          We understand that some travelers will have special needs such as oxygen tanks, wheelchairs, service animals, special meals or baggage handling. Since each airline has different requirements for these requests, please contact the airline directly. Special requests may require airline approval and be subject to an additional charge payable directly to the airline. We have no control over an airline's ability to fulfill customer’s special needs and can not guarantee that such requests will be met by the airline. For a complete list of toll-free airline customer service numbers.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-8" role="button" aria-expanded="false">
          Can I take the return flight only?
        </a>
        <div class="collapse" id="f-8">
          If you choose not to take the outbound portion of your flight, the airlines will consider you a 'no show' and will automatically cancel the remainder of your reservation. That means your return tickets will not be honored, and we will be unable to issue a refund for the unused portion of your trip.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-9" role="button" aria-expanded="false">
          Severe Weather Event Policies
        </a>
        <div class="collapse" id="f-9">
          In the event of a hurricane or other severe weather event, we will work with our travel partners to provide assistance for customers as outlined below.
          Airline Tickets: To request a change to your reservation in the event of severe weather, you must contact us or the airline before the originally scheduled departure time. Failure to do so may cause the airline to invalidate your reservation, causing your ticket to lose its value for future travel or refund.
          The ability to make changes to an airline reservation is dependent on the fare rules associated with your ticket(s). Any exceptions to the fare rules are at the sole discretion of the carrier. Most major carriers allow customers to rebook reservations affected by severe weather within a set number of days without incurring administrative change fees however, if rebooking results in a higher fare, customers are responsible for the difference in price.
          If carrier exceptions are granted, we also will waive our associated fees.
          Hotels: If a weather event occurs close to your travel date, we recommend that you contact your provider directly to determine if they are open for business and will honor your reservation.
          If the provider indicates that they are not open, or for any concern about a reservation in an area impacted by a weather event, please contact us for assistance. Depending on the severity of the weather event and the areas impacted, requests to change or cancel your reservation may be granted.
          If a change or cancel is allowed, we will also will waive our associated fees.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-10" role="button" aria-expanded="false">
          Can I make reservations for my pet?
        </a>
        <div class="collapse" id="f-10">
          No. PPN Merchant & CUG & Agoda Demo with Branding does not have the ability to book reservations for pets - whether traveling in the cargo area, the passenger cabin (in a kennel under the seat) or in a reserved seat. If you are traveling with a pet, please contact your airline directly for information on their policies and fees associated with pet travel.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-11" role="button" aria-expanded="false">
          Can I fly one-way or to multiple cities?
        </a>
        <div class="collapse" id="f-11">
          Yes! We offer both one-way and multi-destination travel.
          If you are requesting a multi-destination itinerary, you will be able to enter a maximum of five flights for up to eight passengers. Your multi-destination trip can either be an open-jaw or a circle trip.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-12" role="button" aria-expanded="false">
          Can I purchase tickets for children traveling without an adult?
        </a>
        <div class="collapse" id="f-12">
          We can book tickets for passengers 15 years and older without an accompanying adult. We recommend that where possible, unaccompanied minors avoid traveling on the last flight of the day or on connecting flights. For children under 15 years old traveling alone, please contact your desired airline directly.
          Keep in mind that unaccompanied minor policies and fees vary by airline. Once booked, we recommend calling your airline directly to inquire about unaccompanied minor requirements, fees, services, or travel restrictions for your specific itinerary. Most reservations can be cancelled within 24-hours of purchase if your confirmed airline will not allow unaccompanied minor travel on your confirmed itinerary.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-13" role="button" aria-expanded="false">
          Traveling with infants
        </a>
        <div class="collapse" id="f-13">
          According to airline policy, an infant is a person under 24 months of age (at the time of both the departure and return flights); if your child will be over 24 months of age by the time you return, you must purchase a ticket for him/her. Below are some things to keep in mind when traveling with infants:
          Travel Within the U.S. and U.S Territories:
          U.S. citizens under the age of 18 do not need a photo ID for domestic travel, as long as an accompanying adult can verify their identity; however, you may wish to bring a valid birth certificate, should the child's/infant's age be questioned. Non-US/Canadian citizen infants are not required to carry their passports if they have documents issued by the U.S. government such as Permanent Resident Cards; those who do not should be carrying their passports while visiting the U.S.
          If the infant is going to sit on a lap, a ticket is not required. Keep in mind that the airlines will only allow one lap infant per adult traveler (age 18 and above); you'll need to purchase a ticket for each infant exceeding the number of adult travelers.
          If you do not want your infant to sit on your lap, you will need to purchase a separate ticket and you will be required to seat the infant in an infant car seat during the flight. You are responsible for providing the infant car seat.

          International Flights:
          All international travelers (including infants) will require a valid passport and you may also need to show additional documentation at your destination and/or in connecting countries.
          Some airlines offer 'lap infant tickets' for a nominal fee. If you wish, you can complete your purchase for the adult and child passengers, then contact your confirmed airline immediately after to purchase an infant ticket directly from the carrier. Your confirmed carrier will inform you if they offer 'lap infant tickets' and of the applicable charges. If you have any problems purchasing your infant ticket directly with the airline and wish to cancel your reservation, contact us within 24 hours of purchase and we will issue a full refund.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-14" role="button" aria-expanded="false">
          Frequent Flyer Miles
        </a>
        <div class="collapse" id="f-14">
          Earning Miles: You can enter your frequent traveler information in the Passenger Names and Flight Preferences section when purchasing your reservation however, because frequent flyer programs vary by airline and are subject to change there is no guarantee you will be able to accumulate miles. To confirm eligibility or to add your frequent flier number post purchase, please contact your carrier directly. Click here to access your carriers contact information.
          Redeeming Miles: Tickets cannot be purchased using frequent traveler miles. If you would like to redeem miles for a ticket, please contact the appropriate carrier directly.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-15" role="button" aria-expanded="false">
          Can I make a reservation if I am not a U.S. resident?
        </a>
        <div class="collapse" id="f-15">
          Yes. U.S. residency is not a requirement for purchase.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-16" role="button" aria-expanded="false">
          How do I check my reservation status?
        </a>
        <div class="collapse" id="f-16">
          The easiest way to see if your request has been accepted is to click the 'Check Your Status' link in the email that we sent to you immediately after you submitted your offer.
          If you do not have access to that email, you can check your status in the Review/Cancel Reservations section on our website. You will need to provide the following information to access your request:
          Your request number or the credit card number used to purchase the ticket.
          The email address that was entered during the request process.

          If your request was successful, you can view the details of your reservation and print your itinerary from here.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-17" role="button" aria-expanded="false">
          How do I confirm my reservation?
        </a>
        <div class="collapse" id="f-17">
          Once your request is submitted, we will display the results on our website within 60 seconds.
          If we successfully booked your reservations we will immediately display your itinerary for you to print or email to fellow travelers or your friends and family. We will also send you an email advising you to check the status of your request online.
          Current reservations can be viewed at any time by accessing the Review/Cancel Reservation section of our website. You will need your Trip Number and the e-mail address you entered during the request process.
          From the Review/Cancel Reservation section of the website you can retrieve and print your itinerary (including confirmation of your purchase price) by clicking the printer icon on the page. This page serves as confirmation of your reservation and your receipt.
          We also advise all customers to confirm their flights at least 24 hours before each departure by calling the airline directly. Airlines will occasionally change flight schedules and it is the travelers' responsibility to be aware of these changes
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-18" role="button" aria-expanded="false">
          How do e-tickets work?
        </a>
        <div class="collapse" id="f-18">
          Whenever possible, we will issue convenient electronic tickets, although this option may not be available on all itineraries. Electronic or 'paperless' tickets are paid reservations stored in the airline's computer system. Once a ticket is issued, reservation is made, an e-ticket exists only as a digital record in the airline computers - no tickets will be mailed to you.
          All you need to travel is your government-issued photo ID (driver's license, passport, etc.). It may also be helpful to bring a copy of your itinerary, which contains your airline confirmation and ticket numbers If you're traveling outside the U.S., all travelers will need a valid passport and you may also need to show additional documentation at your destination and/or in connecting countries.
          Additional notes of interest:
          Whenever possible, we will issue convenient electronic tickets this option may not be available on all itineraries.
          Some carriers require travelers to present the credit card they used to purchase the ticket online at check-in. If you are thinking about purchasing a ticket for a friend or relative who does not have access to your credit card, please call and check with the airline about their specific requirements.

          To receive your boarding passes, you can check-in online on your airline's website or go directly to the airline's check-in counter.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-19" role="button" aria-expanded="false">
          Traveling with children
        </a>
        <div class="collapse" id="f-19">
          Children Under 2 Years Old

          For Domestic Travel (flights within the US or Puerto Rico), most airlines allow adult passengers to carry one infant (child under age 2) on the lap at no charge. If you are planning to carry an infant, please call your confirmed airline post purchase to let them know.

          • If you’re traveling with more than one infant per adult, you’ll need to purchase a seat for each additional infant traveler.
          • If you’re traveling with more than two infants per adult, or if you’re flying in Premium Economy, Business or First class, check with your airline prior to purchase to confirm if it is allowed.
          • Seated infants will require their own ticket and if a car seat is used, it must be FAA approved.

          On international flights (all flights outside of the U.S.), an infant must have a ticket, even if the infant sits on an adult's lap. Priceline does not sell infant tickets; you will need to purchase an "infant ticket" directly with your airline. Please note that all persons, including newborn infants, are required to obtain passports in their own name.

          Children Under 15 Years Old

          Because airline policies vary, we do not book tickets for passengers under the age of 15 unless they are accompanied by an adult, 18 years of age or older. Children under 15 traveling alone on a ticket purchased through us may be denied boarding by the airline.

          Children Between 15 and 18 Years Old

          We can book tickets for passengers 15 years and older without an accompanying adult. We recommend that where possible, unaccompanied minors avoid traveling on the last flight of the day or on connecting flights. Keep in mind that unaccompanied minor policies and fees vary by airline. Once booked, we recommend calling your airline directly to inquire about unaccompanied minor requirements, fees, services, or travel restrictions for your specific itinerary.
          Most reservations can be cancelled within 24-hours of purchase if your confirmed airline will not allow unaccompanied minor travel on your confirmed itinerary.

          Note:

          Some carriers provide a supervision service for unaccompanied minors. Contact your airline directly to determine availability and associated cost.
        </div>
      </li>
    </ul>
    <h3>Making a Reservation</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-20" role="button" aria-expanded="false">
          What's a non-jet airplane?
        </a>
        <div class="collapse" id="f-20">
          on-jet airplanes are turbo-propeller driven or 'commuter' planes. These quick, quiet and comfortable planes offer features usually associated with larger aircraft, including in-flight service and lavatory facilities.
          Most major airlines include non-jet aircraft in their fleets, since many local or regional airports can only be serviced by these smaller non-jet planes. We will not search for flights on non-jet aircraft unless you specifically tell us to do so and we will always try to place you on a jetplane before searching alternatives using non-jet aircraft.
          Pointer: For international travel, you will always fly a jet across the ocean, but you could increase your chances of getting a ticket if you agree to fly on a non-jet airplane for any domestic connections.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-21" role="button" aria-expanded="false">
          Can I fly internationally?
        </a>
        <div class="collapse" id="f-21">
          Yes! You can travel internationally to destinations outside the United States.
          Here are some things to keep in mind when traveling abroad:
          You'll always need a valid passport.
          For international travel, each passenger must have the proper documents for entry/re-entry into a country (i.e. correct visa, valid passport). Requirements vary, based on the passenger's citizenship and country of residence. For international one-way travel, you may also be required to show proof of return travel or onward travel.
          To determine the requirements, contact the embassy or consulate of all countries you are traveling to and connecting through or visit one of these helpful sites: www.travisa.com or www.travel.state.gov
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-22" role="button" aria-expanded="false">
          How can I check the status of my reservation?
        </a>
        <div class="collapse" id="f-22">
          You can access the Review/Cancel Reservation section on our website to check the status of your request. You will need your Trip Number and the e-mail address you entered during the request process.
          If your request was not accepted, you may be presented with options for resubmitting your request. If your request is accepted, you can view your itinerary and some important travel tips. This page can also be used to see any updates to your itinerary due to airline schedule changes.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-23" role="button" aria-expanded="false">
          Can I make a reservation for someone other than myself?
        </a>
        <div class="collapse" id="f-23">
          Yes! You can purchase up to eight tickets, regardless of whether or not you are traveling with the passengers.You must enter each passenger's name exactly as it appears on his or her valid government-issued photo ID. If the name that appears in the airline's reservation system does not match the passenger's identification, the airline may not allow the passenger to board.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-24" role="button" aria-expanded="false">
          Why is the itinerary I want not available?
        </a>
        <div class="collapse" id="f-24">
          Because airline inventory is constantly changing, we are unable to guarantee the availability or price of any flight until the purchase is completed, especially during peak travel periods and major holidays.
          At the time of your flight search, our display shows the lowest fare available from the airlines for the dates and times you requested ... but it is possible for someone else to purchase the last tickets at the quoted price or for the airlines to raise the price before you complete your purchase.
          Please note, for some itineraries, if you requested a First or Business class seat, it may not be available on all or some of your flights because it either may not be offered by your carrier or is not available on the type of aircraft your flight is operated on.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-25" role="button" aria-expanded="false">
          Last-minute travel
        </a>
        <div class="collapse" id="f-25">
          Last-minute travel is easy! If you are selecting an itinerary from a list of fares, you can place a request for same day travel at any time.
          Following are a few details about same-day bookings:
          You will have at least two hours between the time your request is confirmed (local time of departure city) and the time your first flight departs.
          You can depart and return on the same day.
          Electronic tickets will be issued for your trip.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-26" role="button" aria-expanded="false">
          Can I place a reservation on hold before I purchase a ticket?
        </a>
        <div class="collapse" id="f-26">
          We are unable to hold reservations before you purchase tickets.
          By clicking 'Buy My Tickets Now' on our website you are agreeing to make a purchase. If an airline allows us to book your request, your credit card will be charged immediately.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-27" role="button" aria-expanded="false">
          What form of payment do you accept?
        </a>
        <div class="collapse" id="f-27">
          Airlines generally accept all major credit and debit cards. When you are submitting your request, our website will display the payment options that you can use when booking your reservation. Only credit cards accepted by your airline will be presented.
          Airline tickets are available to customers with credit cards issued by banks worldwide. If your country is not listed in the Country drop-down when entering your billing address, you won't be able to book tickets on our website.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-28" role="button" aria-expanded="false">
          Fare Rules and Restrictions
        </a>
        <div class="collapse" id="f-28">
          Your purchase is non-refundable, non-transferable and non-changeable. Your ticket cannot be changed or cancelled. You must fly on the flights assigned to you. Upgrades and standbys are not available and if any part of the ticket(s) is unused, it has no value after ticketed departure time.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-29" role="button" aria-expanded="false">
          How long do I have to wait for my request to process?
        </a>
        <div class="collapse" id="f-29">
          You'll usually know within seconds if your reservation is confirmed. If your reservation is not confirmed after 15 minutes, you will be under no further obligation.
          To see if your reservation is confirmed, go to the Review/Cancel Reservations section on our website. You will need your request number and the e-mail address you entered during the request process to get the results of your reservation.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-30" role="button" aria-expanded="false">
          How to Select Your Seats
        </a>
        <div class="collapse" id="f-30">
          It's quick and easy to select your seats:
          1. Choose the passenger for whom you want to select a seat by clicking the passenger name tab on the left side of the page.
          2. Select a seat by clicking any of the blue available seat images on the seat map.
          3. We suggest you select seats for all passengers on each flight, then use the 'Next Flight' link to do the same for all flights in your itinerary.
          4. You don't need to select a specific seat for every passenger and flight in your itinerary.
          5. When you've made all of your desired seat selections, click the done selecting seats button. We'll summarize your selections for you on the next page. We'll do our best to provide you with the seats you select however, please note that our seat maps reflect current availability, and that the airline may change aircraft and seat assignments prior to departure.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-31" role="button" aria-expanded="false">
          Can I purchase a tax exempt reservation?
        </a>
        <div class="collapse" id="f-31">
          We do not provide customers with the option to purchase travel that is tax exempt and our partners cannot deduct the taxes from purchases made through our website. If you need to purchase travel that is tax-exempt, we recommend that you visit one of our partners and buy from them directly.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-32" role="button" aria-expanded="false">
          What is Secure Flight?
        </a>
        <div class="collapse" id="f-32">
          Secure Flight is a program developed and mandated by the Department of Homeland Security in response to a key 9/11 Commission recommendation: uniform watch list matching by the Transportation Security Administration. The mission of the Secure Flight program is to enhance the security of domestic and international commercial air travel by requiring airlines to provide the TSA with a passenger's name as it appears on the government issued ID they plan to travel with, date of birth, and gender. Because airlines are required to transmit Secure Flight information to the TSA at time of booking, providing this information is required in order to complete your purchase. It is expected that collecting this additional passenger data will improve the travel experience for all airline passengers, including those who have been misidentified in the past.
          The passenger name, date of birth and gender cannot be modified post-purchase. If you need to modify date of birth or gender information post-purchase, please contact your confirmed airline for assistance. Please note that airlines do not provide travel agencies with authorization to process name changes and/or corrections to tickets. As long as your ID matches your airline ticket, you should not encounter any difficulties boarding your plane. For more information on Secure Flight, please visit the TSA Website.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-33" role="button" aria-expanded="false">
          Entering Passenger Names
        </a>
        <div class="collapse" id="f-33">
          Enter passenger name(s) exactly as they appear on the government issued photo identification you plan to provide to airport officials at check-in. Passengers will encounter difficulty passing through airport security or may be denied boarding if their names do not match the identification being presented, and corrections are often not permitted once tickets are confirmed.
          Here are some answers to common name concerns:
          What if I don't have a middle name? This is an optional field.
          Should I use my maiden name or my married name? Enter the name listed on the identification you plan on using at the time of travel.
          What about hyphenated or multiplenames? Hyphens and spaces are not required and will be removed by the airline reservation systems.
          Do I have to add a suffix? No. This is an optional field.
          What if two names are the same? You will need to differentiate by adding either a suffix, middle name or middle initial to one of the passengers.
          There's not enough space to enter my legalname. If the first name too long, shorten it. If middle name is too long, consider removing it. If last name too long consider contacting the airline directly for your reservation; to avoid security problems, we do not suggest you modify your last name.
          What if I only have one name? The same name should be entered in both the first and last name fields.
          What if two names are the same and neither have a suffix? If both identifications have the same exact name with no suffix, you will need to enter the full middle name for one passenger and only the middle initial for the second passenger.
          What if my last name has only one letter? Consider contacting the airlines directly.
          If you have already booked your tickets, contact your airline to confirm the name entered meets their guidelines.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-34" role="button" aria-expanded="false">
          Can I use an airline voucher?
        </a>
        <div class="collapse" id="f-34">
          Vouchers are only good when you're buying tickets directly from the airline and not a travel agent or other provider. Some vouchers also have restrictions such as blackout dates and almost none are good in conjunction with other discounts, promotions, or coupons.
          Most vouchers will expire so don't put off using them for too long. Check the small print on the voucher for details of where and when the voucher can be used.
          Click here to access a list of airline phone numbers and website addresses.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-35" role="button" aria-expanded="false">
          Authorization Questions
        </a>
        <div class="collapse" id="f-35">
          As part of the request process, we authorize your card in order to verify that the card will accept the full amount of the charge. Authorizations will automatically drop off soon after the charges are processed and in most cases are removed within 3 to at most 7 days.
          In addition, if you book a hotel reservation, the hotel may also place an authorization hold on the card you present upon check-in to cover incidentals that may occur during your stay. The amount of the authorization varies by hotel, but in some cases can be as much as $200 USD per night. For more information on hotel authorizations, we suggest you contact the hotel directly.
          Please note that an authorization is not a charge, so it will not appear on your monthly statement. Your card will only be charged once, if and when we successfully book your reservation.
          If you feel you have been authorized in error, please contact us at your earliest convenience.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-36" role="button" aria-expanded="false">
          Can I purchase a reservation for someone else?
        </a>
        <div class="collapse" id="f-36">
          Reservations can be purchased for someone else as long as at least one person named on the reservation is 18 years of age or older if purchasing airline tickets, or 21 for a hotel or vacation packages reservation. Rental car drivers must be at least 21 years of age (24 in some locations). Rentals for drivers aged 18 and older are available in New York only.
          When submitting the request on our website, enter the names of the travelers where required. Under the billing information, enter the billing details of the person paying for the reservation. Initialing the contract page during the request process indicates that the person purchasing reservations and the traveler(s) agree with all terms and conditions.
          Note that the traveler who is checking in at the hotel will require a credit card in their name to cover any incidentals and at the rentalcar counter to pay for the reservation and any optional services and/or equipment such as child safety seats.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-37" role="button" aria-expanded="false">
          What are the taxes and fees?
        </a>
        <div class="collapse" id="f-37">
          Taxes and fees
          The following taxes and fees may apply to airline ticket purchases. The actual taxes and fees for your purchase will be displayed during purchase.
          Government Imposed Taxes and Fees:
          September 11th Security Fee: $5.60 USD applies per one-way trip. There may be multiple one-way trips on the same itinerary.
          Passenger Facility Charges: Up to $18.00 USD depending on the itinerary.
          Travel Facilities Tax: Up to $17.40 USD per round-trip for domestic flights beginning or ending in Alaska or Hawaii.
          Federal Domestic Flight Segment Fee: $4.00 USD per flight segment. A flight segment is defined as one takeoff and one landing.
          U.S. International Departure Tax: $17.50 USD per departure applies to international tickets departing from the U.S.
          U.S. International Arrival Taxes: International flights arriving into the U.S. are assessed the following taxes - US International Arrival tax of $17.50 USD, US Customs fee of $5.50 USD, Immigration (INS) fee of $7.00 USD and Animal and Plant Health Inspection fee (APHIS) of $5.00 USD.
          International Taxes: International travel incurs additional international taxes, the amounts of which can vary dramatically depending upon routing and destination.
          Entry/Exit Fees: If you are traveling internationally, some countries (or airports) charge fees upon entering and/or exiting the country. These fees are not included in the ticket price, or the taxes and fees we display on our website prior to purchase. For more information on a specific country's entry/exit fee, we suggest that you visit www.travel.state.gov prior to departure.
          Airline Imposed Fees:
          Itinerary Changes/Cancellations/Refunds: Post-purchase changes, when permitted, may carry airline-imposed change and/or cancellation fees. These fees will be displayed in your airline’s fare rules.
          Baggage Fees and Optional Services: Airline baggage fees and optional services vary by airline, flight and frequent flyer status. These fees are not included in your airline ticket cost. Please visit the Baggage Fees and Optional Services link on your itinerary for details.
          Airline Surcharge: For many international destinations, airlines impose a surcharge. This could include either a fuel or security surcharge. The amount varies widely depending on the destination and/or carrier. Some airlines include surcharges in their ticket cost. The most common surcharges are either fuel or security surcharges. The amount varies widely depending on both the carrier and the itinerary. When applicable, this amount is included in your airline ticket cost.
          Our Fees:
          Processing Fees: A non-refundable per ticket processing fee will be applied to reservations that include multiple airlines and will be displayed as a Processing Fee on your Contract page.
          Paper Ticket Fees: A $34.95 USD shipping and handling fee will be charged if your itinerary requires the issuance of paper tickets. If your tickets are rerouted or are undeliverable and are returned, there will be an additional $34.95 USD re-handling fee.
          Itinerary Changes/Cancellations/Refunds: If airline fare rules allow itinerary changes or cancellations for a fee, a $30 USD per ticket exchange processing fee will also apply (if non-US currency used, fee will be converted at time of exchange). This fee is not included in the ticket price or the taxes and fees we display on our website.
          The total cost of the ticket, including taxes and fees, is disclosed prior to purchase. By making reservations on this site, you agree to abide by the policies described herein as well as the Terms and Conditions governing your use of this site.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-38" role="button" aria-expanded="false">
          Can I purchase a gift card or gift voucher for someone else?
        </a>
        <div class="collapse" id="f-38">
          We apologize however at this time, we do not offer gift cards or gift vouchers that may be used at a later date or by someone else.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-39" role="button" aria-expanded="false">
          What is a Round Trip Reservation?
        </a>
        <div class="collapse" id="f-39">
          A round trip airline reservation consists of a flight that departs from one airport and arrives at another, and then returns to the original airport.
        </div>
      </li>
    </ul>
    <h3>My Existing Reservation</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-40" role="button" aria-expanded="false">
          How can I add a passenger?
        </a>
        <div class="collapse" id="f-40">
          To add passengers age 15 years or older, you can use our Advanced Flight Options to narrow down your search and book the same flights you are currently confirmed on. To add children under the age of 15 without an adult on the new reservation, you will need to contact your airline directly.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-41" role="button" aria-expanded="false">
          What if my airline declares bankruptcy or goes out of business?
        </a>
        <div class="collapse" id="f-41">
          In the event that your airline declares bankruptcy or goes out of business, don't worry -- you have the same rights as other ticketed passengers. According to the Aviation and Transportation Security Act, other airlines are required to assist passengers stranded by airlines that go bankrupt.
          For your airline's specific policy, we advise you to contact your airline directly. If you are unable to contact your carrier, or they are unable assist you, please send us an e-mail.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-42" role="button" aria-expanded="false">
          How do I file a complaint about my airline experience?
        </a>
        <div class="collapse" id="f-42">
          In the unlikely event that your travel experience is anything less than expected, your airline would like to hear from you. Contact your airline directly so that they can document your experience and give immediate feedback to their staff, if necessary.
          Please click here for a complete list of airline toll-free customer service numbers. To make sure others do not have a similar experience, please provide the airline with all the details of your experience along with all relevant names and dates.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-43" role="button" aria-expanded="false">
          What if the airline I am ticketed on goes on strike?
        </a>
        <div class="collapse" id="f-43">
          You have the same rights as any other passenger in the event of a work stoppage. Your airline is obligated to make alternative arrangements for you to travel at no additional cost in the event your flight is delayed or cancelled - whether you're holding paper tickets or electronic reservations.
          If the airline you're ticketed on goes on strike, please call their customer service department to make alternate flight arrangements.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-44" role="button" aria-expanded="false">
          Pregnant or sick?
        </a>
        <div class="collapse" id="f-44">
          Airlines may require some type of certification that you are safe to fly, depending upon the stage of your pregnancy or the severity of your illness. Consult your physician and contact your airline for details on when flying should be avoided. Please click here to access a list of airline phone numbers.
          If your doctor has already advised you not to travel, please contact us.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-45" role="button" aria-expanded="false">
          I am ticketed on one airline, but the flight is OPERATED BY another
        </a>
        <div class="collapse" id="f-45">
          Some airlines enter into code share agreements with a limited number of select airline partners. This means that on certain routes, the airline on which you are ticketed does not fly its own aircraft to that destination. Instead, it contracts with a partner airline to fly to that destination. The advantage for travelers is a wider choice of destinations plus the security of flying with an airline specifically picked by your ticketing carrier to operate on its behalf, guaranteeing levels of service and facilities.
          In most cases you will check-in with your 'operating' carrier however, we recommend that you verify your flight check-in location with your ticketing carrier. Click here for airline contact information.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-46" role="button" aria-expanded="false">
          Where are my tickets?
        </a>
        <div class="collapse" id="f-46">
          Electronic Tickets
          If you have electronic tickets, you will not be receiving paper tickets because electronic or 'paperless' tickets are reservations stored in the airline's computer system. Click here for more information on how e-tickets work .
          To see if you have electronic (e-tickets), click on the Review/Cancel Reservation section of our website. Enter itinerary number and the e-mail address you entered during the request process to view your current itinerary.
          Paper Tickets
          Paper tickets will be printed the business day following your purchase. You should expect to receive your tickets within 4 business days of making your purchase. For security reasons, you must be available to sign for the tickets when they arrive.
          To check on your ticket delivery go to the Review/Cancel Reservation section of our website. Enter your request number and the e-mail address you entered during the request process to access your current itinerary. That is where you will find your tracking number, delivery method and the appropriate links to check the status of your delivery.
          Please be aware that a $34.95 shipping charge will be applied for all paper tickets sent via Express Mail. International tickets may take an extra day to be confirmed. Once confirmed, they also will be sent to you via Express Mail. If for any reason your tickets are undeliverable and returned to us, there will be an additional $34.95 express re-shipping fee.If you have not received your paper tickets, please contact us.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-47" role="button" aria-expanded="false">
          Can I change the name on my ticket?
        </a>
        <div class="collapse" id="f-47">
          Tickets cannot be transferred to another individual. The name entered in the passenger name field when you make your request is the name that will appear in the airline's reservation system or on your paper ticket(s).
          If a ticket has the traveler's maiden name on it, or the names on the ticket are reversed (last name first, first name last).
          In the case of a maiden name, you may just need to bring your marriage certificate to the gate along with a valid government-issued ID.
          If the names are reversed, you may just need to bring a valid government-issued ID.
          If a passenger only has one name, the same name should be entered in both the first and last name fields.
          If you misspelled the name, please contact us.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-48" role="button" aria-expanded="false">
          Can I transfer the ticket to another person?
        </a>
        <div class="collapse" id="f-48">
          Tickets may not be transferred to another individual. The name entered in the passenger name field when you make your request is the name that will appear in the airline's reservation system or on your paper ticket(s).
          For security reasons, the name on the ticket must match the government-issued photo ID each passenger will present at check-in.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-49" role="button" aria-expanded="false">
          What if I miss my flight?
        </a>
        <div class="collapse" id="f-49">
          Airlines have different policies regarding passengers with non-refundable tickets who miss flights. If you make it to the airport, but miss your flight, go directly to the main ticket counter or ask the nearest gate agent for assistance.
          If you cannot make it to the airport, you will need to contact the airline directly.
          If you need to contact your airline about your reservation, review your itinerary closely to determine which airline operates your flight. Click here for a list containing airline contact information.
          Requests to change or cancel your reservation may be denied based on airline policy. Credit will not be given for any unused tickets or reservations.
          You can check the fare rules and restrictions for your ticket by visiting the Review/Cancel Reservation section of our website. Enter your itinerary number and the e-mail address you entered during the request process to access your current itinerary. When viewing your itinerary, detailed information about the rules and restrictions and any penalties on your ticket can be found in the section labeled 'Fare Rules'.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-50" role="button" aria-expanded="false">
          Can I fly standby?
        </a>
        <div class="collapse" id="f-50">
          In compliance with new Federal security regulations, many airlines do not allow customers to standby for an alternate flight. If you have 'checked luggage' you will not be allowed to fly standby. The airlines are required by law to make sure that each passenger travels on the same flight as his or her luggage.
          To determine if standby travel is allowed for your trip, visit the Review/Cancel Reservation section of our website. Enter your Trip Number and the e-mail address you entered during the request process to access your current itinerary. When viewing your itinerary, detailed information about the fare rules and restrictions on your ticket can be found in the section labeled 'Fare Rules'.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-51" role="button" aria-expanded="false">
          Meal and Seat Preferences
        </a>
        <div class="collapse" id="f-51">
          Meal Preferences
          Your meal preferences have been requested with the airlines for your flights, but are not guaranteed by the airlines and only are applicable for flights that offer meal service.
          Seat Preferences
          While your seat preferences and selections will be provided to the airline, the airline reserves the right to change aircraft and seat assignments prior to departure.
          Seats for multiple passengers have been requested together, but are also subject to airline availability.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-52" role="button" aria-expanded="false">
          Can I change my shipping address?
        </a>
        <div class="collapse" id="f-52">
          For security purposes, we are unable to change the delivery address of paper tickets once they have been purchased. If you have paper tickets, you should expect to receive your tickets within 4 business days of making your purchase. If you are unable to receive tickets at the address specified in your confirmed reservation, we recommend that you pick up your tickets at the local FedEx or USPS office.
          To determine how your tickets were shipped, go to the Review/Cancel Reservation section of our website. That's where you'll find your tracking number, delivery method and the appropriate links to check the status of your delivery.
          To find the local FedEx or USPS office nearest you, contact FedEx at 1-800-Go-FedEx or USPS at 1-800-222-1811. If for any reason your tickets are undeliverable and returned to us, there will be an additional $34.95 USD express re-shipping fee.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-53" role="button" aria-expanded="false">
          How do I get a copy of my flight itinerary and receipt?
        </a>
        <div class="collapse" id="f-53">
          We've made it easy for you to view and print your flight itinerary and receipt.
          If you have e-tickets, visit the Review/Cancel Reservation section of our website. You will need your trip number and the e-mail address you entered when you purchased your tickets. From here you can retrieve and print your itinerary by clicking the printer icon on the top right of the page.
          If you have paper tickets, your receipt is included in your paper ticket package. If you have paper tickets, you should expect to receive your tickets within 4 business days of making your purchase. For security reasons, you must be available to sign for the tickets when they arrive.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-54" role="button" aria-expanded="false">
          Flight Delays
        </a>
        <div class="collapse" id="f-54">
          To check on flight delays, we recommend you contact your carrier directly via phone or by visiting their website. If there is a delay, please follow the carrier's guidance as you may still be required to check in at the regularly scheduled time. If you are looking for general airport delay information, we recommend you visit FAA.com.
          In the event of a delay, we recommend waiting for the scheduled flight. You might also want to visit the airline desk and discuss the issue with an airline representative. As a reminder, if you made hotel or rental car reservations you will want to let them know that you might be arriving later.
          If you are at the airport and concerned that you may miss a connecting flight, you will need to go to the ticket counter and advise them of your concern. If your delayed flight causes you to miss your connecting flight the carrier will re-protect you on the next available flight.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-55" role="button" aria-expanded="false">
          Exchange Guidance
        </a>
        <div class="collapse" id="f-55">
          Exchange rules and fees vary by airline and type of fare purchased. Your airline's rules and fees associated with your ticket can be reviewed within your online itinerary.

          If your airline's fare rules allow an itinerary change, it will need to be done before your first flight departs. Here are some guidelines to facilitate the process:

          Identify your new travel dates and desired itinerary, then call us to complete your exchange.

          The price you see on the website will be the new fare. You will be responsible for the difference in fares plus your airline's change fee. A $30 USD per ticket exchange service fee will be charged by us.

          If the new fare is lower, consider calling your airline directly for the change. We do not have the ability to offer airline travel vouchers (so any decrease is lost if we process the change for you).
          Name changes, including corrections/adjustments and transfers, are not permitted.
          We do not have the ability to override an airline's rules, airfares or change fees.

          Not ready to book a new reservation? Some airlines will allow you to cancel your existing reservation and retain the value of the ticket as a future travel credit (on that airline). As with exchanges, the cancellation of your itinerary must be made pre-travel.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-56" role="button" aria-expanded="false">
          Can I get a refund?
        </a>
        <div class="collapse" id="f-56">
          Your options to change or cancel are dependent upon the airline's fare rules and restrictions. Itinerary changes, if permitted by the fare, may have a change fee charged by the airline typically ranging between $100 to $250 USD per ticket that will vary by market, carrier and specific fare rule. In addition, any changes to your itinerary will incur a $30 USD per ticket service fee. Please review our cancellation policy for more details.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-57" role="button" aria-expanded="false">
          How do I find my reservation?
        </a>
        <div class="collapse" id="f-57">
          Current reservations can be viewed at any time by accessing the Review/Cancel Reservation section of our website. You will need your trip number and the e-mail address you entered during the request process.
          From the Review/Cancel Reservation section of the website you can retrieve and print your itinerary (including confirmation of your purchase price) by clicking the printer icon on the page. This page serves as confirmation of your reservation and also your receipt.
          We also send a confirmation email to the email address used to complete the purchase.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-58" role="button" aria-expanded="false">
          How do I find my Trip Number?
        </a>
        <div class="collapse" id="f-58">
          Your trip number, also referred to as your itinerary number, can be located in the email you received when you booked your ticket(s). Should you not be able to retrieve it please send us an e-mail and we will be happy to assist you.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-59" role="button" aria-expanded="false">
          Customer Service
        </a>
        <div class="collapse" id="f-59">
          Email Us: If you have pre or post purchase questions that are not addressed in our FAQs, or would like to provide feedback on our service, please send us an email and we'll do our best to get back to you within 3 hours.
          Phone Us: If you have an accepted booking you can contact a Customer Service agent by calling 1-877-477-5807. You will be prompted to enter the Request/Itinerary number and the email address you entered while making your request.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-60" role="button" aria-expanded="false">
          Where can I find my ticket/confirmation number?
        </a>
        <div class="collapse" id="f-60">
          An airline confirmation number is a unique identification number made up of letters and/or numbers which allow our airline partners to find your reservation within their booking system. The airlines are also able to locate your reservation using your ticket number(s).
          To find your confirmation and ticket number(s), please visit the Review/Cancel Reservation section of our website where you can find this information under the 'Passenger and Ticket Information' section of your itinerary.
          From the Review/Cancel Reservation section, you can retrieve and print your itinerary (including confirmation of your purchase price) by clicking the printer icon on the page. This page serves as confirmation of your reservation and your receipt.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-61" role="button" aria-expanded="false">
          Can I change or cancel my tickets?
        </a>
        <div class="collapse" id="f-61">
          Cancel and change rules vary by airline and fare purchased. The airline fares rules associated with your flight can be reviewed pre-purchase on your contract and post purchase within the itinerary displayed on our website.
          If cancellations are permitted, a link to cancel may be available within your itinerary or you can call us to complete the cancellation for you.
          If your fare rules allow a change and you are ready to change your reservation, please contact us.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-62" role="button" aria-expanded="false">
          How do I reserve or confirm my seats?
        </a>
        <div class="collapse" id="f-62">
          Advanced seat selection is entirely dependent upon the carrier that books your reservation. Because airlines may change aircraft and seat assignments prior to departure, specific seating requests are not guaranteed.
          You will be presented with one of two scenarios prior to purchase. If the carrier you select provides seat maps, you will be presented the option to select your specific seats. If the carrier does not provide seat maps, your options will be limited to selecting the more general preference of either an aisle or a window seat. If you don't want to select your seats, you can skip these options and the airline will assign your seats for you. Additionally, if seats maps are available, you will be able to change your seat after purchase via the 'Change Seat' link in the Passenger and Ticket section in your itinerary displayed on our website.
          If the 'Change Seat' link is not displayed it means that either your carrier is still in the process of confirming your seats OR the option to select your seats is not available on our website. Some airlines (especially international carriers) do not allow customers to assign seats or only assign seats within 30 days of travel. We recommend you wait, or contact the carrier directly if your seat preference is not eventually displayed on your itinerary.
          If you have already purchased tickets, you can confirm your seat selection in the Review/Cancel Reservation section of our website. You can also contact your carrier directly; however, because some carriers charge a fee to assign seats over the phone, we recommend you first visit the airline's website where you should be able to request your preferred seats. Click here for airline contact information.
        </div>
      </li>
    </ul>
    <h3>Airport/Travel Procedure</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-63" role="button" aria-expanded="false">
          Connections, stops and layovers
        </a>
        <div class="collapse" id="f-63">
          A connection or connecting flight is a flight between your origin and destination cities where you will need to change planes to complete your travel. Your airline has identified these by assigning separate flight numbers to each leg of your journey.
          A layover is the period of time spent on the ground during connections. If you have an overnight layover, your connecting flight will depart the following day.
          A stop is an intermediate stop between your origin and destination cities. Your airline has identified these by assigning the same flight number to each leg of your journey. If your itinerary includes a stop, you typically do not change planes, though you may be required to deplane for a short period before taking off again. When traveling internationally, you will be required to de-plane and either clear Customs and Immigration and/or provide travel documentation to your airline’s departure gate representative at your initial entry into, final departure out of and/or transit through a country regardless of whether your airline has identified the flight as a 'stop' or 'connection'.
          If you choose an itinerary where the price and flight times are displayed, you will always know how many connections and/or stops you have up front. You will be shown your complete itinerary, including total stops (meaning the total of both connections and stops), connecting flights and connection times prior to purchasing your tickets. The location of a stop will be displayed immediately after purchase.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-64" role="button" aria-expanded="false">
          What security procedures should I be aware of?
        </a>
        <div class="collapse" id="f-64">
          Be prepared to encounter more visible and frequent security checks at the airport - from the parking lot right up to the departure gate.
          No one without a ticket is allowed beyond the security checkpoint. However, exceptions can be made for those meeting disabled passengers or minors traveling alone. Some airline clubs also allow members to access lounges with appropriate identification. Please contact the airline ahead of time to make such arrangements.
          Identification
          Passengers must present a valid government-issued photoID, such as a driver's license or passport at check-in. If you're traveling outside the U.S., all travelers will need a valid passport and you may also need to show additional documentation at your destination and/or in connecting countries.
          Baggage
          Airlines are screening all checked luggage for explosives. These machines can be harmful to film, so it's recommended to pack film inside a carry-on bag.
          Screening
          Security personnel may require passengers to remove belt buckles, footwear or jewelry before passing through metal detectors. Anyone carrying a laptop computer or cell phone may be asked to turn it on. Prior to boarding, all travelers must present their government-issued photo ID and boarding passes. Some customers also may be re-screened.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-65" role="button" aria-expanded="false">
          What if the airline cancels my flight?
        </a>
        <div class="collapse" id="f-65">
          If you are at the airport and are bumped from the flight you are ticketed on or the airline cancels your flight(s) for any reason, including airline strikes or severe weather, you are entitled to the same rights as any other passenger. Speak to the gate agent or an airline representative to learn what your options are.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-66" role="button" aria-expanded="false">
          What identification will I need at check-in?
        </a>
        <div class="collapse" id="f-66">
          The documentation you'll need will vary depending upon your citizenship and your destination.
          Travel within the U.S. and U.S. Territories
          U.S. citizens (18 and over) traveling within the U.S. will require a government issued photo ID. U.S. citizens under the age of 18 are not required to provide an ID at the airport security checkpoint.
          Non-US/Canadian citizens are not required to carry their passports if they have documents issued by the U.S. government such as Permanent Resident Cards. Those who do not should be carrying their passports while visiting the U.S.

          International Travel
          All International travelers will need a valid passport, and may also need to show additional documentation at the destination and/or in connecting countries. In addition, all travelers must have the proper documents for entry/re-entry into a country (i.e. correct visa, valid passport, onward/return ticket). For international one-way travel, proof of return travel or onward travel may be required and children travelling without both parents may also require additional documentation. Requirements vary based on the passenger's citizenship and country of residence.
          The following websites may be of assistance:
          U.S citizens interested in passport requirements, can visit the U.S. Department of State's website www.travel.state.gov.
          Canadian citizens can visit the Canadian Foreign Affairs website www.voyage.gc.ca/index-eng.asp.
          For travel visa requirements, citizens of any country can visit www.visahq.com/citizens.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-67" role="button" aria-expanded="false">
          Do I need ID for my child?
        </a>
        <div class="collapse" id="f-67">
          The documentation you'll need for your child will vary depending upon citizenship and destination.
          Travel Within the U.S. and U.S. Territories:
          U.S. citizens under the age of 18 are not required to provide an ID at the airport security checkpoint.
          Non-US/Canadian citizens are not required to carry their passports if they have documents issued by the U.S. government such as Permanent Resident Cards. Those who do not should be carrying their passports while visiting the U.S.

          International Travel
          All international travelers will need a valid passport and you may also need to show additional documentation at your destination and/or in connecting countries.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-68" role="button" aria-expanded="false">
          How do I determine the aircraft type?
        </a>
        <div class="collapse" id="f-68">
          After you've entered your desired departure and arrival cities, and travel dates, a list of available flights will appear. Click on the Full Flight Details link under your preferred flight to view the Aircraft Type. If you have already booked your tickets, the aircraft type will be displayed in the Aircraft column in Your Flight Details section. Usually, the aircraft type or 3-digit code will display for every available flight the airline provides for your itinerary.
          To learn more about aircraft type, please visit the carrier's website or contact them directly. Click here to access a list of airline phone numbers and website addresses.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-69" role="button" aria-expanded="false">
          Airline Partner Service Feedback
        </a>
        <div class="collapse" id="f-69">
          Once a customer is ticketed he or she becomes that airline's customer, and should be given the same courtesies as any other customer. If you have a complaint, you may register an Airline Service Complaint with the Department of Transportation. All complaints received by the DOT are entered in DOT's computerized aviation industry monitoring system, and are charged to the company in question in the monthly Air Travel Consumer Report. This report is distributed to the industry and made available to the news media and the general public so that consumers and air travel companies can compare the complaint records of individual airlines and tour operators. These complaints are reviewed to determine the extent to which carriers are incompliance with federal aviation consumer protection regulations. This system also serves as a basis for rule making, legislation and research.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-70" role="button" aria-expanded="false">
          Questions about schedule changes?
        </a>
        <div class="collapse" id="f-70">
          Airlines, for various reasons, will occasionally adjust their flight times and schedules. As a courtesy, if your itinerary undergoes a schedule change, we will make every attempt to notify you of the changes via email, prior to departure. In addition, we strongly recommend passengers confirm their flights and check-in location directly with the airline prior to each departure. Click here for airline contact information.
          Schedule changes can sometimes result in an itinerary that falls outside of contractual agreements. If a schedule change results in an unacceptable itinerary, please contact us. Requests to change or cancel your reservation may be denied based on airline policy and credit will not be given for unused tickets.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-71" role="button" aria-expanded="false">
          What are the baggage / carry-on restrictions and fees?
        </a>
        <div class="collapse" id="f-71">
          Baggage policies vary by airline. While some airlines may offer a free bag allowance, airlines may charge travelers extra fees for checked and carry-on baggage. Baggage fees are not included in your trip cost and you will be charged directly by your carrier at check-in. Some carriers offer online check-in, which may offer reduced baggage costs. Click here to see who's charging extra fees for checked baggage.
          Generally, travelers are allowed one carry-on bag and one personal item however, some airlines may also charge for carry-on bags. Personal items typically include a purse, briefcase, laptop or diaper bag. Carry-on baggage must be stowed under a seat, or in an overhead compartment.
          Sharp objects, such as knives or scissors, are not allowed in carry-on luggage and all bags are subject to search. For a complete list of items that are not allowed in carry-on or checked baggage, visit www.tsa.gov
          We recommend that you contact your airline directly to confirm its checked and carry-on baggage allowances and fees. Be prepared to provide the weight and/or dimensions of each piece of baggage that you wish to transport. The airline website or representative can provide you with approximate costs for checked, carry-on, oversized and/or extra pieces. Click here for airline contact information.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-72" role="button" aria-expanded="false">
          Check-In Procedures
        </a>
        <div class="collapse" id="f-72">
          Flight check-in is always done directly with your airline.
          Airline Check-In:
          Check-in options and procedures vary by airline. Many airlines offer online passenger and baggage check-in, typically available via your airline’s website between 24-72 hours prior to departure.
          This option allows you to skip the long lines at the airport. Some airlines even offer discounted baggage fees when checking in via their online check-in option.
          Click here to access your airline's website as well as their contact information to check-in/confirm your flight. Your airline confirmation number and ticket number are available on your itinerary.
          At the Airport:
          Unless your airline's website suggests an earlier arrival, plan to arrive at the airport a minimum of 90 minutes in advance of departure for domestic flights and at least 2 hours in advance of departure for international flights.
          Your check-in options may include online check-in on your airline's website, self-service kiosks at the ticket counter, or going to the ticket counter. Check with your confirmed airline to determine which options are available.
          If you have Electronic Tickets (E-tickets):
          If you are flying on MULTIPLE airlines, check in on each airline's website or ask the airline rep for your connecting flight's boarding pass.
          If you are flying on a CODESHARE airline (shown as "operated by"), check-in online with the airline who sold the ticket but go to the "operating carriers" baggage drop counter and gate area or the "operating carriers" ticket counter.

          If you have a Ticketless reservation, your check-in options may include online check-in or going to the ticket counter.
          If you have Paper Tickets you will need to present them at the airline ticket counter to receive your boarding pass.
          If you are flying on MULTIPLE airlines, ask the first airline rep for your connecting flight's boarding pass.
          If you are flying on a CODESHARE airline (shown as "operated by"), go to the "operating carriers" ticket counter and gate area.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-73" role="button" aria-expanded="false">
          Identification and Documentation Requirements
        </a>
        <div class="collapse" id="f-73">
          Identification and documentation requirements are determined by your citizenship and by the countries you will be traveling to, through or from. Travelers who are unable to provide required documentation may be denied boarding without compensation, detained, and/or deported at their own cost. If you are unsure of your travel requirements, please contact the embassy or consulate of the countries you are traveling from, through and to.

          General requirements are outlined below:

          Domestic travel within the U.S. and U.S. Territories

          U.S. Citizens: (18 and over) traveling within the U.S. will require a government issued photo ID. U.S. citizens under the age of 18 are not required to provide an ID.

          Non-US Citizens: (of all ages) traveling within the U.S. are required to carry a passport, Permanent Resident Card or other U.S. government issued ID.

          Domestic travel outside the U.S.

          Documentation requirements vary by country. Please check with the local government for documents required for domestic travel.

          International Travel - Adults and Children

          Passports: All International travelers will need a valid passport. The countries you are traveling to or transiting through might have passport restrictions in place. These often include but are not limited to: the validity period of passport, the number of free pages, restrictions on countries previously visited, or even restrictions on recognizing or allowing visits from citizens of certain countries.

          Additional Documents: In addition to a passport, the countries you are traveling to or transiting through may also require additional documentation such as entry, exit or transit visas, return or onward ticket, financial guarantees or even notarized documents.

          International Travel – Children Under 18 (with or without parents)

          Some countries are working to fight the war on child abductions through new entry, exit and transit documentation. Additional documentation typically includes certified birth certificates (certified within the last 3 months) and/or notarized travel authorizations. Requirements may vary depending on whether the child is traveling with one parent, two parents, or an adult other than a parent.
          When checking with each country you are traveling from, to and through, let them know you are traveling with a child and ask about additional children’s documentation.
        </div>
      </li>
    </ul>
      <h3>Transaction Security</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-74" role="button" aria-expanded="false">
          Important information on internet security
        </a>
        <div class="collapse" id="f-74">
          We are committed to protecting the personal information, including email addresses, of all our customers. We are equally committed to protecting our users from receiving 'spam' email. We never rent or sell any user information with a third party without the explicit consent of the user. You can read more about our commitment to privacy and our customer information practices in our privacy policy.
          The following list details some techniques that 'spammers' may use to get your email address and other sensitive personal information.
          'Dictionary Attack' This is a technique used by spammers which involves identifying a valid email domain name and running a name search program that runs through letter combination permutations. A spammer might then email all the names he comes up with or just hit the ones that sound like 'real' names.
          'Email Sniffer' The Internet, by its nature, is not a 100% secure environment. Programs called 'sniffers' are used by spammers to capture email addresses during a brief window while email messages are in transit between your computer and your ISP, or between your ISP and a website.
          User Level Security Breach In order to prevent your computer from being compromised, we recommend that you keep the operating system and virus protection software up to date and do not leave your computer unlocked when unattended.
          Third Party Security Breach Whenever you send or forward an email to another party, your address could get released on the internet if the other party's computer is subsequently infected by a worm or virus.

          A relatively new internet security warning, called 'phishing', employs a pop up window that mimics another website in an attempt to collect your personal information. To do this, an image that looks like a toolbar and address bar will be used to make it look like you are on a different website. You can help avoid becoming a victim of this technique by making sure that your address bar (where the URL of the website is listed) is text and not an image.
          We use Secure Socket Layer (SSL) technology to encrypt all your personal information (including number, name and address) to prevent it from being read during transmission over the Internet when you are on our site.
          We hope that this information helps you to understand some of the risks that exist with respect to your personal information. Please be assured that we are doing what we can to keep your information safe, secure and private on our end.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-75" role="button" aria-expanded="false">
          What if I suspect unauthorized charges on my credit card?
        </a>
        <div class="collapse" id="f-75">
          In the unlikely event that an unauthorized use of your credit card occurs, immediately notify your credit card issuer. The appropriate 800 number is usually located on the back of the card. It is critical that you contact your credit card issuer directly; they will explain the specific steps required to report suspected fraud, which generally include signing an affidavit attesting to the unauthorized charges and/or canceling the credit card in question. Your credit card issuer is also responsible for initiating the process to validate and refund or reverse the unauthorized charges on your credit card.
          Most credit card companies either cover all charges that result from unauthorized use of your credit card or limit your liability to $50.00 USD - the maximum liability allowed under the Fair Credit Billing Act.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-76" role="button" aria-expanded="false">
          How do I know that my credit card information is safe?
        </a>
        <div class="collapse" id="f-76">
          We handle thousands of online credit card transactions every single day and we want you to feel confident that your personal information is protected.
          With that in mind, we have created a secure environment for credit card transactions. We use Secure Socket Layer (SSL) technology to encrypt all your personal information including number, name and address to prevent it from being read during transmission over the Internet.
          Your trust and respect are our highest priority.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-77" role="button" aria-expanded="false">
          What is a fraudulent e-mail?
        </a>
        <div class="collapse" id="f-77">
          A fraudulent (a.k.a. spoofing, imposter, or phishing e-mail) is one that has been forged so it looks like a legitimate e-mail from aparticular organization. It's goal? Usually to trick you into providing sensitive personal information that can be used for identity theft.
          It's often hard to detect a fraudulent e-mail. That's because the e-mail address of the sender often seems genuine (such as support@company.com), as do the design and graphics. But there are tell talesigns to be aware of. For example, fraudulent e-mails often try to extract personal information from you in one of two ways:
          By luring you into providing it on the spot (e.g., by replying to the e-mail)
          By including links to a site that tries to get you to disclose personal data.

          If you believe that you are the recipient of such a fraudulent email you can forward the suspect email to the Federal Trade Commission at: spam@uce.gov
          You can also contact them at:
          https://www.consumer.gov/idtheft
          1-877-IDTHEFT
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-78" role="button" aria-expanded="false">
          How do I report a spoof e-mail?
        </a>
        <div class="collapse" id="f-78">
          You can forward the suspect email to the Federal Trade Commission at:
          spam@uce.gov
          You can also contact them at:
          https://www.consumer.gov/idtheft
          1-877-IDTHEFT
          Additional Information about Phishing may be obtained from the Federal Trade Commission at:
          https://www.consumer.ftc.gov/articles/0003-phishing
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-79" role="button" aria-expanded="false">
          How can I protect myself?
        </a>
        <div class="collapse" id="f-79">
          We want your online experience to be enjoyable and worry-free. That's why we use secure sockets layer (SSL) encryption and other security procedures. We also want to make you aware of several simple security tips to keep in mind:
          Act quickly if you suspect fraud. If you believe someone is trying to commit fraud, please contact us immediately by sending us an email from our help section.
          Leave suspicious sites. If you suspect that a website is not what it purports to be, leave the site immediately. Do not follow any of the instructions it presents.
          Be alert for scam e-mails. These may appear to come from a trusted business or friend, but actually are designed to trick you into downloading a virus or jumping to a fraudulent website and disclosing sensitive information.
          Don't reply to any e-mail that requests your personal information. Be very suspicious of any e-mail from a business or person that asks for your password, Social Security number, or other highly sensitive information, or one that sends your personal information and asks you to update or confirm it.
          Open e-mails only when you know the sender. Be especially careful about opening an e-mail with an attachment. Even a friend may accidentally send an e-mail with a virus.
          Be careful before clicking on a link contained in an e-mail or other message. The link may not be trustworthy.
          Do not send sensitive personal or financial information unless it is encrypted on a secure website. Regular e-mails are not encrypted and are more like sending a post card. Look for the padlock symbol on the bottom bar of the browser to ensure that the site is running in secure mode BEFORE you enter sensitive information.
          Do business only with companies you know and trust.
          Be Aware. Phony 'lookalike' websites are designed to trick consumers and collect their personal information. Make sure that websites on which you transact business post privacy and security statements, and review them carefully.
          Make sure your home computer has the most current anti-virus software. Anti-virus software needs frequent updates to guard against new viruses. Make sure you download the anti-virus updates as soon as you are notified that a download is available.
          Install a personal firewall to help prevent unauthorized access to your home computer. This is especially important if you connect to the internet via a cable modem or a digital subscriber line (DSL) modem.
          Monitor your transactions. Review your order confirmations, credit card, and bank statements as soon as you receive them to make sure you're being charged only for transactions you made.
          Additional Information about Phishing may be obtained from the Federal Trade Commission at: https://www.consumer.ftc.gov/articles/0003-phishing
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-80" role="button" aria-expanded="false">
          How can I be sure that I'm not dealing with an imposter?
        </a>
        <div class="collapse" id="f-80">
          We will never send you an e-mail asking for your passwords, credit card numbers, or other sensitive information.
          If you're required to enter personal information to perform a transaction on our web site, it's always done on a page secured with SSL technology - you can tell because there'll be a padlock icon at the bottom of your screen. Most important, if you click on the padlock, a security certificate will pop up. In it, there's a section that says 'Issued to:' If it's really a legitimate site, the URL will end in the companies name.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-81" role="button" aria-expanded="false">
          TSA Privacy Notice
        </a>
        <div class="collapse" id="f-81">
          The Transportation Security Administration (TSA) of the U.S. Department of Homeland Security requires us to collect information from you for purposes of watch list screening, under the authority of 49 U.S.C. section 114, and the Intelligence Reform and Terrorism Prevention Act of 2004. Providing this information is voluntary however, if it is not provided, you may be subject to additional screening or denied transport or authorization to enter a sterile area. TSA may share information you provide with law enforcement or intelligence agencies or others under its published system of records notice. For more on TSA Privacy policies, or to view the system of records notice and the privacy impact assessment, please visit the TSA Website.
        </div>
      </li>
    </ul>
      <h3>Unlinked FAQs</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-82" role="button" aria-expanded="false">
          Airline Customer Service Phone List
        </a>
        <div class="collapse" id="f-82">
          All of the airline contact numbers listed below are for customers dialing from within the U.S.
          Please note that some carriers do not offer a toll free number, and dialing them will incur long distance or international calling charges.
          *If you are trying to reach a U.S. based carrier from outside the U.S., we recommend you visit the carriers website for dialing instructions from outside the U.S

          To avoid charges, you can visit the carriers website and contact them via email.
          Phone numbers that start with 011 indicate an international call. International calls often have a delayed connection - please dial slowly and wait for the ring. International calls are usually answered in a foreign language however, most travel agents speak English.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-83" role="button" aria-expanded="false">
          Commonly Used Terms
        </a>
        <div class="collapse" id="f-83">
          Itinerary Number / Request Number
          This is your reference number for the airline reservation you made with our company. Please refer to this number whenever you contact PPN Merchant & CUG & Agoda Demo with Branding about your reservation.
          Booking ID
          This is an alternative reference number that also can be used when you contact PPN Merchant & CUG & Agoda Demo with Branding about your reservation.
          Ticket Number
          This is assigned by the airline that issued your tickets. You can use this number if you need to contact the airline directly.
          Confirmation Number
          This is assigned by the airline that issued your tickets and should be your primary reference number when contacting the airline on all issues related to your trip, including airport check-in. On some airlines, this number can also be used during your e-kiosk check-in process.
        </div>
      </li>
    </ul>
      <h3>PrePurchase Pop-ups</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-84" role="button" aria-expanded="false">
          Why we ask?
        </a>
        <div class="collapse" id="f-84">
          If you are located in a jurisdiction outside of the United States, please note that our website is hosted on servers in the United States, and your data will accordingly be transferred in the United States. We respect non-US privacy laws and complies with the EU Safe Harbor framework as set forth by the United States Department of Commerce regarding the collection, use and processing of data from the European Union.
          For further information, please see our Privacy Policy.
        </div>
      </li>
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-85" role="button" aria-expanded="false">
          What's this?
        </a>
        <div class="collapse" id="f-85">
          Credit Card Security Code
          As part of our commitment to your protection and security, we require you to verify your credit card security code during the identification process to help safeguard you from unauthorized purchases. For your protection, we will not process transactions if the security value you enter does not match the security value assigned by your credit card company.

          Enter the 3 - or 4 - digit code that appears on the front or back of your credit card in the space provided. (Optima Card holders will see these digits above the card number to the left.)
        </div>
      </li>
    </ul>
      <h3>Personal Identification</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-86" role="button" aria-expanded="false">
          What identification will I need at check-in?
        </a>
        <div class="collapse" id="f-86">
          The documentation you'll need will vary depending upon your citizenship and your destination.
          Travel within the U.S. and U.S. Territories
          U.S. citizens (18 and over) traveling within the U.S. will require a government issued photo ID. U.S. citizens under the age of 18 are not required to provide an ID at the airport security checkpoint.
          Non-US/Canadian citizens are not required to carry their passports if they have documents issued by the U.S. government such as Permanent Resident Cards. Those who do not should be carrying their passports while visiting the U.S.

          International Travel
          All International travelers will need a valid passport, and may also need to show additional documentation at the destination and/or in connecting countries. In addition, all travelers must have the proper documents for entry/re-entry into a country (i.e. correct visa, valid passport, onward/return ticket). For international one-way travel, proof of return travel or onward travel may be required and children travelling without both parents may also require additional documentation. Requirements vary based on the passenger's citizenship and country of residence.
          The following websites may be of assistance:
          U.S citizens interested in passport requirements, can visit the U.S. Department of State's website www.travel.state.gov.
          Canadian citizens can visit the Canadian Foreign Affairs website www.voyage.gc.ca/index-eng.asp.
          For travel visa requirements, citizens of any country can visit www.visahq.com/citizens.
        </div>
      </li>
    </ul>
      <h3>Fare Rules</h3>
    <ul class="ul-faq">
      <li>
        <span><em class="fas fa-angle-right"></em></span>
        <a data-toggle="collapse" href="#f-87" role="button" aria-expanded="false">
          Fare Rules and Restrictions
        </a>
        <div class="collapse" id="f-87">
          Your purchase is non-refundable, non-transferable and non-changeable. Your ticket cannot be changed or cancelled. You must fly on the flights assigned to you. Upgrades and standbys are not available and if any part of the ticket(s) is unused, it has no value after ticketed departure time.
        </div>
      </li>
    </ul>
  </div>


</div>
