import {Component, OnInit, ViewEncapsulation, Input, AfterViewInit, ViewChild} from "@angular/core";
import {AuthenticationService} from "../../services/authentication.service";
import {IBookingFee} from "../../interfaces/view.interface";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "../../services/event.service";
import {FlightBookService} from "../../app/travel/contract/book/flight-book.service";
import {CarBookService} from "../../app/cars/contract/book/car-book.service";
import {HotelBookService} from "../../app/hotel/contract/book/hotel-book.service";
import {CartService} from "../../services/cart.service";

@Component({
    selector: 'agent-fees-payment',
    templateUrl: 'agent-fees-payment.html',
    styleUrls: ['./agent-fees-payment.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgentFeesPayment implements OnInit, AfterViewInit {
    @Input() moneyPayment: eAgentBookingPaymentType = eAgentBookingPaymentType.money;
    @Input() bookingType: string = '';

    loginAs: boolean = false;
    showFee: boolean = false;
    fees: IBookingFee = {price: 0, points:0, currencyCode: 'USD', currencySymbol: '$'};
    feeChecked: boolean = true;
    hideOnNoPoints: boolean = false;
    notMuchPoints: boolean = false;
    pointsAvailabeForFee: number = 0;

    private selectedPoints: number = 0;

    constructor(private authService: AuthenticationService,
                public translateService: TranslateService,
                private eventService: EventService,
                private flightBookingService: FlightBookService,
                private carBookingService: CarBookService,
                private hotelBookingService: HotelBookService,
                private cartService: CartService,) {
        this.loginAs = authService.checkLoginAs();
        this.showFee = authService.showFee();

        this.eventService.on('totals', (value)=>{
            this.fees = value;

            this.checkGraphicsOnValuesChanged(false);
        });
    }

    ngOnInit() {
        this.initSelectedPoints();
    }

    ngAfterViewInit() {
        setTimeout(()=>{
            this.initGraphic();
        }, 100);
    }

    private initGraphic() {
        this.feeChecked = this.moneyPayment == eAgentBookingPaymentType.money;

        this.checkedChanged();
        this.checkGraphicsOnValuesChanged();
    }

    checkedChanged() {
        this.eventService.emit({name:'agent-fee-payment', value: this.feeChecked});
    }

    initSelectedPoints() {
        switch(this.bookingType) {
            case 'flight':
                this.selectedPoints = this.flightBookingService.selectedPoints;
                break;
            case 'car':
                this.selectedPoints = this.carBookingService.selectedPoints;
                break;
            case 'hotel':
                this.selectedPoints = this.hotelBookingService.selectedPoints;
                break;
            default:
                this.selectedPoints = this.cartService.selectedPoints;
                break;
        }
    }

    setSelectedPoints(value: number) {
        this.selectedPoints = value;

        this.checkGraphicsOnValuesChanged();
    }

    private checkGraphicsOnValuesChanged(sendChange: boolean = true) {
        this.pointsAvailabeForFee = this.authService.user.last_balance - this.selectedPoints;
        this.notMuchPoints = this.pointsAvailabeForFee < this.fees.points;
        this.hideOnNoPoints = this.fees.points == 0;

        if (this.hideOnNoPoints || this.notMuchPoints) {
            this.moneyPayment = eAgentBookingPaymentType.money;
            this.feeChecked = true;
            if (sendChange) this.checkedChanged();
        }
    }
}

export enum eAgentBookingPaymentType {
    money,
    points
}
