import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[ariaDisabledOnPageChange]'
})
export class AriaDisabled implements AfterViewInit, OnChanges {
    @Input() public ariaDisabledOnPageChange: any;
    @Input() public page: any;

    public constructor(private el: ElementRef) {
    }

    public ngAfterViewInit() {
        this.onChange();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(changes.page){
            this.onChange();
        }
    }

    private onChange() {
        let items = this.el.nativeElement.children[0].children;
        for (let i = 0; i < items.length; i++) {
            let item: HTMLElement = items[i];

            if (item.classList.contains('disabled')) {
                if (item.children.length > 0) {
                    for (let a = 0; a < item.children.length; a++) {
                        let child: HTMLElement = <HTMLElement>item.children[a];
                        if (child.nodeName.toLowerCase() == 'a') {
                            child.setAttribute('aria-disabled', 'true');
                        }
                    }
                }
            }
            else {
                if (item.nodeName.toLowerCase() == 'li') {
                    if (item.children.length > 0) {
                        for (let a = 0; a < item.children.length; a++) {
                            let child: HTMLElement = <HTMLElement>item.children[a];
                            if (child.nodeName.toLowerCase() == 'a') {
                                child.removeAttribute('aria-disabled');
                            }
                        }
                    }
                }
            }
        }
    }
}
