import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import {AlertComponent} from "../directives/alerts/alerts.component";
import {ModalComponent} from "../directives/modal/modal.component";
import {TravelButton} from "../directives/travel-button/travel.button";
import {SpinnerComponent} from "../directives/spinner/spinner.component";
import {RatingComponent} from "../directives/rating/rating.component";
import {PointConverterPipe} from "../pipes/point-converter";
import {PaymentComponent} from "../directives/payment-component/payment.component";
import {EmailValidator} from "../directives/email-validator/email-validator";
import {CommonService} from "../services/common.service";
import {LimitToDirective} from "../directives/limit-to/limit-to";
import {MyEnter} from "../directives/my-enter/my-enter";
import {TextMaskModule} from "angular2-text-mask";
import {EscapeHtmlPipe} from "../pipes/escape-html";
import {CartComponent} from "../directives/cart-component/cart.component";
import {RouterModule} from "@angular/router";
import {SummaryComponent} from "../directives/summary-component/summary.component";
import {InsuranceBannerComponent} from "../directives/insurance-banner/insurance-banner.component";
import {CircleSpinnerComponent} from "../directives/spinner/circle/circle-spinner.component";
import {StepComponent} from "../directives/step-component/step.component";
import {SendItineraryComponent} from "../directives/send-itinerary/send-itinerary.component";
import {Autofocus} from "../directives/auto-focus/auto-focus";
import {NavigationTabComponent} from "../directives/navigation-tab/navigation-tab.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {PriceComponent} from "../directives/price-component/price.component";
import {FloatingLabelDirective} from "../directives/float-label/floating-label";
import {InfantModalComponent} from "../directives/infant-modal/infant-modal.component";
import {BookOverlayComponent} from "../directives/book-overlay/book-overlay.component";
import {ClearInputComponent} from '../directives/clear-input/clear-input-component';
import {ReplacePipe} from "../pipes/replace-pipe";
import {Title} from '@angular/platform-browser';
import {AriaDisabled} from "../directives/aria-disabled/aria-disabled";
import {PtsCurrencyComponent} from "../directives/pts-currency-component/pts-currency.component";
import {SummaryAgentFees} from "../directives/summary-agent-fees/summary-agent-fees";
import {AgentFees} from "../directives/agent-fees/agent-fees";
import {AgentFeesPayment} from "../directives/agent-fees-payment/agent-fees-payment";
import {ItineraryAgentFees} from "../directives/itinerary-agent-fees/itinerary-agent-fees";
import {CancelModalComponent} from "../directives/cancel-modal/cancel-modal.component";
import {CancelMessageComponent} from "../directives/cancel-msg/cancel-msg.component";
import {BaggageFeesLinkComponent} from "../directives/baggage-fee-link/baggage-fees-link.component";
import {BaggageFeesModalComponent} from "../directives/baggage-fees-modal/baggage-fees-modal.component";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {ErrorMessageComponent} from "../directives/error-msg/error-message.component";

@NgModule({
    declarations: [
        AlertComponent,
        ModalComponent,
        RatingComponent,
        EmailValidator,
        TravelButton,
        SpinnerComponent,
        CircleSpinnerComponent,
        PointConverterPipe,
        EscapeHtmlPipe,
        ReplacePipe,
        PaymentComponent,
        CartComponent,
        SummaryComponent,
        StepComponent,
        InsuranceBannerComponent,
        LimitToDirective,
        Autofocus,
        MyEnter,
        SendItineraryComponent,
        NavigationTabComponent,
        PriceComponent,
        PtsCurrencyComponent,
        FloatingLabelDirective,
        InfantModalComponent,
        CancelModalComponent,
        BookOverlayComponent,
        ClearInputComponent,
        AriaDisabled,
        SummaryAgentFees,
        AgentFees,
        AgentFeesPayment,
        ItineraryAgentFees,
        CancelMessageComponent,
        BaggageFeesLinkComponent,
        BaggageFeesModalComponent,
        ErrorMessageComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        NgxSliderModule,
        TextMaskModule,
        TranslateModule,
        NgbTooltipModule
    ],
    exports: [
        TranslateModule,
        CommonModule,
        RouterModule,
        NgbDatepickerModule,
        NgbTooltipModule,
        NgbTypeaheadModule,
        NgbPaginationModule,
        NgxSliderModule,
        ReactiveFormsModule,
        FormsModule,
        AlertComponent,
        EmailValidator,
        ModalComponent,
        RatingComponent,
        TravelButton,
        SpinnerComponent,
        CircleSpinnerComponent,
        PointConverterPipe,
        EscapeHtmlPipe,
        ReplacePipe,
        PaymentComponent,
        SummaryComponent,
        InsuranceBannerComponent,
        LimitToDirective,
        MyEnter,
        Autofocus,
        CartComponent,
        StepComponent,
        SendItineraryComponent,
        NavigationTabComponent,
        PriceComponent,
        PtsCurrencyComponent,
        FloatingLabelDirective,
        InfantModalComponent,
        CancelModalComponent,
        BookOverlayComponent,
        ClearInputComponent,
        AriaDisabled,
        SummaryAgentFees,
        AgentFees,
        AgentFeesPayment,
        ItineraryAgentFees,
        CancelMessageComponent,
        BaggageFeesLinkComponent,
        BaggageFeesModalComponent,
        ErrorMessageComponent
    ],
    providers: [CommonService,DecimalPipe,TranslateService, Title],
})
export class SharedModule {
}
