import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {AuthenticationService} from "../authentication.service";

@Injectable()
export class AuthResolver implements Resolve<any> {
    constructor(
        private authenticationService: AuthenticationService
    ) {

    }

    resolve(route: ActivatedRouteSnapshot){
        return this.authenticationService.getUserInfo()
    }
}
