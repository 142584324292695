<div>
  <h3 class="mt-5 text-left h5"><span id="slider-label" translate>How many <pts-currency ></pts-currency> would you like to redeem?</span></h3>
  <p class="text-left font-s">
    <span>Did you know that even if you have the <pts-currency ></pts-currency> to complete the purchase, you can choose to save up your <pts-currency ></pts-currency> and pay part of it with cash?</span>
  </p>
  <p class="point-used text-left mb-0">{{sliderValue | number:'1.0-0':translateService.currentLang}}
      <pts-currency></pts-currency></p>
  <p class="font-s alert-notification-payment" *ngIf="type =='flight'">
      Your card will be charged for the entire amount <span class="medium">${{flightService.contract.price_details.display_total_fare | number:'1.2-2'}} USD</span>.
      <span *ngIf="pointsAmout > 0">A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you selected <span class="medium">({{pointsAmout | number:'1.0-0'}}).</span></span>
  </p>
    <p class="alert-notification-payment font-s" *ngIf="type == 'car'">
      Your card will not be charged right now and you will pay the car rental agency at the counter.
       <span *ngIf="pointsAmout > 0"> A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you selected <span class="medium">({{pointsAmout | number:'1.0-0'}})</span>.</span>
  </p>
    <p class="alert-notification-payment font-s" *ngIf="type == 'hotel'">
        Your card may be charged right now for the entire amount <span class="medium">${{hotelService.lastHotelSelected.room_data.rate_data[0].price_details.display_total | number:'1.2-2'}} USD</span> as per the payment policy mentioned in the sidebar else you will pay the hotel directly as credit card information would just be used for the reservation.
        <span *ngIf="pointsAmout > 0"> A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you selected <span class="medium">({{pointsAmout | number:'1.0-0'}})</span>.</span>
    </p>
    <p class="font-s alert-notification-payment" *ngIf="type.includes('car') && !type.includes('hotel') && type.includes('flight')" >
        Your card will be charged for the full amount <span class="medium">${{flightService.contract.price_details.display_total_fare | number:'1.2-2'}} USD</span> for your flight reservation at this time but payment for car rental <span class="medium">${{parseNumber(carService.contract.pricing.display_total) | number:'1.2-2'}} USD</span> is due at the counter.
        <span *ngIf="pointsAmout > 0"> A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you selected <span class="medium">({{pointsAmout | number:'1.0-0'}})</span>.</span>
    </p>
  <p class="font-s alert-notification-payment"  *ngIf="type.includes('flight') && type.includes('hotel') && !type.includes('car')">
      Your card will be charged for the full amount <span class="medium">${{flightService.contract.price_details.display_total_fare | number: '1.2-2'}} USD</span> to issue the tickets. For your hotel reservation, your card may be charged right now for the full amount <span class="medium">${{hotelService.lastHotelSelected.room_data.rate_data[0].price_details.display_total | number:'1.2-2'}} USD</span> as per the payment policy mentioned in the sidebar else you will pay the hotel directly as credit card information would just be used for the reservation.<br>
      <span *ngIf="pointsAmout > 0">  A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you selected <span class="medium">({{pointsAmout | number:'1.0-0'}})</span>.</span>
  </p>
    <p class="font-s alert-notification-payment" *ngIf="type.includes('car') && type.includes('hotel') && !type.includes('flight')">
        For your hotel reservation, your card may be charged right now for the full amount <span class="medium">${{hotelService.lastHotelSelected.room_data.rate_data[0].price_details.display_total | number:'1.2-2'}} USD</span> as per the payment policy mentioned in the sidebar else you will pay the hotel directly as credit card information would just be used for the reservation. The payment for car rental <span class="medium">${{parseNumber(carService.contract.pricing.display_total) | number:'1.2-2'}} USD</span> is due at the counter. <br>
        <span *ngIf="pointsAmout > 0">   A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you have selected <span class="medium">({{pointsAmout | number:'1.0-0'}})</span>.</span>
    </p>
    <p class="font-s alert-notification-payment" *ngIf="type.includes('car') && type.includes('hotel') && type.includes('flight')">
        Your card will be charged for the full amount <span class="medium">${{flightService.contract.price_details.display_total_fare | number:'1.2-2'}} USD</span> to issue the tickets. For your hotel reservation, your card may be charged right now for the full amount <span class="medium">${{hotelService.lastHotelSelected.room_data.rate_data[0].price_details.display_total | number:'1.2-2'}} USD</span> as per the payment policy mentioned in the sidebar else you will pay the hotel directly as credit card information would just be used for the reservation. The payment for car rental <span class="medium">${{parseNumber(carService.contract.pricing.display_total) | number:'1.2-2'}} USD</span> is due at the counter.
        <span *ngIf="pointsAmout > 0">   A statement credit of <price-component class="medium" [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component> will be issued for the <pts-currency ></pts-currency> you selected <span class="medium">({{pointsAmout | number:'1.0-0'}})</span>.</span>
    </p>
  <div class="custom-slider  payment-slider">
    <div class="d-relative double-arrow">
      <ngx-slider [(value)]="sliderValue" [options]="sliderOptions" (userChange)="updatePoints($event)"
                  [manualRefresh]="manualRefresh"></ngx-slider>
    </div>
  </div>
  <div *ngIf="needTopUp">
    <div class="top-up-wrapper row">
      <div class="col-md-5 col-sm-12">
        <input id="pts-to-use" class="form-control text-center top-up-input" type="text" (focus)="checkData()" (change)="updateSliderValue($event)"
               value="{{(pointsAmout | number:'1.0-0':translateService.currentLang) + ' ' +showCurrencyPts}}">
        <div class=" col-md-5 col-sm-12 d-md-none d-lg-none">(<price-component [price]="(pointsAmout / conversion_rate)" [currency]="'USD'"    [symbol]="'$'"></price-component>)</div>
      </div>
      <div class="plus-icon col-md-2 col-sm-12">
        <span>
          <img aria-hidden="true" alt="" src="../../assets/images/icons/add.svg" width="14">
        </span>
      </div>
      <div class="col-md-5 col-sm-12">
        <input disabled class="form-control text-center top-up-input" type="text"
               [value]="translateService.currentLang == 'en' ? '$'+(cashAmout | number:'1.2-2'): (cashAmout | number:'1.2-2':translateService.currentLang) + '$'">
        <div class=" col-md-5 col-sm-12 d-md-none d-lg-none"><span translate>(USD Payment)</span>
        </div>
      </div>
      <br>
    </div>
    <div class="top-up-wrapper mt-1 row d-none d-md-flex">
      <div class=" col-md-5 col-sm-12">(<price-component [price]="(pointsAmout / conversion_rate)" [currency]="'USD'" [symbol]="'$'"></price-component>)</div>
      <div class="col-md-2 col-sm-12"></div>
      <div class="col-md-5 col-sm-12"><span translate>(USD Payment)</span></div>
    </div>
  </div>

  <agent-fees-payment #agentPaymentBookingFee [moneyPayment]="agentPaymentByMoney" [bookingType]="type"></agent-fees-payment>

  <hr class="point-hr">
  <h4 class="text--lg bundle-heading-section pl-3 light" translate>  Payment Information</h4>
  <p >
    <span class="font-s" translate>Please enter the HSBC credit card information associated with your HSBC Rewards account. The HSBC credit card entered will be used for your redemption and purchase of your travel reservation.</span>
  </p>
  <div class="card-info-wrapper my-shadow">
    <form class="card-form" [formGroup]="cardForm">
      <div class="row">
        <div class="col-12 col-md-6 form-group">

            <label for="card_first_name" translate>First name</label>
            <input placeholder=" " floatingLabel aria-describedby="fn-card-rq" type="text" class="inputTravel"
                   [ngClass]="{ 'is-invalid': card_first_name.invalid && (card_first_name.touched || submitted) }"
                   formControlName="card_first_name" id="card_first_name">
            <div *ngIf="(card_first_name.touched || submitted) && card_first_name.invalid" class="invalid-feedback">
              <div id="fn-card-rq" aria-live="polite" *ngIf="card_first_name.errors.required" translate>First Name is required</div>
              <div aria-live="polite" *ngIf="card_first_name.errors.pattern" translate>First Name must be a string</div>
            </div>

        </div>
        <div class="col-12 col-md-6 form-group">
          <label for="card_last_name" translate>Last name</label>
          <input placeholder=" " floatingLabel aria-describedby="ln-card-rq" type="text" class="inputTravel"
                   [ngClass]="{ 'is-invalid': card_last_name.invalid && (card_last_name.touched || submitted) }"
                   formControlName="card_last_name" id="card_last_name">
            <div *ngIf="(card_last_name.touched || submitted) && card_last_name.invalid" class="invalid-feedback">
              <div id="ln-card-rq" aria-live="polite" *ngIf="card_last_name.errors.required" translate>Last Name is required</div>
              <div aria-live="polite" *ngIf="card_last_name.errors.pattern" translate>Last Name must be a string</div>
            </div>

        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-7 col-lg-6 form-group">
          <label for="cardNumber" translate>Credit card number</label>
          <input placeholder=" " floatingLabel aria-describedby="card-required" [textMask]="{mask: mask, guide: false}"
                   class="inputTravel" minlength="16" [type]="card_number.invalid? 'text' : 'password'"
                   [ngClass]="{ 'is-invalid': card_number.invalid && (card_number.touched || submitted) }"
                   formControlName="card_number" id="cardNumber">
            <div *ngIf="(card_number.touched || submitted) && card_number.invalid" class="invalid-feedback">
              <div id="card-required" aria-live="polite" *ngIf="card_number.errors.required" translate>Card number is required</div>
              <div aria-live="polite" *ngIf="card_number.errors.requiredLength" translate>Card number must be 16 digits</div>
              <div aria-live="polite" *ngIf="card_number.errors.invalid_bin" translate>Please enter the HSBC Rewards Credit Card that is associated with your HSBC Rewards account</div>
            </div>
        </div>
        <div class="col-12 col-md-5 col-lg-6 form-group">

            <label for="card_type" translate>Credit card type</label>
            <select id="card_type" aria-describedby="card-type-required" name="card_type" formControlName="card_type"
                    class="w-100 selectTravel selectEmpty"
                    [ngClass]="{ 'is-invalid': card_type.invalid && (card_type.touched || submitted) , 'dirty' : card_type.value != '' }">
              <option value=""></option>
              <option *ngFor="let num of cardType" [value]="num.id">
                {{num.name}}
              </option>
            </select>
            <div *ngIf="(card_type.touched || submitted) && card_type.invalid" class="invalid-feedback">
              <div aria-live="polite" id="card-type-required" *ngIf="card_type.errors.required" translate>Card type is required</div>
            </div>

        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 form-group">

            <span class="sr-only" id="card_month_label" translate>Month</span>
            <label id="expire_date_label" for="card_month" translate>Expiry Month</label>
            <select aria-describedby="exp-req" id="card_month" name="card_month" aria-labelledby="expire_date_label card_month_label"
                    [ngClass]="{ 'is-invalid': (expiry_month.invalid || cardForm.hasError('expiry')) && (expiry_month.touched || submitted) , 'dirty' : expiry_month.value != '' }"
                    formControlName="card_month" class="selectTravel selectEmpty">
              <option value="" selected>Select month</option>
              <option *ngFor="let num of cardMonths" [value]="num">
                {{num}}
              </option>
            </select>
            <div *ngIf="(expiry_month.touched || submitted) && (expiry_month.invalid || cardForm.hasError('expiry'))"
                 class="invalid-feedback">
              <div id="exp-req" *ngIf="cardForm.hasError('expiry') && (submitted || expiry_month.touched)" translate>Invalid expiry month</div>
              <div id="expiry-mm-req" *ngIf="expiry_month.errors && expiry_month.errors.required" translate>Expiry month is required</div>
            </div>

        </div>
        <div class="col-12 col-md-4 form-group">
          <label id="card_year_label" for="card_year" translate>Expiry Year</label>
          <select id="card_year" name="card_year" aria-labelledby="expire_date_label card_year_label"
                    [ngClass]="{ 'is-invalid': expiry_year.invalid && (expiry_year.touched || submitted) , 'dirty' : expiry_year.value != '' }"
                    formControlName="card_year" class="selectTravel selectEmpty">
              <option  value="" selected>Select year</option>
              <option *ngFor="let num of cardYear" [value]="num">
                {{num}}
              </option>
            </select>
            <div *ngIf="(expiry_year.touched || submitted) && expiry_year.invalid" class="invalid-feedback">
              <div id="expiry-yy-req" *ngIf="expiry_year.errors.required" translate>Expiry year is required</div>
            </div>
        </div>
        <div class="col-12 col-md-4 form-group">
            <ng-template  #tipCvv>
                <div class="row">
                    <div class="col-12">
                        <p >{{cvvEn}}</p>
                    </div>
                </div>
            </ng-template>
            <label class="no-wrap" for="cvv">CVV &nbsp;</label>
            <span role="button" tabindex="0" triggers="manual" #cvt="ngbTooltip" (blur)="cvt.close();cvvTooltip =false" (focus)="cvt.open()" (click)="cvt.open();cvvTooltip= !cvvTooltip"  [ngbTooltip]="tipCvv">
                 <img alt="help" width="17" [src]=" !cvvTooltip ? tooltipOpened : tooltipClosed ">
                </span>
            <input placeholder="Enter CVV number" type="password" aria-describedby="cvv-required" [textMask]="{mask: cvvMask, guide: false}"
                   class="inputTravel" [ngClass]="{ 'is-invalid': card_cvc.invalid && (card_cvc.touched || submitted) }"
                   formControlName="card_cvc" id="cvv">
            <div *ngIf="(card_cvc.touched || submitted) && card_cvc.invalid" class="invalid-feedback">
              <div id="cvv-required" aria-live="polite" *ngIf="card_cvc.errors.required" translate>CVV is required</div>
              <div aria-live="polite" *ngIf="card_cvc.errors.requiredLength" translate>CVV must be 3 digits</div>
            </div>
        </div>
      </div>
    </form>
  </div>
  <hr class="point-hr">
  <h4 class="text--lg bundle-heading-section pl-3 light"  translate>Billing</h4>
  <p class="font-s">
    <span translate>Please enter billing information </span> as it appears <span translate> on your Credit Card</span>.
  </p>
  <div class="card-info-wrapper billing-info">
    <form class="billing-form" [formGroup]="billingForm">
      <div class="row">
        <div class="form-group col-12">

            <label for="street_address" translate>Address</label>
            <input aria-describedby="add-rq"   type="text" class="inputTravel"
                   [ngClass]="{ 'is-invalid': address.invalid && (address.touched || submitted) }"
                   formControlName="address" id="street_address">
            <div *ngIf="(address.touched || submitted) && address.invalid" class="invalid-feedback">
              <div aria-live="polite" id="add-rq" *ngIf="address.errors.required" translate>Street address is required</div>
              <div aria-live="polite" *ngIf="address.errors.pattern" translate>Street address is invalid</div>
              <div aria-live="polite" *ngIf="address.errors.maxlength" translate>Street address is too long</div>
            </div>

        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-md-6">
            <label for="country-select" translate>Country</label>
            <select aria-describedby="country-rq" id="country-select" name="country" (change)="changeProvince($event)"
                    [ngClass]="{ 'is-invalid': country.invalid && (country.touched || submitted) , 'dirty' : country.value != '' }"
                    formControlName="country" class="selectTravel selectEmpty">
             <option selected value="">Select country</option>
              <option *ngFor="let country of country_list" [value]="country.code">
                {{country.country}}
              </option>
            </select>
            <div *ngIf="(country.touched || submitted) && country.invalid" class="invalid-feedback">
              <div aria-live="polite" id="country-rq" *ngIf="country.errors.required" translate>Country is required</div>
            </div>
        </div>
        <div *ngIf="country.value == 'CA' || country.value == 'US'"  class="form-group col-12 col-md-6">

            <label for="province-select"  translate>State</label>
            <select aria-describedby="province-rq" id="province-select" name="province"
                    [ngClass]="{ 'is-invalid': province.invalid && (province.touched || submitted) , 'dirty' : province.value != '' }"
                    formControlName="province" class="selectTravel selectEmpty">
             <option value="" selected>Select state</option>
              <option *ngFor="let province of province_list" [value]="province.id">
                {{province.label}}
              </option>
            </select>
            <div *ngIf="(province.touched || submitted) && province.invalid" class="invalid-feedback">
              <div aria-live="polite" id="province-rq" *ngIf="province.errors.required" translate>State is required</div>
            </div>
        </div>
          <div *ngIf="country.value != 'CA' && country.value != 'US' && country.value != ''"  class="form-group col-12 col-md-6">
            <label for="province-select"  translate>State</label>
            <input aria-describedby="province2-rq" id="province-select2" name="province2"
                    [ngClass]="{ 'is-invalid': province2.invalid && (province2.touched || submitted) , 'dirty' : province2.value != '' }"
                    formControlName="province2" type="text" class="inputTravel">
            <div *ngIf="(province2.touched || submitted) && province2.invalid" class="invalid-feedback">
              <div aria-live="polite" id="province2-rq" *ngIf="province2.errors.required" translate>State is required</div>
              <div aria-live="polite" id="province2-inv" *ngIf="province2.errors.pattern" translate>State is invalid</div>
              <div aria-live="polite" id="province2-minl" *ngIf="province2.errors.minlength" translate>State is too short </div>
              <div aria-live="polite" id="province2-maxl" *ngIf="province2.errors.maxlength" translate>State is too long </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-md-6">
            <label for="city" translate>City</label>
            <input aria-describedby="city-rq" placeholder=" " floatingLabel type="text" class="inputTravel"
                   [ngClass]="{ 'is-invalid': city.invalid && (city.touched || submitted) }" formControlName="city"
                   id="city">
            <div *ngIf="(city.touched || submitted) && city.invalid" class="invalid-feedback">
              <div aria-live="polite" id="city-rq" *ngIf="city.errors.required" translate>City is required</div>
              <div aria-live="polite" *ngIf="city.errors.pattern" translate>City is invalid</div>
              <div aria-live="polite" *ngIf="city.errors.maxlength" translate>City address is too long</div>
            </div>
        </div>
        <div class="form-group col-12 col-md-6">

            <label for="postal_code"  translate>Zip code</label>
            <input aria-describedby="pc-rq" placeholder=" " floatingLabel type="text" class="inputTravel"
                   [ngClass]="{ 'is-invalid': postal_code.invalid && (postal_code.touched || submitted) }"
                   formControlName="postal_code" id="postal_code">
            <div *ngIf="(postal_code.touched || submitted) && postal_code.invalid" class="invalid-feedback">
              <div aria-live="polite" id="pc-rq" *ngIf="postal_code.errors.required" translate>Postal Code is required
              </div>
              <div aria-live="polite" *ngIf="postal_code.errors.pattern" translate>Postal Code is invalid</div>
              <div aria-live="polite" *ngIf="postal_code.errors.minlength" translate>Postal Code is too short</div>
              <div aria-live="polite" *ngIf="postal_code.errors.maxlength" translate>Postal Code is too long</div>
            </div>

        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6 col-12">

            <label for="email-cd" translate>Email address</label>
            <input aria-describedby="email-rq" placeholder=" " floatingLabel type="email" emailvalidator class="inputTravel"
                   [ngClass]="{ 'is-invalid': email.invalid && (email.touched || submitted) }" formControlName="email"
                   id="email-cd">
            <div *ngIf="(email.touched || submitted) && email.invalid" class="invalid-feedback">
              <div aria-live="polite" id="email-rq" *ngIf="email.errors.required" translate>Email address is required
              </div>
              <div aria-live="polite" *ngIf="email.errors.emailvalidator" translate>Email format is incorrect.</div>
            </div>

        </div>
        <div class="form-group col-lg-6 col-12 ">
          <div class="input-group">
            <label for="payment-cmp-phone" translate>Phone number</label>
            <div class="w-100" >
                <input formControlName="partial_phone_number" id="payment-cmp-phone" placeholder=" " floatingLabel
                     class="inputTravel" type="text"
                     [ngClass]="{ 'is-invalid': phone_number.invalid && (phone_number.touched || submitted) }">
            </div>
          </div>
          <div *ngIf="(phone_number.touched || submitted) && phone_number.invalid" class="invalid-feedback">
            <div aria-live="polite" id="phone-rq" *ngIf="phone_number.errors.required" translate>Phone number is required</div>
            <div aria-live="polite" id="phone-invalid" *ngIf="phone_number.errors.phoneFormat || phone_number.errors.pattern || phone_number.errors.minlength || phone_number.errors.maxlength" translate>Phone number is invalid</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<modal id="suggested-address-modal" #addressModal>
  <div class="app-modal-header"></div>
  <div class="app-modal-body">
    <h5 class="text-center" translate>Please choose and confirm address</h5>
    <div class="form-group row input-wrap input-radio car-radio address-selection pb-0" role="radiogroup"
         aria-labelledby="drop-mode">
      <div class="col-sm-6 col-12">
        <input type="radio" id="my_address" name="my_address" value="used" [(ngModel)]="addressType">
        <label class="container-radio radio-inline" for="my_address"><span translate>Use entrered address</span></label>
        <div class="address-wrapper font-s mb-3">
          <span class="d-block">{{address.value}}</span>
          <span class="d-block">{{city.value}}<span *ngIf="province.value"> ({{province.value}})</span></span>
          <span class="d-block">{{postal_code.value}}</span>
          <span class="d-block">{{country.value}}</span>
        </div>

      </div>
      <div class="col-sm-6 col-12">
        <input type="radio" id="suggested_address" value="verified" name="suggested_address" [(ngModel)]="addressType">
        <label class="container-radio radio-inline" for="suggested_address"><span translate>Use verified address</span></label>
        <div class="address-wrapper font-s">
          <span class="d-block">{{suggestedAddress.address}}</span>
          <span class="d-block">{{suggestedAddress.city}}<span
            *ngIf="suggestedAddress.state"> ({{suggestedAddress.state}})</span></span>
          <span class="d-block">{{suggestedAddress.postcode}}</span>
          <span class="d-block">{{suggestedAddress.country}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer w-100">
    <div class="row text-center send-itinerary-button">
      <div class="col-12 col-sm-6 send-btn">
        <travel-button (onClick)="updateAddress()"><span translate>Update</span></travel-button>
      </div>
      <div class="col-12 col-sm-6">
        <travel-button [customClass]="'travel-tertiary'" data-dismiss="modal"><span translate>Cancel</span>
        </travel-button>
      </div>
    </div>
  </div>
</modal>
