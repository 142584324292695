import {Component, Input, OnInit} from "@angular/core";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
    selector: 'pts-currency',
    inputs: ['customClass', 'showSymbol','switch'],
    styleUrls: ['./pts-currency.component.scss'],
    templateUrl: 'pts-currency.component.html'
})
export class PtsCurrencyComponent implements OnInit{
    @Input() showSymbol: boolean;
    @Input() switch: boolean = false;
    displayCurrency:string;

    constructor(private authService: AuthenticationService){
    }

    ngOnInit(): void {
        this.displayCurrency = this.authService.user.account_type.display_currency_names.name['en-US'];
        if( this.showSymbol ){
            this.displayCurrency =  this.authService.user.account_type.display_currency_names.symbol['en-US'];
        }

        if( this.switch && this.authService.user.account_type.display_currency_name.length > 6  ){
            this.displayCurrency =  this.authService.user.account_type.display_currency_names.name['en-US'];
        }

    }


}
