import {Component} from "@angular/core";

@Component({
    selector: 'infant-modal',
    inputs: ['id'],
    styleUrls: ['./infant-modal.component.scss'],
    templateUrl: 'infant-modal.component.html'
})
export class InfantModalComponent{
    id: string;

    constructor(){
    }
}
