import {AbstractControl, ValidatorFn} from "@angular/forms";

export class CustomValidators {

    static requiredLength(length: number): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            let value = control.value ? control.value : '';
            return value.toString().replace(/\s/g, '').length != length ? {'requiredLength': {value: control.value}} : null;
        };
    }

    static phoneLength(length: number): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            let count = (control.value.match(/\d/g) || []).length ;
            return count != length ? {'phoneFormat': {value: control.value}} : null;
        };
    }

    static checkBinNumbers(bin_range, medium,type): ValidatorFn{
        let result = null;
        // Valid BIN card numbers
        return (control: AbstractControl): {[key: string]: any} | null => {
            let value = control.value.replace(/\s/g, '');
            let binCheck = bin_range.includes(value.substr(0, 6))
            let lastCheck = value.length == 16 && value.substr(value.length- 4) == medium.masked_medium_number;

            if(type != 'BMOCCZ'){
                result = binCheck && lastCheck
            } else {
                result = binCheck
            }

            return  result ? null : {'invalid_bin': true}

        };
    }
}
