import {Pipe, PipeTransform} from '@angular/core';
import {AuthenticationService} from "../services/authentication.service";
import {TranslateService} from "@ngx-translate/core";

@Pipe ({
    name: 'pointConverter'
})

export class PointConverterPipe implements PipeTransform {
    constructor(private authService: AuthenticationService,
                private translateService: TranslateService){}

    transform(value: number): string {
        return (Math.round(value * this.authService.user.conversion_rate)).toLocaleString(this.translateService.currentLang)
    }
}
