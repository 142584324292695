import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FlightBookService} from "../../app/travel/contract/book/flight-book.service";
import {HotelBookService} from "../../app/hotel/contract/book/hotel-book.service";
import {CarBookService} from "../../app/cars/contract/book/car-book.service";
import {CartService} from "../../services/cart.service";
import {environment} from "../../environments/environment";


@Component({
    selector: 'error-message',
    templateUrl: 'error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})

export class ErrorMessageComponent implements OnInit {

    @Input() type: string = null;
    errors$: any[] = [];
    env = environment
    constructor(
        private flightBookService: FlightBookService,
        private hotelBookingService: HotelBookService,
        private carBookingService: CarBookService,
        private cartService: CartService,
    ) {

    }

    ngOnInit() {

        switch (this.type) {
            case 'flight':
                this.flightBookService.getErrors().subscribe((res) => {
                    if (res) {
                        this.errors$ = res
                    }
                })
                break;
            case 'hotel':
                this.hotelBookingService.getErrors().subscribe((res) => {
                    console.log(res)
                    if (res) {
                        this.errors$ = res
                    }
                })
                break;
            case 'car':
                this.carBookingService.getErrors().subscribe((res) => {
                    if (res) {
                        this.errors$ = res                    }
                })
                break;
            case 'bundle':
                this.cartService.getErrors().subscribe((res) => {
                    if (res) {
                        this.errors$ = res                    }
                })
                break;
            default:
                break
        }
    }
    removeAlert(index){
        this.errors$.splice(index,1)
    }
}
