import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';
@Directive({
    selector: '[floatingLabel]'
})
export class FloatingLabelDirective implements AfterViewInit{
    constructor(private elRef: ElementRef) {
    }
    // THIS WILL BE FIRED IF SOMEONE CHANGES THE INPUT
    @HostListener('keyup', ['$event'])
    inputChanged(event) {
       this.setPlaceHolderShown(event.target.value);
    }
    setPlaceHolderShown(value){
        if (value) {
            this.elRef.nativeElement.classList.add('placeholder-hide')
        } else {
            this.elRef.nativeElement.classList.remove('placeholder-hide')
        }
    }

    ngAfterViewInit(): void {
        this.setPlaceHolderShown(this.elRef.nativeElement.value)
    }
}
