import {enableProdMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';


import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from './app.routing';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from './shared.module';
import {AppComponent} from './app.component';
import {DashComponent} from './dashboard/dashboard.component';
import {FaqComponent} from './dashboard/faq/faq.component';
import {TecComponent} from './dashboard/tec/tec.component';
import {ExpiredComponent} from './dashboard/expired/expired.component';
import {NoopInterceptor} from '../resources/http.interceptor';
import {CustomDateParserFormatter, CustomDatepickerI18n} from '../resources/custom-datepicker';
import {NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../directives/alerts/alerts.service';
import {AuthenticationService} from '../services/authentication.service';
import {AuthResolver} from '../services/resolve/auth.resolver';
import {ItineraryResolver} from '../services/resolve/itinerary.resolver';
import {AuthGuard} from './authentication/authentication.guard';
import {FlightService} from '../services/flight.service';
import {HotelService} from '../services/hotel.service';
import {CarService} from '../services/car.service';
import {FlightBookService} from './travel/contract/book/flight-book.service';
import {CarBookService} from './cars/contract/book/car-book.service';
import {HotelBookService} from './hotel/contract/book/hotel-book.service';
import {ItineraryService} from '../services/itinerary.service';
import {CartService} from '../services/cart.service';
import {StepService} from '../directives/step-component/step.service';
import {Broadcaster} from '../resources/broadcaster';
import {EventService} from '../services/event.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { environment } from '../environments/environment';
import {NgxGoogleAnalyticsModule} from "ngx-google-analytics";

registerLocaleData(localeFr, 'fr', localeFrExtra);

const en_json = require('../assets/i18n/en.json');
const fr_json = require('../assets/i18n/fr.json');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.ga_key),
        SharedModule
    ],
    declarations: [
        AppComponent,
        DashComponent,
        FaqComponent,
        TecComponent,
        ExpiredComponent,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        AlertService,
        AuthenticationService,
        AuthResolver,
        ItineraryResolver,
        AuthGuard,
        FlightService,
        HotelService,
        CarService,
        FlightBookService,
        CarBookService,
        HotelBookService,
        ItineraryService,
        CartService,
        StepService,
        Broadcaster,
        EventService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
