// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // API url
  origin: window['env'].origin,
  environment: window['env'].environment,
  ga_key: window['env'].ga_key,

  showBanner: window['env'].showBanner,
  showFareFamily: window['env'].showFareFamily,
  showCancellation: window['env'].showCancellation,
  showFee: window['env'].showFee,
  showErrorDetail: window['env'].showErrorDetail,
  platform_version: window['env'].platform_version,
  airlineAsset: window['env'].airlineAsset,
  alertMsg: window['env'].alertMsg,

  //BMO
  //podium_url: 'https://podium-bmo-stage-white-label.engagelabs.co/',
  //reference_code: '457e55b9-cd95-42e9-90e4-06c797595b8d',

  //HSBC
  podium_url: window['env'].podium_url,
  reference_code: window['env'].reference_code,

  //Wings
  // window.env.podium_url = 'https://podium-wings-stage-white-label.engagelabs.co';
  // window.env.reference_code = '738b2a7e-2a2b-4868-86d5-2d987c9f3be3';
};
