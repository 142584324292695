<div id="{{id}}"  class="modal cancel-modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close primary-blue"  (click)="clear()" [attr.aria-label]="'Close' | translate"><img width="24" alt="" src="/assets/images/icons/close-granite.svg"></button>
            </div>
            <div class="modal-body">
                <div *ngIf="showForm == 0 ">
                    <div class="medium font-l mb-3" ><span translate>Proceed with cancellation?</span><br><span translate>Once the cancellation is complete it cannot be undone.</span></div>
                    <div ><span translate>Please acknowledge and accept the following terms:</span>
                    <ul class="mt-3" >
                        <li translate>Only the selected itinerary will be cancelled. If multiple itineraries were booked, they will need to be cancelled individually with the respective provider.</li>
                        <li translate>Any applicable refund will be posted to accounts within 5 to 10 business days from the date the cancellation was processed. If a refund is not received in 10 business days, please contact the phone number provided in the cancellation confirmation email.</li>
                        <li translate>Redeemed points for this itinerary will not be returned to your account. As a statement credit was issued to your card, only charges made on the card will be refunded as per the applicable supplier’s terms.</li>
                    </ul>
                    </div>
                    <div class="container-check mt-1 mb-4">
                        <div class="input-wrap input-checkbox ">
                            <input [(ngModel)]="accept_cancel_terms" id="cancel_terms" type="checkbox">
                            <label for="cancel_terms" translate>Accept cancellation terms.</label>
                        </div>
                    </div>
                    <div class="text-right mt-3">
                        <travel-button [customClass]="'travel-secondary min-width'" (click)="manageCancelModal(false)"><span [ngClass]="{'d-none':!isAgent   }"   translate>Back</span><span [ngClass]="{'d-none':isAgent}"   translate>Keep itinerary</span></travel-button>
                        &nbsp;&nbsp;
                        <travel-button [disabled]="!accept_cancel_terms" [ladda]="laddaCancel" [customClass]="'min-width'" (click)="cancelBooking(payment_mode)"><span translate>Yes, cancel itinerary</span></travel-button>
                    </div>
                </div>
                <div id="pay-opt">
                    <div  *ngIf="showForm == 1">
                        <div class="medium font-l mb-3" translate>How would you like to pay the Phone Cancellation Fee?</div>
                        <div translate>A Phone Cancellation Fee will be charged for this cancellation and can be paid by credit card or points. Alternatively, you can cancel the itinerary directly through the travel portal free of charge.</div>
                        <div  class="form-group input-wrap input-radio flight-radio " role="radiogroup" aria-labelledby="cancel-fee">
                            <input [ngClass]="{'d-none':canc_fee_data == undefined}"  type="radio" id="card-fee" value="card-fee" name="paymode" data-toggle="collapse" href="#payment-fee-form" role="button" aria-expanded="false" aria-controls="payment-fee-form"
                                   [(ngModel)]="payment_mode" (ngModelChange)="clearValue()">
                            <label *ngIf="canc_fee_data != undefined"  class="mb-3 container-radio radio-inline d-block" for="card-fee"><span ><span *ngIf="translateService.currentLang == 'fr'">Payer par </span> <span [ngClass]="{'text-lowercase':translateService.currentLang != 'en'}" translate>Credit Card</span> (<price-component [price]="showValue(canc_fee_data[0].is_points,canc_fee_data[0].amount,'cash')" [currency]="'USD'"></price-component>)</span></label>

                            <input [disabled]="isPayableWithPoints()"  [ngClass]="{'d-none':canc_fee_data == undefined}" type="radio" id="points-fee" value="points-fee" name="paymode" data-toggle="collapse" href="#points-selected" role="button" aria-expanded="false" aria-controls="points-selected"
                                    [(ngModel)]="payment_mode" (ngModelChange)="clearValue()">
                            <label *ngIf="canc_fee_data != undefined" class="mb-3 container-radio radio-inline d-block" for="points-fee"><span ><span translate>Pay with Points</span> ({{showValue(canc_fee_data[0].is_points,canc_fee_data[0].amount,'pts')}} <span [ngClass]="{'text-lowercase':translateService.currentLang != 'en'} " >Points</span>)</span></label>
                            <input  type="radio" id="escalated" value="escalated" name="paymode"  role="button" aria-expanded="false" aria-controls="escalated-form"
                                   [(ngModel)]="payment_mode" >
                            <label [ngClass]="{'d-none':!noFee,'d-block':noFee}"  class="container-radio radio-inline " for="escalated">
                                <form id="escalated-form"  class="container"  [formGroup]="escalatedForm" >
                                    <div class="row">
                                        <div style="margin-top: -4px;margin-left: -14px;" [ngClass]="{'col-sm-6': translateService.currentLang == 'en' , 'col-sm-10': translateService.currentLang != 'en' }">
                                            <div class="form-group">
                                                    <select *ngIf="no_fee_data" style="padding: 5px" [attr.disabled]="payment_mode == 'escalated' && no_fee_data != undefined ? null : true"  id="escalated-reason" [attr.aria-label]="'escalated-reason' | translate" formControlName="reason" name="escalated-issue"
                                                            class="selectTravel selectEmpty"  [ngClass]="{ 'is-invalid': (reason.invalid ) && (reason.touched || submitted) && payment_mode == 'escalated' , 'dirty' : reason.value != ''} ">
                                                        <option  value="" selected><span>Escalated Issue – Waive Fee</span></option>
                                                        <option *ngFor="let r of no_fee_data.reasons" [value]="r.id">{{r.reason}}</option>
                                                    </select>
                                                    <div *ngIf="(reason.touched || submitted) && reason.invalid && payment_mode == 'escalated'" class="invalid-feedback">
                                                        <div id="reason-escalated" aria-live="polite" *ngIf="reason.errors.required" ><span translate>Please select a Waive Fee Reason</span></div>
                                                    </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </label>
                        </div>
                    </div>
                    <div  id="payment-fee-form" [ngClass]="{'d-none':showForm != 2, 'd-block':showForm == 2}"  >
                        <div class="card-body">
                            <h5 class="mb-4 light text--lg bundle-heading-section pl-3" translate>Payment Information</h5>
                            <div >
                                <form class="card-form" [formGroup]="cardForm">
                                    <div class="row">
                                        <div class="col-12 col-md-6 form-group">

                                            <label for="card_first_name" translate>First name</label>
                                            <input placeholder=" " floatingLabel aria-describedby="fn-card-rq" type="text" class="inputTravel"
                                                   [ngClass]="{ 'is-invalid': card_first_name.invalid && (card_first_name.touched || submitted) }"
                                                   formControlName="card_first_name" id="card_first_name">
                                            <div *ngIf="(card_first_name.touched || submitted) && card_first_name.invalid" class="invalid-feedback">
                                                <div id="fn-card-rq" aria-live="polite" *ngIf="card_first_name.errors.required" translate>First Name is required</div>
                                                <div aria-live="polite" *ngIf="card_first_name.errors.pattern" translate>First Name must be a string</div>
                                            </div>

                                        </div>
                                        <div class="col-12 col-md-6 form-group">
                                            <label for="card_last_name" translate>Last name</label>
                                            <input placeholder=" " floatingLabel aria-describedby="ln-card-rq" type="text" class="inputTravel"
                                                   [ngClass]="{ 'is-invalid': card_last_name.invalid && (card_last_name.touched || submitted) }"
                                                   formControlName="card_last_name" id="card_last_name">
                                            <div *ngIf="(card_last_name.touched || submitted) && card_last_name.invalid" class="invalid-feedback">
                                                <div id="ln-card-rq" aria-live="polite" *ngIf="card_last_name.errors.required" translate>Last Name is required</div>
                                                <div aria-live="polite" *ngIf="card_last_name.errors.pattern" translate>Last Name must be a string</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-7 col-lg-6 form-group">
                                            <label for="cardNumber" translate>Credit card number</label>
                                            <input placeholder=" " floatingLabel aria-describedby="card-required" [textMask]="{mask: mask, guide: false}"
                                                   class="inputTravel" minlength="16" [type]="card_number.invalid? 'text' : 'password'"
                                                   [ngClass]="{ 'is-invalid': card_number.invalid && (card_number.touched || submitted) }"
                                                   formControlName="card_number" id="cardNumber">
                                            <div *ngIf="(card_number.touched || submitted) && card_number.invalid" class="invalid-feedback">
                                                <div id="card-required" aria-live="polite" *ngIf="card_number.errors.required" translate>Card number is required</div>
                                                <div aria-live="polite" *ngIf="card_number.errors.requiredLength" translate>Card number must be 16 digits</div>
                                                <div aria-live="polite" *ngIf="card_number.errors.invalid_bin" translate>Please enter the HSBC Rewards Credit Card that is associated with your HSBC Rewards account</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-5 col-lg-6 form-group">

                                            <label for="card_type" translate>Credit card type</label>
                                            <select id="card_type" aria-describedby="card-type-required" name="card_type" formControlName="card_type"
                                                    class="w-100 selectTravel selectEmpty"
                                                    [ngClass]="{ 'is-invalid': card_type.invalid && (card_type.touched || submitted) , 'dirty' : card_type.value != '' }">
                                                <option value=""></option>
                                                <option *ngFor="let num of cardType" [value]="num.id">
                                                    {{num.name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(card_type.touched || submitted) && card_type.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="card-type-required" *ngIf="card_type.errors.required" translate>Card type is required</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-4 form-group">

                                            <span class="sr-only" id="card_month_label" translate>Month</span>
                                            <label id="expire_date_label" for="card_month" translate>Expiry Month</label>
                                            <select aria-describedby="exp-req" id="card_month" name="card_month" aria-labelledby="expire_date_label card_month_label"
                                                    [ngClass]="{ 'is-invalid': (expiry_month.invalid || cardForm.hasError('expiry')) && (expiry_month.touched || submitted) , 'dirty' : expiry_month.value != '' }"
                                                    formControlName="card_month" class="selectTravel selectEmpty">
                                                <option value="" selected>Select month</option>
                                                <option *ngFor="let num of cardMonths" [value]="num">
                                                    {{num}}
                                                </option>
                                            </select>
                                            <div *ngIf="(expiry_month.touched || submitted) && (expiry_month.invalid || cardForm.hasError('expiry'))"
                                                 class="invalid-feedback">
                                                <div id="exp-req" *ngIf="cardForm.hasError('expiry') && (submitted || expiry_month.touched)" translate>Invalid expiry month</div>
                                                <div id="expiry-mm-req" *ngIf="expiry_month.errors.required" translate>Expiry month is required</div>
                                            </div>

                                        </div>
                                        <div class="col-12 col-md-4 form-group">
                                            <label id="card_year_label" for="card_year" translate>Expiry Year</label>
                                            <select id="card_year" name="card_year" aria-labelledby="expire_date_label card_year_label"
                                                    [ngClass]="{ 'is-invalid': expiry_year.invalid && (expiry_year.touched || submitted) , 'dirty' : expiry_year.value != '' }"
                                                    formControlName="card_year" class="selectTravel selectEmpty">
                                                <option  value="" selected>Select year</option>
                                                <option *ngFor="let num of cardYear" [value]="num">
                                                    {{num}}
                                                </option>
                                            </select>
                                            <div *ngIf="(expiry_year.touched || submitted) && expiry_year.invalid" class="invalid-feedback">
                                                <div id="expiry-yy-req" *ngIf="expiry_year.errors.required" translate>Expiry year is required</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 form-group">
                                            <ng-template  #tipCvv>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p >{{cvvEn}}</p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <label class="no-wrap" for="cvv">CVV &nbsp;</label>
                                            <span role="button" tabindex="0" triggers="manual" #cvt="ngbTooltip" (blur)="cvt.close();cvvTooltip =false" (focus)="cvt.open()" (click)="cvt.open();cvvTooltip= !cvvTooltip"  [ngbTooltip]="tipCvv">
                                             <img alt="help" width="17" [src]=" !cvvTooltip ? tooltipOpened : tooltipClosed ">
                                              </span>
                                            <input placeholder="Enter CVV number" type="password" aria-describedby="cvv-required" [textMask]="{mask: cvvMask, guide: false}"
                                                   class="inputTravel" [ngClass]="{ 'is-invalid': card_cvc.invalid && (card_cvc.touched || submitted) }"
                                                   formControlName="card_cvc" id="cvv">
                                            <div *ngIf="(card_cvc.touched || submitted) && card_cvc.invalid" class="invalid-feedback">
                                                <div id="cvv-required" aria-live="polite" *ngIf="card_cvc.errors.required" translate>CVV is required</div>
                                                <div aria-live="polite" *ngIf="card_cvc.errors.requiredLength" translate>CVV must be 3 digits</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <h5 class="mt-4 mb-4 light text--lg bundle-heading-section pl-3" translate>Billing </h5>
                            <div >
                                <form class="billing-form" [formGroup]="billingForm">
                                    <div class="row">
                                        <div class="form-group col-12">

                                            <label for="street_address" translate>Address</label>
                                            <input aria-describedby="add-rq"   type="text" class="inputTravel"
                                                   [ngClass]="{ 'is-invalid': address.invalid && (address.touched || submitted) }"
                                                   formControlName="address" id="street_address">
                                            <div *ngIf="(address.touched || submitted) && address.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="add-rq" *ngIf="address.errors.required" translate>Street address is required</div>
                                                <div aria-live="polite" *ngIf="address.errors.pattern" translate>Street address is invalid</div>
                                                <div aria-live="polite" *ngIf="address.errors.maxlength" translate>Street address is too long</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-12 col-md-6">
                                            <label for="country-select" translate>Country</label>
                                            <select aria-describedby="country-rq" id="country-select" name="country" (change)="changeProvince($event)"
                                                    [ngClass]="{ 'is-invalid': country.invalid && (country.touched || submitted) , 'dirty' : country.value != '' }"
                                                    formControlName="country" class="selectTravel selectEmpty">
                                                <option selected value="">Select country</option>
                                                <option *ngFor="let country of country_list" [value]="country.code">
                                                    {{country.country}}
                                                </option>
                                            </select>
                                            <div *ngIf="(country.touched || submitted) && country.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="country-rq" *ngIf="country.errors.required" translate>Country is required</div>
                                            </div>
                                        </div>
                                        <div *ngIf="country.value == 'CA' || country.value == 'US'"  class="form-group col-12 col-md-6">

                                            <label for="province-select"  translate>State</label>
                                            <select aria-describedby="province-rq" id="province-select" name="province"
                                                    [ngClass]="{ 'is-invalid': province.invalid && (province.touched || submitted) , 'dirty' : province.value != '' }"
                                                    formControlName="province" class="selectTravel selectEmpty">
                                                <option value="" selected>Select state</option>
                                                <option *ngFor="let province of province_list" [value]="province.id">
                                                    {{province.label}}
                                                </option>
                                            </select>
                                            <div *ngIf="(province.touched || submitted) && province.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="province-rq" *ngIf="province.errors.required" translate>State is required</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-12 col-md-6">
                                            <label for="city" translate>City</label>
                                            <input aria-describedby="city-rq" placeholder=" " floatingLabel type="text" class="inputTravel"
                                                   [ngClass]="{ 'is-invalid': city.invalid && (city.touched || submitted) }" formControlName="city"
                                                   id="city">
                                            <div *ngIf="(city.touched || submitted) && city.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="city-rq" *ngIf="city.errors.required" translate>City is required</div>
                                                <div aria-live="polite" *ngIf="city.errors.pattern" translate>City is invalid</div>
                                                <div aria-live="polite" *ngIf="city.errors.maxlength" translate>City address is too long</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12 col-md-6">

                                            <label for="postal_code"  translate>Zip code</label>
                                            <input aria-describedby="pc-rq" placeholder=" " floatingLabel type="text" class="inputTravel"
                                                   [ngClass]="{ 'is-invalid': postal_code.invalid && (postal_code.touched || submitted) }"
                                                   formControlName="postal_code" id="postal_code">
                                            <div *ngIf="(postal_code.touched || submitted) && postal_code.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="pc-rq" *ngIf="postal_code.errors.required" translate>Postal Code is required
                                                </div>
                                                <div aria-live="polite" *ngIf="postal_code.errors.pattern" translate>Postal Code is invalid</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-lg-6 col-12">

                                            <label for="email-cd" translate>Email address</label>
                                            <input aria-describedby="email-rq" placeholder=" " floatingLabel type="email" emailvalidator class="inputTravel"
                                                   [ngClass]="{ 'is-invalid': email.invalid && (email.touched || submitted) }" formControlName="emailCanc"
                                                   id="email-cd">
                                            <div *ngIf="(email.touched || submitted) && email.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="email-rq" *ngIf="email.errors.required" translate>Email address is required
                                                </div>
                                                <div aria-live="polite" *ngIf="email.errors.emailvalidator" translate>Email format is incorrect.</div>
                                            </div>

                                        </div>
                                        <div class="form-group col-lg-6 col-12 ">
                                            <div class="input-group">
                                                <label for="payment-cmp-phone" translate>Phone number</label>
                                                <div class="w-100" >
                                                    <input formControlName="partial_phone_number" id="payment-cmp-phone" placeholder=" " floatingLabel
                                                           class="inputTravel" type="text"
                                                           [ngClass]="{ 'is-invalid': phone_number.invalid && (phone_number.touched || submitted) }">
                                                </div>
                                            </div>
                                            <div *ngIf="(phone_number.touched || submitted) && phone_number.invalid" class="invalid-feedback">
                                                <div aria-live="polite" id="phone-rq" *ngIf="phone_number.errors.required" translate>Phone number is required</div>
                                                <div aria-live="polite" id="phone-invalid" *ngIf="phone_number.errors.phoneFormat || phone_number.errors.pattern || phone_number.errors.minlength || phone_number.errors.maxlength" translate>Phone number is invalid</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showForm != 0" class="text-right">
                        <travel-button [customClass]="'travel-secondary min-width'" (click)=" manageCancelModal(false)"><span [ngClass]="{'d-none':showForm <2   }"   translate>Back</span><span [ngClass]="{'d-none':showForm != 1}"   translate>Keep itinerary</span></travel-button>
                        &nbsp;&nbsp;&nbsp;
                        <travel-button [disabled]="checkValidity()"  [customClass]="'min-width'" (click)="manageNext()"><span>Next</span></travel-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
