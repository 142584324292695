import {Component, Input, ViewChild} from "@angular/core";
import {IBaggageAllowance} from "../../interfaces/flight.interface";
import {ModalComponent} from "../modal/modal.component";

const $ = require("jquery");

@Component({
    selector: 'baggage-fees-modal',
    templateUrl: 'baggage-fees-modal.component.html'
})

export class BaggageFeesModalComponent {

    @Input() baggage_info: IBaggageAllowance[]= [];
    @ViewChild('baggage_fees_modal') baggage_fees_modal: ModalComponent;



    constructor() {


    }
    openBaggageModal(index, baggage_allowances){
        this.baggage_info = baggage_allowances
        this.baggage_fees_modal.show(null,$('#baggage-allowances-link-'+index));
    }

}
