<!-- PLATFORM VERSION 2 -->
<span class="add-on" *ngIf="baggage_allowances?.length">
  <img aria-hidden="true" alt="" src="../../assets/images/bag.svg">
  <a id="baggage-allowances-link-{{index}}" role="button" tabindex="0"
     (keydown.enter)="openBaggageModal(index,baggage_allowances)"
     (click)="openBaggageModal(index,baggage_allowances)">
    <u class="regular" translate>Baggage Fees</u>
    <!--                     <img alt="Open in new tab" class="new-modal-window-icon" src="../../../assets/images/new_window.svg">-->
  </a>
</span>
<!-- PLATFORM VERSION 1 -->
<span  class="add-on" *ngIf="baggage_carrier?.available === 'true' || baggage_carrier?.popup_url">
  <img aria-hidden="true" alt="" src="../../assets/images/bag.svg">
  <a rel="noopener noreferrer" [href]="baggage_carrier?.popup_url ? baggage_carrier?.popup_url : 'https://www.priceline.com/help-page/submenu/241/261/baggage-fees-and-carry-on-limits'" target="_blank">
     <u class="regular"translate>Baggage Fees</u>
     <img alt="Open in new tab" class="new-modal-window-icon" src="../../assets/images/new_window.svg">
  </a>
</span>
