<div class="agent-fees-container" [ngClass]="{'hidden': !showFee || !loginAs || !isPresent || noFeeForThisAgent}">
    <div id="title-container" class="logged-in-as-banner top text-center medium py-2" [translateParams]="{typeText: typeText}" translate>Agent [typeText] Booking Fee</div>
    <div style="margin-top: 20px" class="form-group input-wrap input-radio pb-0 pt-2">
        <input type="radio" id="{{agentFeePriceId}}" [value]="true" name="{{feeId}}" [(ngModel)]="feeChecked" (change)="checkedChanged()">
        <label class="container-radio radio-inline pr-3" for="{{agentFeePriceId}}">+ {{fees.points.toLocaleString(this.translateService.currentLang)}} <span translate>pts</span> (<price-component [symbol]="fees.currencySymbol" [price]="fees.price.toString()" [currency]="fees.currencyCode"></price-component>) <span [translateParams]="{typeText: typeText}" translate>HSBC Agent [typeText] Booking Fee</span></label>
    </div>
    <div *ngIf="reasonsList.length > 0" style="display: flex; width: 100%">
        <div class="form-group input-wrap input-radio pb-0 reason-fee-container">
            <input type="radio" id="{{agentFeeNoId}}" [value]="false" name="{{feeId}}"
                   [(ngModel)]="feeChecked" (change)="checkedChanged()">
            <label class="container-radio radio-inline pr-3" for="{{agentFeeNoId}}"><span translate></span></label>
        </div>
        <div class="form-group no-fees-container">
            <div class="floating-label">
                <label for="{{noFeeReasonId}}"><span [translateParams]="{typeText: typeText}" translate>No [typeText] Booking Fee Reason</span></label>
                <select disabled id="{{noFeeReasonId}}" class="selectTravel minimum-width" [(ngModel)]="reason" (change)='submit()'>
                    <option *ngFor="let reasonItem of reasonsList" [value]="reasonItem.id">{{reasonItem.reason}}</option>
                </select>
                <span *ngIf="noFeeInvalid" class="invalid-feedback" aria-live="polite"
                      id="{{noFeeReasonReqId}}" translate>Please select a reason</span>
            </div>
        </div>
    </div>
</div>
