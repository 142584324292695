import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable()
export class StepService {

    private _currentStep: number = 0;

    constructor(private router: Router,
                private route:ActivatedRoute,
                private http: HttpClient) {
        //Add this check to preset the value to avoid stupid ngif value changed error
        if(router.url.includes('contract/view')){
            this.currentStep = 0;
        }
        if(router.url.includes('travel/contract/view')){
            this.currentStep = 3;
        }


    }

    get currentStep(): number {
        return this._currentStep;
    }

    set currentStep(value: number) {
        this._currentStep = value;
    }

}
