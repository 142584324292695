<button class="btn btn-primary travel-button"
        [ngClass]="customClass"
        (click)="fireClick()"
        tabindex="0"
        [type]="type"
        [attr.aria-describedby]="ariadescribedby || undefined"
        [attr.aria-invalid]="ariainvalid || undefined"
        [attr.aria-labelledby]="arialabelledby || undefined"
        [attr.disabled]="(disabled || ladda) || undefined">
  <span [hidden]="ladda" class="button-text"><ng-content></ng-content></span>
  <span [hidden]="!ladda" class="spinner-border spinner-border-sm" role="status" [attr.aria-hidden]="ladda"> </span>
</button>
