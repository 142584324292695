import {Component, ViewEncapsulation} from '@angular/core';
import {CommonService} from "../../../services/common.service";

@Component({
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FaqComponent{
    faq_type;
    faqData = null;
    faqLoading = false;
    faqList = [{id:'hotel',label:'Hotel Reservations'},{id:'car',label:'Car Rentals'},{id:'flight',label:'Flights'}];

    constructor(private commonService: CommonService) {
        this.faq_type = this.faqList[0];
        // this.getFaq();
    }

    getFaq(){
        this.faqLoading = true;
        this.commonService.getFaq(this.faq_type.id).subscribe((resp)=>{
            this.faqLoading = false;
            this.faqData = resp.data;
        })
    }

    parseText(text){
        let result = text;
        if(result){
            result = result.replace(/[[\]]/g,'<br>')
        }

        return result
    }
}
