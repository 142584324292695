import {Component, OnInit, ViewEncapsulation, Input, AfterViewInit} from "@angular/core";
import {AuthenticationService} from "../../services/authentication.service";
import {IBookingFee} from "../../interfaces/view.interface";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "../../services/event.service";
import utils, { eBookingType } from "../../resources/utils";
import {IAdditionalFeeReason} from "../../interfaces/admim-user.interface";

@Component({
    selector: 'agent-fees',
    templateUrl: 'agent-fees.html',
    styleUrls: ['./agent-fees.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgentFees implements OnInit, AfterViewInit {
    @Input() bookingType: eBookingType = eBookingType.none;
    @Input() reason: string = '';
    @Input() isPresent: boolean = true;

    fees: IBookingFee = {price: 0, points:0, currencyCode: 'USD', currencySymbol: '$'};
    reasonsList: IAdditionalFeeReason[] = [];

    loginAs: boolean = false;
    showFee: boolean = false
    typeText: string = '';

    noFeeInvalid: boolean = false;
    feeChecked: boolean = true;
    noFeeForThisAgent: boolean = true;

    feeId = 'fee-';
    agentFeePriceId = 'agent-fee-price-';
    agentFeeNoId = 'agent-fee-no-';
    noFeeReasonId = 'no-fee-reason-';
    noFeeReasonReqId = 'noFeeReason-req';

    constructor(private authService: AuthenticationService,
                public translateService: TranslateService,
                private eventService: EventService) {
        this.loginAs = authService.checkLoginAs();
        this.showFee = authService.showFee();
    }

    ngOnInit() {
        if (this.loginAs) {
            switch (this.bookingType) {
                case eBookingType.flight:
                    this.typeText = this.translateService.instant('Flight');
                    break;
                case eBookingType.car:
                    this.typeText = this.translateService.instant('Car');
                    break;
                case eBookingType.hotel:
                    this.typeText = this.translateService.instant('Hotel');
                    break;
                default:
                    break;
            }

            this.feeId += this.typeText;
            this.agentFeePriceId += this.typeText;
            this.agentFeeNoId += this.typeText;
            this.noFeeReasonId += this.typeText;
            this.noFeeReasonReqId += this.typeText;

            this.loadBookingFeeAndReasons();
        }
    }

    ngAfterViewInit() {
        setTimeout(()=>{
            this.initGraphic();
        }, 100);
    }

    private initGraphic() {
        if (this.reason != '') {
            let el = <HTMLSelectElement>document.getElementById(this.noFeeReasonId);
            el.value = this.reason;

            this.feeChecked = false;
            this.checkedChanged();
        }
    }

    checkedChanged() {
        let el = <HTMLSelectElement>document.getElementById(this.noFeeReasonId);
        if (this.feeChecked) {
            el.value = '';
            this.noFeeInvalid = false;
            this.reason = '';
        }
        el.disabled = this.feeChecked;

        let event: IAgentFeeEvent = {bookingType: this.bookingType, feeEnabled: this.feeChecked};
        this.eventService.emit({name:'agent-fee', value: event});
    }

    submit(): boolean {
        if (!this.feeChecked) {
            let el = <HTMLSelectElement>document.getElementById(this.noFeeReasonId);
            this.noFeeInvalid = el.value == '';
        }
        else {
            this.noFeeInvalid = false;
        }

        return !this.noFeeInvalid;
    }

    private loadBookingFeeAndReasons() {
        if (this.authService.isAdmin() && this.authService.additionalFees.length > 0) {
            this.noFeeForThisAgent = this.authService.additionalFees.length == 0;
            if (!this.noFeeForThisAgent) {
                this.fees = utils.getBookingFee(this.authService.additionalFees, this.bookingType, this.authService.user.conversion_rate);
                this.noFeeForThisAgent = this.fees.points == 0;
                this.reasonsList = utils.getBookingFeeReason(this.authService.additionalFees);
            }
        }
    }
}

export interface IAgentFeeEvent {
    bookingType: eBookingType,
    feeEnabled: boolean
}
