import {Component, ViewEncapsulation} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
    templateUrl: './expired.component.html',
    styleUrls: ['./expired.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ExpiredComponent{
    constructor(private authService:AuthenticationService) {}

    goTo(url){
        this.authService.redirectToPodium(url,'_self', false);
    }

}
