import {Injectable} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";

export enum Events{
    'showMap',
    'successMessage',
    'errorMessage'
    // other events here
}

export class EmitEvent {
    name: any;
    value: any;
}

@Injectable()
export class EventService {

    private subject$ = new Subject()

    emit(event: EmitEvent) {
        this.subject$.next(event);

    }

    on(event: string, action: any): Subscription {
        return this.subject$.pipe(
            filter((e: EmitEvent) => e.name == event),
            map((e: EmitEvent) => e.value)).subscribe(action);
    }

}