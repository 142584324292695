import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges, OnDestroy, OnInit, QueryList,
    SimpleChanges,
    ViewChild, ViewChildren, ViewEncapsulation
} from "@angular/core";

import {AuthenticationService} from "../../services/authentication.service";
import {FlightBookService} from "../../app/travel/contract/book/flight-book.service";
import {Broadcaster} from "../../resources/broadcaster";
import {CarBookService} from "../../app/cars/contract/book/car-book.service";
import {HotelBookService} from "../../app/hotel/contract/book/hotel-book.service";
import {CarService} from "../../services/car.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AlertService} from "../alerts/alerts.service";
import * as _ from 'lodash-es'
import {ModalComponent} from "../modal/modal.component";
import {CartService} from "../../services/cart.service";
import {FlightService} from "../../services/flight.service";
import {IFlightSearch} from "../../interfaces/flight.interface";
import {TranslateService} from "@ngx-translate/core";
const $ = require("jquery");

@Component({
    selector: 'summary-component',
    templateUrl: 'summary.component.html',
    styleUrls: ['./summary.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SummaryComponent implements  OnInit , OnDestroy , OnChanges  {
    // COMMON-STUFF
    private visaIcon = "/assets/images/visa-icon.png";
    private mastercardIcon = "/assets/images/mastercard-icon.svg";
    private contract: any;
    contractFlight: any = null;
    contractHotel: any = null;
    contractCar: any = null;
    conversion_rate:any;
    private topUpAmount: number;
    showTopUp: boolean = false;
    private showPayment: boolean = false;
    private booking_info:any;
    totalFlight:number = 0;
    totalHotel:number = 0;
    totalCar:number = 0;
    private laddaBook: boolean = false;
    private addInsurance: any;
    private info: any = null;
    size:any;
    private initialized: boolean = false;
    private currentFlightList: IFlightSearch;
    tooltipCar:boolean=false;
    tooltipFlight:boolean=false;
    tooltipHotel:boolean=false;
    tooltipBanner:boolean=false;
    tooltipOpen= '/assets/images/icons/icons-help.svg';
    tooltipClose= '/assets/images/icons/iconsHelpActive.svg';
    @Input() type: any = [];
    @Input() contracts: any = [];
    @Input() carContract;
    @Input() travelInsurance;

    //FLIGHT-STUFF
    private fare_rules = null;
    @ViewChild('fareRulesModal') fareRulesModal: ModalComponent;
    @ViewChild('resortFee') resortFee: ModalComponent;
    @ViewChild('allInfoModal') allInfoModal: ModalComponent;
    @ViewChild('allTaxesModal') allTaxesModal: ModalComponent;
    @ViewChild('baggageModal') baggageModal: ModalComponent;
    @ViewChildren('fare') set fareRulesText(components: QueryList<ElementRef>) {
        if (components && components.length && !this.fare_rules) {
            this.fare_rules = components;
            components.forEach((t) => {
                let spanText = $(t.nativeElement).text();
                let spanArr = _.split(spanText, '#fare_rules_START#')
                if(spanArr.length > 1){
                    let spanA = _.split(spanArr[1], '#fare_rules_END#')
                    $(t.nativeElement).text('');
                    let firstSpan = $(`<span>${spanArr[0]}</span>`);
                    $(t.nativeElement).append(firstSpan);
                    let a = $(`<a class="fare-rules-link">${spanA[0]}</a>`).on('click', () => {
                        this.openModal();
                    })
                    $(t.nativeElement).append(a);
                    let lastSpan = $(`<span>${spanA[1]}</span>`);
                    $(t.nativeElement).append(lastSpan);
                }
            })

        }
    }

    //CAR-STUFF
    @ViewChild('contract_info_modal') contract_info_modal: ElementRef;
    @ViewChild('policy_modal') policy_modal: ElementRef;
    @ViewChildren('info') set informationText(components: QueryList<ElementRef>) {
        if (components && components.length && !this.info) {
            this.info = components;
            let values = [
                {start: '#START_ANCHOR_PARTNERPAYMENTOPTIONS#', end: '#END_ANCHOR_PARTNERPAYMENTOPTIONS#', click: true},
                {start: '#START_ANCHOR_RENTALPOLICYANDRULES#', end: '#END_ANCHOR_RENTALPOLICYANDRULES#', click:true},
                {start: '#START_ANCHOR_TERMSANDCONDITIONS#', end: '#END_ANCHOR_TERMSANDCONDITIONS#', link: '#/tec'},
                {
                    start: '#START_ANCHOR_PRIVACYPOLICY#',
                    end: '#END_ANCHOR_PRIVACYPOLICY#',
                    link: 'https://www.engagepeople.com/privacy/"',
                    target: '_blank'
                }];

            _.forEach(values, (separator) => {
                components.forEach((t) => {
                    let spanText = $(t.nativeElement).html();
                    let spanArr = _.split(spanText, separator.start);
                    if (spanArr.length > 1) {
                        let spanA = _.split(spanArr[1], separator.end);
                        $(t.nativeElement).text('');
                        let firstSpan = $(`<span>${spanArr[0]}</span>`);
                        $(t.nativeElement).append(firstSpan);
                        let a = ""
                        if (separator.link) {
                            a = $(`<a class="" href="${separator.link}" target="${separator.target ? separator.target : '_self'}">${spanA[0]}</a>`)
                        }
                        if(separator.click) {
                            a = $(`<a class="modal-link">${spanA[0]}</a>`).click(()=>{
                                this.openPolicyModal()
                            });
                        }
                        $(t.nativeElement).append(a);
                        let lastSpan = $(`<span>${spanA[1]}</span>`);
                        $(t.nativeElement).append(lastSpan);
                    }
                })
            });
        }
    }

    //HOTEL-STUFF
    @ViewChild('hotelInfoModal') hotelInfoModal: ModalComponent;
    @ViewChild('hotelTaxAndFees') hotelTaxAndFees: ModalComponent;


    constructor(private carService: CarService,
                private route: ActivatedRoute,
                public bookingCarService: CarBookService,
                private hotelBookService:HotelBookService,
                private elementRef:ElementRef,
                private bookingFlightService:FlightBookService,
                private authService: AuthenticationService,
                private alertService: AlertService,
                private broadcaster: Broadcaster,
                private flightService: FlightService,
                private cartService:CartService,
                public translateService: TranslateService,
                private router: Router) {

        this.conversion_rate = this.authService.user.conversion_rate;

        router.events.subscribe((val:any) => {
            if(val instanceof NavigationEnd){
                if(val.url.includes('book')){
                    this.showTopUp = true;
                }
            }
        });

    }


    ngOnInit(): void {
        this.broadcaster.on<string>('show-book')
            .subscribe((data: any) => {
                setTimeout(()=>{
                    this.showTopUp = data;
                },100)
            });
        //Case when there are not bundle
        switch (this.type[0]) {
            case 'car':
                this.booking_info = this.bookingCarService.bookData;
                this.topUpAmount = this.bookingCarService.selectedPoints;
                if (this.booking_info) {
                    this.bookingCarService.selectedPoints = this.booking_info.points
                }
                break;
            case 'hotel':
                this.booking_info = this.hotelBookService.bookData;
                this.topUpAmount = this.hotelBookService.selectedPoints;
                break;
            case 'flight':
                this.booking_info = this.bookingFlightService.bookData;
                this.topUpAmount = this.bookingFlightService.selectedPoints;
                break;
        }
        //If there are selectedPoints show the topup for selected points
        if(this.topUpAmount || this.cartService.selectedPoints){
            this.showTopUp = true
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.contracts){
            this.contracts = changes.contracts.currentValue;
        }
        if(changes.carContract){
            //If the price change update the car contract in the array
            let carContractIndex = _.findIndex(this.type,(o)=>{return o == 'car'});
            this.contracts[carContractIndex] = changes.carContract.currentValue;
        }
        this.assignContract()
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkPageSize()
    }

    openFeeModal(){
        this.resortFee.show(null, $('#resort-fee-open'));
    }

    checkPageSize(){
        return this.size = window.innerWidth;
    }

    //COMMON-FUNCTION
    assignContract(){
        //Normal flow only 1 item
        if(this.type.length == 1 ){
            switch (this.type[0]) {
                case 'flight':
                    this.contractFlight = this.contracts;
                    break;
                case 'hotel':
                    this.contractHotel = this.contracts;
                    break;
                case 'car':
                    this.contractCar = this.contracts;
            }
        } else {
            //Bundle flow
            _.forEach(this.type, (item,index)=>{

                switch (item) {
                    case 'flight':
                        this.contractFlight = this.contracts[index];
                        this.totalFlight = this.contractFlight.price_details.display_total_fare;
                        break;
                    case 'hotel':
                        this.contractHotel = this.contracts[index];
                        this.totalHotel = this.contractHotel.room_data.rate_data[0].price_details.display_total;
                        break;
                    case 'car':
                        this.contractCar = this.contracts[index];
                        this.totalCar =  this.bookingCarService.bookInsurance != 0 ? Number(this.parseNumber(this.contractCar.pricing.display_total) + this.bookingCarService.bookInsurance) : this.parseNumber(this.contractCar.pricing.display_total);
                }
            });
        }
    }

    displayPrice() {
        let result:number = 0;
        if (this.showTopUp) {
            //Selected points to spend
            if(this.type.length == 1 ){
                switch (this.type[0]) {
                    case 'car':
                        result = this.bookingCarService.selectedPoints;
                        break;
                    case 'hotel':
                        result = this.hotelBookService.selectedPoints;
                        break;
                    case 'flight':
                        result = this.bookingFlightService.selectedPoints;
                        break;
                }
            }else{
                result = this.cartService.selectedPoints;
            }

        } else {
            //Total value spent
            _.forEach(this.type, (item,index)=>{
                switch (item){
                    case 'car':
                        if (this.bookingCarService.bookInsurance != 0) {
                            result += Number((this.parseNumber(this.contractCar.pricing.display_total) + this.bookingCarService.bookInsurance).toFixed(2));
                        } else {
                            result += Number((this.parseNumber(this.contractCar.pricing.display_total)).toFixed(2));
                        }
                        break;
                    case 'hotel':
                        result += Number(this.contractHotel.room_data.rate_data[0].price_details.display_total);
                        break;
                    case 'flight':
                        result += Number(this.contractFlight.price_details.display_total_fare);
                        break;
                }
            });
        }

        return result;
    }

    displayCashAmount() {
        if (this.type.length == 1 ) {
            switch ( this.type[0] ) {
                case 'car':
                    let total = this.parseNumber(this.contractCar.pricing.display_total) + this.bookingCarService.bookInsurance;
                    return Number((((total * this.conversion_rate) - this.bookingCarService.selectedPoints) / Number(this.conversion_rate))).toFixed(2)
                case 'hotel':
                    return Number(((this.contractHotel.room_data.rate_data[0].price_details.display_total * this.conversion_rate) - this.hotelBookService.selectedPoints).toFixed(2)) / Number(this.conversion_rate);
                case 'flight':
                    return Number(((this.contractFlight.price_details.display_total_fare * this.conversion_rate) - this.bookingFlightService.selectedPoints).toFixed(2)) / Number(this.conversion_rate);
            }
        } else{

            let totalPrice = 0;
            _.forEach(this.type,(item)=>{
                switch ( item ) {
                    case 'car':
                        totalPrice += this.parseNumber(this.contractCar.pricing.display_total) + this.bookingCarService.bookInsurance;
                        break;
                    case 'hotel':
                        totalPrice += this.contractHotel.room_data.rate_data[0].price_details.display_total;
                        break;
                    case 'flight':
                        totalPrice += this.contractFlight.price_details.display_total_fare;
                        break;
                }
            });

            return Number((Number(((totalPrice * this.conversion_rate) - this.cartService.selectedPoints))/ this.conversion_rate).toFixed(2));
        }
    }

    changePaymentInfo(){
        if(this.type.length == 1 ){
            switch (this.type[0]) {
                case 'flight':
                    this.router.navigate(['/travel/contract/passenger'], { queryParams: { change: 'payment' } });
                    break;
                case 'hotel':
                    this.contractHotel = this.contracts;
                    break;
                case 'car':
                    this.contractCar = this.contracts;
            }
        }
    }

    // CAR FUNCTION
    openPolicyModal(){
        $(this.contract_info_modal.nativeElement).modal('hide');
        $(this.contract_info_modal.nativeElement).on('hidden.bs.modal',  (e)=> {
            $(this.policy_modal.nativeElement).modal('show');
        })
    }

    getTaxesAndFeeTooltip() {

        let result = '';
        _.forEach(this.contractCar.pricing.taxes_and_fees.breakdown_data, (item) => {
            result += item.title + ': ' + item.display_price + '<br>';
        });

        return result
    }

    parseNumber(number) {
        return parseFloat(number.replace(/,/g, ''))
    }

    differenceBetweenToDate() {

        let perDay = 1000 * 60 * 60 * 24;

        if (this.contractCar.dropoff_date) {

            let d1 = new Date(this.contractCar.pickup_date);
            d1.setHours(parseInt(this.contractCar.pickup_time.split(':')[0]), parseInt(this.contractCar.pickup_time.split(':')[1]), 0, 0);
            let d2 = new Date(this.contractCar.dropoff_date);
            d2.setHours(parseInt(this.contractCar.dropoff_time.split(':')[0]), parseInt(this.contractCar.dropoff_time.split(':')[1]), 0, 0);

            return Math.ceil((d2.getTime() - d1.getTime()) / perDay);

        } else {
            return null;
        }
    }

    // FLIGHT FUNCTION

    openTaxesModal(){
        this.allTaxesModal.show(null, $('#flight-taxes-modal'));
    }
    openModal(){
        this.fareRulesModal.show();
    }
    openBaggeInfoModal(){
        this.baggageModal.show(null,$('#baggage-modal'))
    }

    openFlightInfoModal(){
        this.allInfoModal.show()
    }

    //HOTEL-FUNCTION
    openHotelInfoModal(){
        this.hotelInfoModal.show(null,$('#hotel-fee-link'))
    }

    openTaxAndFee(){
        this.hotelTaxAndFees.show(null, $('#hotel-tax-link'))
    }

    //CAR-FUNCTION
    openCarInfoModal(){
        $(this.contract_info_modal.nativeElement).modal('show');
    }

    ngOnDestroy(): void {
        this.showTopUp = false;

        switch (this.type) {
            case 'car':
                if (this.contract_info_modal){
                    $(this.contract_info_modal.nativeElement).modal('hide');
                    $(this.policy_modal.nativeElement).modal('hide');
                }
                break;
            case 'hotel':
                break;
            case 'flight':
                break;
        }
    }
}
