import {Component, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
    selector: 'navigation-tab-component',
    templateUrl: 'navigation-tab.component.html',
    styleUrls: ['./navigation-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NavigationTabComponent{

    env = environment;
    constructor(private router: Router) {
    }

    navigateTo(url: string) {
        this.router.navigate([url]).then();
    }
}
