import * as _ from 'lodash-es'
import {IAdditionalFee} from "../interfaces/admim-user.interface";
import {IBookingFee} from "../interfaces/view.interface";
export default class Utils {
    static fromDateToNgb(date: Date) {
        return {day: date.getDate(), month: date.getMonth() +1, year: date.getFullYear()}
    }
    static fromNgbDateToString(date: any) {

        return date ?  date.year + '-' + date.month.toString().padStart(2, '0') + '-' + date.day.toString().padStart(2, '0') : ''
    }

    static generateTimes(interval: number) {
        let d = new Date(); //get a date object
        d.setHours(0,0,0,0); //reassign it to today's midnight


        let date = d.getDate();
        let timeArr = [];
        while ( date == d.getDate() )
        {
            let hours = d.getHours().toString();
            let minutes = d.getMinutes().toString();
            // hours = hours == '0' ? '12': hours; //if it is 0, then make it 12
            let ampm = parseInt(hours) >= 12 ? "pm": "am";
            hours =  parseInt(hours) >= 12 ? ( parseInt(hours) - 12).toString(): hours; //if more than 12, reduce 12 and set am/pm flag
            hours = ( "0" + hours ).slice(-2); //pad with 0
            minutes = ( "0" + d.getMinutes() ).slice(-2); //pad with 0

            timeArr.push( hours + ":" + minutes + " " + ampm );
            d.setMinutes( d.getMinutes() + interval); //increment by 5 minutes
        }
        _.forEach( timeArr , (t,i)=>{
            if( t == '00:00 pm'){
                timeArr.splice(i,1,'12:00 pm');

            }
            if(t == '00:30 pm') {
                timeArr.splice(i , 1 ,'12:30 pm')
            }

        })
        return timeArr
    }

    static convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');

        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }

    static createDateArray(date: Date, minutes: number){
        const dates: Date[] = [];
        let starDate = date;
        dates.push(starDate);
        for (let i: number = 1; i <= (1440/minutes); i++) {
            if ( i !=  (1440/minutes)) {
                starDate = new Date(starDate.getTime() + minutes*60000)
            } else {
                starDate = new Date(new Date().setHours(23,59,0))
            }

            dates.push(starDate);
        }
        return dates;
    }

    static intersectFilter (first = [], ...rest) {
        rest = rest.map(array => new Set(array))
        return first.filter(e => rest.every(set => set.has(e)))
    }
    static clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] == '') {
                delete obj[propName];
            }
        }
    }

    static trapFocus(element, namespace) {
        let focusableEls = element.querySelectorAll('a[href]:not([disabled]), span[role="button"], button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        let firstFocusableEl = focusableEls[0];
        let lastFocusableEl = focusableEls[focusableEls.length - 1];
        let KEYCODE_TAB = 9;

        element.addEventListener('keydown', function(e) {
            let isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if ( e.shiftKey ) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }

        });
    }

    static formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    static isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    }

    static androidOrIOS() {
        const userAgent = navigator.userAgent;
        if(/android/i.test(userAgent)){
            return 'Android';
        }
        if(/iPad|iPhone|iPod/i.test(userAgent)){
            return 'IOS';
        }

        return 'Unknown';
    }

    static isAndroid() {
        if (this.isMobileDevice()) {
            return this.androidOrIOS() == 'Android';
        }

        return false;
    }

    static isIOS() {
        if (this.isMobileDevice()) {
            return this.androidOrIOS() == 'IOS';
        }

        return false;
    }

    static getBookingFee(additionalFees: IAdditionalFee[], bookingType: eBookingType, conversion_rate: number) {
        let feeTypeCode = this.getBookingFeeCodeFromType(bookingType);
        let fee: IBookingFee = {points: 0, price: 0, currencyCode: '', currencySymbol: ''};
        let addFees = additionalFees.filter(additionalFee => additionalFee.additional_fee_type.code == feeTypeCode);
        if (addFees.length > 0) {
            let addFee = addFees[0];
            if (addFee.is_points) {
                fee.price = addFee.amount / conversion_rate;
                fee.points = addFee.amount;
            }
            else {
                fee.price = addFee.amount;
                fee.points = addFee.amount * conversion_rate;
            }

            fee.currencyCode = addFee.currency.code;
            fee.currencySymbol = addFee.currency.symbol;
        }

        return fee;
    }

    static getBookingFeeId(additionalFees: IAdditionalFee[], bookingType: eBookingType) {
        let feeTypeCode = this.getBookingFeeCodeFromType(bookingType);

        let addFees = additionalFees.filter(additionalFee => additionalFee.additional_fee_type.code == feeTypeCode);
        if (addFees.length > 0) {
            return addFees[0].id;
        }

        return -1;
    }

    static getBookingFeeReason(additionalFees: IAdditionalFee[]) {
        let addFees = additionalFees.filter(additionalFee => additionalFee.additional_fee_type.code == 'NOFEETRAV');
        if (addFees.length > 0 && addFees[0].reasons) {
            return addFees[0].reasons;
        }

        return [];
    }

    static getBookingFeeNoReasonID(additionalFees: IAdditionalFee[]) {
        let addFees = additionalFees.filter(additionalFee => additionalFee.additional_fee_type.code == 'NOFEETRAV');
        if (addFees.length > 0) {
            return addFees[0].id;
        }

        return 0;
    }

    static getBookingFeeCodeFromType(bookingType: eBookingType) {
        let feeTypeCode = '';
        switch (bookingType) {
            case eBookingType.flight:
                feeTypeCode = 'TRAVF';
                break;
            case eBookingType.hotel:
                feeTypeCode = 'TRAVH';
                break;
            case eBookingType.car:
                feeTypeCode = 'TRAVC';
                break;
        }

        return feeTypeCode;
    }

    static formatRefundDate(refundDate){
        let date= new Date(refundDate);
        let locale = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timezoneLong = new Intl.DateTimeFormat('en-us',{timeZoneName:'long'}).format(date);
        timezoneLong = timezoneLong.split(',')[1];

        let offset = date.getTimezoneOffset();
        let offsetH = Math.floor(Math.abs(offset/ 60));
        let offsetVal = offset / 60 < 0 ? offsetH < 10 ?  '+0' + offsetH : '+' + offsetH :  offsetH < 10 ?  '-0' + offsetH : '-' + offsetH;
        let offsetMinute = Math.abs(offset) % 60; //positive number to handle cases ahead of UTC
        let offsetMinuteText = (offsetMinute < 10 ? '0' + offsetMinute : offsetMinute);
        let UTCDate;
        let options = {
            timeZone: locale,
            hour12:true,
            year: "numeric",
            month: "2-digit",
            day: "numeric",
            hour:'2-digit',
            minute:'2-digit'
        } as const;

        UTCDate = date.toLocaleString( 'en-ZA' , options);

        UTCDate = UTCDate.replace('/', '-').replace('/','-').replace(',', ' ');


        return (UTCDate).toUpperCase() + timezoneLong + (" (UTC" + offsetVal +':'+ offsetMinuteText + ")").toUpperCase();
    }

}

export interface NgbDate {
    year: number,
    day: number,
    month: number
}
export enum eBookingType {
    none,
    flight,
    hotel,
    car,
    bundle
}
