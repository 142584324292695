import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {throwError} from "rxjs";
import {IFlightSearch} from "../interfaces/flight.interface";
import {IHotelContract} from "../interfaces/hotel.interface";
import {AlertService} from "../directives/alerts/alerts.service";
import * as _ from 'lodash-es';

@Injectable()
export class HotelService {

    constructor(private router: Router,
                private http: HttpClient,
                private alertService: AlertService) {
    }

    private _lastSearchHotel: IFlightSearch;
    private _contract: IHotelContract;
    private _hotelView: IHotelContract;
    private _lastHotelSelected:any;
    private _searchHotelParams;
    private _lastCity;

    get lastSearchHotel(): any {
        return this._lastSearchHotel;
    }

    set lastSearchHotel(value: any) {
        this._lastSearchHotel = value;
    }

    get searchHotelParams() {
        return this._searchHotelParams || JSON.parse(localStorage.getItem('searchHotelParams'));
    }

    set searchHotelParams(value) {
        localStorage.setItem('searchHotelParams', JSON.stringify(value));
        this._searchHotelParams = value;
    }

    get contract(): IHotelContract {
        return this._contract;
    }

    set contract(value: IHotelContract) {
        this._contract = value;
    }

    set lastCity(value:any){
        this._lastCity = value;
    }

    get lastCity(){
        return this._lastCity

    }
    get lastHotelSelected(): any {
        return this._lastHotelSelected || JSON.parse(localStorage.getItem('lastHotelSelected'));
    }

    set lastHotelSelected(value: any) {
        localStorage.setItem('lastHotelSelected', JSON.stringify(value));
        this._lastHotelSelected = value;
    }

    getCities(str: string) {
        return this.http.post<any>('hotel/locations', {
            location: str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }).pipe(map(data => {
            let result;
            let airports = data.data.airports ? data.data.airports : [];
            let cities = data.data.cities ? data.data.cities : [];
            result = cities.concat(airports);
            this.lastCity = result;
            return result
        })).catch((err) => {
            this.alertService.addMessage('danger' , err.error.data.location[0])
            return throwError(err)
        });
    }

    getImages(hotel_id: number) {
        return this.http.post<any>('hotel/photos', {
            hotel_ids: hotel_id,
            image_size: 'small'
        }).pipe(map(data => {
            return data.data
        })).catch((err) => {
            return throwError(err)
        });
    }

    searchHotels(payload: any = this.searchHotelParams) {
        this.searchHotelParams = payload;
        payload.output_version = 3;
        let searchData = _.cloneDeep(payload);
        return this.http.post<any>('hotel/search', payload).pipe(map(data => {
            this.lastSearchHotel = _.extend(data.data, {search_data: searchData})
            return this.lastSearchHotel
        })).catch((err) => {
            return throwError(err)
        });
    }

    getHotelDetail(id: number) {
        return this.http.post<any>('hotel/detail', {
            hotel_id: id
        }).pipe(map(data => {
            this.contract = _.assign(this.contract,{hotel_detail: data.data})
            return data.data
        })).catch((err) => {
            return throwError(err)
        });
    }

    getHotelView(ppn_bundle: string) {
        return this.http.post<any>('hotel/view', {
            // ppn_bundle: ppn_bundle
            ppn_bundle: ppn_bundle,
            output_version : 3
        }).pipe(map(data => {
            if(this.lastHotelSelected){
                this.contract = _.assign(this.contract,{hotel_view: data.data,room_data: this.lastHotelSelected.room_data})
            }
            return data.data
        })).catch((err) => {
            return throwError(err)
        });
    }

}
