<div *ngIf="env.showErrorDetail && errors$.length">
  <div *ngFor="let err of errors$; let $i=index" class="alert-notification error d-flex align-items-center mb-3">
    <div class="alert-icon">
      <img width="20" alt="" src="/assets/images/icons/StatusErrorOnLight.svg">
    </div>
    <div class="pl-2 alert-msg-wrap">
      <span class="msg-title medium">{{err.message}}</span>
    </div>
    <button (click)="removeAlert($i)" type="button" class="ml-auto alert-close close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
