import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    templateUrl: './tec.component.html',
    styleUrls: ['./tec.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TecComponent{
    constructor() {
        // this.faq_type = this.faqList[0];
    }
}
