import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ItineraryService} from "../itinerary.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";

@Injectable()
export class ItineraryResolver implements Resolve<any> {

    constructor(private itineraryService: ItineraryService, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.itineraryService.getItinerary().pipe(catchError(err => {
            this.router.navigate(["/itinerary"]);
            return EMPTY;
        }));
    }
}
