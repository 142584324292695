import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'lodash-es'
import {TranslationWidth} from "@angular/common";
const I18N_VALUES = {
    en: {
        weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    fr: {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc']
    }
};


@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(private translateService: TranslateService) {
        super();
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this.translateService.currentLang].months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return I18N_VALUES[this.translateService.currentLang].weekdays[weekday - 1];
    }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (_.isObject(value)) {
            let v = <string>value;
            let date = v.split(this.DELIMITER);
            return {
                day : parseInt(date[0], 10),
                month : parseInt(date[1], 10),
                year : parseInt(date[2], 10)
            };
        }else{
            if(value.length == 10){
                let dates = value.split('/')
                let val = {
                    year: parseInt(dates[0]),
                    month: parseInt(dates[1]),
                    day: parseInt(dates[2])
                };
                return val
            }
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.year + this.DELIMITER + (date.month < 10 ? '0' + date.month : date.month) + this.DELIMITER + (date.day < 10 ? '0'+ date.day : date.day ) : '';
    }
}
