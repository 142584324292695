import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {AuthenticationService} from "../../services/authentication.service";
import {StepService} from "../step-component/step.service";
import {IBookingFee} from "../../interfaces/view.interface";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "../../services/event.service";
import {IAgentFeeEvent} from "../agent-fees/agent-fees";
import {FlightBookService} from "../../app/travel/contract/book/flight-book.service";
import {HotelBookService} from "../../app/hotel/contract/book/hotel-book.service";
import {CarBookService} from "../../app/cars/contract/book/car-book.service";
import {CartService} from "../../services/cart.service";
import utils, { eBookingType } from "../../resources/utils";
import {Router, Event, NavigationEnd} from '@angular/router';

@Component({
    selector: 'summary-agent-fees',
    templateUrl: 'summary-agent-fees.html',
    styleUrls: ['./summary-agent-fees.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SummaryAgentFees implements OnInit, OnChanges {
    @Input() bookingType: eBookingType = eBookingType.none;

    flightFees: IBookingFee = {price: 0, points:0, currencyCode: 'USD', currencySymbol: '$'};
    hotelFees: IBookingFee = {price: 0, points:0, currencyCode: 'USD', currencySymbol: '$'};
    carFees: IBookingFee = {price: 0, points:0, currencyCode: 'USD', currencySymbol: '$'};
    totals: IBookingFee = {price: 0, points:0, currencyCode: 'USD', currencySymbol: '$'};

    loginAs: boolean = false;
    showFee: boolean = false;
    size: any;

    totalPoints: number = 0;
    totalPrice: number = 0;

    internalFlightFees: IBookingFee;
    internalHotelFees: IBookingFee;
    internalCarFees: IBookingFee;
    flightFeeActive: boolean = true;
    hotelFeeActive: boolean = true;
    carFeeActive: boolean = true;

    feePaymentValue: number = 0;
    feePricePaymentEnabled: boolean = true;
    viewStep: number = 0;

    isBookTerminated: boolean = false;

    private totalCurrency: string = '';
    private totalSymbol: string = '';

    constructor(private authService: AuthenticationService,
                public stepService: StepService,
                public translateService: TranslateService,
                private eventService: EventService,
                private flightBookService: FlightBookService,
                private hotelBookService: HotelBookService,
                private carBookService: CarBookService,
                private cartService: CartService,
                private router: Router) {
        this.loginAs = authService.checkLoginAs();
        this.showFee = authService.showFee() && authService.additionalFees.length > 0
    }

    ngOnInit() {
        this.loadBookingFee();

        this.initData();

        this.attachEvents();

        this.subscribeOnRouteChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refreshData();
    }

    subscribeOnRouteChange() {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.isBookTerminated = event.url.includes('/contract/book');
            }
        });

        this.isBookTerminated = this.router.url.includes('/contract/book');
    }

    parseNumber(number) {
        return parseFloat(number.replace(/,/g, ''))
    }

    getStep() {
        if (this.bookingType == eBookingType.bundle) {
            return this.viewStep;
        }
        else {
            return this.stepService.currentStep;
        }
    }

    private initData() {
        switch (this.bookingType) {
            case eBookingType.flight:
                this.initDataSetFlight();
                break;
            case eBookingType.hotel:
                this.initDataSetHotel();
                break;
            case eBookingType.car:
                this.initDataSetCar();
                break;
            case eBookingType.bundle: {
                this.initDataSetFlight();
                this.initDataSetHotel();
                this.initDataSetCar();
                break;
            }
        }

        this.refreshData();
    }

    private initDataSetFlight() {
        if (this.flightBookService.bookData) {
            this.flightFeeActive = this.flightBookService.bookData.agentBookingFlightNoFeeReason == '';
            this.onAgentFeePaymentChange(this.flightBookService.bookData.agentBookingPaymentType == 0)
        }
    }

    private initDataSetHotel() {
        if (this.hotelBookService.bookData) {
            this.hotelFeeActive = this.hotelBookService.bookData.agentBookingHotelNoFeeReason == '';
            this.onAgentFeePaymentChange(this.hotelBookService.bookData.agentBookingPaymentType == 0)
        }
    }

    private initDataSetCar() {
        if (this.carBookService.bookData) {
            this.carFeeActive = this.carBookService.bookData.agentBookingCarNoFeeReason == '';
            this.onAgentFeePaymentChange(this.carBookService.bookData.agentBookingPaymentType == 0)
        }
    }

    attachEvents() {
        this.eventService.on('agent-fee', (value)=>{
            this.onAgentFeeChange(<IAgentFeeEvent>value);
        });

        this.eventService.on('agent-fee-payment', (value)=>{
            this.onAgentFeePaymentChange(<boolean>value);
        });

        this.eventService.on('summary-agent-view-step', (value)=>{
            this.onSummaryAgentViewStepChange(<number>value);
        });
    }

    checkPageSize() {
        return this.size = window.innerWidth;
    }

    private refreshData() {
        this.internalFlightFees = this.flightFeeActive ? this.flightFees : {points: 0, price: 0, currencyCode: 'USD', currencySymbol: '$'};
        this.internalHotelFees = this.hotelFeeActive ? this.hotelFees : {points: 0, price: 0, currencyCode: 'USD', currencySymbol: '$'};
        this.internalCarFees = this.carFeeActive ? this.carFees : {points: 0, price: 0, currencyCode: 'USD', currencySymbol: '$'};

        this.getTotalPoints();
        this.getTotalPrice();
        this.getTotalCurrency();

        if (this.feePricePaymentEnabled) {
            this.feePaymentValue = this.totalPrice;
        }
        else {
            this.feePaymentValue = this.totalPoints;
        }

        this.totals = {price: Number(this.totalPrice), points: Number(this.totalPoints),
                                    currencyCode: this.totalCurrency, currencySymbol: this.totalSymbol};
        this.eventService.emit({name: 'totals', value: this.totals});
    }

    private getTotalPoints() {
        let r = 0;
        if (this.flightFees) {
            r += this.internalFlightFees.points;
        }
        if (this.hotelFees) {
            r += this.internalHotelFees.points;
        }
        if (this.carFees) {
            r += this.internalCarFees.points;
        }

        this.totalPoints = r;
    }

    private getTotalPrice() {
        let r = 0;
        if (this.flightFees) {
            r += this.internalFlightFees.price;
        }
        if (this.hotelFees) {
            r += this.internalHotelFees.price;
        }
        if (this.carFees) {
            r += this.internalCarFees.price;
        }

        this.totalPrice = r;
    }

    private getTotalCurrency() {
        if (this.flightFeeActive) {
            this.totalCurrency = this.internalFlightFees.currencyCode;
            this.totalSymbol = this.internalFlightFees.currencySymbol;
        }
        if (this.hotelFeeActive) {
            this.totalCurrency = this.internalHotelFees.currencyCode;
            this.totalSymbol = this.internalHotelFees.currencySymbol;
        }
        if (this.carFeeActive) {
            this.totalCurrency = this.internalCarFees.currencyCode;
            this.totalSymbol = this.internalCarFees.currencySymbol;
        }
    }

    private onAgentFeeChange(value: IAgentFeeEvent) {
        switch (value.bookingType) {
            case eBookingType.flight:
                this.flightFeeActive = value.feeEnabled;
                break;
            case eBookingType.hotel:
                this.hotelFeeActive = value.feeEnabled;
                break;
            case eBookingType.car:
                this.carFeeActive = value.feeEnabled;
                break;
            default:
                return;
        }

        this.refreshData();
    }

    private onAgentFeePaymentChange(value: boolean) {
        this.feePricePaymentEnabled = value;
        this.refreshData();
    }

    private onSummaryAgentViewStepChange(value: number) {
        this.viewStep = value;
    }

    private loadBookingFee() {
        if (this.authService.isAdmin() && this.authService.additionalFees.length > 0) {
            switch (this.bookingType) {
                case eBookingType.flight:
                    this.flightFees = utils.getBookingFee(this.authService.additionalFees, eBookingType.flight, this.authService.user.conversion_rate);
                    break;
                case eBookingType.hotel:
                    this.hotelFees = utils.getBookingFee(this.authService.additionalFees, eBookingType.hotel, this.authService.user.conversion_rate);
                    break;
                case eBookingType.car:
                    this.carFees = utils.getBookingFee(this.authService.additionalFees, eBookingType.car, this.authService.user.conversion_rate);
                    break;
                case eBookingType.bundle:
                    if (this.cartService.bundle.item.includes('flight')) {
                        this.flightFees = utils.getBookingFee(this.authService.additionalFees, eBookingType.flight, this.authService.user.conversion_rate);
                    }

                    if (this.cartService.bundle.item.includes('hotel')) {
                        this.hotelFees = utils.getBookingFee(this.authService.additionalFees, eBookingType.hotel, this.authService.user.conversion_rate);
                    }

                    if (this.cartService.bundle.item.includes('car')) {
                        this.carFees = utils.getBookingFee(this.authService.additionalFees, eBookingType.car, this.authService.user.conversion_rate);
                    }
                    break;
            }
        }
    }
}
