<div class="send-itinerary-wrapper">
  <a tabindex="0" role="button" >
    <span class="icon"><em class="far fa-envelope mr-3"></em></span>
    <span translate (click)="openItineraryModal()">Email itinerary</span>
  </a>
</div>
<modal id="send-itinerary-modal" #itineraryModal>
  <div autofocus tabindex="-1" class="app-modal-header"><span class="h3" translate>Email Itinerary</span></div>
  <div class="app-modal-body">
    <form class="card-form container pt-2 pl-0" [formGroup]="itineraryForm">
      <div class="row">
        <div class="col-sm-12 form-group">
            <label for="resend-email" translate>Email Address</label>
            <input required type="email" id="resend-email" emailvalidator aria-describedby="resend-email-required resend-email-invalid" floatingLabel class="inputTravel" [ngClass]="{ 'is-invalid': email.invalid && (email.touched || submitted) }" formControlName="email" id="email">
            <div *ngIf="(email.touched || submitted) && email.invalid" class="invalid-feedback">
              <div id="resend-email-required" *ngIf="email.errors.required" translate>Email address is required</div>
              <div id="resend-email-invalid" *ngIf="email.errors.emailvalidator" translate>Email format is incorrect.</div>
            </div>
        </div>
      </div>
      <div class="row text-center send-itinerary-button">
        <div class="col-12 col-sm-6">
          <travel-button data-dismiss="modal"><span translate>Close</span></travel-button>
        </div>
        <div class="col-12 col-sm-6 send-btn">
          <travel-button [ladda]="laddaItinerary" [ariainvalid]="email.invalid" [ariadescribedby]="'resend-email-required resend-email-invalid'" (onClick)="sendItinerary()"><span translate>Send</span></travel-button>
        </div>
      </div>
    </form>
  </div>
</modal>








