<nav class="navigation d-none d-sm-block">
  <ul>
    <li class="text-center" routerLinkActive="active-page">
      <div role="link" tabindex="0" (keydown.enter)="navigateTo('/travel')" routerLink="/travel">
        <img aria-hidden="true" alt="airplane icon" width="48" src="../../assets/images/airplane_trs.svg">
        <br> <span translate>Flights</span>
      </div>
    </li>
    <li class="text-center" routerLinkActive="active-page">
      <div role="link" tabindex="0" (keydown.enter)="navigateTo('/bundle')" routerLink="/bundle">
        <img aria-hidden="true" alt="airplane icon" width="48" src="../../assets/images/airplane_trs.svg">
        <img aria-hidden="true" alt="hotel icon" width="48" src="../../assets/images/hotels.svg">
        <br> <span translate>Flight + Hotel</span>
      </div>
    </li>
    <li class="text-center" routerLinkActive="active-page">
      <div role="link" tabindex="0" (keydown.enter)="navigateTo('/hotels')" routerLink="/hotels">
        <img aria-hidden="true" alt="hotel icon" width="48" src="../../assets/images/hotels.svg">
        <br> <span translate>Hotels</span>
      </div>
    </li>
    <li class="text-center" routerLinkActive="active-page">
      <div role="link" tabindex="0" (keydown.enter)="navigateTo('/cars')" routerLink="/cars">
        <img aria-hidden="true" alt="car icon" width="48" src="../../assets/images/cars_trs.svg">
        <br> <span translate>Car Rentals</span>
      </div>
    </li>
    <li *ngIf="env.environment == 'develop' || env.environment == 'staging'" routerLinkActive="active-page" class="text-center float-right">
      <div tabindex="-1" routerLink="/itinerary">
        <img aria-hidden="true" style="visibility: hidden" alt="car icon" width="40" height="48" src="../../assets/images/suitcases.svg">
        <br> <span style="color: white" translate>My Itineraries</span>
      </div>
    </li>
  </ul>
</nav>
<div class="d-block d-sm-none small-nav">
  <div class="d-flex">
    <div class="col p-3 text-center" role="link" tabindex="0" routerLink="/travel" routerLinkActive="active-page" (keydown.enter)="navigateTo('/travel')">
      <img aria-hidden="true" alt="airplane icon" width="32" src="../../assets/images/airplane_trs.svg">
      <span class="pl-3"><strong translate>Flights</strong></span>
    </div>
    <div class="col p-3 border-left text-center" role="link" tabindex="0" routerLink="/bundle" routerLinkActive="active-page" (keydown.enter)="navigateTo('/bundle')">
      <span class="air-and-hotel">
       <img aria-hidden="true" alt="airplane icon" width="32" src="../../assets/images/airplane_trs.svg">
       <img aria-hidden="true" alt="hotel icon" width="32" src="../../assets/images/hotels.svg">
      </span>
      <span class="pl-2"><strong translate>Flight + Hotel</strong></span>
    </div>
  </div>
  <div class="d-flex">
    <div class="col p-3 text-center" role="link" tabindex="0" routerLink="/hotels" routerLinkActive="active-page" (keydown.enter)="navigateTo('/hotels')">
      <img aria-hidden="true" alt="hotel icon" width="32" src="../../assets/images/hotels.svg">
      <span class="pl-3"><strong translate>Hotels</strong></span>
    </div>
    <div class="col p-3 border-left text-center" role="link" tabindex="0" routerLink="/cars" routerLinkActive="active-page" (keydown.enter)="navigateTo('/cars')">
      <img aria-hidden="true" alt="airplane icon" width="32" src="../../assets/images/cars_trs.svg">
      <span class="pl-3"><strong translate>Car Rentals</strong></span>
    </div>
  </div>
</div>
