<div #myModal class="modal fade" tabindex="-1" role="dialog">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <ng-content select=".app-modal-header"></ng-content>
                <button type="button" class="close" data-dismiss="modal" (click)="dismiss()" [attr.aria-label]="'Close' | translate"><span aria-hidden="true"><img aria-hidden="true" alt="" src="../../assets/images/icons/close-black.svg"></span></button>
            </div>
            <div class="modal-body">
                <ng-content select=".app-modal-body"></ng-content>
            </div>
            <div class="modal-footer">
                <ng-content select=".app-modal-footer"></ng-content>
            </div>
        </div>
    </div>
</div>
