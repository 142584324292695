import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../services/authentication.service";
import {EventService} from "../../services/event.service";
import {Component, Input, OnInit} from "@angular/core";
import * as _ from 'lodash-es'

const $ = require("jquery");

@Component({
    selector: 'cancel-msg',
    styleUrls: ['./cancel-msg.component.scss'],
    templateUrl: 'cancel-msg.component.html'
})

export class CancelMessageComponent implements  OnInit{
    @Input() successMsg: boolean;
    @Input() errorMsg: boolean;
    showMsgFee:boolean=false;

    constructor(private translateService: TranslateService,
                public authService:AuthenticationService,
                private eventService:EventService
    ) {


    }

    ngOnInit(): void {

        this.showMsgFee = !_.isEmpty(this.authService.additionalFees);
    }

    closeBanner(){
        this.eventService.emit( {name:'successMessage',value:false} );
        this.eventService.emit( {name:'errorMessage',value:false} );
        this.successMsg = false;
        this.errorMsg = false;
    }

}
