import {
    AfterContentChecked,
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {CommonService} from "../../services/common.service";
import {NgbDatepickerConfig} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "../../services/event.service";
import {environment} from '../../environments/environment';

declare var gtag;
const $ = require("jquery");
const menuList = require('./menu.json');

@Component({
    templateUrl: './dashboard.component.html'
})

export class DashComponent implements OnInit, AfterViewChecked, AfterViewInit, AfterContentChecked{

    private language: string = null;
    currentMenu = null;
    routeLoading = false;
    private hsbcADV = "../../assets/images/hsbcadv.png";
    private hsbcCASH = "../../assets/images/hsbccash.png";
    private hsbcPB = "../../assets/images/hsbcpb.png";
    private hsbcPREM = "../../assets/images/hsbcprem.png";
    private hsbcPWE = "../../assets/images/hsbcpweL.png";
    private hsbcRBBI = "../../assets/images/hsbcrbbi.jpg";
    creditCard:any;
    private size: number = window.innerWidth;
    year: number = (new  Date()).getFullYear()
    menuDisplayed: boolean = false;
    profileMenu: boolean = false;
    hamburgerMenu: boolean = false;
    showNotification: boolean = false;
    showFullScreen:boolean;
    loginAsBanner: boolean = false;
    loggedInMemberName: string = '';

    @ViewChild('profilemenu') el: ElementRef;
    @ViewChild('HotelSearchComponent') openMaps;
    constructor(private router: Router,
        private route: ActivatedRoute,
        public authService: AuthenticationService,
        private translateService: TranslateService,
        private datepickerConfig: NgbDatepickerConfig,
        private cdRef: ChangeDetectorRef,
        private commonService: CommonService,
        private eventService:EventService
    ) {
        //Datepicker config
        datepickerConfig.firstDayOfWeek = 7;
        this.language = this.translateService.currentLang || localStorage.getItem('lang') || 'en';
        router.events.subscribe((val:any) => {
            if (val instanceof NavigationStart) {
                if(val.url != '/travel' && val.url != '/cars' && val.url != '/hotels' && val.url != '/bundle'){
                    this.routeLoading = true
                    window.scrollTo(0, 0)
                }
            }
            this.eventService.on('showMap', (paymentAmount)=>{
                this.showFullScreen = paymentAmount;
            });
            if (val instanceof NavigationCancel || val instanceof NavigationError) {
                this.routeLoading = false
            }

            if(val instanceof NavigationEnd){
                this.routeLoading = false

                //Analytics code
                if(environment.ga_key){
                    try {
                        gtag('config', environment.ga_key, {
                            page_path: val.urlAfterRedirects,
                        });
                    } catch (e) {

                    }
                }

                // see also
                if(val.url.split("/").length > 2){
                    commonService.setBack('');
                } else {
                    if (val.url.includes('travel') || val.url === '/' || val.url.includes('bundle')){
                        commonService.setBack('travel-background-sm')
                    } else if( val.url.includes('cars')){
                        commonService.setBack('cars-background-sm')
                    } else if( val.url.includes('hotels')) {
                        commonService.setBack('hotel-background-sm')
                    } else {
                        commonService.setBack('')
                    }
                }

                // //Check inactivity
                // if(this.authService.isLogged && localStorage.getItem('last_activity') && !val.url.includes('authentication')){
                //     let now = new Date();
                //
                //     let diff = Math.abs(now.getTime() - (new Date(localStorage.getItem('last_activity'))).getTime());
                //
                //     if(Math.floor((diff/1000)/60) > 15 ){
                //         this.authService.logout();
                //     }
                // }
                // localStorage.setItem('last_activity', (new Date()).toString())
            }
        });
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges()
    }
    formatPoints(points){
        return points.toLocaleString(this.translateService.currentLang)
    }

    redirectToPodium(url, target='_self'){
        this.authService.redirectToPodium(url,target).subscribe();
    }

    logout(){
        localStorage.clear()
        this.authService.redirectToPodium('logout','_self',false)
    }


    switchLanguage(language: string) {
        this.translateService.use(language);
        localStorage.setItem('lang',language);
        // let param = {
        //     queryParams:{}
        // };
        // if(this.router.url.includes('?')){
        //     param.queryParams = this.parseQuery(this.router.url.split('?')[1]);
        // }
        // this.redirectTo(this.router.url.split('?')[0],param)

        //TODO when we enable the above also remmeber to remake the call inside the resolve
        window.location.reload();
    }

    redirectTo(uri:string, param){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
                this.router.navigate([uri], param)
            }
        );
    }

    selectMenu(name,event) {
        event.stopPropagation();
        if (menuList[this.translateService.currentLang][name]) {
            this.currentMenu = menuList[this.translateService.currentLang][name]
        } else {
            this.currentMenu = null
        }
    }

    goTo(route,event){
        if(route == 'travel-page'){
            this.currentMenu = null
            this.router.navigate(['travel']);
        } else{
            this.redirectToPodium(route)
            event.stopPropagation();
        }
        this.closeMenu()

    }

    goBack(event) {
        event.stopPropagation();
        this.currentMenu = null
        // this.menuDisplayed = false;
    }

    closeMenu(){
        this.menuDisplayed = false;
        this.profileMenu = false
        this.hamburgerMenu = false
        $('.main-wrapper').removeClass('unscroll');
    }

    openMenu(type){
        if(type=='menu'){
            this.hamburgerMenu = true
        } else {
            this.profileMenu = true
        }
        this.menuDisplayed = true;
        $('.main-wrapper').addClass('unscroll');

        this.trapUnTrapFocus();
    }

    ngAfterViewChecked(): void {
        //enable bootstrap tooltip everywhere
        $('[data-toggle="tooltip"]').tooltip();
    }

    ngOnInit(): void {
        this.loginAsBanner = this.authService.checkLoginAs();
        if (this.loginAsBanner) {
            this.loggedInMemberName = this.authService.getMemberName();
        }

        this.checkCard();
        //every time the route change scroll to top

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

    }

    checkCard() {
        switch (this.authService.user.account_type.reference_identifier) {
            case 'HSBCPWE':
                this.creditCard = this.hsbcPWE;
                break;
            case 'HSBCPREM':
                this.creditCard = this.hsbcPREM;
                break;
            case 'HSBCADV':
                this.creditCard = this.hsbcADV;
                break;
            case 'HSBCCASH':
                this.creditCard = this.hsbcCASH;
                if(environment.showBanner){
                    this.showNotification = true;
                }
                break;
            case 'HSBCPB':
                this.creditCard = this.hsbcPB;
                break;
            case 'HSBCRBBI':
            case 'HSBCRBBC':
                this.creditCard = this.hsbcRBBI;
                break;
            default:
                this.creditCard = "../../assets/images/hsbc-rewards-cc-large.png";
                break;
        }
    }

    parseQuery(queryString) {
        let query = {};
        let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.size = window.innerWidth;
        //close profile menu if screen goes up
        if(this.size > 575){
            $(this.el.nativeElement).removeClass('show')
        }
    }


    detectBlur(e) {
        // if next focus is not 'switch accounts', then collapse the dropdown
        if (
            e.relatedTarget &&
            !e.relatedTarget.innerHTML.includes('Switch accounts')
        ) {
            $('#profile-button-toggle').dropdown('toggle');
        }
    }

    ngAfterViewInit(): void {
        $(document).on('click', '.mobile-view .dropdown-menu',  (e)=> {
            e.stopPropagation();
        });

        $('#profile-button-toggle').on('show.bs.dropdown',  ()=> {
            // do something…
        })
    }

    trapUnTrapFocus() {
        setTimeout(() => {
            let element = document.getElementById('mobilemenu');
            let focusableEls = element.querySelectorAll(
                '.selectable-element'
            );

            let firstFocusableEl: any = focusableEls[0];
            let lastFocusableEl: any = focusableEls[focusableEls.length - 1];
            const KEYCODE_TAB = 9;
            element.addEventListener('keydown', (e) => {
                focusableEls = element.querySelectorAll(
                    '.selectable-element'
                )
                firstFocusableEl = focusableEls[0];
                lastFocusableEl = focusableEls[focusableEls.length - 1];
                const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
                if (!isTabPressed) {
                    return
                }
                if (e.shiftKey) {
                    /* shift + tab */
                    if (
                        document.activeElement === firstFocusableEl
                    ) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } /* tab */ else if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault()
                }
            })

        }, 100);
    }

    // isMobile() {
    //     utils.isMobileDevice();
    // }
}
